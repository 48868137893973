import { Settings } from 'lucide-react';
import { ActionButton } from '../../../../ui/action-button';

type RightActionSectionResultProps = {
    isConfigSectionExist: boolean;
    onConfigButtonClick: () => void;
};

export function RightActionSectionResult({
    isConfigSectionExist,
    onConfigButtonClick,
}: RightActionSectionResultProps) {
    return isConfigSectionExist ? (
        <ActionButton onClick={onConfigButtonClick}>
            <Settings />
        </ActionButton>
    ) : null;
}
