import { useCallback, useEffect, useRef, useState } from 'react';

export default function useTimer(
    durationInMs: number,
    paused = false,
    tickDuration = 10,
) {
    const currentIntervalId = useRef(0);
    const startTime = useRef({
        dateReference: new Date().getTime(),
        remainingTime: durationInMs,
        currentTime: durationInMs,
    });
    const [time, setTime] = useState(durationInMs);

    const set = (time: number) => {
        if (time <= 0) {
            stopTimer();
            return;
        }
        startTime.current.currentTime = time;
        setTime(time);
    };

    const decrementTime = useCallback(() => {
        if (paused) {
            clearInterval(currentIntervalId.current);
            return;
        }
        const currentDate = new Date().getTime();
        const diffBetweenTwoDateInMs =
            currentDate - startTime.current.dateReference;
        const currentTime =
            startTime.current.remainingTime -
            Math.round(diffBetweenTwoDateInMs);
        if (currentTime <= 0) {
            stopTimer();
            return;
        }
        startTime.current.currentTime = currentTime;
        setTime(currentTime);
    }, [setTime, paused]);

    useEffect(() => {
        if (!paused) {
            startTime.current.dateReference = new Date().getTime();
            startTime.current.remainingTime = startTime.current.currentTime;
        }
    }, [paused]);

    useEffect(() => {
        currentIntervalId.current = window.setInterval(
            decrementTime,
            tickDuration,
        );

        return () => {
            clearInterval(currentIntervalId.current);
        };
    }, [decrementTime, tickDuration]);

    const stopTimer = () => {
        clearInterval(currentIntervalId.current);
        setTime(0);
        startTime.current.currentTime = 0;
    };

    return { time, set };
}
