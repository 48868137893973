import { useContext } from 'react';
import { useStore } from 'zustand';
import { EndConfirmationState } from '../exercise/states/end-confimation/end-confirmation-state';
import { EndState } from '../exercise/states/end';
import { PlayingState } from '../exercise/states/playing';
import { ConfigurationState } from '../exercise/states/configuration';
import { TutorialState } from '../exercise/states/tutorial';
import { useTranslation } from '../../../hooks/use-translation';
import { ScaleName } from '../../../services/scales/scale.type';
import { ExerciseConfigurationTitle } from '../exercise/states/configuration/exercise-configuration-title';
import { FindScalePostionExerciseContext } from './store/context';
import { Results } from './states/end/results';
import { QuestionSection } from './states/playing/question-section';
import { AnswerSection } from './states/playing/answer-section';
import { ConfigurationModal } from './states/playing/configuration-modal';
import { FindScalePositionConfig } from './types';

type StatesProps = {
    defaultConfig: FindScalePositionConfig;
    scaleName: ScaleName | null;
};

export function States({ defaultConfig, scaleName }: StatesProps) {
    const store = useContext(FindScalePostionExerciseContext);
    const thematic = useStore(store, (state) => state.thematic);
    const { t } = useTranslation();

    return (
        <>
            <ConfigurationState
                store={store}
                title={
                    <ExerciseConfigurationTitle
                        thematic={thematic}
                        lessonType="fretboard-exercise"
                        specificLabels={
                            scaleName !== null
                                ? [t(`scale.name.${scaleName}`)]
                                : [t('all-scales')]
                        }
                    >
                        {t('pages.FIND_SCALE_POSITION.title')}
                    </ExerciseConfigurationTitle>
                }
                parentPageId="SCALES"
            />
            <EndState store={store}>
                <Results scaleName={scaleName} />
            </EndState>
            <TutorialState store={store} />
            <PlayingState
                answerSection={<AnswerSection />}
                configSection={
                    <ConfigurationModal
                        defaultConfig={defaultConfig}
                        scaleName={scaleName}
                    />
                }
                isHelpAvailable
                questionSection={<QuestionSection />}
                store={store}
            />
            <EndConfirmationState store={store} />
        </>
    );
}
