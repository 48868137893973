import Emittery from 'emittery';
import {
    IWindowFocusAndBlurService,
    WindowFocusAndBlurServiceEvents,
} from './window-focus-and-blur.types';

export class WindowFocusAndBlurService
    extends Emittery<WindowFocusAndBlurServiceEvents>
    implements IWindowFocusAndBlurService
{
    constructor() {
        super();
        this.addListeners();
    }

    private addListeners() {
        window.addEventListener('focus', () => {
            this.onFocus();
        });
        window.addEventListener('blur', () => {
            this.onBlur();
        });
    }

    private onFocus() {
        this.emit('focus');
    }

    private onBlur() {
        this.emit('blur');
    }
}
