import { QuizModeSelector } from '../quiz-mode-selector';
import { QuizMode } from '../../../services/exercise/exercise.types';
import { useTranslation } from '../../../hooks/use-translation';

type QuizModeSelectorFieldProps = {
    defaultValue?: QuizMode;
    value: QuizMode;
    onChange: (value: QuizMode) => void;
};

export function QuizModeSelectorField({
    value,
    onChange,
    defaultValue,
}: QuizModeSelectorFieldProps) {
    const { t } = useTranslation();

    return (
        <div className="flex flex-col items-center gap-2">
            <label>{t('fields.quiz-mode.label.title')}</label>
            <QuizModeSelector
                appLabel={t('fields.quiz-mode.label.app')}
                defaultValue={defaultValue}
                guitarLabel={t('fields.quiz-mode.label.guitar')}
                onChange={onChange}
                value={value}
            />
        </div>
    );
}
