import { LanguageSection } from './language-section';
import { ThemeSection } from './theme-section';

export function SettingsGeneral() {
    return (
        <div className="vertical-content-distribution-base">
            <LanguageSection />
            <ThemeSection />
        </div>
    );
}
