import { FindTheNoteFromRootAndIntervalProgressStoredPayload } from '../../progress/find-the-note-from-root-and-interval/types';
import { StoredProgressEntries } from '../../progress/progress.types';
import { StoreCapacitor } from '../storage.capacitor';

export function getFindTheNoteFromRootAndIntervalProgressCapacitorStore() {
    return new StoreCapacitor<
        StoredProgressEntries<FindTheNoteFromRootAndIntervalProgressStoredPayload>
    >(
        'quiz.find-the-note-from-root-and-interval.progress',
        [],
        (value) => JSON.stringify(value),
        (value) => JSON.parse(value),
    );
}
