import { AnimatePresence } from 'framer-motion';
import { usePlayingState } from '../../../../../services/exercise/hooks/use-playing-state';
import { ExerciseStoreApi } from '../../../../../services/exercise/exercise.types';
import { BottomRightActionSectionResult } from './bottom-right-action-section-result';

type BottomRightActionSectionProps<
    Config,
    Question,
    Answer,
    ResultMeta,
    RoundMeta,
    ProgressPayload,
> = {
    store: ExerciseStoreApi<
        Config,
        Question,
        Answer,
        ResultMeta,
        RoundMeta,
        ProgressPayload
    >;
};

export function BottomRightActionSection<
    Config,
    Question,
    Answer,
    ResultMeta,
    RoundMeta,
    ProgressPayload,
>({
    store,
}: BottomRightActionSectionProps<
    Config,
    Question,
    Answer,
    ResultMeta,
    RoundMeta,
    ProgressPayload
>) {
    const { isInPlayingResultState } = usePlayingState(store);

    return (
        <AnimatePresence>
            {isInPlayingResultState ? (
                <BottomRightActionSectionResult store={store} />
            ) : null}
        </AnimatePresence>
    );
}
