import { orderBy } from 'lodash/fp';
import { Position } from '@notacami/core/fretboard';
import { NoteDetails } from '../notes/notes.types';
import { PositionWithChromaAndMidi } from './fretboard.types';
import { isPositionEqual } from './is-positions-equal';

export function findPositionByPlayedNote(
    availablePositions: PositionWithChromaAndMidi[],
    previoulyFoundPosition: Position,
    playedNoteDetails: NoteDetails,
    isPlayingDescending: boolean,
): Position | null {
    const expectedPositionWithChromaAndMidi = availablePositions.find(
        ({ position }) => isPositionEqual(previoulyFoundPosition, position),
    );

    if (expectedPositionWithChromaAndMidi === undefined) {
        throw new Error(
            `Expected position with chroma and midi not found: ${previoulyFoundPosition.toString()}`,
        );
    }

    const positionFoundInSamePlayingDirection =
        foundMatchingPositionByChromaAndPlayingDirection(
            availablePositions,
            expectedPositionWithChromaAndMidi,
            playedNoteDetails,
            isPlayingDescending,
        );

    if (positionFoundInSamePlayingDirection !== undefined) {
        return positionFoundInSamePlayingDirection;
    }

    const positionFoundInOppositePlayingDirection =
        foundMatchingPositionByChromaAndPlayingDirection(
            availablePositions,
            expectedPositionWithChromaAndMidi,
            playedNoteDetails,
            !isPlayingDescending,
        );

    if (positionFoundInOppositePlayingDirection !== undefined) {
        return positionFoundInOppositePlayingDirection;
    }

    return null;
}

function foundMatchingPositionByChromaAndPlayingDirection(
    availablePositions: PositionWithChromaAndMidi[],
    expectedPositionWithChromaAndMidi: PositionWithChromaAndMidi,
    playedNoteDetails: NoteDetails,
    isPlayingDescending: boolean,
) {
    const positionsWithMatchingChroma = availablePositions
        .filter(({ chroma }) => playedNoteDetails.chroma === chroma)
        .filter(({ midi }) =>
            isPlayingDescending
                ? midi > expectedPositionWithChromaAndMidi.midi
                : midi < expectedPositionWithChromaAndMidi.midi,
        )
        .map((availablePosition) => ({
            availablePosition,
            midi: availablePosition.midi,
        }));

    const positionsWithMatchingChromaSortedByMidiDiff = orderBy(
        'midi',
        isPlayingDescending ? 'asc' : 'desc',
        positionsWithMatchingChroma,
    );

    if (positionsWithMatchingChromaSortedByMidiDiff.length !== 0) {
        return positionsWithMatchingChromaSortedByMidiDiff[0].availablePosition
            .position;
    }
}
