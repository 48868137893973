import { useEffect, useRef, useState } from 'react';

export function useTemporaryVariable<T>(
    delayBeforeCleanup: number,
    cleanupTick = 100,
) {
    const intervalIdsRef = useRef<number>();
    const [variablesWithTime, setVariablesWithTime] = useState<
        [time: number, value: T][]
    >([]);

    const cleanUpVariable = () => {
        setVariablesWithTime((state) =>
            state.filter((variable) => new Date().getTime() < variable[0]),
        );
    };

    const addVariable = (value: T) => {
        setVariablesWithTime((state) => [
            ...state,
            [new Date().getTime() + delayBeforeCleanup, value],
        ]);
    };

    useEffect(() => {
        if (variablesWithTime.length !== 0) {
            intervalIdsRef.current = window.setInterval(
                cleanUpVariable,
                cleanupTick,
            );
        }
        return () => {
            if (intervalIdsRef.current) {
                window.clearInterval(intervalIdsRef.current);
            }
        };
    }, [variablesWithTime, cleanupTick]);

    return {
        addVariable,
        variables: variablesWithTime.map(
            (variableWithTime) => variableWithTime[1],
        ),
    };
}
