import { Note } from 'tonal';
import { Interval } from '../intervals/intervals.constants';
import { NoteName } from './notes.types';

export function getNoteFromRootAndInterval(
    root: NoteName,
    interval: Interval,
): NoteName {
    const note = Note.transpose(root, interval);

    return note;
}
