import { DividerWithTitle } from '@notacami/ui';
import { DeviceSection } from './device-section';
import { PersonaSection } from './persona-section';

export function ConfigurationSection() {
    return (
        <div className="vertical-content-distribution-base">
            <DividerWithTitle
                title="Configuration"
                tagName="h2"
                titleLevel={3}
            />
            <DeviceSection />
            <PersonaSection />
        </div>
    );
}
