import { MicOff } from 'lucide-react';
import { useTranslation } from '../../hooks/use-translation';

export function MicUnsupportedContent() {
    const { t } = useTranslation();
    return (
        <div className="vertical-content-distribution-base items-center">
            <MicOff
                strokeWidth={1.6}
                className="w-12 h-12 stroke-neutral-600 dark:stroke-neutral-300"
            />
            <p className="max-w-xs text-center">
                {t('tutorial.mic-tutorial.unsupported-mic-step.description')}
            </p>
        </div>
    );
}
