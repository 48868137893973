import { cn } from '@notacami/core/css';
import { StringSelectorItem } from './string-selector-item';

type StringSelectorProps = {
    leftHanded: boolean;
    onChange: (stringIndex: number) => void;
    strings: SelectableStringInput[];
};

export type SelectableStringInput = {
    id: string;
    index: number;
    label: string;
    octave: number;
    selected: boolean;
    stringNumber: number;
    disabled: boolean;
};

export function StringSelector({
    leftHanded,
    onChange,
    strings,
}: StringSelectorProps) {
    return (
        <ul
            className={cn(
                'relative flex gap-2 px-1 border-2 border-neutral-300 dark:border-neutral-600 rounded-lg mt-5',
                leftHanded && 'flex-row-reverse',
            )}
        >
            {strings.map((string) => (
                <StringSelectorItem
                    id={string.id}
                    key={string.id}
                    label={string.label}
                    octave={string.octave}
                    onChange={onChange}
                    selected={string.selected}
                    stringNumber={string.stringNumber}
                    stringIndex={string.index}
                    disabled={string.disabled}
                />
            ))}
        </ul>
    );
}
