import { useStore } from 'zustand';
import { AnimatePresence } from 'framer-motion';
import { ExerciseStoreApi } from '../../../../../services/exercise/exercise.types';
import { useResult } from '../../../../../services/exercise/hooks/use-result';
import { usePlayingState } from '../../../../../services/exercise/hooks/use-playing-state';
import { QuestionScoringResult } from './question-scoring-result';

type QuestionSectionGenericResultProps<
    Config,
    Question,
    Answer,
    ResultMeta,
    RoundMeta,
    ProgressPayload,
> = {
    store: ExerciseStoreApi<
        Config,
        Question,
        Answer,
        ResultMeta,
        RoundMeta,
        ProgressPayload
    >;
};

export function QuestionSectionGenericResult<
    Config,
    Question,
    Answer,
    ResultMeta,
    RoundMeta,
    ProgressPayload,
>({
    store,
}: QuestionSectionGenericResultProps<
    Config,
    Question,
    Answer,
    ResultMeta,
    RoundMeta,
    ProgressPayload
>) {
    const result = useResult(store);
    const thematic = useStore(store, (state) => state.thematic);

    const { isInPlayingResultState } = usePlayingState(store);

    return (
        <div className="absolute right-0 bottom-0">
            <AnimatePresence>
                {isInPlayingResultState && result?.isCorrect ? (
                    <QuestionScoringResult thematic={thematic} />
                ) : null}
            </AnimatePresence>
        </div>
    );
}
