import { Fretboard } from '../../../services/fretboard';
import { ScaleName } from '../../../services/scales/scale.type';
import { TuningInfo } from '../../../services/tuning/tuning.types';
import { getSelectableStringsFromTuning } from '../exercise/states/configuration/configuration.utils';
import { getConfigDataByMaybeScaleName } from './get-config-data-by-maybe-scale-name';
import { FindScalePositionConfig } from './types';

export function getDefaultConfig(
    tuningInfo: TuningInfo,
    fretboard: Fretboard,
    scaleName: ScaleName | null,
): FindScalePositionConfig {
    return {
        selectableStrings: getSelectableStringsFromTuning(tuningInfo.notes),
        tuningInfo,
        fretboard,
        ...getConfigDataByMaybeScaleName(scaleName),
    };
}
