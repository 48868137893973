import { useCallback, useContext, useEffect } from 'react';
import { Position } from '@notacami/core/fretboard';
import { GuitarNeck } from '../../../guitar-neck/guitar-neck';
import { useGuitarNeckVertical } from '../../../../../hooks/use-guitar-neck-vertical';
import { useFindTheIntervalOnTheNeckStore } from '../../store/hook';
import { FindTheIntervalOnTheNeckAnswer } from '../../types';
import { useQuestion } from '../../../../../services/exercise/hooks/use-question';
import { FindTheIntervalOnTheNeckExerciseContext } from '../../store/context';
import {
    DEFAULT_FRET_LENGTH,
    findPositionByPlayedNote,
    isPositionEqual,
    useLeftHanded,
} from '../../../../../services/fretboard';
import { usePreferencesStore } from '../../../preferences/use-preferences-context';
import { useTemporaryVariable } from '../../../../../hooks/use-temporary-variable';
import { NoteDetails } from '../../../../../services/notes/notes.types';
import { ServicesContext } from '../../../../../services/services.context';
import { AnswerSectionPlayCell } from './answer-section-play-cell';

export function AnswerSectionPlay() {
    const { notePlayed } = useContext(ServicesContext);
    const isVertical = useGuitarNeckVertical();
    const leftHanded = useLeftHanded();
    const fretboard = usePreferencesStore((state) => state.fretboard);

    const { addVariable, variables: wrongAnswerPositions } =
        useTemporaryVariable<Position>(2000);
    const config = useFindTheIntervalOnTheNeckStore((state) => state.config);
    const quizMode = useFindTheIntervalOnTheNeckStore(
        (state) => state.quizMode,
    );

    const addError = useFindTheIntervalOnTheNeckStore(
        (state) => state.addError,
    );
    const submitAnswer = useFindTheIntervalOnTheNeckStore(
        (state) => state.submitAnswer,
    );

    const store = useContext(FindTheIntervalOnTheNeckExerciseContext);
    const question = useQuestion(store);

    const givenNoteChroma = question.question.expectedNoteChroma;
    const givenRootPosition = question.question.givenRootPosition;
    const selectablePositionsWithInterval =
        question.question.selectablePositionsWithInterval;
    const correctAnswer = question.correctAnswer;

    const submitResponse = useCallback(
        (position: FindTheIntervalOnTheNeckAnswer) => {
            if (!isPositionEqual(position, correctAnswer)) {
                handleError(position);
            } else {
                submitAnswer(position);
            }
        },
        [submitAnswer],
    );

    const handleError = (errorPosition: Position) => {
        addError(errorPosition);
        addVariable(errorPosition);
    };

    const submitResponseInGuitarMode = useCallback(() => {
        submitResponse(question.correctAnswer);
    }, [question, submitResponse]);

    const handleNoteChange = useCallback(
        ({ noteDetails }: { noteDetails: NoteDetails }) => {
            if (noteDetails.chroma === givenNoteChroma) {
                submitResponseInGuitarMode();
            } else {
                const position = findPositionByPlayedNote(
                    question.question.noteChromaAndMidiPositions,
                    givenRootPosition,
                    noteDetails,
                    true,
                );
                if (position !== null) {
                    addVariable(position);
                    addError(position);
                }
            }
        },
        [submitResponseInGuitarMode, givenNoteChroma],
    );

    useEffect(() => {
        notePlayed.on('changed', handleNoteChange);

        return () => {
            notePlayed.off('changed', handleNoteChange);
        };
    }, [handleNoteChange]);

    const handleSelect = (position: Position) => {
        submitResponse(position);
    };

    return (
        <GuitarNeck
            numberOfFrets={DEFAULT_FRET_LENGTH}
            tuning={config.tuningInfo.notes}
            isVertical={isVertical}
            buildCellsComponent={AnswerSectionPlayCell}
            leftHanded={leftHanded}
            additionalCellProps={{
                fretboard,
                givenRootPosition,
                onSelect: handleSelect,
                quizMode,
                selectablePositionsWithInterval,
                wrongAnswerPositions,
                correctAnswer,
            }}
        />
    );
}
