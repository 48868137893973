import { useContext } from 'react';
import { useStore } from 'zustand';
import { sleep } from '@notacami/core/testing';
import { LessonContext } from '../../../services/lesson';
import { useTryToResumeOrPrompt } from '../../../hooks/use-try-to-resume-or-prompt';
import { Consumers } from '../../../services/pitch-detection-consumer/consumers';
import { MicError } from '../../../services/pitch-detection';
import { useMicErrorDialogStore } from '../mic-error-dialog.store';
import { LessonIntroduction } from './main-steps/introduction/lesson-introduction';
import { LessonSteps } from './main-steps/steps/lesson-steps';
import { EndConfirmationState } from './main-steps/end-confimation/end-confirmation-state';
import { EndSummaryState } from './main-steps/end-summary/end-summary-state';
import { LessonMicOnboarding } from './main-steps/mic-onboarding/lesson-mic-onboarding';

export function LessonMainSteps() {
    const store = useContext(LessonContext);

    const mainStep = useStore(store, (state) => state.mainStep);
    const goToMicOnboarding = useStore(
        store,
        (state) => state.goToMicOnboarding,
    );
    const derivedLessonInfo = useStore(
        store,
        (state) => state.derivedLessonInfo,
    );
    const lessonLanguage = useStore(store, (state) => state.lessonLanguage);
    const isInMicOnboarding = useStore(
        store,
        (state) => state.isInMicOnboarding,
    );
    const shouldListen = useStore(
        store,
        (state) =>
            state.derivedLessonInfo?.steps.some(
                (step) => step.type === 'teacher-listening',
            ) || false,
    );
    const goToSteps = useStore(store, (state) => state.goToSteps);

    const openMicErrorDialog = useMicErrorDialogStore(
        (state) => state.openDialog,
    );

    const handleResumeFail = (micError: MicError) => {
        openMicErrorDialog(micError);
    };

    const handleResumeSucceed = () => {
        goToSteps();
    };

    const handleNeedToPrompt = () => {
        goToMicOnboarding();
    };

    const { execute, isPending } = useTryToResumeOrPrompt({
        consumerId: Consumers.LESSON_INTRODUCTION_TRY,
        needToPrompt: handleNeedToPrompt,
        resumeSucceed: handleResumeSucceed,
        resumeFail: handleResumeFail,
    });

    const handleStartClick = async () => {
        await sleep(100);

        if (shouldListen) {
            execute();
        } else {
            goToSteps();
        }
    };

    return derivedLessonInfo && lessonLanguage ? (
        <>
            {mainStep === 'introduction' ? (
                <LessonIntroduction
                    mainThematic={derivedLessonInfo.mainThematic}
                    name={derivedLessonInfo.name}
                    currentLanguage={lessonLanguage}
                    estimatedDuration={derivedLessonInfo.estimatedDuration}
                    description={derivedLessonInfo.description}
                    lessonType={derivedLessonInfo.lessonType}
                    disableStartButton={derivedLessonInfo.steps.length === 0}
                    onStartClick={handleStartClick}
                    isPending={isPending}
                />
            ) : null}
            <LessonMicOnboarding />
            {mainStep === 'steps' ||
            (mainStep === 'end-confirmation' && !isInMicOnboarding) ? (
                <LessonSteps />
            ) : null}
            {mainStep === 'end-summary' ? <EndSummaryState /> : null}
            <EndConfirmationState />
        </>
    ) : null;
}
