import { SyntheticEvent, useContext } from 'react';
import { FormFieldContainer } from '@notacami/ui';
import { TuningId } from '@notacami/core/tuning';
import { TUNINGS_LIST } from '../../../../services/tuning/tuning.constants';
import { getTuningSummary } from '../../../../services/tuning/get-tuning-summary';
import { usePreferencesStore } from '../../preferences/use-preferences-context';
import { useTranslation } from '../../../../hooks/use-translation';
import { ServicesContext } from '../../../../services/services.context';

export function TuningSection() {
    const { preferenceTuningStore } = useContext(ServicesContext);
    const { t } = useTranslation();
    const changeTuning = usePreferencesStore((state) => state.setTuning);
    const tuning = usePreferencesStore((state) => state.tuningInfo);

    const handleChange = (event: SyntheticEvent<HTMLSelectElement>) => {
        const selectedTuningId = event.currentTarget.value as TuningId;
        changeTuning(selectedTuningId);
        preferenceTuningStore.set(selectedTuningId);
    };

    return (
        <FormFieldContainer
            id="tuning-setting"
            label={t('pages.settings.tuning.title')}
            input={
                <select
                    name="tuning-setting"
                    id="tuning-setting"
                    onChange={handleChange}
                    value={tuning.id}
                    className="select w-36"
                >
                    {TUNINGS_LIST.map((group) => {
                        return (
                            <optgroup
                                label={t(
                                    `pages.settings.tuning.group.${group.name}`,
                                )}
                                key={group.name}
                            >
                                {group.tunings.map((tuning) => (
                                    <option value={tuning.id} key={tuning.id}>
                                        {tuning.name} :{' '}
                                        {getTuningSummary(tuning.notes)}
                                    </option>
                                ))}
                            </optgroup>
                        );
                    })}
                </select>
            }
        />
    );
}
