import { NoteName } from 'tonal';
import { getRandomElement } from '../../utils/get-random-element';
import { NOTES_WITH_ALTERED_NOTES, UNALTERED_NOTES } from './notes.constants';

export function getRandomNote(
    withAlteredNotes: boolean,
    excludedNote?: NoteName,
): NoteName {
    const notes = withAlteredNotes ? NOTES_WITH_ALTERED_NOTES : UNALTERED_NOTES;

    const filteredNotes = notes.filter((note) => excludedNote !== note);

    return getRandomElement(filteredNotes);
}
