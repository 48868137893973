import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { TuningId } from '@notacami/core/tuning';
import { EventInPart } from '../../../../../../services/composer';
import {
    FretboardNoteDetails,
    useLeftHanded,
} from '../../../../../../services/fretboard';
import { useGuitarNeckVertical } from '../../../../../../hooks/use-guitar-neck-vertical';
import { getTuningInfo } from '../../../../../../services/tuning/get-tuning-info';
import { ServicesContext } from '../../../../../../services/services.context';
import { GuitarNeck } from '../../../../guitar-neck/guitar-neck';
import { ListeningStep } from '../../../../../../services/teacher';
import { StepTeacherListeningNeckCell } from './step-teacher-listening-neck-cell';

type StepTeacherListeningProps = {
    eventsInPart: EventInPart[];
    fretboardNoteDetails: FretboardNoteDetails;
    fretLength: number;
    tuningId: TuningId;
};

export function StepTeacherListening({
    eventsInPart,
    fretboardNoteDetails,
    fretLength,
    tuningId,
}: StepTeacherListeningProps) {
    const { teacher } = useContext(ServicesContext);
    const [currentListeningStep, setCurrentListeningStep] =
        useState<ListeningStep | null>(null);

    const isVertical = useGuitarNeckVertical();
    const leftHanded = useLeftHanded();
    const tuningInfo = getTuningInfo(tuningId);

    const handleStepToListenUpdated = useCallback(
        (listeningStep: ListeningStep) => {
            setCurrentListeningStep(listeningStep);
        },
        [],
    );

    const notesToDisplay = useMemo(() => {
        if (currentListeningStep === null) {
            return [];
        }
        const noteStartEvents = eventsInPart.filter(
            (event) => event.type === 'note-start',
        );
        const noteId =
            currentListeningStep.notes[0].noteIdInPartNoteStartEvents;
        const noteStartEvent = noteStartEvents.find(
            (event) => event.id === noteId,
        );
        if (noteStartEvent === undefined) {
            return [];
        }
        const groupId = noteStartEvent.groupId;
        return noteStartEvents.filter((event) => event.groupId === groupId);
    }, [currentListeningStep, eventsInPart]);

    const notesToPlayPositions = useMemo(() => {
        if (currentListeningStep === null) {
            return [];
        }
        const noteStartEvents = eventsInPart.filter(
            (event) => event.type === 'note-start',
        );
        const noteIds = currentListeningStep.notes.map(
            (note) => note.noteIdInPartNoteStartEvents,
        );
        return noteStartEvents
            .filter((event) => noteIds.includes(event.id))
            .map((event) => event.position);
    }, [currentListeningStep, eventsInPart]);

    useEffect(() => {
        teacher.on('step-to-listen-updated', handleStepToListenUpdated);
        teacher.startListening(eventsInPart);

        return () => {
            teacher.off('step-to-listen-updated', handleStepToListenUpdated);
            teacher.stopListening();
        };
    }, [handleStepToListenUpdated]);

    return (
        <GuitarNeck
            leftHanded={leftHanded}
            isVertical={isVertical}
            numberOfFrets={fretLength}
            tuning={tuningInfo.notes}
            buildCellsComponent={StepTeacherListeningNeckCell}
            additionalCellProps={{
                fretboardNoteDetails,
                notesToDisplay: notesToDisplay,
                notesToPlayPositions: notesToPlayPositions,
            }}
        />
    );
}
