import { DetailedHTMLProps, HTMLAttributes } from 'react';
import { Title } from '../title';

type H3Props = DetailedHTMLProps<
    HTMLAttributes<HTMLHeadingElement>,
    HTMLHeadingElement
>;

export function H3({ children }: H3Props) {
    return (
        <Title tagName="h3" level={3} className="mt-8 mb-2">
            {children}
        </Title>
    );
}
