import { Controller, useForm } from 'react-hook-form';
import {
    FindScalePositionConfig,
    SelectableScaleName,
    SelectableScalePositionType,
} from '../../types';
import { useFindScalePositionStore } from '../../store/hook';
import { MultipleStringSelectorField } from '../../../../ui/form/multiple-string-selector-field';
import {
    ScalePositionType,
    useLeftHanded,
} from '../../../../../services/fretboard';
import { useTranslation } from '../../../../../hooks/use-translation';
import { useSafeAreaInsets } from '../../../../../hooks/use-safe-area-insets';
import {
    Drawer,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
} from '../../../../ui/drawer';
import { RestoreDefaultConfigButton } from '../../../exercise/states/playing/restore-default-config-button';
import { MultiSelectField } from '../../../../ui/form/multi-select-field';
import { MultiSelectOption } from '../../../../ui/multi-select';
import { ScaleName } from '../../../../../services/scales/scale.type';
import { ConfigurationModalSubmitButton } from './configuration-modal-submit-button';

type ConfigurationModalProps = {
    defaultConfig: FindScalePositionConfig;
    scaleName: ScaleName | null;
};

export function ConfigurationModal({
    defaultConfig,
    scaleName,
}: ConfigurationModalProps) {
    const { t } = useTranslation();
    const leftHanded = useLeftHanded();
    const insets = useSafeAreaInsets();

    const config = useFindScalePositionStore((state) => state.config);
    const mainStep = useFindScalePositionStore((state) => state.mainStep);
    const thematic = useFindScalePositionStore((state) => state.thematic);
    const updateConfig = useFindScalePositionStore(
        (state) => state.updateConfig,
    );
    const closeConfig = useFindScalePositionStore((state) => state.closeConfig);
    const skipQuestion = useFindScalePositionStore(
        (state) => state.skipQuestion,
    );
    const resumeQuiz = useFindScalePositionStore((state) => state.resumeQuiz);

    const {
        control,
        handleSubmit,
        formState: { isDirty },
        reset,
    } = useForm<FindScalePositionConfig>({
        defaultValues: config,
    });

    const onSubmit = (data: FindScalePositionConfig) => {
        updateConfig(data);

        if (isDirty) {
            skipQuestion();
        }
        closeConfig();

        reset(data);
    };

    const handleRestoreDefaultConfigClick = () => {
        reset(defaultConfig, { keepDefaultValues: true });
    };

    const handleOpenChange = (value: boolean) => {
        if (!value) {
            reset();
            resumeQuiz();
            closeConfig();
        }
    };

    return (
        <Drawer open={mainStep === 'config'} onOpenChange={handleOpenChange}>
            <DrawerContent insets={insets}>
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="relative z-20"
                >
                    <DrawerHeader>
                        <div className="vertical-content-distribution-base">
                            <Controller
                                name="selectableStrings"
                                control={control}
                                render={({ field }) => (
                                    <MultipleStringSelectorField
                                        leftHanded={leftHanded}
                                        onChange={field.onChange}
                                        strings={field.value}
                                    />
                                )}
                            />
                            <Controller
                                name="scalePositionTypes"
                                control={control}
                                render={({ field }) => {
                                    const handleChange = (
                                        value: MultiSelectOption<ScalePositionType>[],
                                    ) => {
                                        const transformedValue: SelectableScalePositionType[] =
                                            value.map(({ checked, value }) => ({
                                                selected: checked,
                                                type: value,
                                            }));
                                        field.onChange(transformedValue);
                                    };
                                    return (
                                        <MultiSelectField
                                            label={t(
                                                'fields.scale-position-types.label',
                                            )}
                                            onChange={handleChange}
                                            value={field.value.map(
                                                ({ type, selected }) => ({
                                                    checked: selected,
                                                    value: type,
                                                    label: t(
                                                        `scale-position-type.${type}`,
                                                    ),
                                                }),
                                            )}
                                        />
                                    );
                                }}
                            />
                            {scaleName === null ? (
                                <Controller
                                    name="scaleNames"
                                    control={control}
                                    render={({ field }) => {
                                        const handleChange = (
                                            value: MultiSelectOption<ScaleName>[],
                                        ) => {
                                            const transformedValue: SelectableScaleName[] =
                                                value.map(
                                                    ({ checked, value }) => ({
                                                        selected: checked,
                                                        name: value,
                                                    }),
                                                );
                                            field.onChange(transformedValue);
                                        };
                                        return (
                                            <MultiSelectField
                                                label={t('fields.scales.label')}
                                                onChange={handleChange}
                                                value={field.value.map(
                                                    ({ name, selected }) => ({
                                                        checked: selected,
                                                        value: name,
                                                        label: t(
                                                            `scale.name.${name}`,
                                                        ),
                                                    }),
                                                )}
                                            />
                                        );
                                    }}
                                />
                            ) : null}
                        </div>
                    </DrawerHeader>
                    <DrawerFooter>
                        <RestoreDefaultConfigButton
                            control={control}
                            defaultConfig={defaultConfig}
                            onClick={handleRestoreDefaultConfigClick}
                        />
                        <ConfigurationModalSubmitButton
                            control={control}
                            thematic={thematic}
                        />
                    </DrawerFooter>
                </form>
            </DrawerContent>
        </Drawer>
    );
}
