import { Position } from '@notacami/core/fretboard';
import { Fretboard, isPositionEqual } from '../../../../../services/fretboard';
import { BuildCellsComponentProps } from '../../../guitar-neck/guitar-neck.types';
import { NeckCellStatus } from '../../../neck-cells/neck-cell-status';
import { NeckCellInterval } from '../../../neck-cells/neck-cell-interval';

type AnswerSectionResultCellProps = BuildCellsComponentProps & {
    fretboard: Fretboard;
    correctAnswer: Position;
    givenRootPosition: Position;
};

export function AnswerSectionResultCell({
    position,
    isOpenString,
    getElementByPosition,
    isVertical,
    fretboard,
    correctAnswer,
    givenRootPosition,
}: AnswerSectionResultCellProps) {
    const fretboardNote = getElementByPosition(fretboard.noteDetails);

    if (isPositionEqual(correctAnswer, position)) {
        return (
            <NeckCellStatus
                isOpenString={isOpenString}
                isVertical={isVertical}
                label={fretboardNote.pitchClass}
                status="success"
            />
        );
    }

    if (isPositionEqual(givenRootPosition, position)) {
        return (
            <NeckCellInterval
                isOpenString={isOpenString}
                isVertical={isVertical}
                notePitchClass={fretboardNote.pitchClass}
                interval="1P"
                position={position}
            />
        );
    }

    return null;
}
