import { useStore } from 'zustand';
import { AnimatePresence } from 'framer-motion';
import { usePlayingState } from '../../../../../services/exercise/hooks/use-playing-state';
import { ExerciseStoreApi } from '../../../../../services/exercise/exercise.types';
import { BottomLeftActionSectionHelp } from './bottom-left-action-section-help';

type BottomLeftActionSectionProps<
    Config,
    Question,
    Answer,
    ResultMeta,
    RoundMeta,
    ProgressPayload,
> = {
    store: ExerciseStoreApi<
        Config,
        Question,
        Answer,
        ResultMeta,
        RoundMeta,
        ProgressPayload
    >;
};

export function BottomLeftActionSection<
    Config,
    Question,
    Answer,
    ResultMeta,
    RoundMeta,
    ProgressPayload,
>({
    store,
}: BottomLeftActionSectionProps<
    Config,
    Question,
    Answer,
    ResultMeta,
    RoundMeta,
    ProgressPayload
>) {
    const { isInPlayingPlayState } = usePlayingState(store);
    const answerTipText = useStore(store, (state) => state.answerTipText);
    const thematic = useStore(store, (state) => state.thematic);

    return (
        <AnimatePresence>
            {isInPlayingPlayState ? (
                <BottomLeftActionSectionHelp
                    answerTipText={answerTipText}
                    thematic={thematic}
                />
            ) : null}
        </AnimatePresence>
    );
}
