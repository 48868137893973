import { ReactNode } from 'react';
import { TabBar } from '../ui/tab-bar';
import { Toaster } from '../ui/toaster';

type Props = {
    children: ReactNode;
};

export function RootLayout({ children }: Props) {
    return (
        <>
            {children}
            <TabBar />
            <Toaster />
        </>
    );
}
