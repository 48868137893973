import { createContext, PropsWithChildren, useRef } from 'react';
import {
    PersonaProps,
    PersonaStore,
    createPersonaStore,
} from './persona.store';

type PreferenceProviderProps = PropsWithChildren<PersonaProps>;

export const PersonaContext = createContext<PersonaStore>({} as PersonaStore);

export function PersonaProvider({
    children,
    ...props
}: PreferenceProviderProps) {
    const store = useRef(createPersonaStore(props)).current;

    return (
        <PersonaContext.Provider value={store}>
            {children}
        </PersonaContext.Provider>
    );
}
