export const MARKERS = [
    {
        level: -60,
        status: 'good' as const,
        text: '-60',
    },
    {
        level: -40,
        status: 'good' as const,
        text: '',
    },
    {
        level: -28,
        status: 'good' as const,
        text: '',
    },
    {
        level: -20,
        status: 'good' as const,
        text: '-20',
    },
    {
        level: -16,
        status: 'good' as const,
        text: '',
    },
    {
        level: -12,
        status: 'good' as const,
        text: '',
    },
    {
        level: -8,
        status: 'good' as const,
        text: '-8',
    },
    {
        level: -4,
        status: 'good' as const,
        text: '',
    },
    {
        level: -2,
        status: 'good' as const,
        text: '',
    },
    {
        level: 0,
        status: 'good' as const,
        text: '0',
    },
    {
        level: 2,
        status: 'high' as const,
        text: '',
    },
    {
        level: 4,
        status: 'high' as const,
        text: '4',
    },
    {
        level: 6,
        status: 'peak' as const,
        text: '',
    },
    {
        level: 8,
        status: 'peak' as const,
        text: '8',
    },
];
