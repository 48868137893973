import { LessonId, LESSONS } from '@notacami/core/lesson';
import { AppLanguage } from '@notacami/core/i18n';
import { LessonProvider } from '../../../services/lesson';
import { LessonMainSteps } from './lesson-main-steps';

type LessonProps = {
    lessonId: LessonId;
    lessonLanguage: AppLanguage;
};

export function Lesson({ lessonId, lessonLanguage }: LessonProps) {
    const lessonInfo = LESSONS[lessonId];

    return (
        <LessonProvider lessonInfo={lessonInfo} lessonLanguage={lessonLanguage}>
            <LessonMainSteps />
        </LessonProvider>
    );
}
