import { forwardRef } from 'react';
import { Button } from '@notacami/ui';
import { MicDeniedPermissionContent } from '../mic-denied-permission-content';
import { TutorialStepContent } from '../tutorial-step-content';
import { usePlatform } from '../../../hooks/use-platform';
import { useTranslation } from '../../../hooks/use-translation';

type PermissionDeniedStepProps = {
    abortTutorial: () => void;
};

export const PermissionDeniedStep = forwardRef<
    HTMLDivElement,
    PermissionDeniedStepProps
>(({ abortTutorial }, ref) => {
    const { t } = useTranslation();
    const { platform } = usePlatform();

    return platform !== undefined ? (
        <TutorialStepContent
            ref={ref}
            content={<MicDeniedPermissionContent platform={platform} />}
            action={
                <Button onClick={abortTutorial}>{t('button.i-get-it')}</Button>
            }
        />
    ) : null;
});

PermissionDeniedStep.displayName = 'PermissionDeniedStep';
