export function drawGraph(
    buffer: Float32Array,
    range: { start: number; end: number },
    canvas: {
        context: CanvasRenderingContext2D;
        width: number;
        height: number;
    },
    bufferLength: number,
) {
    const WIDTH = canvas.width;
    const HEIGHT = canvas.height;

    const ctx = canvas.context;

    let x = 0;

    const realSize = bufferLength / 2;
    const rangeSize = range.end - range.start;
    const startIndex = Math.round((range.start / 100) * realSize);
    const nbIndexOnRange = Math.round((rangeSize / 100) * realSize);

    const largeurBarre = WIDTH / nbIndexOnRange;

    for (let i = startIndex; i < nbIndexOnRange + startIndex; i++) {
        const barHeight = (buffer[i] + 140) * 3;
        ctx.fillStyle = `rgb(255, ${barHeight + 20},50)`;
        ctx.fillRect(x, HEIGHT - barHeight, largeurBarre, barHeight);
        x += largeurBarre;
    }
}
