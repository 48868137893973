import { cn } from '@notacami/core/css';

type LogoTextProps = {
    className?: string;
    type?: 'default' | 'monochrome' | 'on-primary';
};

export function LogoText({ className, type = 'default' }: LogoTextProps) {
    return (
        <span
            className={cn(
                'font-semibold text-color-title',
                type === 'on-primary' && 'text-lighter',
                className,
            )}
        >
            <span
                className={cn(
                    'text-transparent bg-clip-text bg-gradient-to-r',
                    type === 'default' && [
                        'to-indigo-400 from-indigo-700',
                        'dark:to-indigo-600 dark:from-indigo-400',
                    ],
                    type === 'on-primary' && 'to-indigo-200 from-indigo-100',
                    type === 'monochrome' && [
                        'to-neutral-500/80 via-neutral-500 from-neutral-600',
                        'dark:to-neutral-500 dark:via-neutral-400 dark:from-neutral-200',
                    ],
                )}
            >
                nota
            </span>
            camí
        </span>
    );
}
