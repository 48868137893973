import { Note } from 'tonal';

export function getNoteListChroma(
    notes: string[],
): { chroma: number; notePitchClass: string }[] {
    return notes.map((note) => {
        const chroma = Note.chroma(note);
        const notePitchClass = Note.pitchClass(note);
        return {
            chroma,
            notePitchClass,
        };
    });
}
