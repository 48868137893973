import Emittery from 'emittery';
import { getContext, start } from 'tone';

type AppAudioContextEvents = {
    initialize: undefined;
};

export class AppAudioContext extends Emittery<AppAudioContextEvents> {
    public audioContext: AudioContext;
    private clickHandler: EventListener;
    private focusHandler: EventListener;
    private blurHandler: EventListener;

    constructor() {
        super();

        this.clickHandler = this.initialize.bind(this);
        this.focusHandler = this.resumeContextWhenSuspended.bind(this);
        this.blurHandler = this.suspendContextWhenRunning.bind(this);

        if (typeof window !== 'undefined') {
            window.addEventListener('click', this.clickHandler);
            window.addEventListener('focus', this.focusHandler);
            window.addEventListener('blur', this.blurHandler, false);
        }
    }

    public async initialize() {
        window.removeEventListener('click', this.clickHandler);

        await start();
        this.audioContext = getContext().rawContext as AudioContext;
        this.emit('initialize');
    }

    private async resumeContextWhenSuspended() {
        if (this.audioContext && this.audioContext.state === 'suspended') {
            window.addEventListener('click', this.clickHandler);
        }
    }

    private suspendContextWhenRunning() {
        if (this.audioContext && this.audioContext.state === 'running') {
            this.audioContext.suspend();
        }
    }
}
