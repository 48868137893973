type State = {
    wayStepIndex: number;
    isWayBack: boolean;
};

type Action =
    | {
          type: 'RESET';
      }
    | {
          type: 'GO_WAY_BACK';
      }
    | {
          type: 'GO_NEXT_STEP';
      };

export const INITIAL_STATE: State = {
    wayStepIndex: 0,
    isWayBack: false,
};

export function reducer(state: State, action: Action): State {
    switch (action.type) {
        case 'RESET':
            return {
                wayStepIndex: 0,
                isWayBack: false,
            };
        case 'GO_WAY_BACK':
            return {
                wayStepIndex: 0,
                isWayBack: true,
            };

        case 'GO_NEXT_STEP':
            return {
                ...state,
                wayStepIndex: state.wayStepIndex + 1,
            };

        default:
            return state;
    }
}
