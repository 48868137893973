import { Position } from '@notacami/core/fretboard';
import {
    aggregateIntervalPositionAndDistance,
    Fretboard,
    getFretboardDistancesByPosition,
    getFretboardMiddlePositionBetweenTwoPositions,
    Segment,
} from '../fretboard';
import { getFretboardPositiveIntervalsByPosition } from '../fretboard-interval';
import { getAvailablePathsForSegment } from './get-available-paths-for-segment';
import { getBetterSegmentIndex } from './get-better-segment-index';
import { getFilteredIntervalsPositionForSegmentComputation } from './get-filtered-intervals-position-for-segment-computation';
import { getSegmentScore } from './get-segment-score';

export function computeSegment(
    fretboard: Fretboard,
    scaleIntervals: string[],
    startPosition: Position,
    endPosition: Position,
    maxFretDiffByString: number,
    maxFretDiffBySegment: number,
): Segment | null {
    const positiveIntervalsPosition = getFretboardPositiveIntervalsByPosition(
        fretboard,
        startPosition,
        scaleIntervals,
    );

    const positionBetweenUnissonAndOctave =
        getFretboardMiddlePositionBetweenTwoPositions(
            endPosition,
            startPosition,
        );

    const fretboardDistances = getFretboardDistancesByPosition(
        fretboard,
        positionBetweenUnissonAndOctave,
    );

    const aggregates = aggregateIntervalPositionAndDistance(
        fretboard,
        positiveIntervalsPosition,
        fretboardDistances,
    );

    const scaleIntervalsPosition =
        getFilteredIntervalsPositionForSegmentComputation(
            aggregates,
            scaleIntervals,
            startPosition,
            endPosition,
        );

    const availablePaths = getAvailablePathsForSegment(
        scaleIntervalsPosition,
        scaleIntervals,
    );

    const pathsScore = availablePaths.map((path, pathIndex) =>
        getSegmentScore(
            pathIndex,
            path,
            maxFretDiffByString,
            maxFretDiffBySegment,
        ),
    );

    const betterPathIndex = getBetterSegmentIndex(pathsScore);

    if (betterPathIndex !== null) {
        const betterSegment = availablePaths[betterPathIndex].map(
            ({ position, interval }) => ({ position, interval }),
        );

        return betterSegment;
    }

    return null;
}
