import { Position } from '@notacami/core/fretboard';
import { ArrowPosition } from '../../components/business/neck-cells/highlight-position-cell.constants';
import { getFretBoundariesByPositions } from './get-fret-boundaries-by-positions';
import { getStringBoundariesByPositions } from './get-string-boundaries-by-positions';

export function getArrowPositionTowardsMiddleOfPositionsByPosition(
    positions: Position[],
    position: Position,
): ArrowPosition {
    const stringBoundaries = getStringBoundariesByPositions(positions);
    const fretBoundaries = getFretBoundariesByPositions(positions);

    const stringDiff = stringBoundaries.max - stringBoundaries.min;
    const fretDiff = fretBoundaries.max - fretBoundaries.min;

    const middleString = stringBoundaries.min + stringDiff / 2;
    const middleFret = fretBoundaries.min + fretDiff / 2;

    if (position[0] > middleString) {
        if (position[1] > middleFret) {
            return ArrowPosition.TOP_RIGHT;
        }
        if (position[1] < middleFret) {
            return ArrowPosition.TOP_LEFT;
        }
        return ArrowPosition.TOP;
    }

    if (position[0] < middleString) {
        if (position[1] > middleFret) {
            return ArrowPosition.BOTTOM_RIGHT;
        }
        if (position[1] < middleFret) {
            return ArrowPosition.BOTTOM_LEFT;
        }
        return ArrowPosition.BOTTOM;
    }

    if (position[1] > middleFret) {
        return ArrowPosition.RIGHT;
    }
    if (position[1] < middleFret) {
        return ArrowPosition.LEFT;
    }
    return ArrowPosition.BOTTOM;
}
