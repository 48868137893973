import { TuningId } from '@notacami/core/tuning';
import { IStore } from '../storage/storage.type';
import {
    StoredProgressEntries,
    StoredProgressEntryOutcome,
} from './progress.types';
import { AbstractProgressEntry } from './abstract-progress-entry';
import { ProgressSource } from './progress.source';

export class ProgressRepository<Payload, StoredPayload>
    implements ProgressSource<Payload, StoredPayload>
{
    constructor(
        private readonly progressStore: IStore<
            StoredProgressEntries<StoredPayload>
        >,
        private readonly fromStoreEntry: (
            entry: StoredProgressEntryOutcome<StoredPayload>,
        ) => AbstractProgressEntry<Payload, StoredPayload>,
    ) {}

    public async saveRecordDataByTuningId(
        tuningId: TuningId,
        sessionPracticeTime: number,
        entries: AbstractProgressEntry<Payload, StoredPayload>[],
    ): Promise<void> {
        const storedProgress = await this.progressStore.get();

        const entriesByTuningIndex = storedProgress.findIndex(
            (entriesByTuning) => entriesByTuning['0'] === tuningId,
        );

        const formatedEntries = entries.map((entry) => entry.toStoreOutcome());

        if (entriesByTuningIndex === -1) {
            await this.progressStore.set([
                ...storedProgress,
                [tuningId, sessionPracticeTime, formatedEntries],
            ]);
        } else {
            await this.progressStore.set(
                storedProgress.map((entriesByTuning, index) => {
                    return index !== entriesByTuningIndex
                        ? entriesByTuning
                        : [
                              entriesByTuning[0],
                              entriesByTuning[1] + sessionPracticeTime,
                              formatedEntries,
                          ];
                }),
            );
        }
    }

    public async deleteRecordByTuningId(tuningId: TuningId): Promise<void> {
        const storedProgress = await this.progressStore.get();

        const entriesByTuningIndex = storedProgress.findIndex(
            (entriesByTuning) => entriesByTuning['0'] === tuningId,
        );

        if (entriesByTuningIndex !== -1) {
            await this.progressStore.set(
                storedProgress.filter(
                    (entriesByTuning) => entriesByTuning['0'] !== tuningId,
                ),
            );
        }
    }

    public async getProgressDataByTuningId(tuningId: TuningId) {
        const storedProgress = await this.progressStore.get();

        const foundRecordByTuning = storedProgress.find(
            (recordsByTuning) => recordsByTuning[0] === tuningId,
        );

        const foundEntries = foundRecordByTuning?.[2] || [];
        const foundPracticeTime = foundRecordByTuning?.[1] || 0;
        const convertedEntries = foundEntries.map((foundEntry) =>
            this.fromStoreEntry(foundEntry).toOutcome(),
        );

        return {
            tuningId,
            entries: convertedEntries,
            practiceTime: foundPracticeTime,
        };
    }

    public async getRecordEntriesByTuningId(tuningId: TuningId) {
        const storedProgress = await this.progressStore.get();

        const foundRecordByTuning = storedProgress.find(
            (recordsByTuning) => recordsByTuning[0] === tuningId,
        );

        const foundEntries = foundRecordByTuning?.[2] || [];
        const convertedEntries = foundEntries.map((foundEntry) =>
            this.fromStoreEntry(foundEntry),
        );

        return convertedEntries;
    }
}
