import { ScalePositionType } from './fretboard.types';

export const MAX_FRET_DIFF_BY_STRING = 4;

export const MAX_FRET_DIFF_BY_SEGMENT = 5;

export const MAX_FRET_DIFF_FOR_PRECOMPUTE_INTERVALS = 8;

export const DEFAULT_FRET_LENGTH = 12;

export const SMALL_FRET_LENGTH = 6;

export const SCALE_POSITION_TYPE_ORDER: ScalePositionType[] = [
    'diagonal',
    'compact',
    'other',
];
