import { SCALE_POSITION_TYPE_ORDER, ScalePosition } from '../fretboard';

export function getScalePositionsOrderedByScaleType(
    scalePositions: ScalePosition[],
): ScalePosition[] {
    return SCALE_POSITION_TYPE_ORDER.reduce<ScalePosition[]>(
        (scalePositionsAccumulator, currentType) => [
            ...scalePositionsAccumulator,
            ...scalePositions.filter(({ type }) => type === currentType),
        ],
        [],
    );
}
