import { Note } from 'tonal';
import { NoteDetails } from './notes.types';

export function getNoteDetails(noteName: string): NoteDetails {
    const noteInfo = Note.get(noteName);
    return {
        name: noteName,
        pitchClass: noteInfo.pc,
        chroma: noteInfo.chroma ?? -1,
        oct: noteInfo.oct ?? -1,
        midi: noteInfo.midi ?? -1,
    };
}
