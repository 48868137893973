// from https://stringjoy.com/alternate-tunings/
// and https://www.fender.com/online-ukulele-tuner

import { TuningItem } from './tuning.types';

const UKULELE_TUNINGS: TuningItem[] = [
    {
        id: 'ukulele-standard',
        name: 'Standard',
        notes: ['g4', 'c4', 'e4', 'a4'],
    },
];

const BASS_TUNINGS: TuningItem[] = [
    { id: 'bass-standard', name: 'Standard', notes: ['e1', 'a1', 'd2', 'g2'] },
    { id: 'bass-drop-d', name: 'Drop D', notes: ['d1', 'a1', 'd2', 'g2'] },
];

const GUITAR_TUNINGS: TuningItem[] = [
    {
        id: 'guitar-standard',
        name: 'Standard',
        notes: ['e2', 'a2', 'd3', 'g3', 'b3', 'e4'],
    },
    {
        id: 'guitar-drop-d',
        name: 'Drop D',
        notes: ['d2', 'a2', 'd3', 'g3', 'b3', 'e4'],
    },
    {
        id: 'guitar-d-sharp-standard',
        name: 'D# Standard',
        notes: ['d#2', 'g#2', 'c#3', 'f#3', 'a#3', 'd#4'],
    },
    {
        id: 'guitar-d-standard',
        name: 'D Standard',
        notes: ['d2', 'g2', 'c3', 'f3', 'a3', 'd4'],
    },
    {
        id: 'guitar-c-sharp-standard',
        name: 'C# Standard',
        notes: ['c#2', 'f#2', 'b2', 'e3', 'g#3', 'c#4'],
    },
    {
        id: 'guitar-double-drop-d',
        name: 'Double Drop D',
        notes: ['d2', 'a2', 'd3', 'g3', 'b3', 'd4'],
    },
    {
        id: 'guitar-drop-c',
        name: 'Drop C',
        notes: ['c2', 'g2', 'c3', 'f3', 'a3', 'd4'],
    },
    {
        id: 'guitar-drop-c-sharp',
        name: 'Drop C#',
        notes: ['c#2', 'g#2', 'c#3', 'f#3', 'a#3', 'd#4'],
    },
    {
        id: 'guitar-drop-b',
        name: 'Drop B',
        notes: ['b1', 'f#2', 'b2', 'e3', 'g#3', 'c#4'],
    },
    {
        id: 'guitar-drop-a-sharp',
        name: 'Drop A#',
        notes: ['a#1', 'f2', 'a#2', 'd#3', 'g3', 'c4'],
    },
    {
        id: 'guitar-drop-a',
        name: 'Drop A',
        notes: ['a1', 'e2', 'a2', 'd3', 'f#3', 'b3'],
    },
    {
        id: 'guitar-open-d',
        name: 'Open D',
        notes: ['d2', 'a2', 'd3', 'f#3', 'a3', 'd4'],
    },
    {
        id: 'guitar-open-d-minor',
        name: 'Open D Minor',
        notes: ['d2', 'a2', 'd3', 'f3', 'a3', 'd4'],
    },
    {
        id: 'guitar-open-g',
        name: 'Open G',
        notes: ['d2', 'g2', 'd3', 'g3', 'b3', 'd4'],
    },
    {
        id: 'guitar-open-g-minor',
        name: 'Open G Minor',
        notes: ['d2', 'g2', 'd3', 'g3', 'a#3', 'd4'],
    },
    {
        id: 'guitar-open-c',
        name: 'Open C',
        notes: ['c2', 'g2', 'c3', 'g3', 'c4', 'e4'],
    },
    {
        id: 'guitar-open-c-sharp',
        name: 'Open C#',
        notes: ['c#2', 'f#2', 'b3', 'e3', 'g#3', 'c#4'],
    },
    {
        id: 'guitar-open-c-minor',
        name: 'Open C Minor',
        notes: ['c2', 'g2', 'c3', 'g3', 'c4', 'd#4'],
    },
    {
        id: 'guitar-open-e7',
        name: 'Open E7',
        notes: ['e2', 'g#2', 'd3', 'e3', 'b3', 'e4'],
    },
    {
        id: 'guitar-open-e-minor7',
        name: 'Open E Minor7',
        notes: ['e2', 'b2', 'd3', 'g3', 'b3', 'e4'],
    },
    {
        id: 'guitar-open-g-major7',
        name: 'Open G Major7',
        notes: ['d2', 'g2', 'd3', 'f#3', 'b3', 'd4'],
    },
    {
        id: 'guitar-open-a-minor',
        name: 'Open A Minor',
        notes: ['e2', 'a2', 'e3', 'a3', 'c4', 'e4'],
    },
    {
        id: 'guitar-open-a-minor7',
        name: 'Open A Minor7',
        notes: ['e2', 'a2', 'e3', 'g3', 'c4', 'e4'],
    },
    {
        id: 'guitar-open-e',
        name: 'Open E',
        notes: ['e2', 'b2', 'e3', 'g#3', 'b3', 'e4'],
    },
    {
        id: 'guitar-open-a',
        name: 'Open A',
        notes: ['e2', 'a2', 'c#3', 'e3', 'a3', 'e4'],
    },
    {
        id: 'guitar-c-tuning',
        name: 'C Tuning',
        notes: ['c2', 'f2', 'a#2', 'd#3', 'g3', 'c4'],
    },
    {
        id: 'guitar-c-sharp-tuning',
        name: 'C# Tuning',
        notes: ['c#2', 'f#2', 'b2', 'e3', 'g#3', 'c#4'],
    },
    {
        id: 'guitar-b-flat-tuning',
        name: 'Bb Tuning',
        notes: ['a#1', 'd#2', 'g#2', 'c#3', 'f3', 'a#3'],
    },
    {
        id: 'guitar-a-standard',
        name: 'A Standard',
        notes: ['a1', 'd2', 'g2', 'c3', 'e3', 'a3'],
    },
    {
        id: 'guitar-dadddd',
        name: 'D A D D D D',
        notes: ['d2', 'a2', 'd3', 'd3', 'd4', 'd4'],
    },
    {
        id: 'guitar-cgdgbd',
        name: 'C G D G B D',
        notes: ['c2', 'g2', 'd3', 'g3', 'b3', 'd4'],
    },
    {
        id: 'guitar-cgdgbe',
        name: 'C G D G B E',
        notes: ['c2', 'g2', 'd3', 'g3', 'b3', 'e4'],
    },
    {
        id: 'guitar-dadead',
        name: 'D A D E A D',
        notes: ['d2', 'a2', 'd3', 'e3', 'a3', 'd4'],
    },
    {
        id: 'guitar-dgdgad',
        name: 'D G D G A D',
        notes: ['d2', 'g2', 'd3', 'g3', 'a3', 'd4'],
    },
    {
        id: 'guitar-open-d-sus2',
        name: 'Open Dsus2',
        notes: ['d2', 'a2', 'd3', 'g3', 'a3', 'd4'],
    },
    {
        id: 'guitar-open-g-sus2',
        name: 'Open Gsus2',
        notes: ['d2', 'g2', 'd3', 'g3', 'c4', 'd4'],
    },
    {
        id: 'guitar-g6',
        name: 'G6',
        notes: ['d2', 'g2', 'd3', 'g3', 'b3', 'e4'],
    },
    {
        id: 'guitar-modal-g',
        name: 'Modal G',
        notes: ['d2', 'g2', 'd3', 'g3', 'c4', 'd4'],
    },
    {
        id: 'guitar-overtone',
        name: 'Overtone',
        notes: ['c3', 'e3', 'g3', 'a#3', 'c4', 'd4'],
    },
    {
        id: 'guitar-pentatonic',
        name: 'Pentatonic',
        notes: ['a2', 'c3', 'd3', 'e3', 'g3', 'a4'],
    },
    {
        id: 'guitar-minor-third',
        name: 'Minor Third',
        notes: ['c3', 'd#3', 'f#3', 'a3', 'c4', 'd#4'],
    },
    {
        id: 'guitar-major-third',
        name: 'Major Third',
        notes: ['c3', 'e3', 'g#3', 'c4', 'e4', 'g#4'],
    },
    {
        id: 'guitar-all-fourths',
        name: 'All Fourths',
        notes: ['e2', 'a2', 'd3', 'g3', 'c4', 'f4'],
    },
    {
        id: 'guitar-augmented-fourths',
        name: 'Augmented Fourths',
        notes: ['c2', 'f#2', 'c3', 'f#3', 'c4', 'f#4'],
    },
    {
        id: 'guitar-slow-motion',
        name: 'Slow Motion',
        notes: ['d2', 'g2', 'd3', 'f3', 'c4', 'd4'],
    },
    {
        id: 'guitar-admiral',
        name: 'Admiral',
        notes: ['c2', 'g2', 'd3', 'g3', 'b3', 'c4'],
    },
    {
        id: 'guitar-buzzard',
        name: 'Buzzard',
        notes: ['c2', 'f2', 'c3', 'g3', 'a#3', 'f4'],
    },
    {
        id: 'guitar-face',
        name: 'Face',
        notes: ['c2', 'g2', 'd3', 'g3', 'a3', 'd4'],
    },
    {
        id: 'guitar-four-and-twenty',
        name: 'Four and Twenty',
        notes: ['d2', 'a2', 'd3', 'd3', 'a3', 'd4'],
    },
    {
        id: 'guitar-ostrich',
        name: 'Ostrich',
        notes: ['d2', 'd3', 'd3', 'd3', 'd4', 'd4'],
    },
    {
        id: 'guitar-capo-300',
        name: 'Capo 300',
        notes: ['c2', 'g2', 'd3', 'd#3', 'd4', 'd#4'],
    },
    {
        id: 'guitar-balalaika',
        name: 'Balalaika',
        notes: ['e2', 'a2', 'd3', 'e3', 'e3', 'a3'],
    },
    {
        id: 'guitar-charango',
        name: 'Charango',
        notes: ['g2', 'c3', 'e3', 'a3', 'e4'],
    },
    {
        id: 'guitar-cittern-one',
        name: 'Cittern One',
        notes: ['c2', 'f2', 'c3', 'g3', 'c4', 'd4'],
    },
    {
        id: 'guitar-cittern-two',
        name: 'Cittern Two',
        notes: ['c2', 'g2', 'c3', 'g3', 'c4', 'g4'],
    },
    {
        id: 'guitar-dobro',
        name: 'Dobro',
        notes: ['g2', 'b2', 'd3', 'g3', 'b3', 'd4'],
    },
    {
        id: 'guitar-lefty',
        name: 'Lefty',
        notes: ['e4', 'b3', 'g3', 'd3', 'a2', 'e2'],
    },
    {
        id: 'guitar-mandoguitar',
        name: 'Mandoguitar',
        notes: ['c2', 'g2', 'd3', 'a3', 'e4', 'b4'],
    },
    {
        id: 'guitar-rusty-cage',
        name: 'Rusty Cage',
        notes: ['b1', 'a2', 'd3', 'g3', 'b3', 'e4'],
    },
];

export const TUNINGS = [...GUITAR_TUNINGS, ...BASS_TUNINGS, ...UKULELE_TUNINGS];

export const TUNINGS_LIST = [
    {
        name: 'guitar' as const,
        tunings: [GUITAR_TUNINGS[0], GUITAR_TUNINGS[1]],
    },
    { name: 'bass' as const, tunings: BASS_TUNINGS },
    { name: 'ukulele' as const, tunings: UKULELE_TUNINGS },
];
