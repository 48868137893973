import { SyntheticEvent, useContext } from 'react';
import { FormFieldContainer } from '@notacami/ui';
import { ThemePreference } from '../../../../utils/theme.types';
import { useTranslation } from '../../../../hooks/use-translation';
import { usePreferencesStore } from '../../preferences/use-preferences-context';
import { ServicesContext } from '../../../../services/services.context';

export function ThemeSection() {
    const { t } = useTranslation();
    const { preferenceThemeStore } = useContext(ServicesContext);
    const themePreference = usePreferencesStore(
        (state) => state.themePreference,
    );
    const setTheme = usePreferencesStore((state) => state.setThemePreference);

    const handleSelectChange = async (
        event: SyntheticEvent<HTMLSelectElement>,
    ) => {
        const selectedThemePreference = event.currentTarget
            .value as ThemePreference;

        await preferenceThemeStore.set(selectedThemePreference);
        setTheme(selectedThemePreference);
    };

    return (
        <FormFieldContainer
            id="theme-setting"
            label={t('pages.settings.general.appearence.title')}
            input={
                <select
                    name="theme-setting"
                    id="theme-setting"
                    defaultValue={themePreference}
                    onChange={handleSelectChange}
                    className="select w-48"
                >
                    <option value="system">
                        {t('pages.settings.general.appearence.option.system')}
                    </option>
                    <option value="light">
                        {t('pages.settings.general.appearence.option.light')}
                    </option>
                    <option value="dark">
                        {t('pages.settings.general.appearence.option.dark')}
                    </option>
                </select>
            }
        />
    );
}
