import { ScaleType } from 'tonal';
import { AVAILABLE_SCALE_NAMES } from './scales.constants';
import { ScaleName } from './scale.type';

function nameIsScaleName(name: string): name is ScaleName {
    return AVAILABLE_SCALE_NAMES.includes(name as ScaleName);
}

// https://back2guitar.com/principales-gammes-guitare/
export function getScaleList(): ScaleName[] {
    return ScaleType.names().filter(nameIsScaleName);
}
