import { motion } from 'framer-motion';
import { forwardRef } from 'react';
import { cn } from '@notacami/core/css';
import {
    getClassnamesByColorVariant,
    getColorVariantByThematic,
} from '@notacami/core/design';
import { Thematic } from '@notacami/core/thematic';
import { useTranslation } from '../../../../../hooks/use-translation';

type QuestionScoringResultProps = {
    thematic: Thematic;
};

export const QuestionScoringResult = forwardRef<
    HTMLDivElement,
    QuestionScoringResultProps
>(({ thematic }, ref) => {
    const { t } = useTranslation();

    const colorVariant = getColorVariantByThematic(thematic);
    const classNames = getClassnamesByColorVariant(colorVariant);

    return (
        <motion.div
            ref={ref}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className={cn(
                'relative px-2 text-xs font-semibold whitespace-nowrap',
                classNames.background,
                classNames.text,
                'rounded-full h-6',
                'flex items-center',
            )}
        >
            {t(`components.question-scoring-result.well-done`)}
        </motion.div>
    );
});
QuestionScoringResult.displayName = 'QuestionScoringResult';
