import { Fragment, ReactNode, useState } from 'react';
import { Link } from 'react-router-dom';
import { getArrowColor } from '@notacami/core/design';
import { motion } from 'framer-motion';
import { cn } from '@notacami/core/css';
import { ConditionalWrapper } from '@notacami/core/components';
import { ArrowRight, ChevronRight } from 'lucide-react';
import { Surface, SpinLoader } from '@notacami/ui';

type NavigationButtonContainerProps = {
    children: ReactNode;
    className?: string;
    onClick?: () => void;
    to?: string;
    withChevron?: boolean;
    withLoading?: boolean;
};

export function NavigationButtonContainer({
    children,
    className,
    onClick,
    to,
    withChevron,
    withLoading,
}: NavigationButtonContainerProps) {
    const [itemClicked, setItemClicked] = useState(false);

    const handleClick = () => {
        onClick && onClick();
        setItemClicked(true);
    };

    return (
        <ConditionalWrapper
            items={[
                {
                    condition: to !== undefined,
                    wrapper: (children, index) =>
                        to !== undefined ? (
                            <Link
                                key={index}
                                className={className}
                                to={to}
                                onClick={handleClick}
                            >
                                {children}
                            </Link>
                        ) : (
                            <Fragment key={index}>{children}</Fragment>
                        ),
                },
                {
                    condition: to === undefined && onClick !== undefined,
                    wrapper: (children, index) => (
                        <button
                            key={index}
                            className={className}
                            onClick={handleClick}
                        >
                            {children}
                        </button>
                    ),
                },
                {
                    condition: to === undefined && onClick === undefined,
                    wrapper: (children) => (
                        <div className={className}>{children}</div>
                    ),
                },
            ]}
        >
            <Surface
                surfaceId="app"
                className="p-4 gap-3 rounded-xl regular-border flex items-center justify-between"
            >
                <div className="gap-3 flex items-center justify-start leading-tight">
                    {children}
                </div>
                {(to !== undefined || onClick !== undefined) &&
                !withLoading &&
                !withChevron ? (
                    <motion.div animate={{ x: itemClicked ? 30 : 0 }}>
                        <ArrowRight
                            className={cn(getArrowColor(), 'h-7 w-7')}
                        />
                    </motion.div>
                ) : null}
                {(to !== undefined || onClick !== undefined) &&
                !withLoading &&
                withChevron ? (
                    <ChevronRight className={cn(getArrowColor(), 'h-7 w-7')} />
                ) : null}
                {(to !== undefined || onClick !== undefined) &&
                withLoading &&
                !withChevron ? (
                    <SpinLoader className={cn(getArrowColor(), 'h-7 w-7')} />
                ) : null}
            </Surface>
        </ConditionalWrapper>
    );
}
