import { uniqWith } from 'lodash/fp';
import { Position } from '@notacami/core/fretboard';
import { Fretboard, isPositionEqual, ScalePosition } from '../fretboard';
import { getScalePositionSegmentsAcrossEntireNeckHeight } from './get-scale-position-segments-across-entire-neck-height';
import { getScalePositionsSegmentsBySegments } from './get-scale-positions-segments-by-segments';
import { getScalePositionType } from './get-scale-position-type';
import { getScalePositionsOrderedByScaleType } from './get-scale-positions-orderer-by-scale-type';

export function getScalePositionsAcrossEntireNeckHeight(
    fretboard: Fretboard,
    scaleIntervals: string[],
    position: Position,
    maxFretDiffByString: number,
    maxFretDiffBySegment: number,
): ScalePosition[] {
    const segments = getScalePositionSegmentsAcrossEntireNeckHeight(
        fretboard,
        scaleIntervals,
        position,
        maxFretDiffByString,
        maxFretDiffBySegment,
    );

    const scalePositionsSegments =
        getScalePositionsSegmentsBySegments(segments);

    const flattedScalePositions = scalePositionsSegments.map((segments) =>
        segments.flat(),
    );

    const scalePositionsWithoutDuplicatePosition = flattedScalePositions.map(
        uniqWith((positionWithInterval1, positionWithInterval2) =>
            isPositionEqual(
                positionWithInterval1.position,
                positionWithInterval2.position,
            ),
        ),
    );

    const scalePositions = scalePositionsWithoutDuplicatePosition.map(
        (positions) => ({
            type: getScalePositionType(positions),
            positions,
        }),
    );

    return getScalePositionsOrderedByScaleType(scalePositions);
}
