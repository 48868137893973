import { CornerDownLeft } from 'lucide-react';
import { Link } from 'react-router-dom';
import { Title } from '@notacami/ui';
import { useTranslation } from '../../hooks/use-translation';
import { PageUrls } from '../../utils/routes.contants';
import { useHideTabBar } from '../../components/ui/tab-bar.hooks';
import { PageLayout } from '../../components/business/page-layout';
import { Settings } from '../../components/business/settings/settings';

export function SettingsPage() {
    useHideTabBar();
    const { t } = useTranslation();

    return (
        <PageLayout
            title={
                <Title tagName="h1" level={2}>
                    {t('pages.settings.title')}
                </Title>
            }
            leftAction={
                <Link to={PageUrls.HOME}>
                    <CornerDownLeft size={30} />
                </Link>
            }
            content={<Settings />}
        />
    );
}
