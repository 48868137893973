import { Switch } from '../switch';

type SwitchFieldProps = {
    checked: boolean;
    disabled?: boolean;
    onChange: (checked: boolean) => void;
    label: string;
    name: string;
};

export function SwitchField({
    checked,
    disabled = false,
    onChange,
    label,
    name,
}: SwitchFieldProps) {
    return (
        <div className="flex justify-between gap-4 items-center h-full">
            <label className="text-left" htmlFor={name}>
                {label}
            </label>
            <Switch
                id={name}
                name={name}
                checked={checked}
                onCheckedChange={onChange}
                disabled={disabled}
            />
        </div>
    );
}
