import { SegmentScore } from '../fretboard';

export function getBetterSegmentIndex(
    segmentsScore: SegmentScore[],
): number | null {
    const sortedSegmentsScoreByAscendingDistance = [...segmentsScore].sort(
        (firstSegment, secondSegment) =>
            firstSegment.sumOfPositionsDistanceFromMiddleOfSegment -
            secondSegment.sumOfPositionsDistanceFromMiddleOfSegment,
    );

    const segmentWithCorrectFretsDiff =
        sortedSegmentsScoreByAscendingDistance.find(
            (segment) =>
                segment.isCorrectFretDiffForEveryString &&
                segment.isCorrectSegmentFretDiff &&
                segment.areStringIndexesAreConsecutive,
        );

    if (segmentWithCorrectFretsDiff !== undefined) {
        return segmentWithCorrectFretsDiff.segmentIndex;
    }

    return null;
}
