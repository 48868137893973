import { AnchorHTMLAttributes, DetailedHTMLProps } from 'react';
import { Button } from '../button';

type EmProps = DetailedHTMLProps<
    AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
>;

export function A(props: EmProps) {
    return (
        <Button variant="link" asChild className="p-0" colorVariant="indigo">
            <a {...props}>{props.children}</a>
        </Button>
    );
}
