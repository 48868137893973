import { Thematic } from '@notacami/core/thematic';
import { getRoutesByThematic } from '../../utils/routes.utils';
import { useTranslation } from '../../hooks/use-translation';
import { NavigationButtonContainer } from '../ui/navigation-button-container';
import { ThematicDot } from './thematic-dot';

type ThematicItemProps = { thematic: Thematic };

export function ThematicItem({ thematic }: ThematicItemProps) {
    const { t } = useTranslation();

    return (
        <li>
            <NavigationButtonContainer to={getRoutesByThematic(thematic)}>
                <div className="flex gap-3 items-center text-color-title">
                    <ThematicDot thematic={thematic} />
                    <span>{t(`thematic.${thematic}`)}</span>
                </div>
            </NavigationButtonContainer>
        </li>
    );
}
