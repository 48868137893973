import { Surface } from '@notacami/ui';
import { ProgressData } from '../../services/progress/progress.types';
import { formatIntervalsProgressData } from '../../services/progress/format-intervals-progress-data';
import { useTranslation } from '../../hooks/use-translation';
import { ProgressBar } from './progress-bar';
import { ProgressPraticeTime } from './progress-practice-time';

type FindTheIntervalOnTheNeckProgressProps = {
    intervals: string[] | null;
    currentProgressData: ProgressData<{
        interval: string;
    }>;
    previousProgressData: ProgressData<{
        interval: string;
    }>;
};

export function FindTheIntervalOnTheNeckProgress({
    intervals,
    currentProgressData,
    previousProgressData,
}: FindTheIntervalOnTheNeckProgressProps) {
    const { t } = useTranslation();

    const progressData = formatIntervalsProgressData(
        intervals,
        currentProgressData,
        previousProgressData,
    );

    return (
        <div className="relative flex min-h-full justify-center items-center w-full vertical-content-distribution-lg">
            <Surface
                surfaceId="light"
                className="flex justify-center items-center w-full p-4 rounded-xl"
            >
                <ProgressPraticeTime
                    fromInMs={previousProgressData.practiceTime}
                    toInMs={currentProgressData.practiceTime}
                    className="w-full"
                />
            </Surface>
            <div className="vertical-content-distribution-base w-full">
                {progressData.updatedProgressData.map((entry, index) => {
                    return (
                        <ProgressBar
                            key={entry.semitones}
                            staggerIndex={index}
                            title={entry.intervals.join(' / ')}
                            label={t('tab-header.number-of-repetition')}
                            min={0}
                            max={10}
                            from={entry.previousSumOfCorrectAnswer}
                            to={entry.currentSumOfCorrectAnswer}
                        />
                    );
                })}
            </div>

            <div className="vertical-content-distribution-base w-full">
                {progressData.sameProgressData.map((entry, index) => {
                    return (
                        <ProgressBar
                            key={entry.semitones}
                            staggerIndex={
                                progressData.updatedProgressData.length + index
                            }
                            title={entry.intervals.join(' / ')}
                            label={t('tab-header.number-of-repetition')}
                            min={0}
                            max={10}
                            from={entry.previousSumOfCorrectAnswer}
                            to={entry.currentSumOfCorrectAnswer}
                            size="sm"
                        />
                    );
                })}
            </div>
        </div>
    );
}
