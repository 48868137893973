import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import {
    getIsValidLessonId,
    getIsValidLessonLanguage,
    LESSONS,
} from '@notacami/core/lesson';
import { useHideTabBar } from '../components/ui/tab-bar.hooks';
import { Lesson } from '../components/business/lesson';
import { useCurrentLanguage } from '../hooks/use-current-language';

export function LessonPage() {
    useHideTabBar();

    const currentLanguage = useCurrentLanguage();
    const navigate = useNavigate();

    const params = useParams();
    const lessonIdInUrl = params.id;

    const [searchParams] = useSearchParams();
    const lessonLanguageInUrl = searchParams.get('language');

    const isLessonIdInUrlValid = getIsValidLessonId(lessonIdInUrl);

    const lessonInfo = isLessonIdInUrlValid
        ? LESSONS[lessonIdInUrl]
        : undefined;

    const isValidLessonLanguageInUrl = getIsValidLessonLanguage(
        lessonIdInUrl,
        lessonLanguageInUrl,
    );

    const lessonIsAvailableForCurrentLanguage =
        lessonInfo !== undefined
            ? lessonInfo.availableLanguages.includes(currentLanguage)
            : false;

    const lessonLanguage = isValidLessonLanguageInUrl
        ? lessonLanguageInUrl
        : currentLanguage;

    useEffect(() => {
        if (
            !isLessonIdInUrlValid ||
            (isLessonIdInUrlValid &&
                !lessonIsAvailableForCurrentLanguage &&
                !isValidLessonLanguageInUrl)
        ) {
            navigate('/');
        }
    }, []);

    return isLessonIdInUrlValid &&
        (lessonIsAvailableForCurrentLanguage || isValidLessonLanguageInUrl) ? (
        <Lesson lessonId={lessonIdInUrl} lessonLanguage={lessonLanguage} />
    ) : null;
}
