import { useSearchParams } from 'react-router-dom';
import { ScaleName } from '../../services/scales/scale.type';
import { SCALE_SEARCH_PARAM } from '../../utils/routes.contants';
import { FindScalePosition } from '../../components/business/find-scale-position';

export function FindScalePositionPage() {
    const [searchParams] = useSearchParams();

    const scaleName = searchParams.get(SCALE_SEARCH_PARAM) as ScaleName | null;

    return <FindScalePosition scaleName={scaleName} />;
}
