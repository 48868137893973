import { DetailedHTMLProps, HTMLAttributes } from 'react';

type UlProps = DetailedHTMLProps<
    HTMLAttributes<HTMLUListElement>,
    HTMLUListElement
>;

export function Ul(props: UlProps) {
    return <ul className="list-inside mb-4 last:mb-0" {...props} />;
}
