import { cn } from '@notacami/core/css';

type StringNumberProps = {
    className?: string;
    selected: boolean;
    stringNumber: number;
};

export function StringNumber({
    selected,
    stringNumber,
    className,
}: StringNumberProps) {
    return (
        <div
            className={cn(
                className,
                'w-4 h-4 rounded-full flex justify-center items-start text-xs font-semibold transition-all',
                selected &&
                    'bg-stone-500 dark:bg-stone-700 text-neutral-100 dark:text-neutral-300',
                !selected &&
                    'bg-stone-300 dark:bg-stone-800 text-neutral-100 dark:text-neutral-500',
            )}
        >
            {stringNumber}
        </div>
    );
}
