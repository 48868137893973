import { LessonInfo } from '@notacami/core/lesson';
import { LESSON_BASE } from './demo-lesson-base';

export const DEMO_LESSON_EN_FR_TEACHER_SPEAKING_TEXT: LessonInfo = {
    ...LESSON_BASE,
    id: 'demo-lesson-en-fr-teacher-speaking-text',
    steps: [
        {
            id: '0',
            type: 'teacher-speaking',
            text: [
                {
                    locale: 'en',
                    text: 'Text En',
                },
                { locale: 'fr', text: 'Text Fr' },
            ],
        },
    ],
};
