import { NOTES_WITH_ALTERED_NOTES } from '../../../../../../services/notes/notes.constants';
import { shuffle } from '../../../../../../utils/array.utils';
import { FindTheNoteFromRootAndIntervalAnswer } from '../../../types';

export function computeQuestionMeta(
    correctAnswer: FindTheNoteFromRootAndIntervalAnswer,
) {
    const listNotes = NOTES_WITH_ALTERED_NOTES;
    const isListNotesContainCorrectAnswer =
        NOTES_WITH_ALTERED_NOTES.includes(correctAnswer);
    const listNotesWithCorrectAnswer = isListNotesContainCorrectAnswer
        ? listNotes
        : [...listNotes, correctAnswer];

    return {
        listNotes: shuffle(listNotesWithCorrectAnswer),
    };
}
