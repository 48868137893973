import { useEffect, useState } from 'react';
import { Thematic } from '@notacami/core/thematic';
import { AnswerHelp } from './answer-help';
import { HelpButton } from './help-button';

type BottomLeftActionSectionHelpProps = {
    answerTipText: string | null;
    thematic: Thematic;
};

export function BottomLeftActionSectionHelp({
    answerTipText,
    thematic,
}: BottomLeftActionSectionHelpProps) {
    const [isOpen, setIsOpen] = useState(false);

    const handleClick = () => {
        setIsOpen((state) => !state);
    };

    useEffect(() => {
        function closeWhenNoAnswerTipText() {
            if (answerTipText === null) {
                setIsOpen(false);
            }
        }
        closeWhenNoAnswerTipText();
    }, [answerTipText]);

    return (
        <>
            <HelpButton
                isOpen={isOpen}
                hasAnswerHelpText={answerTipText !== null}
                onClick={handleClick}
                thematic={thematic}
            />
            {answerTipText !== null && isOpen ? (
                <AnswerHelp
                    key={answerTipText}
                    className="absolute bottom-14 min-w-24 w-72"
                >
                    {answerTipText}
                </AnswerHelp>
            ) : null}
        </>
    );
}
