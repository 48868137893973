import { cn } from '@notacami/core/css';
import { CENTS } from './constants';

export function TunerCentsOffMarkers() {
    return (
        <>
            <div className="flex z-10 relative">
                {CENTS.map(({ text }, index) => (
                    <div
                        key={index}
                        className="w-[10px] flex flex-col items-center justify-end"
                    >
                        {text ? (
                            <div className="text-xs text-neutral-200">
                                {text}
                            </div>
                        ) : null}
                    </div>
                ))}
            </div>
            <div className="flex z-10 relative">
                {CENTS.map(({ size, x, text }, index) => (
                    <div
                        key={index}
                        className="w-[10px] flex flex-col items-center justify-center"
                    >
                        <div
                            className={cn(
                                'rounded-full',
                                text !== '' && 'bg-neutral-200',
                                size === 'big' && 'w-1 h-2 bg-neutral-400',
                                size === 'small' && 'w-px h-1 bg-neutral-500',
                                x === 0 && 'h-4 bg-neutral-200',
                            )}
                        ></div>
                    </div>
                ))}
            </div>
        </>
    );
}
