import { Segment } from '../fretboard';

export function getOctavePositionWithIntervalInSegment(segment: Segment) {
    const foundPositionWithInterval = segment.find(
        ({ interval }) => interval === '8P',
    );

    if (!foundPositionWithInterval) {
        throw new Error('No octave position found for this segment');
    }

    return foundPositionWithInterval.position;
}
