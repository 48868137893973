import { Note } from 'tonal';
import { usePreferencesStore } from '../../components/business/preferences/use-preferences-context';
import {
    UNALTERED_NOTES,
    UNALTERED_NOTES_LATIN,
} from '../notes/notes.constants';
import { NoteNotation } from './note-notation.type';
import { replaceSharpAndFlatSymbols } from './note-notation.utils';

function translateNoteName(noteNotation: NoteNotation) {
    return (note: string) => {
        const noteInfo = Note.get(note);
        const step = noteInfo.step ?? 0;
        const accidentals = noteInfo.acc;
        const noteName =
            noteNotation === 'latin'
                ? UNALTERED_NOTES_LATIN[step]
                : UNALTERED_NOTES[step];
        return replaceSharpAndFlatSymbols(`${noteName}${accidentals}`);
    };
}

export function useNoteNotation() {
    const noteNotation = usePreferencesStore((state) => state.noteNotation);
    const n = translateNoteName(noteNotation);
    return { n, noteNotation };
}
