import { compile, run } from '@mdx-js/mdx';
import { MDX_COMPONENTS } from '@notacami/ui';
import * as runtime from 'react/jsx-runtime';
import { useEffect, useState } from 'react';
import { MDXModule } from 'mdx/types';

const make = (content: string) =>
    compile(content, {
        outputFormat: 'function-body',
    });

const mdxToJavascript = async (content: string): Promise<string> => {
    const code = await make(content);
    return globalThis.String(code);
};

type MdxComponentProps = {
    text: string;
};

export function MdxComponent({ text }: MdxComponentProps) {
    const [mdxModule, setMdxModule] = useState<MDXModule | null>(null);
    const baseUrl = import.meta.url;

    useEffect(() => {
        async function getMdxModule() {
            const code = await mdxToJavascript(text);
            // @ts-expect-error: known issue on run options (mdx RunOptions > Jsx > type = unknown does not fix to React typing)
            const mdxModule = await run(code, {
                ...runtime,
                baseUrl,
            });

            setMdxModule(mdxModule);
        }

        getMdxModule();
    }, [text]);

    return mdxModule !== null ? (
        <mdxModule.default components={MDX_COMPONENTS} />
    ) : null;
}
