import { getClassnamesByColorVariant } from '@notacami/core/design';
import { cva } from 'class-variance-authority';

const redButtonClassnames = getClassnamesByColorVariant('red');

export const buttonVariants = cva(
    'inline-flex items-center justify-center rounded-xl transition-colors focus-visible:outline-none disabled:pointer-events-none disabled:opacity-50 duration-300',
    {
        variants: {
            size: {
                default: 'px-4 py-2',
                sm: 'px-2 py-1 text-sm',
                lg: 'px-6 py-3 text-base font-semibold',
                icon: 'h-10 w-10',
            },
            variant: {
                default: '',
                destructive: [
                    redButtonClassnames.background,
                    redButtonClassnames.backgroundHover,
                    redButtonClassnames.text,
                ],
                outline: 'border-2',
                ghost: '',
                link: 'underline underline-offset-4 hover:no-underline p-0 inline',
            },
        },
        defaultVariants: {
            variant: 'default',
            size: 'default',
        },
    },
);
