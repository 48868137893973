import { useEffect, useReducer } from 'react';
import { getPlatorm } from '../utils/platform';

type Platform = 'ios' | 'android' | 'web';

type State = {
    isLoading: boolean;
    platform: Platform | undefined;
};

type Action =
    | {
          type: 'FETCH_COMPLETED';
          platform: Platform;
      }
    | {
          type: 'FETCH_START';
      };

const INITIAL_STATE = {
    isLoading: false,
    platform: undefined,
};

const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case 'FETCH_COMPLETED':
            return { isLoading: false, platform: action.platform };
        case 'FETCH_START':
            return { isLoading: true, platform: undefined };
        default:
            return state;
    }
};

export function usePlatform() {
    const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

    useEffect(() => {
        async function fetchPlatform() {
            dispatch({ type: 'FETCH_START' });
            const platform = await getPlatorm();
            dispatch({ type: 'FETCH_COMPLETED', platform });
        }
        fetchPlatform();
    }, []);

    return state;
}
