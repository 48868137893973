import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react';
import * as SwitchPrimitives from '@radix-ui/react-switch';
import { cn } from '@notacami/core/css';

const Switch = forwardRef<
    ElementRef<typeof SwitchPrimitives.Root>,
    ComponentPropsWithoutRef<typeof SwitchPrimitives.Root>
>(({ className, ...props }, ref) => (
    <SwitchPrimitives.Root
        className={cn(
            'peer inline-flex h-8 w-14 shrink-0 cursor-pointer items-center rounded-full border-2 transition-colors focus-visible:outline-none',
            'focus-visible:ring-2 focus-visible:ring-offset-2',
            'border-neutral-300 focus-visible:ring-neutral-950 focus-visible:ring-offset-neutral-200 data-[state=checked]:bg-stone-300 data-[state=unchecked]:bg-stone-200',
            'dark:border-neutral-600 dark:focus-visible:ring-neutral-300 dark:focus-visible:ring-offset-neutral-500 dark:data-[state=checked]:bg-stone-600 dark:data-[state=unchecked]:bg-black',
            'disabled:cursor-not-allowed disabled:opacity-50',
            className,
        )}
        {...props}
        ref={ref}
    >
        <SwitchPrimitives.Thumb
            className={cn(
                'pointer-events-none block h-7 w-7 rounded-full ring-0 transition-transform data-[state=checked]:translate-x-6 data-[state=unchecked]:translate-x-0',
                'border-2 dark:border-stone-700 data-[state=checked]:border-stone-300 data-[state=unchecked]:border-stone-200',
                'bg-stone-600 dark:bg-stone-200',
            )}
        />
    </SwitchPrimitives.Root>
));
Switch.displayName = SwitchPrimitives.Root.displayName;

export { Switch };
