type Accumulator = [isDescending: boolean, previousIndex: number];

function getAreNumbersDescendingReducer(
    accumulator: Accumulator,
    currentNumber: number,
): Accumulator {
    if (!accumulator[0]) return accumulator;

    return [accumulator[1] >= currentNumber, currentNumber];
}

export function getAreNumbersDescending(numbers: number[]): boolean {
    const areNumbersDescending = numbers.reduce(
        getAreNumbersDescendingReducer,
        [true, +Infinity],
    )[0];

    return areNumbersDescending;
}
