import { Position } from '@notacami/core/fretboard';
import { getAreNumbersConsecutive } from '../../utils/get-are-numbers-consecutive';

export function getAreStringIndexesAreConsecutive(
    positions: Position[],
): boolean {
    const stringIndexes = positions.map((position) => position[0]);

    return getAreNumbersConsecutive(stringIndexes);
}
