import { FormFieldContainer } from '@notacami/ui';
import { Badge } from '../../../ui/badge';

export function ViteSection() {
    const mode = import.meta.env.MODE;

    return (
        <FormFieldContainer
            id="vite-mode"
            label="Vite Mode"
            input={
                <Badge colorVariant={mode === 'production' ? 'red' : 'emerald'}>
                    {mode}
                </Badge>
            }
        />
    );
}
