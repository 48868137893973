import { PUSH_MAX_HEIGHT } from './expandable-card.constants';

type Action =
    | {
          type: 'INIT';
          pushWidth: number;
          height: number;
          pushHeight: number;
      }
    | {
          type: 'OPEN';
          x: number;
          y: number;
      }
    | { type: 'OPEN_COMPLETED' }
    | { type: 'CLOSE' }
    | { type: 'CLOSE_COMPLETED' }
    | { type: 'PICTURE_LOADED' }
    | {
          type: 'RESIZE';
          pushHeight: number;
          pushWidth: number;
          x: number;
          y: number;
      };

export type State = {
    height: number;
    isInitialized: boolean;
    isOpenCompleted: boolean;
    isPictureLoaded: boolean;
    isTransitioning: boolean;
    open: boolean;
    pushHeight: number;
    pushWidth: number;
    x: number;
    y: number;
};

export const DEFAULT_STATE: State = {
    height: PUSH_MAX_HEIGHT,
    isInitialized: false,
    isOpenCompleted: false,
    isPictureLoaded: false,
    isTransitioning: false,
    open: false,
    pushHeight: PUSH_MAX_HEIGHT,
    pushWidth: 0,
    x: 0,
    y: 0,
};

export const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case 'INIT':
            return {
                ...state,
                height: action.height,
                isInitialized: true,
                pushHeight: action.pushHeight,
                pushWidth: action.pushWidth,
            };
        case 'OPEN':
            return {
                ...state,
                isOpenCompleted: false,
                isTransitioning: true,
                open: true,
                x: action.x,
                y: action.y,
            };
        case 'CLOSE':
            return {
                ...state,
                isOpenCompleted: false,
                isTransitioning: true,
                open: false,
            };
        case 'OPEN_COMPLETED':
            return {
                ...state,
                isOpenCompleted: true,
                isTransitioning: false,
            };
        case 'CLOSE_COMPLETED':
            return {
                ...state,
                isOpenCompleted: false,
                isTransitioning: false,
            };
        case 'PICTURE_LOADED':
            return {
                ...state,
                isPictureLoaded: true,
            };
        case 'RESIZE':
            return {
                ...state,
                pushHeight: action.pushHeight,
                pushWidth: action.pushWidth,
                x: action.x,
                y: action.y,
            };
        default:
            return state;
    }
};
