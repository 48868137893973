import { LessonProvider } from '../../../services/lesson';
import { LessonMainSteps } from './lesson-main-steps';
import { LessonPostMessage } from './lesson-post-message';

export function LessonCreation() {
    return (
        <LessonProvider>
            <LessonPostMessage />
            <LessonMainSteps />
        </LessonProvider>
    );
}
