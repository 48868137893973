import { Music4 } from 'lucide-react';
import { useTranslation } from '../../hooks/use-translation';
import { ChromaticTuner } from './sound-capture-debug/chromatic-tuner';
import { ReferenceFrequencySection } from './settings/instrument/reference-frequency-section';

export function TuningStepContent() {
    const { t } = useTranslation();
    return (
        <div className="vertical-content-distribution-base items-center">
            <Music4
                strokeWidth={1.6}
                className="w-12 h-12 stroke-neutral-600 dark:stroke-neutral-300"
            />
            <p className="max-w-xs text-center">
                {t('tutorial.mic-tutorial.tuning-step.description')}
            </p>
            <ChromaticTuner />
            <ReferenceFrequencySection />
        </div>
    );
}
