import { Scale } from 'tonal';
import { Position } from '@notacami/core/fretboard';
import { getScalePositionsAcrossEntireNeckHeight } from '../../../../../../services/fretboard-scale';
import {
    Fretboard,
    MAX_FRET_DIFF_BY_SEGMENT,
    MAX_FRET_DIFF_BY_STRING,
} from '../../../../../../services/fretboard';
import { ScaleName } from '../../../../../../services/scales/scale.type';

export function getScalePositionsByScaleNameAndPosition(
    fretboard: Fretboard,
    scaleName: ScaleName,
    position: Position,
) {
    const scaleInfo = Scale.get(scaleName);
    const scaleIntervals = scaleInfo.intervals;

    const scalePositions = getScalePositionsAcrossEntireNeckHeight(
        fretboard,
        scaleIntervals,
        position,
        MAX_FRET_DIFF_BY_STRING,
        MAX_FRET_DIFF_BY_SEGMENT,
    );

    return scalePositions;
}
