import { Control, FieldValues, useWatch } from 'react-hook-form';
import { isEqual } from 'lodash/fp';
import { Button } from '@notacami/ui';
import { useTranslation } from '../../../../../hooks/use-translation';

type RestoreDefaultConfigButtonProps<T> = {
    onClick: () => void;
    defaultConfig: FieldValues;
    control: Control<T & FieldValues>;
};

export function RestoreDefaultConfigButton<T>({
    onClick,
    defaultConfig,
    control,
}: RestoreDefaultConfigButtonProps<T>) {
    const { t } = useTranslation();

    const data = useWatch({ control });

    const isDefault = isEqual(data, defaultConfig);

    return (
        <Button
            variant="ghost"
            type="button"
            onClick={onClick}
            disabled={isDefault}
            size="sm"
        >
            {t('button.restore-default-config-settings')}
        </Button>
    );
}
