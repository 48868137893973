import { TuningId } from '@notacami/core/tuning';
import { getFretboardIntervalsForEachPosition } from '../../../services/fretboard-interval';
import {
    DEFAULT_FRET_LENGTH,
    Fretboard,
    getFretboardNoteDetailsByTuningAndFretLength,
} from '../../../services/fretboard';
import { getTuningInfo } from '../../../services/tuning/get-tuning-info';
import { TuningInfo } from '../../../services/tuning/tuning.types';

export function getFretboardAndTuningInfoByTuningId(tuningId: TuningId): {
    fretboard: Fretboard;
    tuningInfo: TuningInfo;
} {
    const tuningInfo = getTuningInfo(tuningId);
    const fretboard = getFretboardNoteDetailsByTuningAndFretLength(
        tuningInfo.notes,
        DEFAULT_FRET_LENGTH,
    );
    const fretboardIntervals = getFretboardIntervalsForEachPosition(fretboard);

    return {
        tuningInfo,
        fretboard: {
            noteDetails: fretboard,
            intervals: fretboardIntervals,
        },
    };
}
