import { orderBy, partition } from 'lodash/fp';
import { getGroupedIntervalsByNumberOfSemitones } from '../intervals/get-grouped-intervals-by-number-of-semitones';
import { PLAYABLE_INTERVALS_LIST } from '../intervals/intervals.constants';
import { getMergedIntervalScoringEntries } from './get-merged-interval-progress-entries';
import { ProgressData } from './progress.types';

export function formatIntervalsProgressData(
    intervals: string[] | null,
    currentProgressData: ProgressData<{
        interval: string;
    }>,
    previousProgressData: ProgressData<{
        interval: string;
    }>,
) {
    const playableIntervals = getGroupedIntervalsByNumberOfSemitones(
        PLAYABLE_INTERVALS_LIST,
    );

    const availableIntervals =
        intervals !== null
            ? getGroupedIntervalsByNumberOfSemitones(intervals)
            : [];

    const mergedPreviousProgressData = getMergedIntervalScoringEntries(
        previousProgressData.entries.map((entry) => [
            entry.payload.interval,
            entry.numberOfCorrectAnswer,
        ]),
    );

    const mergedCurrentProgressData = getMergedIntervalScoringEntries(
        currentProgressData.entries.map((entry) => [
            entry.payload.interval,
            entry.numberOfCorrectAnswer,
        ]),
    );

    const aggregates = playableIntervals.map(([semitones, intervalList]) => {
        const maybePreviousSumOfCorrectAnswer = mergedPreviousProgressData.find(
            (prevEntry) => prevEntry.semitones === semitones,
        );
        const previousSumOfCorrectAnswer =
            maybePreviousSumOfCorrectAnswer?.sumOfCorrectAnswer ?? 0;

        const maybeCurrentSumOfCorrectAnswer = mergedCurrentProgressData.find(
            (curEntry) => curEntry.semitones === semitones,
        );
        const currentSumOfCorrectAnswer =
            maybeCurrentSumOfCorrectAnswer?.sumOfCorrectAnswer ?? 0;

        const maybeAvailableInterval = availableIntervals.find(
            (availableInterval) => availableInterval[0] === semitones,
        );

        const intervalListToDisplay =
            maybeAvailableInterval !== undefined
                ? maybeAvailableInterval[1]
                : intervalList;

        return {
            semitones: semitones,
            intervals: intervalListToDisplay,
            previousSumOfCorrectAnswer,
            currentSumOfCorrectAnswer,
        };
    });

    const filteredAggregates = aggregates.filter((aggregate) =>
        intervals !== null
            ? aggregate.intervals.some((interval) =>
                  intervals.includes(interval),
              )
            : true,
    );

    const orderedAggregates = orderBy('semitones', 'asc', filteredAggregates);

    const orderedAggregatesSequence = partition(
        ({
            previousSumOfCorrectAnswer,
            currentSumOfCorrectAnswer,
        }: {
            previousSumOfCorrectAnswer: number;
            currentSumOfCorrectAnswer: number;
        }) => {
            return previousSumOfCorrectAnswer !== currentSumOfCorrectAnswer;
        },
        orderedAggregates,
    );

    return {
        sameProgressData: orderedAggregatesSequence[1],
        updatedProgressData: orderedAggregatesSequence[0],
    };
}
