import { TuningId } from '@notacami/core/tuning';
import { TUNINGS } from './tuning.constants';
import { TuningInfo } from './tuning.types';
import { getStringListByUniqBaseNote } from './get-string-list-by-uniq-base-note';
import { getTuningSummary } from './get-tuning-summary';

export function getTuningInfo(id: TuningId): TuningInfo {
    const foundTuning = TUNINGS.find(({ id: _id }) => _id == id);
    if (foundTuning === undefined) {
        throw new Error(`Tuning not found: id ${id}`);
    }
    return {
        ...foundTuning,
        stringList: getStringListByUniqBaseNote(foundTuning.notes),
        tuningSummary: getTuningSummary(foundTuning.notes),
    };
}
