import { StoreCapacitor } from '../storage.capacitor';

export function getPreferenceLeftHandedCapacitorStore() {
    return new StoreCapacitor<boolean>(
        'settings.left-handed',
        false,
        (value) => JSON.stringify(value),
        (value) => JSON.parse(value),
    );
}
