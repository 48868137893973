import { cn } from '@notacami/core/css';
import { Checkbox } from './checkbox';

export type MultiSelectOption<T> = {
    value: T & string;
    label: string;
    checked: boolean;
};

type MultiSelectProps<T> = {
    className?: string;
    options: MultiSelectOption<T>[];
    onChange: (options: MultiSelectOption<T>[]) => void;
};

function getOptionIdByValue<T>(value: T & string) {
    return `multi-select-option-${value}`;
}

export function MultiSelect<T>({
    className,
    options,
    onChange,
}: MultiSelectProps<T>) {
    const handleChange = (value: T & string) => (checked: boolean) => {
        const updatedOptions = options.map((option) =>
            option.value === value
                ? {
                      ...option,
                      checked,
                  }
                : option,
        );

        onChange(updatedOptions);
    };

    return (
        <div className={cn('flex gap-2 flex-wrap justify-center', className)}>
            {options.map((option) => {
                const id = getOptionIdByValue(option.value);
                return (
                    <div
                        key={id}
                        className={cn(
                            'py-1 px-3 rounded-full flex justify-center items-center text-sm',
                            option.checked
                                ? 'bg-neutral-500 text-lighter'
                                : 'bg-neutral-300 dark:bg-neutral-700',
                        )}
                    >
                        <Checkbox
                            id={id}
                            name={id}
                            checked={option.checked}
                            onCheckedChange={handleChange(option.value)}
                        />
                        <label htmlFor={id} className="pl-2">
                            {option.label}
                        </label>
                    </div>
                );
            })}
        </div>
    );
}
