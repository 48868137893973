import { ReactNode } from 'react';
import { Title } from '@notacami/ui';

type ThematicPageContentTitleProps = {
    children: ReactNode;
};

export function ThematicPageContentTitle({
    children,
}: ThematicPageContentTitleProps) {
    return (
        <div className="flex px-4 justify-between gap-4 capitalize">
            <Title tagName="h2" level={3}>
                {children}
            </Title>
            <div className="h-auto regular-border-color border-r-2" />
        </div>
    );
}
