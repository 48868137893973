import * as Slider from '@radix-ui/react-slider';
import { cn } from '@notacami/core/css';

type RangeInputProps = {
    defaultValues?: number[];
    min: number;
    max: number;
    onValueChange: (values: number[]) => void;
    widthClassName?: string;
};

export function RangeInput({
    defaultValues,
    min,
    max,
    onValueChange,
    widthClassName,
}: RangeInputProps) {
    const handleValueChange = (values: number[]) => {
        onValueChange(values);
    };

    const thumbClassnames =
        'block w-5 h-5 bg-neutral-100 rounded-full border-neutral-800 border';
    return (
        <Slider.Root
            className={cn(
                'relative flex items-center select-none touch-none h-5',
                widthClassName || 'w-full',
            )}
            defaultValue={defaultValues || [min, max]}
            max={max}
            min={min}
            onValueChange={handleValueChange}
        >
            <Slider.Track className="relative bg-neutral-900 flex-grow rounded-full h-1">
                <Slider.Range className="absolute bg-primary rounded-full h-full" />
            </Slider.Track>
            <Slider.Thumb className={thumbClassnames} aria-label="Min" />
            <Slider.Thumb className={thumbClassnames} aria-label="Max" />
        </Slider.Root>
    );
}
