import { ProgressService } from '../progress';
import { ProgressRepository } from '../progress.repository';
import { StoredProgressEntries } from '../progress.types';
import { IStore } from '../../storage/storage.type';
import { fromResult, fromStoredEntry } from './progress-entry';
import { FindTheNoteFromRootAndIntervalProgressStoredPayload } from './types';

export function getFindTheNoteFromRootAndIntervalProgressInstance(
    storeInstance: IStore<
        StoredProgressEntries<FindTheNoteFromRootAndIntervalProgressStoredPayload>
    >,
) {
    return new ProgressService(
        new ProgressRepository(storeInstance, fromStoredEntry),
        fromResult,
    );
}
