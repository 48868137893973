import { useCallback, useContext, useEffect } from 'react';
import { Position } from '@notacami/core/fretboard';
import { GuitarNeck } from '../../../guitar-neck/guitar-neck';
import { useGuitarNeckVertical } from '../../../../../hooks/use-guitar-neck-vertical';
import {
    FindTheNoteOnTheNeckAnswer,
    FindTheNoteOnTheNeckConfig,
} from '../../types';
import { useQuestion } from '../../../../../services/exercise/hooks/use-question';
import { FindTheNoteOnTheNeckExerciseContext } from '../../store/context';
import {
    DEFAULT_FRET_LENGTH,
    isPositionEqual,
    useLeftHanded,
} from '../../../../../services/fretboard';
import { usePreferencesStore } from '../../../preferences/use-preferences-context';
import { QuizMode } from '../../../../../services/exercise/exercise.types';
import { useTemporaryVariable } from '../../../../../hooks/use-temporary-variable';
import { NoteDetails } from '../../../../../services/notes/notes.types';
import { ServicesContext } from '../../../../../services/services.context';
import { AnswerSectionPlayCell } from './answer-section-play-cell';

type AnswerSectionPlayProps = {
    addError: (currentUserAnswer: FindTheNoteOnTheNeckAnswer) => void;
    config: FindTheNoteOnTheNeckConfig;
    quizMode: QuizMode;
    submitAnswer: (userAnswer: FindTheNoteOnTheNeckAnswer) => void;
};

export function AnswerSectionPlay({
    addError,
    config,
    quizMode,
    submitAnswer,
}: AnswerSectionPlayProps) {
    const { notePlayed } = useContext(ServicesContext);
    const isVertical = useGuitarNeckVertical();
    const leftHanded = useLeftHanded();
    const fretboard = usePreferencesStore((state) => state.fretboard);
    const { addVariable, variables: wrongAnswerPositions } =
        useTemporaryVariable<Position>(2000);

    const store = useContext(FindTheNoteOnTheNeckExerciseContext);
    const question = useQuestion(store);

    const givenStringIndex = question.question.givenStringIndex;
    const givenNoteChroma = question.question.givenNoteChroma;

    const handlePositionSelect = useCallback(
        (position: FindTheNoteOnTheNeckAnswer) => {
            if (!isPositionEqual(position, question.correctAnswer)) {
                handleError(position);
            } else {
                submitAnswer(position);
            }
        },
        [submitAnswer],
    );

    const handleError = (errorPosition: Position) => {
        addError(errorPosition);
        addVariable(errorPosition);
    };

    const submitResponseInGuitarMode = useCallback(() => {
        handlePositionSelect(question.correctAnswer);
    }, [question, handlePositionSelect]);

    const handleNoteChange = useCallback(
        ({ noteDetails }: { noteDetails: NoteDetails }) => {
            if (noteDetails.chroma === givenNoteChroma) {
                submitResponseInGuitarMode();
            } else {
                const errorFretIndex = fretboard.noteDetails[
                    givenStringIndex
                ].findIndex(
                    (note, index) =>
                        note.chroma === noteDetails.chroma && index !== 0,
                );
                handleError([givenStringIndex, errorFretIndex]);
            }
        },
        [submitResponseInGuitarMode, givenNoteChroma],
    );

    useEffect(() => {
        notePlayed.on('changed', handleNoteChange);

        return () => {
            notePlayed.off('changed', handleNoteChange);
        };
    }, [handleNoteChange]);

    const currentSelectableStrings = fretboard.noteDetails.map(
        (_, stringIndex) => stringIndex === givenStringIndex,
    );

    return (
        <GuitarNeck
            numberOfFrets={DEFAULT_FRET_LENGTH}
            tuning={config.tuningInfo.notes}
            isVertical={isVertical}
            highlightedStrings={
                quizMode === 'guitar' ? currentSelectableStrings : undefined
            }
            buildCellsComponent={AnswerSectionPlayCell}
            leftHanded={leftHanded}
            additionalCellProps={{
                fretboard,
                givenStringIndex: givenStringIndex,
                onSelect: handlePositionSelect,
                quizMode,
                showNeckHeadNotes: config.showNeckHeadNotes,
                wrongAnswerPositions,
            }}
        />
    );
}
