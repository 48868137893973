import { FretboardNoteDetails } from '../../../../../../services/fretboard';
import {
    FindTheIntervalOnTheNeckAnswer,
    FindTheIntervalOnTheNeckResultMeta,
} from '../../../types';

export function computeResultMeta(
    correctAnswer: FindTheIntervalOnTheNeckAnswer,
    notesOnFretboard: FretboardNoteDetails,
): FindTheIntervalOnTheNeckResultMeta {
    return {
        noteToPlay: notesOnFretboard[correctAnswer[0]][correctAnswer[1]].name,
    };
}
