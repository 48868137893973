import { useContext } from 'react';
import { FormFieldContainer } from '@notacami/ui';
import { InputNumber } from '../../../ui/input-number';
import {
    MAX_REFERENCE_FREQUENCY,
    MIN_REFERENCE_FREQUENCY,
} from '../../../../services/frequency/frequency.constants';
import { usePreferencesStore } from '../../preferences/use-preferences-context';
import { useTranslation } from '../../../../hooks/use-translation';
import { ServicesContext } from '../../../../services/services.context';

export function ReferenceFrequencySection() {
    const { notePlayed, preferenceReferenceFrequencyStore } =
        useContext(ServicesContext);
    const { t } = useTranslation();
    const changeReferenceFrequency = usePreferencesStore(
        (state) => state.setFrenquencyReference,
    );
    const referenceFrequency = usePreferencesStore(
        (state) => state.referenceFrequency,
    );

    const handleChange = (newValue: number) => {
        changeReferenceFrequency(newValue);
        preferenceReferenceFrequencyStore.set(newValue);
        notePlayed.setReferenceFrequency(newValue);
    };

    const handleMinusButtonClick = () => {
        handleChange(Math.max(referenceFrequency - 1, MIN_REFERENCE_FREQUENCY));
    };

    const handlePlusButtonClick = () => {
        handleChange(Math.min(referenceFrequency + 1, MAX_REFERENCE_FREQUENCY));
    };

    return (
        <FormFieldContainer
            id="reference-frequency-setting"
            label={t('pages.settings.reference-frequency.title')}
            input={
                <InputNumber
                    className="w-12"
                    id="reference-frequency-setting"
                    max={MAX_REFERENCE_FREQUENCY}
                    min={MIN_REFERENCE_FREQUENCY}
                    onMinusClick={handleMinusButtonClick}
                    onPlusClick={handlePlusButtonClick}
                    onValueChange={handleChange}
                    value={referenceFrequency}
                />
            }
        />
    );
}
