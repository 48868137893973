import { useContext } from 'react';
import { ServicesContext } from '../../../services/services.context';
import { States } from './states';
import { FindTheNoteFromRootAndIntervalExerciseProvider } from './store/context';
import { computeQuizTurnQuestionAction } from './store/specific-actions/compute-quiz-turn-question';
import { computeQuizTurnResultAction } from './store/specific-actions/compute-quiz-turn-result';
import { getProgressDataAction } from './store/specific-actions/get-progress-data';
import { manageProgressDataAction } from './store/specific-actions/manage-progress-data';
import { getDefaultConfig } from './get-default-config';

type FindTheNoteFromRootAndIntervalProps = {
    availableIntervals: string[] | null;
};

export function FindTheNoteFromRootAndInterval({
    availableIntervals,
}: FindTheNoteFromRootAndIntervalProps) {
    const { findTheNoteFromRootAndIntervalProgress } =
        useContext(ServicesContext);
    const defaultConfig = getDefaultConfig(availableIntervals);

    return (
        <FindTheNoteFromRootAndIntervalExerciseProvider
            computeQuizTurnQuestion={computeQuizTurnQuestionAction}
            computeQuizTurnResult={computeQuizTurnResultAction}
            config={defaultConfig}
            getProgressData={getProgressDataAction}
            manageProgressData={manageProgressDataAction}
            progressService={findTheNoteFromRootAndIntervalProgress}
            thematic={'intervals'}
        >
            <States
                availableIntervals={availableIntervals}
                defaultConfig={defaultConfig}
            />
        </FindTheNoteFromRootAndIntervalExerciseProvider>
    );
}
