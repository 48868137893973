import { AppLanguage } from '@notacami/core/i18n';
import { TranslationKey } from './translations.types';

export const TRANSLATIONS: Record<AppLanguage, { translation: Record<TranslationKey, string> }> = {
    en: {
        translation: {
            'advices.recordingButtonIntro0': 'Hi!!!',
            'advices.recordingButtonIntro1': "I'm listening to you play",
            'advices.recordingButtonIntro2': 'If you need to adjust the listening, click on the microphone button',
            'advices.recordingButtonIntro3': 'Good exercise!',
            'all-intervals': 'all intervals',
            'all-scales': 'all scales',
            'all-strings': 'all strings',
            'button.accept': 'I agree',
            'button.apply': 'Apply',
            'button.back': 'Back',
            'button.close': 'Close',
            'button.done': 'Done',
            'button.i-get-it': 'I get it',
            'button.in-progress': 'In progress',
            'button.its-ok': "It's ok",
            'button.lets-go': "Let's go",
            'button.next': 'Next',
            'button.no': 'No',
            'button.play': 'Play',
            'button.privacy-policy': 'Privacy policy',
            'button.restart': 'Restart',
            'button.restore-default-config-settings': 'Restore default settings',
            'button.settings': 'Go to settings',
            'button.start': 'Start',
            'button.stop': 'Stop',
            'button.terms-and-conditions': 'Terms and conditions',
            'button.trigger': 'Trigger',
            'button.yes': 'Yes',
            'components.end-confirmation-modal.description': 'Your progress will still be saved',
            'components.end-confirmation-modal.title': 'Do you want to stop the current exercise?',
            'components.question-scoring-result.well-done': 'Well done',
            'components.visualize-scale-positions-position-selector.positions': 'Positions',
            description: 'Description',
            'estimated-duration': 'Estimated duration: <0>{{estimatedDuration}} minutes</0>',
            'exercise.find-scale-position.app.tip-way-back': 'You can start again in the other direction by starting from the arrow',
            'exercise.find-scale-position.app.tip-way': 'You can start by selecting the tonic indicated by the arrow',
            'exercise.find-scale-position.guitar.tip-way-back': 'You can start again in the other direction, starting from the arrow',
            'exercise.find-scale-position.guitar.tip-way': 'You can start by playing the tonic indicated by the arrow',
            'exercise.help': 'Help',
            'fields.intervals.label': 'Intervals',
            'fields.mode.label': 'Mode',
            'fields.practice-duration.label': 'Practice duration (min)',
            'fields.quiz-mode.label.app': 'the app',
            'fields.quiz-mode.label.guitar': 'your guitar',
            'fields.quiz-mode.label.title': 'Respond on',
            'fields.root.label': 'Root',
            'fields.scale-position-types.label': 'Position type',
            'fields.scale.label': 'Scale',
            'fields.scales.label': 'Scales',
            'fields.selectable-strings.label': 'Choosing strings',
            'fields.show-intervals.label': 'Show intervals',
            'fields.show-neck-head-notes.label': 'Notes on open strings',
            'fields.with-altered-notes.label': 'Altered notes',
            'fields.with-compound-intervals.label': 'Include compound intervals',
            'fields.with-diminished-and-augmented-intervals.label': 'Include diminished and augmented intervals',
            'interval-name': '$t(interval-quality.{{quality}}) $t(interval-number.{{number}})',
            'interval-number.1': 'unison',
            'interval-number.11': 'eleventh',
            'interval-number.13': 'thirteenth',
            'interval-number.2': 'second',
            'interval-number.3': 'third',
            'interval-number.4': 'fourth',
            'interval-number.5': 'fifth',
            'interval-number.6': 'sixth',
            'interval-number.7': 'seventh',
            'interval-number.8': 'octave',
            'interval-number.9': 'ninth',
            'interval-quality.A': 'augmented',
            'interval-quality.AA': 'doubly augmented',
            'interval-quality.d': 'diminished',
            'interval-quality.dd': 'doubly diminished',
            'interval-quality.M': 'major',
            'interval-quality.m': 'minor',
            'interval-quality.P': 'perfect',
            'not-defined': 'Not defined',
            'notes-on-guitar-neck': 'Notes on guitar neck',
            'lessonType.dictionary': 'Dictionary',
            'lessonType.fretboard-exercise': 'Fretboard exercise',
            'lessonType.listening-exercise': 'Listening exercise',
            'lessonType.quiz-exercise': 'Quiz Exercise',
            'lessonType.visualization': 'Visualization on the neck',
            'pages./find-the-interval-on-the-neck.question': 'Can you play the <0>{{interval}}</0> of <0>{{note}}</0> (string <0>{{stringIndex}}</0>)?',
            'pages./find-the-note-from-root-and-interval.question': 'Can you give me the <0>{{givenInterval}}</0> interval for the <0>{{givenNote}}</0> note?',
            'pages./find-the-note-on-the-neck.question': 'Can you play the note <0>{{note}}</0> on string number <0>{{stringIndex}}</0>?',
            'pages./find-the-note-on-the-neck/progress.deleting-modal.title': 'Do you really want to delete your progress?',
            'pages.DICTIONARY_OF_MUSICAL_SCALES.title': 'Dictionary of musical scales',
            'pages.FIND_SCALE_POSITION.question': 'Can you play the <0>{{tonicPitchClass}}</0> <0>$t(scale.name.{{scaleName}})</0> scale through the octaves indicated?',
            'pages.FIND_SCALE_POSITION.title': 'Find position',
            'pages.FIND_THE_INTERVAL_ON_THE_NECK.title': 'Find the interval on the neck',
            'pages.FIND_THE_NOTE_FROM_ROOT_AND_INTERVAL.title': 'Find the note from root and interval',
            'pages.FIND_THE_NOTE_ON_THE_NECK.title': 'Find the note',
            'pages.settings.admin.device-id-from-url.title': 'Device Id from URL',
            'pages.settings.admin.persona.label': 'Persona',
            'pages.settings.admin.mic-tutorial-state.label': 'Reset',
            'pages.settings.admin.mic-tutorial-state.title': 'Microphone permission',
            'pages.settings.admin.title': 'Admin',
            'pages.settings.admin.trigger-analytics.title': 'Test analytics',
            'pages.settings.admin.trigger-web-error.title': 'Test error monitoring (web)',
            'pages.settings.admin.trigger-native-error.title': 'Test error monitoring (native)',
            'pages.settings.general.appearence.option.dark': 'Dark theme',
            'pages.settings.general.appearence.option.light': 'Light theme',
            'pages.settings.general.appearence.option.system': 'Device theme',
            'pages.settings.general.appearence.title': 'Appearence',
            'pages.settings.general.language.option.en': 'English',
            'pages.settings.general.language.option.fr': 'Français',
            'pages.settings.general.language.option.system': 'Device language',
            'pages.settings.general.language.title': 'Language',
            'pages.settings.general.title': 'General',
            'pages.settings.instrument.title': 'Your instrument',
            'pages.settings.left-handed.title': 'Left-handed',
            'pages.settings.music.title': 'Music',
            'pages.settings.note-detection.mic-settings.label': 'Microphone setting',
            'pages.settings.note-detection.title': 'Note detection',
            'pages.settings.note-notation.option.english': 'English: C, D, ..., B',
            'pages.settings.note-notation.option.latin': 'Latin: Do, Re, ..., Si',
            'pages.settings.note-notation.title': 'Notes notation',
            'pages.settings.reference-frequency.title': 'Reference frenquency (Hz)',
            'pages.settings.title': 'Settings',
            'pages.settings.tuning.group.bass': 'Bass',
            'pages.settings.tuning.group.guitar': 'Guitar',
            'pages.settings.tuning.group.ukulele': 'Ukulele',
            'pages.settings.tuning.title': 'Tuning',
            'pages.SOUND_CAPTURE_DEBUG.title': 'Sound capture debug',
            'pages.thematic.exercises': 'Exercises',
            'pages.VISUALIZE_SCALE_POSITIONS.title': 'Visualize positions',
            'pages.visualize_interval_positions.title': 'Visualize positions',
            'pages.welcome.title': 'welcome',
            'practice-time': 'Practice time',
            'scale-and-name': '$t(scale.name.{{scaleName}}) scale',
            'scale-position-type.compact': 'Compact',
            'scale-position-type.diagonal': 'Diagonal',
            'scale-position-type.other': 'Other',
            'scale.name.altered': 'Altered',
            'scale.name.augmented heptatonic': 'Augmented heptatonic',
            'scale.name.augmented': 'Augmented',
            'scale.name.balinese': 'Balinese',
            'scale.name.bebop locrian': 'Bebop locrian',
            'scale.name.bebop major': 'Bebop major',
            'scale.name.bebop minor': 'bebop minor',
            'scale.name.bebop': 'Bebop',
            'scale.name.chromatic': 'Chromatic',
            'scale.name.composite blues': 'Composite blues',
            'scale.name.diminished': 'Diminished',
            'scale.name.dorian #4': 'Dorian #4',
            'scale.name.dorian b2': 'Dorian b2',
            'scale.name.dorian': 'Dorian',
            'scale.name.double harmonic lydian': 'Double harmonic lydian',
            'scale.name.double harmonic major': 'Double harmonic major',
            'scale.name.egyptian': 'Egyptian',
            'scale.name.enigmatic': 'Enigmatic',
            'scale.name.flamenco': 'Flamenco',
            'scale.name.flat six pentatonic': 'Flat six pentatonic',
            'scale.name.flat three pentatonic': 'Flat six pentatonic',
            'scale.name.half-whole diminished': 'Half-whole diminished',
            'scale.name.harmonic major': 'Harmonic major',
            'scale.name.harmonic minor': 'Harmonic minor',
            'scale.name.hirajoshi': 'Hirajoshi',
            'scale.name.hungarian major': 'Hungarian major',
            'scale.name.hungarian minor': 'Hungarian minor',
            'scale.name.ichikosucho': 'Ichikosucho',
            'scale.name.in-sen': 'In-sen',
            'scale.name.ionian pentatonic': 'Ionian pentatonic',
            'scale.name.iwato': 'Iwato',
            'scale.name.kafi raga': 'Kafi raga',
            'scale.name.kumoijoshi': 'Kumoijoshi',
            'scale.name.leading whole tone': 'Leading whole tone',
            'scale.name.locrian #2': 'Locrian #2',
            'scale.name.locrian 6': 'Locrian 6',
            'scale.name.locrian major': 'Locrian major',
            'scale.name.locrian pentatonic': 'Locrian pentatonic',
            'scale.name.locrian': 'Locrian',
            'scale.name.lydian #5P pentatonic': 'Lydian #5P pentatonic',
            'scale.name.lydian #9': 'Lydian #9',
            'scale.name.lydian augmented': 'Lydian augmented',
            'scale.name.lydian diminished': 'Lydian diminished',
            'scale.name.lydian dominant pentatonic': 'Lydian dominant pentatonic',
            'scale.name.lydian dominant': 'Lydian dominant',
            'scale.name.lydian minor': 'Lydian minor',
            'scale.name.lydian pentatonic': 'Lydian pentatonic',
            'scale.name.lydian': 'Lydian',
            'scale.name.major augmented': 'Major augmented',
            'scale.name.major blues': 'Major blues',
            'scale.name.major pentatonic': 'Major pentatonic',
            'scale.name.major': 'Major',
            'scale.name.malkos raga': 'Malkos raga',
            'scale.name.melodic minor': 'Melodic minor',
            'scale.name.minor #7M pentatonic': 'Minor #7M pentatonic',
            'scale.name.minor bebop': 'Minor bebop',
            'scale.name.minor blues': 'Minor blues',
            'scale.name.minor hexatonic': 'Minor hexatonic',
            'scale.name.minor pentatonic': 'Minor pentatonic',
            'scale.name.minor six diminished': 'Minor six diminished',
            'scale.name.minor six pentatonic': 'Minor six pentatonic',
            'scale.name.minor': 'Minor',
            'scale.name.mixolydian b6': 'Mixolydian b6',
            'scale.name.mixolydian pentatonic': 'Mixolydian pentatonic',
            'scale.name.mixolydian': 'Mixolydian',
            'scale.name.mystery #1': 'Mystery #1',
            'scale.name.neopolitan major pentatonic': 'Neopolitan major pentatonic',
            'scale.name.neopolitan major': 'Neopolitan major',
            'scale.name.oriental': 'Oriental',
            'scale.name.pelog': 'Pelog',
            'scale.name.persian': 'Persian',
            'scale.name.phrygian dominant': 'Phrygian dominant',
            'scale.name.phrygian': 'Phrygian',
            'scale.name.piongio': 'Piongio',
            'scale.name.prometheus neopolitan': 'Prometheus neopolitan',
            'scale.name.prometheus': 'Prometheus',
            'scale.name.purvi raga': 'Purvi raga',
            'scale.name.ritusen': 'Ritusen',
            'scale.name.scriabin': 'Scriabin',
            'scale.name.six tone symmetric': 'Six tone symmetric',
            'scale.name.spanish heptatonic': 'Spanish heptatonic',
            'scale.name.super locrian pentatonic': 'Super locrian pentatonic',
            'scale.name.todi raga': 'Todi raga',
            'scale.name.ultralocrian': 'Ultralocrian',
            'scale.name.vietnamese 1': 'Vietnamese 1',
            'scale.name.whole tone pentatonic': 'Whole tone pentatonic',
            'scale.name.whole tone': 'Whole tone',
            'scale.tonic.letter': 'T',
            'screenshot-0.title': '<0></0> listens to you play<br />to teach you <1>music theory</1>',
            'screenshot-1.title': 'Learn the basics of <0>music theory</0>',
            'screenshot-2.title': 'Browse the <0>exercises</0> and <0>interactive worksheets</0>',
            'screenshot-3.title': 'Reply on the <0>application</0> or<br />with your <0>guitar</0>',
            'screenshot-4.title': 'The application <0>listens</0> to you play<br />and <0>guides</0> you',
            'screenshot-5.title': 'Track your <0>progress</0>',
            'string-and-pitch-class': '{{pitchClass}} string',
            'tab-bar.home': 'home',
            'tab-header.number-of-repetition': 'Nb repetition',
            'thematic.intervals': 'Intervals',
            'thematic.notes': 'Notes',
            'thematic.scales': 'Scales',
            'title.progress': 'Your progress',
            'toast.operation-done': 'Operation performed',
            'tutorial.mic-tutorial.explain-permission-step.description': 'I need your permission to listen to you play.\nIs that okay with you?',
            'tutorial.mic-tutorial.gain-setting-step.description': 'To hear you better, the sound level should approach the <0>0 dB</0> marker (without exceeding it) when you play a note.',
            'tutorial.mic-tutorial.get-ready-step.find-the-note-on-the-neck-playing-description': "You're all set. Put your mobile in front of you and get ready, the exercise is about to begin.",
            'tutorial.mic-tutorial.get-ready-step.mic-setting-get-ready-step-description': "You're all set. You can now practice some exercises with your instrument.",
            'tutorial.mic-tutorial.permission-denied-step.description-native': 'Access application settings to change permission',
            'tutorial.mic-tutorial.permission-denied-step.description': 'Permission to access the microphone must be granted in order to practice with your instrument.',
            'tutorial.mic-tutorial.quiet-place-step.description': 'Settle down in a quiet place.',
            'tutorial.mic-tutorial.tuning-step.description': "Make sure you're in tune so I can recognize the notes",
            'tutorial.mic-tutorial.unsupported-mic-step.description': 'Unfortunately, there is no microphone accessible via the application.',
            "scale.name.messiaen's mode #3": "Messiaen's mode #3",
            "scale.name.messiaen's mode #4": "Messiaen's mode #4",
            "scale.name.messiaen's mode #5": "Messiaen's mode #5",
            "scale.name.messiaen's mode #6": "Messiaen's mode #6",
            "scale.name.messiaen's mode #7": "Messiaen's mode #7",
            and: 'and',
        },
    },
    fr: {
        translation: {
            'advices.recordingButtonIntro0': 'Salut !!!',
            'advices.recordingButtonIntro1': "Je t'étoute jouer",
            'advices.recordingButtonIntro2': "Si tu as besoin de régler l'écoute, clique sur le bouton micro",
            'advices.recordingButtonIntro3': 'Bon exercice !',
            'all-intervals': 'tous les intervalles',
            'all-scales': 'toutes les gammes',
            'all-strings': 'toutes les cordes',
            'button.accept': "D'accord",
            'button.apply': 'Appliquer',
            'button.back': 'Retour',
            'button.close': 'Fermer',
            'button.done': "C'est fait",
            'button.i-get-it': "J'ai compris",
            'button.in-progress': 'En cours',
            'button.its-ok': "C'est bon",
            'button.lets-go': "C'est parti",
            'button.next': 'Suivant',
            'button.no': 'Non',
            'button.play': 'Jouer',
            'button.privacy-policy': 'Politique de confidentialité',
            'button.restart': 'Relancer',
            'button.restore-default-config-settings': 'Rétablir les paramètres par défaut',
            'button.settings': 'Accéder aux paramètres',
            'button.start': 'Démarrer',
            'button.stop': 'Arrêter',
            'button.terms-and-conditions': "Conditions d'utilisation",
            'button.trigger': 'Déclencher',
            'button.yes': 'Oui',
            'components.end-confirmation-modal.description': 'Ta progression sera tout de même sauvegardée.',
            'components.end-confirmation-modal.title': "Veux-tu arrêter l'exercice en cours ?",
            'components.question-scoring-result.well-done': 'Bien joué',
            'components.visualize-scale-positions-position-selector.positions': 'Positions',
            description: 'Description',
            'estimated-duration': 'Durée estimée : <0>{{estimatedDuration}} minutes</0>',
            'exercise.find-scale-position.app.tip-way-back': "Vous pouvez repartir dans l'autre sens en commençant depuis la flêche",
            'exercise.find-scale-position.app.tip-way': 'Vous pouvez commencer par sélectionner la tonique indiquée par la flêche',
            'exercise.find-scale-position.guitar.tip-way-back': "Vous pouvez repartir dans l'autre sens en commençant depuis la flêche",
            'exercise.find-scale-position.guitar.tip-way': 'Vous pouvez commencer par jouer la tonique indiquée par la flêche',
            'exercise.help': 'Aide',
            'fields.intervals.label': 'Intervales',
            'fields.mode.label': 'Mode',
            'fields.practice-duration.label': 'Durée de la pratique (min)',
            'fields.quiz-mode.label.app': "l'application",
            'fields.quiz-mode.label.guitar': 'ta guitare',
            'fields.quiz-mode.label.title': 'Répondre sur',
            'fields.root.label': 'Tonique',
            'fields.scale-position-types.label': 'Type de position',
            'fields.scale.label': 'Gamme',
            'fields.scales.label': 'Gammes',
            'fields.selectable-strings.label': 'Choisir les cordes',
            'fields.show-intervals.label': 'Montrer les intervalles',
            'fields.show-neck-head-notes.label': 'Notes sur les cordes à vide',
            'fields.with-altered-notes.label': 'Notes altérées',
            'fields.with-compound-intervals.label': 'Inclure les intervalles redoublés',
            'fields.with-diminished-and-augmented-intervals.label': 'Inclure les intervalles diminués et augmentés',
            'interval-name': '$t(interval-number.{{number}}) $t(interval-quality.{{quality}})',
            'interval-number.1': 'unison',
            'interval-number.11': 'onzième',
            'interval-number.13': 'treizième',
            'interval-number.2': 'seconde',
            'interval-number.3': 'tierce',
            'interval-number.4': 'quarte',
            'interval-number.5': 'quinte',
            'interval-number.6': 'sixte',
            'interval-number.7': 'septième',
            'interval-number.8': 'octave',
            'interval-number.9': 'neuvième',
            'interval-quality.A': 'augmentée',
            'interval-quality.AA': 'suraugmentée',
            'interval-quality.d': 'diminuée',
            'interval-quality.dd': 'sous-diminuée',
            'interval-quality.M': 'majeure',
            'interval-quality.m': 'mineure',
            'interval-quality.P': 'juste',
            'not-defined': 'Non défini',
            'notes-on-guitar-neck': 'Les notes sur le manche',
            'lessonType.dictionary': 'Dictionnaire',
            'lessonType.fretboard-exercise': 'Exercice sur le manche',
            'lessonType.listening-exercise': "Exercice d'écoute",
            'lessonType.quiz-exercise': 'Quiz',
            'lessonType.visualization': 'Visualisation sur le manche',
            'pages./find-the-interval-on-the-neck.question': "Pouvez-vous jouer l'interval <0>{{interval}}</0> de <0>{{note}}</0> (corde <0>{{stringIndex}}</0>) ?",
            'pages./find-the-note-from-root-and-interval.question': "Pouvez-vous me donner l'interval de <0>{{givenInterval}}</0> pour la note <0>{{givenNote}}</0> ?",
            'pages./find-the-note-on-the-neck.question': 'Peux-tu jouer la note <0>{{note}}</0> sur la corde numéro <0>{{stringIndex}}</0> ?',
            'pages./find-the-note-on-the-neck/progress.deleting-modal.title': 'Veux-tu vraiment supprimer ta progression ?',
            'pages.DICTIONARY_OF_MUSICAL_SCALES.title': 'Dictionnaire de gammes',
            'pages.FIND_SCALE_POSITION.question': 'Pouvez-vous jouer la gamme de <0>{{tonicPitchClass}}</0> <0>$t(scale.name.{{scaleName}})</0> en passant par les octaves indiquées ?',
            'pages.FIND_SCALE_POSITION.title': 'Trouver la position',
            'pages.FIND_THE_INTERVAL_ON_THE_NECK.title': "Trouve l'interval sur le manche",
            'pages.FIND_THE_NOTE_FROM_ROOT_AND_INTERVAL.title': "Trouve la note depuis la tonique et l'interval",
            'pages.FIND_THE_NOTE_ON_THE_NECK.title': 'Trouver la note',
            'pages.settings.admin.device-id-from-url.title': 'Device Id dans URL',
            'pages.settings.admin.persona.label': 'Persona',
            'pages.settings.admin.mic-tutorial-state.label': 'Réinitialiser',
            'pages.settings.admin.mic-tutorial-state.title': 'Permission microphone',
            'pages.settings.admin.title': 'Admin',
            'pages.settings.admin.trigger-analytics.title': 'Test analytics',
            'pages.settings.admin.trigger-web-error.title': 'Test de surveillance des erreurs (web)',
            'pages.settings.admin.trigger-native-error.title': 'Test de surveillance des erreurs (natif)',
            'pages.settings.general.appearence.option.dark': 'Thème sombre',
            'pages.settings.general.appearence.option.light': 'Thème clair',
            'pages.settings.general.appearence.option.system': "Thème de l'appareil",
            'pages.settings.general.appearence.title': 'Apparence',
            'pages.settings.general.language.option.en': 'English',
            'pages.settings.general.language.option.fr': 'Français',
            'pages.settings.general.language.option.system': "Langue de l'appareil",
            'pages.settings.general.language.title': 'Langue',
            'pages.settings.general.title': 'Général',
            'pages.settings.instrument.title': 'Votre instrument',
            'pages.settings.left-handed.title': 'Manche gaucher',
            'pages.settings.music.title': 'Musique',
            'pages.settings.note-detection.mic-settings.label': 'Réglage du microphone',
            'pages.settings.note-detection.title': 'Détection de note',
            'pages.settings.note-notation.option.english': 'Anglaise : C, D, ..., B',
            'pages.settings.note-notation.option.latin': 'Latine : Do, Re, ..., Si',
            'pages.settings.note-notation.title': 'Notation des notes',
            'pages.settings.reference-frequency.title': 'Fréquence de référence (Hz)',
            'pages.settings.title': 'Paramètres',
            'pages.settings.tuning.group.bass': 'Basse',
            'pages.settings.tuning.group.guitar': 'Guitare',
            'pages.settings.tuning.group.ukulele': 'Ukulélé',
            'pages.settings.tuning.title': 'Accordage',
            'pages.SOUND_CAPTURE_DEBUG.title': 'Debug de la capture de son',
            'pages.thematic.exercises': 'Exercices',
            'pages.VISUALIZE_SCALE_POSITIONS.title': 'Visualiser les positions',
            'pages.visualize_interval_positions.title': 'Visualiser les positions',
            'pages.welcome.title': 'welcome',
            'practice-time': 'Temps de pratique',
            'scale-and-name': 'Gamme $t(scale.name.{{scaleName}})',
            'scale-position-type.compact': 'Compacte',
            'scale-position-type.diagonal': 'Diagonale',
            'scale-position-type.other': 'Autre',
            'scale.name.altered': 'Altérée',
            'scale.name.augmented heptatonic': 'Augmentée heptatonique',
            'scale.name.augmented': 'Augmentée',
            'scale.name.balinese': 'Balinaise',
            'scale.name.bebop locrian': 'Bebop locrien',
            'scale.name.bebop major': 'Bebop majeure',
            'scale.name.bebop minor': 'Bebop mineure',
            'scale.name.bebop': 'Bebop',
            'scale.name.chromatic': 'Chromatique',
            'scale.name.composite blues': 'Composite blues',
            'scale.name.diminished': 'Diminuée',
            'scale.name.dorian #4': 'Dorien #4',
            'scale.name.dorian b2': 'Dorien b2',
            'scale.name.dorian': 'Dorien',
            'scale.name.double harmonic lydian': 'Lydien double harmonique',
            'scale.name.double harmonic major': 'Majeure double harmonique',
            'scale.name.egyptian': 'Égyptienne',
            'scale.name.enigmatic': 'Énigmatique',
            'scale.name.flamenco': 'Flamenco',
            'scale.name.flat six pentatonic': 'Pentatonique bémol 6',
            'scale.name.flat three pentatonic': 'Pentatonique bémol 3',
            'scale.name.half-whole diminished': 'Demi-diminuée',
            'scale.name.harmonic major': 'Majeure harmonique',
            'scale.name.harmonic minor': 'Mineure harmonique',
            'scale.name.hirajoshi': 'Hirajoshi',
            'scale.name.hungarian major': 'Hongroise majeure',
            'scale.name.hungarian minor': 'Hongroise mineure',
            'scale.name.ichikosucho': 'Ichikosucho',
            'scale.name.in-sen': 'In-sen',
            'scale.name.ionian pentatonic': 'Ionien pentatonique',
            'scale.name.iwato': 'Iwato',
            'scale.name.kafi raga': 'Kafi raga',
            'scale.name.kumoijoshi': 'Kumoijoshi',
            'scale.name.leading whole tone': 'Par tons en tête',
            'scale.name.locrian #2': 'Locrien #2',
            'scale.name.locrian 6': 'Locrien 6',
            'scale.name.locrian major': 'Locrien majeure',
            'scale.name.locrian pentatonic': 'Locrien pentatonique',
            'scale.name.locrian': 'Locrien',
            'scale.name.lydian #5P pentatonic': 'Lydien #5P pentatonique',
            'scale.name.lydian #9': 'Lydien #9',
            'scale.name.lydian augmented': 'Lydien augmentée',
            'scale.name.lydian diminished': 'Lydien diminuée',
            'scale.name.lydian dominant pentatonic': 'Lydien dominant pentatonique',
            'scale.name.lydian dominant': 'Lydien dominant',
            'scale.name.lydian minor': 'Lydien mineure',
            'scale.name.lydian pentatonic': 'Lydien pentatonique',
            'scale.name.lydian': 'Lydien',
            'scale.name.major augmented': 'Majeure augmentée',
            'scale.name.major blues': 'Majeure blues',
            'scale.name.major pentatonic': 'Majeure pentatonique',
            'scale.name.major': 'Majeure',
            'scale.name.malkos raga': 'Malkos raga',
            'scale.name.melodic minor': 'Mineure mélodique',
            'scale.name.minor #7M pentatonic': 'Mineure #7M pentatonique',
            'scale.name.minor bebop': 'Mineure bebop',
            'scale.name.minor blues': 'Mineure blues',
            'scale.name.minor hexatonic': 'Mineure hexatonique',
            'scale.name.minor pentatonic': 'Mineure pentatonique',
            'scale.name.minor six diminished': 'Mineure six diminuée',
            'scale.name.minor six pentatonic': 'Mineure six pentatonique',
            'scale.name.minor': 'Mineure',
            'scale.name.mixolydian b6': 'Mixolydien b6',
            'scale.name.mixolydian pentatonic': 'Mixolydien pentatonique',
            'scale.name.mixolydian': 'Mixolydien',
            'scale.name.mystery #1': 'Mystère #1',
            'scale.name.neopolitan major pentatonic': 'Napolitaine majeure pentatonique',
            'scale.name.neopolitan major': 'Napolitaine majeure',
            'scale.name.oriental': 'Orientale',
            'scale.name.pelog': 'Pelog',
            'scale.name.persian': 'Persane',
            'scale.name.phrygian dominant': 'Phrygienne dominante',
            'scale.name.phrygian': 'Phrygienne',
            'scale.name.piongio': 'Piongio',
            'scale.name.prometheus neopolitan': 'Prometheus napolitaine',
            'scale.name.prometheus': 'Prometheus',
            'scale.name.purvi raga': 'Purvi raga',
            'scale.name.ritusen': 'Ritusen',
            'scale.name.scriabin': 'Scriabin',
            'scale.name.six tone symmetric': 'Symétrique à 6 tons',
            'scale.name.spanish heptatonic': 'Espagnole heptatonique',
            'scale.name.super locrian pentatonic': 'Super locrien pentatonique',
            'scale.name.todi raga': 'Todi raga',
            'scale.name.ultralocrian': 'Ultralocrien',
            'scale.name.vietnamese 1': 'Vietnamienne 1',
            'scale.name.whole tone pentatonic': 'Par tons pentatonique',
            'scale.name.whole tone': 'Par tons',
            'scale.tonic.letter': 'T',
            'screenshot-0.title': '<0></0><br />vous écoute jouer<br />pour vous apprendre la <1>théorie musicale</1>',
            'screenshot-1.title': "S'initier aux bases de la <0>théorie musicale</0>",
            'screenshot-2.title': 'Parcourez les <0>exercices</0> et les <0>fiches interactives</0>',
            'screenshot-3.title': 'Répondre sur l’<0>application</0> ou<br />avec votre <0>guitare</0>',
            'screenshot-4.title': "L'application <0>vous écoute</0> jouer<br />et <0>vous guide</0>",
            'screenshot-5.title': 'Suivez votre <0>progression</0>',
            'string-and-pitch-class': 'Corde {{pitchClass}}',
            'tab-bar.home': 'accueil',
            'tab-header.number-of-repetition': 'Nb répétition',
            'thematic.intervals': 'Les intervalles',
            'thematic.notes': 'Les notes',
            'thematic.scales': 'Les gammes',
            'title.progress': 'Ta progression',
            'toast.operation-done': 'Opération effectuée',
            'tutorial.mic-tutorial.explain-permission-step.description': "J'ai besoin de ta permission pour pouvoir t'écouter jouer.\nC'est bon pour toi ?",
            'tutorial.mic-tutorial.gain-setting-step.description': "Pour mieux t'entendre, le niveau sonore doit s'approcher du marqueur <0>0 dB</0> (sans le dépasser) lorsque tu joues une note.",
            'tutorial.mic-tutorial.get-ready-step.find-the-note-on-the-neck-playing-description': "Tout est ok. Pose ton mobile devant toi et prépare-toi, l'exercice va débuter.",
            'tutorial.mic-tutorial.get-ready-step.mic-setting-get-ready-step-description': 'Tout est ok. Tu peux maintenant pratiquer sur certains exercices avec ton instrument.',
            'tutorial.mic-tutorial.permission-denied-step.description-native': "Accèder aux paramètres de l'application pour changer la permission",
            'tutorial.mic-tutorial.permission-denied-step.description': "La permission pour accéder au microphone doit être accepté pour pouvoir pratiquer l'exercice avec ton instrument.",
            'tutorial.mic-tutorial.quiet-place-step.description': 'Installe-toi dans un endroit calme.',
            'tutorial.mic-tutorial.tuning-step.description': "Assure-toi d'être bien accordé pour que je puisse reconnaître les notes",
            'tutorial.mic-tutorial.unsupported-mic-step.description': "Malheureusement il n'y a pas de microphone accessible par l'application.",
            "scale.name.messiaen's mode #3": 'Mode de Messiaen #3',
            "scale.name.messiaen's mode #4": 'Mode de Messiaen #4',
            "scale.name.messiaen's mode #5": 'Mode de Messiaen #5',
            "scale.name.messiaen's mode #6": 'Mode de Messiaen #6',
            "scale.name.messiaen's mode #7": 'Mode de Messiaen #7',
            and: 'et',
        },
    },
};
