import { useContext } from 'react';
import { usePreferencesStore } from '../preferences/use-preferences-context';
import { ScaleName } from '../../../services/scales/scale.type';
import { ServicesContext } from '../../../services/services.context';
import { States } from './states';
import { manageProgressDataAction } from './store/specific-actions/manage-progress-data';
import { FindScalePositionExerciseProvider } from './store/context';
import { computeQuizTurnQuestionAction } from './store/specific-actions/compute-quiz-turn-question';
import { computeQuizTurnResultAction } from './store/specific-actions/compute-quiz-turn-result';
import { getDefaultConfig } from './get-default-config';
import { getProgressDataAction } from './store/specific-actions/get-progress-data';

type FindScalePositionProps = {
    scaleName: ScaleName | null;
};

export function FindScalePosition({ scaleName }: FindScalePositionProps) {
    const { findScalePositionProgress } = useContext(ServicesContext);
    const tuning = usePreferencesStore((state) => state.tuningInfo);
    const fretboard = usePreferencesStore((state) => state.fretboard);
    const defaultConfig = getDefaultConfig(tuning, fretboard, scaleName);

    return (
        <FindScalePositionExerciseProvider
            computeQuizTurnQuestion={computeQuizTurnQuestionAction}
            computeQuizTurnResult={computeQuizTurnResultAction}
            config={defaultConfig}
            getProgressData={getProgressDataAction}
            manageProgressData={manageProgressDataAction}
            progressService={findScalePositionProgress}
            thematic="scales"
        >
            <States scaleName={scaleName} defaultConfig={defaultConfig} />
        </FindScalePositionExerciseProvider>
    );
}
