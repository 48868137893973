import { ReactNode, forwardRef } from 'react';
import { motion } from 'framer-motion';

type TutorialStepContentProps = {
    content: ReactNode;
    action: ReactNode;
};

export const TutorialStepContent = forwardRef<
    HTMLDivElement,
    TutorialStepContentProps
>(({ content, action }, ref) => {
    return (
        <motion.div
            ref={ref}
            initial={{ x: 10, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: -10, opacity: 0 }}
            className="absolute inset-0 flex flex-col justify-between items-center min-h-full gap-4 p-5"
        >
            <div className="grow flex flex-col justify-center items-center gap-4">
                {content}
            </div>
            {action}
        </motion.div>
    );
});

TutorialStepContent.displayName = 'TutorialStepContent';
