import { Position } from '@notacami/core/fretboard';
import { getRangeNumber } from '../../utils/array.utils';
import { BoundariesOnString } from './fretboard.types';

export function getPositionsByFretBoundariesOnStrings(
    boundariesOnStrings: BoundariesOnString[],
): Position[] {
    const positions = boundariesOnStrings.flatMap((boundariesOnString) => {
        const stringIndex = boundariesOnString.stringIndex;
        const isMinAndMaxEqual =
            boundariesOnString.min === boundariesOnString.max;
        if (isMinAndMaxEqual) {
            return [[stringIndex, boundariesOnString.min] satisfies Position];
        } else {
            const fretIndexes = getRangeNumber(
                boundariesOnString.min,
                boundariesOnString.max,
            );
            return fretIndexes.map(
                (fretIndex) => [stringIndex, fretIndex] satisfies Position,
            );
        }
    });
    return positions;
}
