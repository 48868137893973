import { useContext, useEffect, useState } from 'react';
import { Thematic } from '@notacami/core/thematic';
import { SliderInput } from '../ui/slider-input';
import { ServicesContext } from '../../services/services.context';

type GainInputProps = {
    thematic: Thematic;
};

export default function GainInput({ thematic }: GainInputProps) {
    const { pitchDetection, preferenceGainStore } = useContext(ServicesContext);
    const [gain, setGain] = useState<number | undefined>();
    const [defaultGain, setDefaultGain] = useState<number | undefined>();

    const handleGainValueChange = (value: number) => {
        setGain(value);
        preferenceGainStore.set(value);
        pitchDetection.applyGain(value);
    };

    useEffect(() => {
        async function getPersistedGain() {
            const persistedGain = await preferenceGainStore.get();
            setDefaultGain(persistedGain);
            setGain(persistedGain);
        }
        getPersistedGain();
    }, []);

    return (
        <div className="flex gap-4 min-w-[150px] max-w-3xl h-5">
            {defaultGain !== undefined ? (
                <>
                    <SliderInput
                        min={1}
                        max={100}
                        defaultValue={defaultGain}
                        onValueChange={handleGainValueChange}
                        thematic={thematic}
                    />
                    <div className="tabular-nums">{gain}</div>
                </>
            ) : null}
        </div>
    );
}
