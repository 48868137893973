import Emittery from 'emittery';

export enum MicError {
    UNSUPPORTED = 'unsupported',
    NOT_PERMITTED = 'not permitted',
}

export type UpdatedData = {
    buffer: Float32Array;
    bufferLength: number;
    sampleRate: number;
};

export type PitchDetectionServiceEvents = {
    'mic-error': MicError;
    'mic-close': undefined;
    'mic-open': undefined;
    'frequencies-update': UpdatedData;
    'sinewave-update': UpdatedData;
    'frequency-detect': number;
    'silence-detect': undefined;
    'meter-update': number;
};

export type IPitchDetectionService = Emittery<PitchDetectionServiceEvents> & {
    resume(): Promise<
        | {
              error: MicError;
          }
        | {
              error: null;
          }
    >;

    applyGain(value: number): void;

    get isRunning(): boolean;

    stop(): void;
};
