import { Position } from '@notacami/core/fretboard';
import { NoteStartEvent } from '../../../../../../services/composer';
import {
    FretboardNoteDetails,
    isPositionEqual,
} from '../../../../../../services/fretboard';
import { BuildCellsComponentProps } from '../../../../guitar-neck/guitar-neck.types';
import { NeckCellInterval } from '../../../../neck-cells/neck-cell-interval';
import { NeckNote } from '../../../../neck-cells/neck-note';

type StepTeacherPlayingNeckCellProps = BuildCellsComponentProps & {
    fretboardNoteDetails: FretboardNoteDetails;
    notesToDisplay: NoteStartEvent[];
    notesToPlayPositions: Position[];
};

export function StepTeacherListeningNeckCell({
    fretboardNoteDetails,
    isOpenString,
    isVertical,
    notesToDisplay,
    notesToPlayPositions,
    position,
    getElementByPosition,
}: StepTeacherPlayingNeckCellProps) {
    const noteToDisplay = notesToDisplay.find((noteToDisplay) =>
        isPositionEqual(noteToDisplay.position, position),
    );

    const isNoteToPlay = notesToPlayPositions.some((notesToPlayPosition) =>
        isPositionEqual(notesToPlayPosition, position),
    );

    const fretboardNote = getElementByPosition(fretboardNoteDetails);

    if (noteToDisplay === undefined && position[1] === 0 && !isNoteToPlay) {
        return (
            <NeckNote
                isOpenString={isOpenString}
                isVertical={isVertical}
                position={position}
                notePitchClass={fretboardNote.pitchClass}
            />
        );
    }

    return (
        <>
            {noteToDisplay !== undefined &&
            noteToDisplay.noteDisplayMode === 'pitch-class' ? (
                <NeckNote
                    isPlayed={isNoteToPlay}
                    isOpenString={isOpenString}
                    isVertical={isVertical}
                    notePitchClass={noteToDisplay.pitchClassToDisplay}
                    position={position}
                />
            ) : null}
            {noteToDisplay !== undefined &&
            noteToDisplay.noteDisplayMode === 'interval' &&
            noteToDisplay.intervalToDisplay !== undefined ? (
                <NeckCellInterval
                    isPlayed={isNoteToPlay}
                    isOpenString={isOpenString}
                    isVertical={isVertical}
                    interval={noteToDisplay.intervalToDisplay}
                    position={position}
                />
            ) : null}
            {noteToDisplay === undefined && isNoteToPlay ? (
                <NeckNote
                    isPlayed
                    isOpenString={isOpenString}
                    isVertical={isVertical}
                    position={position}
                    notePitchClass={fretboardNote.pitchClass}
                />
            ) : null}
        </>
    );
}
