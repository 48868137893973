import { getNoteFromRootAndInterval } from '../../../../../../services/notes/get-note-from-root-and-interval';
import {
    FindTheNoteFromRootAndIntervalAnswer,
    FindTheNoteFromRootAndIntervalQuestion,
} from '../../../types';

export function computeCorrectAnswer(
    question: FindTheNoteFromRootAndIntervalQuestion,
): FindTheNoteFromRootAndIntervalAnswer {
    const correctAnswer = getNoteFromRootAndInterval(
        question.givenNote,
        question.givenInterval,
    );

    return correctAnswer;
}
