import { MotionSurface } from '@notacami/ui';
import { MdxComponent } from '../../../../mdx-component';

type StepTeacherSpeakingProps = {
    text: string;
};

export function StepTeacherSpeaking({ text }: StepTeacherSpeakingProps) {
    return (
        <MotionSurface
            surfaceId="app"
            className="relative max-w-md px-4 py-2 rounded-xl"
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
        >
            <MdxComponent text={text} />
        </MotionSurface>
    );
}
