import VuMeterMarkers from './VuMeterMarkers';

export default function VuMeter() {
    return (
        <div className="bg-neutral-200 w-60 rounded-xl border-2 border-neutral-300 p-px">
            <div className="flex gap-2 justify-between items-center bg-neutral-900 px-3 py-1 rounded-xl relative border-neutral-300 border-2">
                <div className="text-neutral-400 text-xs">
                    VU <span className="text-xs text-neutral-500">(dB)</span>
                </div>
                <VuMeterMarkers />
            </div>
        </div>
    );
}
