---
title: Getting started
---

I'd like to share a few tips with you before you start browsing the application.

## Who is <LogoText /> for?

This app is for all those curious people who wonder: How is music constructed? Why does the melody sometimes sound sad and sometimes happy?

## What can <LogoText /> do for you?

The application is made up of several themes that will enable you to
better understand the neck of your instrument and music theory.

The aim is to help you progress in your **improvisations** and **compositions**.

The various activities have been designed to be as fun as possible.

The exercises listen to you and guide you as needed.

<LogoText /> invites you to take your intrument in your hands and practice.

## How to use <LogoText />?

The application's format is designed to allow you to practice
over very short periods of time (minimum 1 minute).

The application has been thought out with themes to follow in order.
You can start with “**Notes**”, then move on to the next one.

**Enjoy the game!**