import { useEffect, useState } from 'react';
import { InputNumber } from './input-number';

type DurationProps = {
    name: string;
    value: number;
    onChange: (value: number) => void;
    onBlur: () => void;
};

const MIN_ROUND_NUMBER = 1;
const MAX_ROUND_NUMBER = 15;
const DURATION_STEPS = [1, 3, 5, 10, 15];

export function Duration({ name, onBlur, onChange, value }: DurationProps) {
    const [inputValue, setInputValue] = useState<number>(value);

    const handleChange = (newValue: number) => {
        setInputValue(newValue);
    };

    const handleMinusButtonClick = () => {
        setInputValue((prev) => {
            const index = DURATION_STEPS.findIndex((value) => value === prev);
            return DURATION_STEPS[Math.max(0, index - 1)];
        });
    };

    const handlePlusButtonClick = () => {
        setInputValue((prev) => {
            const index = DURATION_STEPS.findIndex((value) => value === prev);
            return DURATION_STEPS[
                Math.min(DURATION_STEPS.length - 1, index + 1)
            ];
        });
    };

    useEffect(() => {
        if (!onChange) return;
        onChange(inputValue);
    }, [onChange, inputValue]);

    return (
        <InputNumber
            className="w-10"
            onMinusClick={handleMinusButtonClick}
            onPlusClick={handlePlusButtonClick}
            value={value}
            min={MIN_ROUND_NUMBER}
            max={MAX_ROUND_NUMBER}
            id={name}
            onValueChange={handleChange}
            onBlur={onBlur}
        />
    );
}
