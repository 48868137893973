import { createStore } from 'zustand';
import { Persona } from '../../../services/persona';

export type PersonaProps = {
    persona: Persona | null;
};

export type PersonaState = PersonaProps & {
    setPersona: (persona: Persona) => void;
};

export type PersonaStore = ReturnType<typeof createPersonaStore>;

export const createPersonaStore = (initProps: PersonaProps) => {
    return createStore<PersonaState>()((set) => ({
        ...initProps,
        setPersona: (persona: Persona) => {
            set({
                persona,
            });
        },
    }));
};
