import { Link } from 'react-router-dom';
import { LogoText, Title } from '@notacami/ui';
import { Settings2 } from 'lucide-react';
import { PageUrls } from '../utils/routes.contants';
import { useShowTabBar } from '../components/ui/tab-bar.hooks';
import { HomePageContent } from '../components/business/home-page-content';
import { PageLayout } from '../components/business/page-layout';
import { LogoDevBadge } from '../components/business/logo-dev-badge';

export function HomePage() {
    useShowTabBar();

    return (
        <PageLayout
            title={
                <div className="flex gap-2 items-center">
                    <Title level={1} tagName="h1">
                        <LogoText className="text-3xl" />
                    </Title>
                    <LogoDevBadge />
                </div>
            }
            rightAction={
                <Link to={PageUrls.SETTINGS}>
                    <Settings2 size={30} />
                </Link>
            }
            content={<HomePageContent />}
        />
    );
}
