import { NavigationButtonContainer } from '../../../ui/navigation-button-container';
import { useTryToResumeOrPrompt } from '../../../../hooks/use-try-to-resume-or-prompt';
import { Consumers } from '../../../../services/pitch-detection-consumer/consumers';
import { useTranslation } from '../../../../hooks/use-translation';
import { useSettingsStore } from '../settings.store';

export function MicSection() {
    const { t } = useTranslation();
    const openModal = useSettingsStore((state) => state.openModal);

    const { execute, isPending } = useTryToResumeOrPrompt({
        consumerId: Consumers.SETTINGS_MIC_SECTION_TRY,
    });

    const handleClick = () => {
        openModal('gain-setting');
        execute();
    };

    return (
        <div>
            <NavigationButtonContainer
                onClick={handleClick}
                withLoading={isPending}
                withChevron={!isPending}
                className="w-full"
            >
                {t('pages.settings.note-detection.mic-settings.label')}
            </NavigationButtonContainer>
        </div>
    );
}
