import { ThemePreference } from '../../../utils/theme.types';
import { StoreCapacitor } from '../storage.capacitor';

export function getPreferenceThemeCapacitorStore() {
    return new StoreCapacitor<ThemePreference>(
        'settings.theme',
        'system',
        (value) => value,
        (value) => value as ThemePreference,
    );
}
