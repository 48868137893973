import { cn } from '@notacami/core/css';

export function GradientDivider() {
    return (
        <div
            className={cn(
                'relative opacity-30 h-px w-1/2 self-center',
                'bg-gradient-to-r',
                'from-neutral-900/30 via-neutral-900 to-neutral-900/30',
                'dark:from-neutral-100/30 dark:via-neutral-100 dark:to-neutral-100/30',
            )}
        />
    );
}
