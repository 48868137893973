import { cn } from '@notacami/core/css';
import { getNoteItemBaseClassnames } from '../../../services/fretboard';

type NoteSelectProps = {
    isOpenString: boolean;
    isVertical: boolean;
    className?: string;
};

export function PlayingAreaCell({
    isOpenString,
    isVertical,
    className,
}: NoteSelectProps) {
    return (
        <div className={cn('animate-pulse', className)}>
            <div
                className={cn(
                    ...getNoteItemBaseClassnames(isVertical, isOpenString),
                    'bg-neutral-500/70 dark:bg-neutral-500/70',
                )}
            />
        </div>
    );
}
