import { cn } from '@notacami/core/css';
import { Title, TitleLevel, TitleTagName } from './title';
import { Divider } from './divider';

type DividerWithTitleProps = {
    title: string;
    className?: string;
    tagName: TitleTagName;
    titleLevel: TitleLevel;
};

export function DividerWithTitle({
    title,
    className,
    tagName,
    titleLevel,
}: DividerWithTitleProps) {
    return (
        <div className={cn('flex items-center gap-2', className)}>
            <Title tagName={tagName} level={titleLevel}>
                {title}
            </Title>
            <Divider className="grow" />
        </div>
    );
}
