import { Position } from '@notacami/core/fretboard';
import {
    Fretboard,
    getRandomPositionOnFretboard,
    ScalePosition,
    ScalePositionType,
} from '../../../../../../services/fretboard';
import { ScaleName } from '../../../../../../services/scales/scale.type';
import { getRangeNumberByLength } from '../../../../../../utils/array.utils';
import { getRandomElement } from '../../../../../../utils/get-random-element';
import { getScalePositionsByScaleNameAndPosition } from './get-scale-positions-by-scale-name-and-position';

export function tryToFindScalePositionByScaleNameAndPosition(
    scaleName: ScaleName,
    fretboard: Fretboard,
    availableStringIndexes: number[],
    availableScalePositionTypes: ScalePositionType[],
    positionsAlreadyTried: Position[],
) {
    const numberOfFrets = fretboard.noteDetails[0].length;

    const numberOfPossiblePositions =
        availableStringIndexes.length * numberOfFrets;

    if (numberOfPossiblePositions === positionsAlreadyTried.length) {
        return null;
    }

    const randomPosition = getRandomPositionOnFretboard(
        availableStringIndexes,
        numberOfFrets,
        positionsAlreadyTried,
    );

    const scalePositions = getScalePositionsByScaleNameAndPosition(
        fretboard,
        scaleName,
        randomPosition,
    );

    const hasAtLeastOneScalePositionWithInterestingType = scalePositions.some(
        (scalePosition) =>
            availableScalePositionTypes.includes(scalePosition.type),
    );

    if (hasAtLeastOneScalePositionWithInterestingType) {
        return getRandomScalePositionByAvailableTypes(
            scalePositions,
            availableScalePositionTypes,
        );
    }

    return tryToFindScalePositionByScaleNameAndPosition(
        scaleName,
        fretboard,
        availableStringIndexes,
        availableScalePositionTypes,
        [...positionsAlreadyTried, randomPosition],
    );
}

function getRandomScalePositionByAvailableTypes(
    scalePositions: ScalePosition[],
    availableScalePositionTypes: ScalePositionType[],
): ScalePosition {
    const filteredScalePositions = scalePositions.filter(({ type }) =>
        availableScalePositionTypes.includes(type),
    );

    const indexes = getRangeNumberByLength(filteredScalePositions.length);
    const randomIndex = getRandomElement(indexes);
    return filteredScalePositions[randomIndex];
}
