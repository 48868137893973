import { LanguagePreference } from '../../i18n/languages.types';
import { StoreCapacitor } from '../storage.capacitor';

export function getPreferenceLanguageCapacitorStore() {
    return new StoreCapacitor<LanguagePreference>(
        'settings.language',
        'system',
        (value) => value,
        (value) => value as LanguagePreference,
    );
}
