import { Position } from '@notacami/core/fretboard';
import { getRangeNumber } from '../../utils/array.utils';
import { getCombinationFromTwoArray } from '../../utils/get-combination-from-two-arrays';
import { Fretboard } from './fretboard.types';

export function getFretboardAllPositions(fretboard: Fretboard): Position[] {
    const numberOfStrings = fretboard.noteDetails.length;
    const numberOfFrets = fretboard.noteDetails[0].length;
    const positions = getCombinationFromTwoArray(
        getRangeNumber(0, numberOfStrings - 1),
        getRangeNumber(0, numberOfFrets - 1),
    );

    return positions;
}
