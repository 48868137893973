import { MultiSelect, MultiSelectOption } from '../multi-select';

type MultiSelectFieldProps<T> = {
    label: string;
    value: MultiSelectOption<T>[];
    onChange: (value: MultiSelectOption<T>[]) => void;
};

export function MultiSelectField<T>({
    label,
    value,
    onChange,
}: MultiSelectFieldProps<T>) {
    return (
        <div className="flex flex-col gap-4 justify-between items-center">
            <div>{label}</div>
            <MultiSelect onChange={onChange} options={value} />
        </div>
    );
}
