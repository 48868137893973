import { X } from 'lucide-react';
import { motion } from 'framer-motion';
import { forwardRef } from 'react';
import { cn } from '@notacami/core/css';
import { getNoteItemBaseClassnames } from '../../../services/fretboard';
import { getNoteColorClassnamesByStatus } from '../utils';

type NeckCellErrorProps = {
    isOpenString: boolean;
    isVertical: boolean;
};

export const NeckCellError = forwardRef<HTMLDivElement, NeckCellErrorProps>(
    ({ isOpenString, isVertical }, ref) => {
        const colors = getNoteColorClassnamesByStatus('error');

        return (
            <motion.div
                ref={ref}
                initial={{ opacity: 0, scale: 3 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 1 }}
                className={cn(
                    ...getNoteItemBaseClassnames(isVertical, isOpenString),
                    colors.text,
                    colors.border,
                    'border-4',
                    !isOpenString && colors.backgroundTransparent,
                    isOpenString && colors.background,
                )}
            >
                <div>
                    <X />
                </div>
            </motion.div>
        );
    },
);
NeckCellError.displayName = 'NeckCellError';
