import { PositionWithInterval } from '../../../../../../services/fretboard';
import { FindScalePositionAnswer } from '../../../types';

export function computeCorrectAnswer(
    scalePositionsWithInterval: PositionWithInterval[],
): FindScalePositionAnswer {
    const positions = scalePositionsWithInterval.map(
        ({ position }) => position,
    );

    const wayBackPositions = [...positions].reverse();

    return {
        way: positions,
        wayBack: wayBackPositions,
    };
}
