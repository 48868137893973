import { FindScalePositionProgressStoredPayload } from '../../progress/find-scale-position/types';
import { StoredProgressEntries } from '../../progress/progress.types';
import { StoreCapacitor } from '../storage.capacitor';

export function getFindScalePositionProgressCapacitorStore() {
    return new StoreCapacitor<
        StoredProgressEntries<FindScalePositionProgressStoredPayload>
    >(
        'quiz.find-scale-position.progress',
        [],
        (value) => JSON.stringify(value),
        (value) => JSON.parse(value),
    );
}
