import { Link } from 'react-router-dom';
import { CornerDownLeft } from 'lucide-react';
import { Title } from '@notacami/ui';
import { useTranslation } from '../../hooks/use-translation';
import { PageUrls } from '../../utils/routes.contants';
import SoundCaptureDebug from '../../components/business/sound-capture-debug';
import { PageLayout } from '../../components/business/page-layout';

export function SoundCaptureDebugPage() {
    const { t } = useTranslation();
    return (
        <PageLayout
            title={
                <Title level={2} tagName="h1">
                    {t('pages.SOUND_CAPTURE_DEBUG.title')}
                </Title>
            }
            leftAction={
                <Link to={PageUrls.SETTINGS_ADMIN}>
                    <CornerDownLeft size={30} />
                </Link>
            }
            content={<SoundCaptureDebug />}
        />
    );
}
