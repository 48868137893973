import { ColorVariant } from './design.types';

export function getClassnamesByColorVariant(variant: ColorVariant) {
    switch (variant) {
        case 'indigo':
            return {
                backgroundTransparent: 'bg-indigo-500/80',
                background: 'bg-indigo-500',
                backgroundHover: 'hover:bg-indigo-600',
                border: 'border-indigo-500',
                borderHover:
                    'hover:border-indigo-900 dark:hover:border-indigo-300',
                ring: 'ring-indigo-500',
                text: 'text-lighter',
                textColored: 'text-indigo-500 dark:text-indigo-400',
                textColoredHover:
                    'hover:text-indigo-800 dark:hover:text-indigo-200',
                stroke: 'stroke-indigo-500 dark:stroke-indigo-400',
                fill: 'fill-indigo-500 dark:fill-indigo-400',
            };
        case 'emerald':
            return {
                backgroundTransparent:
                    'bg-emerald-400/80 dark:bg-emerald-300/80',
                background: 'bg-emerald-400 dark:bg-emerald-300',
                backgroundHover:
                    'hover:bg-emerald-300 dark:hover:bg-emerald-100',
                border: 'border-emerald-400',
                borderHover:
                    'hover:border-emerald-600 dark:hover:border-emerald-200',
                ring: 'ring-emerald-400',
                text: 'text-darker',
                textColored: 'text-emerald-600 dark:text-emerald-400',
                textColoredHover:
                    'hover:text-emerald-800 dark:hover:text-emerald-200',
                stroke: 'stroke-emerald-500 dark:stroke-emerald-400',
                fill: 'fill-emerald-500 dark:fill-emerald-400',
            };
        case 'yellow':
            return {
                backgroundTransparent: 'bg-yellow-400/80 dark:bg-yellow-500/80',
                background: 'bg-yellow-400 dark:bg-yellow-500',
                backgroundHover: 'hover:bg-yellow-300 dark:hover:bg-yellow-400',
                border: 'border-yellow-400 dark:border-yellow-500',
                borderHover:
                    'hover:border-yellow-500 dark:hover:border-yellow-300',
                ring: 'ring-yellow-400 dark:ring-yellow-500',
                text: 'text-darker',
                textColored: 'text-yellow-600 dark:text-yellow-400',
                textColoredHover:
                    'hover:text-yellow-800 dark:hover:text-yellow-200',
                stroke: 'stroke-yellow-500 dark:stroke-yellow-400',
                fill: 'fill-yellow-600 dark:fill-yellow-400',
            };
        case 'sky':
            return {
                backgroundTransparent: 'bg-sky-500/80',
                background: 'bg-sky-500',
                backgroundHover: 'hover:bg-sky-600',
                border: 'border-sky-400 dark:border-sky-500',
                borderHover: 'hover:border-sky-600 dark:hover:border-sky-300',
                ring: 'ring-sky-400 dark:ring-sky-500',
                text: 'text-lighter',
                textColored: 'text-sky-600 dark:text-sky-400',
                textColoredHover: 'hover:text-sky-900 dark:hover:text-sky-200',
                stroke: 'stroke-sky-500 dark:stroke-sky-400',
                fill: 'fill-sky-500 dark:fill-sky-400',
            };
        case 'pink':
            return {
                backgroundTransparent: 'bg-fuchsia-400/80',
                background: 'bg-fuchsia-500',
                backgroundHover: 'hover:bg-fuchsia-600',
                border: 'border-fuchsia-400 dark:border-fuchsia-500',
                borderHover:
                    'hover:border-fuchsia-600 dark:hover:border-fuchsia-300',
                ring: 'ring-fuchsia-400 dark:ring-fuchsia-500',
                text: 'text-lighter',
                textColored: 'text-fuchsia-500 dark:text-fuchsia-400',
                textColoredHover:
                    'hover:text-fuchsia-800 dark:hover:text-fuchsia-200',
                stroke: 'stroke-fuchsia-500 dark:stroke-fuchsia-400',
                fill: 'fill-fuchsia-500 dark:fill-fuchsia-400',
            };
        case 'red':
            return {
                backgroundTransparent: 'bg-red-500/80',
                background: 'bg-red-500',
                backgroundHover: 'hover:bg-red-600',
                border: 'border-red-500',
                borderHover: 'hover:border-red-800 dark:hover:border-red-300',
                ring: 'ring-red-500',
                text: 'text-lighter',
                textColored: 'text-red-500 dark:text-red-400',
                textColoredHover: 'hover:text-red-800 dark:hover:text-red-200',
                stroke: 'stroke-red-500 dark:stroke-red-400',
                fill: 'fill-red-500 dark:fill-red-400',
            };
        case 'slate':
            return {
                backgroundTransparent: 'bg-slate-400/80 dark:bg-slate-500/80',
                background: 'bg-slate-500 dark:bg-slate-500',
                backgroundHover: 'hover:bg-slate-600 dark:hover:bg-slate-600',
                border: 'border-slate-400 dark:border-slate-500',
                borderHover:
                    'hover:border-slate-700 dark:hover:border-slate-400',
                ring: 'ring-slate-400 dark:ring-slate-500',
                text: 'text-lighter',
                textColored: 'text-slate-500 dark:text-slate-400',
                textColoredHover:
                    'hover:text-slate-800 dark:hover:text-slate-200',
                stroke: 'stroke-slate-500 dark:stroke-slate-400',
                fill: 'fill-slate-500 dark:fill-slate-400',
            };
        case 'neutral-invert':
            return {
                backgroundTransparent:
                    'bg-neutral-600/80 dark:bg-neutral-300/80',
                background: 'bg-neutral-600 dark:bg-neutral-300',
                backgroundHover:
                    'hover:bg-neutral-950 dark:hover:bg-neutral-50',
                border: 'border-neutral-600 dark:border-neutral-300',
                borderHover:
                    'hover:border-neutral-950 dark:hover:border-neutral-50',
                ring: 'ring-neutral-600 dark:ring-neutral-300',
                text: 'text-lighter dark:text-darker',
                textColored: 'text-darker dark:text-lighter',
                textColoredHover: 'hover:text-dark dark:hover:text-light',
                stroke: 'stroke-neutral-600 dark:stroke-neutral-300',
                fill: 'fill-neutral-600 dark:fill-neutral-300',
            };
        case 'neutral':
            return {
                backgroundTransparent:
                    'bg-neutral-300/80 dark:bg-neutral-700/80',
                background: 'bg-neutral-300 dark:bg-neutral-700',
                backgroundHover:
                    'hover:bg-neutral-100 dark:hover:bg-neutral-800',
                border: 'border-neutral-300 dark:border-neutral-700',
                borderHover:
                    'hover:border-neutral-500 dark:hover:border-neutral-400',
                ring: 'ring-neutral-300 dark:ring-neutral-700',
                text: 'text-darker dark:text-lighter',
                textColored: 'text-darker dark:text-lighter',
                textColoredHover: 'hover:text-dark dark:hover:text-light',
                stroke: 'stroke-neutral-300 dark:stroke-neutral-700',
                fill: 'fill-neutral-300 dark:fill-neutral-700',
            };
    }
}
