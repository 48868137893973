import { Dialog, DialogContent, DialogHeader } from '../ui/dialog';
import { usePlatform } from '../../hooks/use-platform';
import { useSafeAreaInsets } from '../../hooks/use-safe-area-insets';
import { MicDeniedPermissionContent } from './mic-denied-permission-content';

type MicDeniedPermissionDialogProps = {
    onOpenChange?: (value: boolean) => void;
    open: boolean;
};

export function MicDeniedPermissionDialog({
    onOpenChange,
    open,
}: MicDeniedPermissionDialogProps) {
    const { platform } = usePlatform();
    const insets = useSafeAreaInsets();

    return open && platform !== undefined ? (
        <Dialog open={open} onOpenChange={onOpenChange}>
            <DialogContent insets={insets}>
                <DialogHeader>
                    <MicDeniedPermissionContent platform={platform} />
                </DialogHeader>
            </DialogContent>
        </Dialog>
    ) : null;
}
