import { IStore } from '../../storage/storage.type';
import { ProgressService } from '../progress';
import { ProgressRepository } from '../progress.repository';
import { StoredProgressEntries } from '../progress.types';
import { fromResult, fromStoredEntry } from './progress-entry';
import { FindTheIntervalOnTheNeckProgressStoredPayload } from './types';

export function getFindTheIntervalOnTheNeckProgressInstance(
    storeInstance: IStore<
        StoredProgressEntries<FindTheIntervalOnTheNeckProgressStoredPayload>
    >,
) {
    return new ProgressService(
        new ProgressRepository(storeInstance, fromStoredEntry),
        fromResult,
    );
}
