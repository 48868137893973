import { ReactNode } from 'react';
import { getDeviceInfo } from '@notacami/core/device';
import { SafeAreaInsets } from '../components/business/safe-area.types';
import { SafeAreaProvider } from '../components/business/safe-area.context';
import { DeviceIdFromUrlProvider } from '../components/business/device-id.context';
import { useGetInitialDeviceIdFromUrl } from './use-get-initial-device-id-from-url';

type DeviceBootProps = {
    safeAreaInsets?: SafeAreaInsets;
    children: ReactNode;
};

export function DeviceBoot({
    safeAreaInsets: initialSafeAreaInsets,
    children,
}: DeviceBootProps) {
    const initialDeviceIdFromUrl = useGetInitialDeviceIdFromUrl();
    const initialSafeAreaInsetsFromUrl =
        initialDeviceIdFromUrl !== null
            ? getDeviceInfo(initialDeviceIdFromUrl).insets
            : undefined;

    return (
        <DeviceIdFromUrlProvider deviceId={initialDeviceIdFromUrl}>
            <SafeAreaProvider
                forceInsets={
                    initialSafeAreaInsets ?? initialSafeAreaInsetsFromUrl
                }
            >
                {children}
            </SafeAreaProvider>
        </DeviceIdFromUrlProvider>
    );
}
