import { useContext, useEffect, useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import { NoteDetails, NoteName } from '../../../services/notes/notes.types';
import { ServicesContext } from '../../../services/services.context';
import { NoteItem } from './note-item';

export function Note() {
    const { notePlayed } = useContext(ServicesContext);
    const [notes, setNotes] = useState<{ noteName: NoteName; id: number }[]>(
        [],
    );

    const handleAskDisappear = (id: number) => {
        setNotes((prev) => prev.filter((note) => note.id !== id));
    };

    const handleNoteChange = ({
        noteDetails,
    }: {
        noteDetails: NoteDetails;
    }) => {
        setNotes((prev) => [
            ...prev,
            { noteName: noteDetails.pitchClass, id: new Date().getTime() },
        ]);
    };

    useEffect(() => {
        notePlayed.on('changed', handleNoteChange);

        return () => {
            notePlayed.off('changed', handleNoteChange);
        };
    }, []);

    return (
        <AnimatePresence>
            {notes.map((note) => (
                <NoteItem
                    key={note.id}
                    id={note.id}
                    noteName={note.noteName}
                    onAskDisappear={handleAskDisappear}
                />
            ))}
        </AnimatePresence>
    );
}
