import { BuildCellsComponentProps } from '../../../guitar-neck/guitar-neck.types';
import {
    isPositionEqual,
    PositionWithInterval,
} from '../../../../../services/fretboard';
import { NeckCellInterval } from '../../../neck-cells/neck-cell-interval';

type AnswerSectionResultCellProps = BuildCellsComponentProps & {
    positions: PositionWithInterval[];
};

export function AnswerSectionResultCell({
    isOpenString,
    position,
    isVertical,
    positions,
}: AnswerSectionResultCellProps) {
    const intervalPosition = positions.find((positionWithInterval) =>
        isPositionEqual(positionWithInterval.position, position),
    );

    if (intervalPosition === undefined) {
        return null;
    }

    return (
        <NeckCellInterval
            isOpenString={isOpenString}
            isVertical={isVertical}
            interval={intervalPosition.interval ?? ''}
            position={position}
        />
    );
}
