import { NoteEvent } from '@notacami/core/composer';
import { FretboardNoteDetails } from '../fretboard';
import { EventInPart } from './composer.types';
import { createPartEvents } from './create-part-events';

export function computeNotesToPlay(
    noteEvents: NoteEvent[],
    fretboardNoteDetails: FretboardNoteDetails,
): EventInPart[] {
    const endEventTime = noteEvents.reduce((acc: number, curr: NoteEvent) => {
        if (curr.duration + curr.time > acc) {
            return curr.duration + curr.time;
        } else {
            return acc;
        }
    }, 0);
    const sequence = [
        ...noteEvents,
        {
            type: 'group-note' as const,
            groupType: 'none' as const,
            time: 0,
            duration: endEventTime,
        },
    ];
    return createPartEvents(sequence, fretboardNoteDetails);
}
