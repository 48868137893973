import { useTranslation } from '../../../hooks/use-translation';
import {
    StringSelector,
    SelectableStringInput,
} from '../../business/string-selector';

type StringSelectorFieldProps = {
    leftHanded: boolean;
    onChange: (value: SelectableStringInput[]) => void;
    strings: SelectableStringInput[];
};

export function MultipleStringSelectorField({
    leftHanded,
    onChange,
    strings,
}: StringSelectorFieldProps) {
    const { t } = useTranslation();

    const handleChange = (stringIndex: number) => {
        const updatedSelectableStrings = strings.map((string) => ({
            ...string,
            selected:
                stringIndex === string.index
                    ? !string.selected
                    : string.selected,
        }));
        onChange(updatedSelectableStrings);
    };

    return (
        <div className="flex flex-col gap-2 items-center">
            <label className="block">
                {t('fields.selectable-strings.label')}
            </label>
            <StringSelector
                leftHanded={leftHanded}
                onChange={handleChange}
                strings={strings}
            />
        </div>
    );
}
