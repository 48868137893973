import { motion } from 'framer-motion';
import { cn } from '@notacami/core/css';
import { useNoteNotation } from '../../services/note-notation/use-note-notation';
import { StringNumber } from './string-number';

type NeckHeadStringSelectorItemProps = {
    id: string;
    label: string;
    onChange: (stringIndex: number) => void;
    selected: boolean;
    stringNumber: number;
    stringIndex: number;
    disabled: boolean;
    isVertical: boolean;
};

export function NeckHeadStringSelectorItem({
    id,
    label,
    onChange,
    selected,
    stringNumber,
    stringIndex,
    disabled,
    isVertical,
}: NeckHeadStringSelectorItemProps) {
    const { n } = useNoteNotation();

    const handleChange = () => {
        onChange(stringIndex);
    };

    return (
        <li className={cn('relative flex justify-center items-center')}>
            <input
                hidden
                type="checkbox"
                id={id}
                checked={selected}
                onChange={handleChange}
                disabled={disabled}
            />

            <motion.div
                animate={
                    isVertical
                        ? { y: selected ? -30 : 0 }
                        : { x: selected ? -30 : 0 }
                }
                className={cn(
                    'absolute',
                    isVertical ? 'left-1/2 -ml-2' : 'top-1/2 -mt-2',
                )}
            >
                <StringNumber stringNumber={stringNumber} selected={selected} />
            </motion.div>
            <div className="py-1">
                <label
                    className={cn(
                        'relative flex justify-center items-center rounded-md px-2 py-1 text-sm font-semibold',
                        isVertical ? 'w-9 h-6' : 'h-10 w-6',
                        'transition-all cursor-pointer',
                        selected && 'bg-stone-300 text-neutral-500',
                        !selected &&
                            'bg-stone-500 dark:bg-stone-900 text-neutral-200 dark:text-neutral-400',
                        disabled &&
                            'bg-neutral-800 dark:bg-neutral-600 text-neutral-500 dark:text-neutral-500 cursor-auto',
                    )}
                    htmlFor={id}
                >
                    {n(label)}
                </label>
            </div>
        </li>
    );
}
