import { FindTheNoteOnTheNeckProgressPayload } from '../../../../../services/progress/find-the-note-on-the-neck/types';
import { IProgressService } from '../../../../../services/progress/progress.types';
import {
    FindTheNoteOnTheNeckAnswer,
    FindTheNoteOnTheNeckConfig,
    FindTheNoteOnTheNeckQuestion,
    FindTheNoteOnTheNeckQuestionMeta,
    FindTheNoteOnTheNeckResultMeta,
    FindTheNoteOnTheNeckTurnQuestion,
    FindTheNoteOnTheNeckTurnResult,
} from '../../types';

export const manageProgressDataAction = async (
    progressService: IProgressService<
        FindTheNoteOnTheNeckQuestion,
        FindTheNoteOnTheNeckAnswer,
        FindTheNoteOnTheNeckQuestionMeta,
        FindTheNoteOnTheNeckResultMeta,
        FindTheNoteOnTheNeckProgressPayload
    >,
    config: FindTheNoteOnTheNeckConfig,
    questions: FindTheNoteOnTheNeckTurnQuestion[],
    results: FindTheNoteOnTheNeckTurnResult[],
    sessionPracticeTime: number,
): Promise<void> => {
    const resultIndexesWithCorrectAnswer = results
        .map((_, index) => index)
        .filter((_, index) => results[index].isCorrect);

    if (resultIndexesWithCorrectAnswer.length > 0) {
        const filteredQuestions = questions.filter((_, index) =>
            resultIndexesWithCorrectAnswer.includes(index),
        );

        const filteredResults = results.filter((_, index) =>
            resultIndexesWithCorrectAnswer.includes(index),
        );

        await progressService.saveProgressFromResultsByTuningId(
            config.tuningInfo.id,
            filteredQuestions,
            filteredResults,
            sessionPracticeTime,
        );
    }
};
