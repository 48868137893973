import {
    FindTheIntervalOnTheNeckConfig,
    FindTheIntervalOnTheNeckAnswer,
    FindTheIntervalOnTheNeckQuestion,
} from '../../../types';

export function computeCorrectAnswer(
    _config: FindTheIntervalOnTheNeckConfig,
    question: FindTheIntervalOnTheNeckQuestion,
): FindTheIntervalOnTheNeckAnswer {
    return question.expectedIntervalPosition;
}
