import { QuizMode } from '../../../../../services/exercise/exercise.types';
import {
    FindTheNoteOnTheNeckConfig,
    FindTheNoteOnTheNeckTurnQuestion,
} from '../../types';
import { computeCorrectAnswer } from './utils/compute-correct-answer';
import { computeNumberOfErrorsAllowed } from './utils/compute-number-or-errors-allowed';
import { computeQuestion } from './utils/compute-question';

export const computeQuizTurnQuestionAction = (
    config: FindTheNoteOnTheNeckConfig,
    previousQuestions: FindTheNoteOnTheNeckTurnQuestion[],
    quizMode: QuizMode,
): FindTheNoteOnTheNeckTurnQuestion => {
    const question = computeQuestion(config, previousQuestions);
    const correctAnswer = computeCorrectAnswer(config, question);
    const numberOfErrorsAllowed = computeNumberOfErrorsAllowed(quizMode);

    const turnQuestion = {
        question,
        meta: undefined,
        correctAnswer,
        numberOfErrorsAllowed,
    };

    return turnQuestion;
};
