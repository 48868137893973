import { FindTheIntervalOnTheNeckProgressPayload } from '../../../../../services/progress/find-the-interval-on-the-neck/types';
import { IProgressService } from '../../../../../services/progress/progress.types';
import {
    FindTheIntervalOnTheNeckAnswer,
    FindTheIntervalOnTheNeckConfig,
    FindTheIntervalOnTheNeckQuestion,
    FindTheIntervalOnTheNeckQuestionMeta,
    FindTheIntervalOnTheNeckResultMeta,
} from '../../types';

export const getProgressDataAction = async (
    progressService: IProgressService<
        FindTheIntervalOnTheNeckQuestion,
        FindTheIntervalOnTheNeckAnswer,
        FindTheIntervalOnTheNeckQuestionMeta,
        FindTheIntervalOnTheNeckResultMeta,
        FindTheIntervalOnTheNeckProgressPayload
    >,
    config: FindTheIntervalOnTheNeckConfig,
) => {
    return progressService.getProgressDataByTuningId(config.tuningInfo.id);
};
