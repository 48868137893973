import { Dialog, DialogContent, DialogHeader } from '../ui/dialog';
import { useSafeAreaInsets } from '../../hooks/use-safe-area-insets';
import { MicUnsupportedContent } from './mic-unsupported-content';

type MicUnsupportedDialogProps = {
    onOpenChange?: (value: boolean) => void;
    open: boolean;
};

export function MicUnsupportedDialog({
    onOpenChange,
    open,
}: MicUnsupportedDialogProps) {
    const insets = useSafeAreaInsets();

    return open ? (
        <Dialog open={open} onOpenChange={onOpenChange}>
            <DialogContent insets={insets}>
                <DialogHeader>
                    <MicUnsupportedContent />
                </DialogHeader>
            </DialogContent>
        </Dialog>
    ) : null;
}
