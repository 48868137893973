import { Position } from '@notacami/core/fretboard';
import { AnimatePresence } from 'framer-motion';
import { NeckCellSelect } from '../../../neck-cells/neck-cell-select';
import { NeckNote } from '../../../neck-cells/neck-note';
import { BuildCellsComponentProps } from '../../../guitar-neck/guitar-neck.types';
import {
    Fretboard,
    isPositionEqual,
    PositionWithInterval,
} from '../../../../../services/fretboard';
import { NeckCellInterval } from '../../../neck-cells/neck-cell-interval';
import { QuizMode } from '../../../../../services/exercise/exercise.types';
import { NeckCellError } from '../../../neck-cells/neck-cell-error';
import { PlayingAreaCell } from '../../../neck-cells/playing-area-cell';

type AnswerSectionPlayCellProps = BuildCellsComponentProps & {
    correctAnswer: Position;
    fretboard: Fretboard;
    givenRootPosition: Position;
    onSelect: (position: Position) => void;
    quizMode: QuizMode;
    selectablePositionsWithInterval: PositionWithInterval[];
    wrongAnswerPositions: Position[];
};

export function AnswerSectionPlayCell({
    correctAnswer,
    fretboard,
    getElementByPosition,
    givenRootPosition,
    isOpenString,
    isVertical,
    onSelect,
    position,
    quizMode,
    selectablePositionsWithInterval,
    wrongAnswerPositions,
}: AnswerSectionPlayCellProps) {
    const fretboardNote = getElementByPosition(fretboard.noteDetails);

    const isWrongAnswerPosition = wrongAnswerPositions.some(
        (wrongAnswerPosition) => isPositionEqual(position, wrongAnswerPosition),
    );

    const isSelectableCell = selectablePositionsWithInterval.some(
        (selectablePosition) =>
            isPositionEqual(selectablePosition.position, position),
    );

    const isRootPosition = isPositionEqual(givenRootPosition, position);
    const isCorrectPosition = isPositionEqual(correctAnswer, position);

    return (
        <div className="relative flex flex-col items-center">
            {quizMode === 'guitar' && isSelectableCell && !isRootPosition ? (
                <PlayingAreaCell
                    className={!isWrongAnswerPosition ? 'relative' : 'absolute'}
                    isOpenString={isOpenString}
                    isVertical={isVertical}
                />
            ) : null}
            {isRootPosition ? (
                <NeckCellInterval
                    isOpenString={isOpenString}
                    isVertical={isVertical}
                    notePitchClass={fretboardNote.pitchClass}
                    interval="1P"
                    position={position}
                />
            ) : null}
            {isOpenString && !isSelectableCell && !isRootPosition ? (
                <NeckNote
                    isOpenString={true}
                    isVertical={isVertical}
                    notePitchClass={fretboardNote.pitchClass}
                    position={position}
                />
            ) : null}
            <AnimatePresence mode="popLayout">
                {isSelectableCell &&
                quizMode === 'app' &&
                !isWrongAnswerPosition ? (
                    <NeckCellSelect
                        isOpenString={isOpenString}
                        position={position}
                        value={false}
                        onSelect={onSelect}
                        isVertical={isVertical}
                        dataTestId={
                            isCorrectPosition ? 'correct-answer' : undefined
                        }
                    />
                ) : null}
                {isWrongAnswerPosition && !isOpenString && !isRootPosition ? (
                    <NeckCellError
                        key="neck-cell-error"
                        isOpenString={isOpenString}
                        isVertical={isVertical}
                    />
                ) : null}
            </AnimatePresence>
        </div>
    );
}
