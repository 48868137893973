import { SyntheticEvent, useContext, useEffect, useState } from 'react';
import { FormFieldContainer } from '@notacami/ui';
import {
    LANGUAGE_PREFERENCE_OPTIONS,
    LanguagePreference,
} from '../../../../services/i18n';
import { useTranslation } from '../../../../hooks/use-translation';
import { ServicesContext } from '../../../../services/services.context';

export function LanguageSection() {
    const { i18n, preferenceLanguageStore: preferencesLanguageStore } =
        useContext(ServicesContext);
    const { t } = useTranslation();
    const options = LANGUAGE_PREFERENCE_OPTIONS;
    const [langPreference, setLangPreference] = useState<LanguagePreference>();
    const [persistedPreference, setPersistedPreference] =
        useState<LanguagePreference>();

    const handleSelectChange = async (
        event: SyntheticEvent<HTMLSelectElement>,
    ) => {
        const selectedLanguagePreference = event.currentTarget
            .value as LanguagePreference;
        setLangPreference(selectedLanguagePreference);
        await preferencesLanguageStore.set(selectedLanguagePreference);
        i18n.updateLanguage(selectedLanguagePreference);
    };

    useEffect(() => {
        async function getPersistedLanguagePreference() {
            const persistedLanguagePreference =
                await preferencesLanguageStore.get();
            setPersistedPreference(persistedLanguagePreference);
        }
        getPersistedLanguagePreference();
    }, []);

    return (
        <>
            {persistedPreference !== undefined ? (
                <FormFieldContainer
                    id="language-setting"
                    label={t('pages.settings.general.language.title')}
                    input={
                        <select
                            name="language-setting"
                            id="language-setting"
                            defaultValue={persistedPreference}
                            onChange={handleSelectChange}
                            value={langPreference}
                            className="select w-48"
                        >
                            {options.map((option) => (
                                <option value={option} key={option}>
                                    {t(
                                        `pages.settings.general.language.option.${option}`,
                                    )}
                                </option>
                            ))}
                        </select>
                    }
                />
            ) : null}
        </>
    );
}
