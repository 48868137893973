import { FindTheNoteOnTheNeckProgressStoredPayload } from '../../progress/find-the-note-on-the-neck/types';
import { StoredProgressEntries } from '../../progress/progress.types';
import { StoreCapacitor } from '../storage.capacitor';

export function getFindTheNoteOnTheNeckProgressCapacitorStore() {
    return new StoreCapacitor<
        StoredProgressEntries<FindTheNoteOnTheNeckProgressStoredPayload>
    >(
        'quiz.find-the-note-on-the-neck.progress',
        [],
        (value) => JSON.stringify(value),
        (value) => JSON.parse(value),
    );
}
