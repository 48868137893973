import { ReactNode, forwardRef } from 'react';
import { motion } from 'framer-motion';
import { cn } from '@notacami/core/css';
import { Surface } from '@notacami/ui';

type AnswerHelpProps = {
    children: ReactNode;
    className?: string;
};

export const AnswerHelp = forwardRef<HTMLDivElement, AnswerHelpProps>(
    ({ children, className }, ref) => {
        return (
            <motion.div
                ref={ref}
                className={cn('flex justify-center', className)}
                initial={{ y: 8, opacity: 0 }}
                animate={{
                    y: 0,
                    opacity: 1,
                }}
                exit={{ opacity: 0, y: 8 }}
            >
                <Surface
                    className="text-sm text-left rounded-2xl px-3 py-1 flex gap-2 justify-center items-center"
                    colorVariant="neutral-invert"
                >
                    {children}
                </Surface>
            </motion.div>
        );
    },
);
AnswerHelp.displayName = 'AnswerTip';
