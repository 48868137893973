import { Position } from '@notacami/core/fretboard';
import { getRangeNumberByLength } from '../../utils/array.utils';
import { getCombinationFromTwoArray } from '../../utils/get-combination-from-two-arrays';

export function getFretboardPositionsByStringIndexesAndNumberOfFrets(
    stringIndexes: number[],
    numberOfFrets: number,
): Position[] {
    const positions = getCombinationFromTwoArray(
        stringIndexes,
        getRangeNumberByLength(numberOfFrets),
    );
    return positions;
}
