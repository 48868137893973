import { TuningId } from '@notacami/core/tuning';
import { StoreCapacitor } from '../storage.capacitor';

export function getPreferenceTuningCapacitorStore() {
    return new StoreCapacitor<TuningId>(
        'settings.tuning',
        'guitar-standard',
        (value) => value,
        (value) => value as TuningId,
    );
}
