export const CENTS = [
    {
        x: -50,
        size: 'big',
        text: 'cent',
    },
    {
        x: -45,
        size: 'small',
        text: '',
    },
    {
        x: -40,
        size: 'big',
        text: '',
    },
    {
        x: -35,
        size: 'small',
        text: '',
    },
    {
        x: -30,
        size: 'big',
        text: '',
    },
    {
        x: -25,
        size: 'small',
        text: '',
    },
    {
        x: -20,
        size: 'big',
        text: '-20',
    },
    {
        x: -15,
        size: 'small',
        text: '',
    },
    {
        x: -10,
        size: 'big',
        text: '',
    },
    {
        x: -5,
        size: 'small',
        text: '',
    },
    {
        x: 0,
        size: 'big',
        text: '',
    },
    {
        x: 5,
        size: 'small',
        text: '',
    },
    {
        x: 10,
        size: 'big',
        text: '',
    },
    {
        x: 15,
        size: 'small',
        text: '',
    },
    {
        x: 20,
        size: 'big',
        text: '20',
    },
    {
        x: 25,
        size: 'small',
        text: '',
    },
    {
        x: 30,
        size: 'big',
        text: '',
    },
    {
        x: 35,
        size: 'small',
        text: '',
    },
    {
        x: 40,
        size: 'big',
        text: '',
    },
    {
        x: 45,
        size: 'small',
        text: '',
    },
    {
        x: 50,
        size: 'big',
        text: 'cent',
    },
];
