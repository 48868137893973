import { animate, motion } from 'framer-motion';
import { useEffect, useRef } from 'react';
import { cn } from '@notacami/core/css';
import { Clock } from 'lucide-react';
import { formatMsToMMSS } from '../../services/time';
import { useTranslation } from '../../hooks/use-translation';

type ProgressPraticeTimeProps = {
    fromInMs: number;
    toInMs: number;
    className?: string;
};

const ANIMATION_DURATION = 0.75;

export function ProgressPraticeTime({
    fromInMs,
    toInMs,
    className,
}: ProgressPraticeTimeProps) {
    const { t } = useTranslation();
    const nodeRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const node = nodeRef.current;

        if (node === null) return;

        const controls = animate(fromInMs, toInMs, {
            duration: ANIMATION_DURATION,
            onUpdate(value) {
                node.textContent = formatMsToMMSS(value * 1000);
            },
        });

        return () => controls.stop();
    }, [fromInMs, toInMs]);

    return (
        <div
            className={cn('flex justify-between items-center gap-4', className)}
        >
            <div className="flex gap-2 items-center">
                <Clock className="w-6 h-6 min-w-6 min-h-6" />
                <span className="leading-tight text-sm">
                    {t('practice-time')}
                </span>
            </div>
            <motion.div
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: ANIMATION_DURATION, type: 'spring' }}
            >
                <div
                    className="text-3xl font-semibold tabular-nums"
                    ref={nodeRef}
                />
            </motion.div>
        </div>
    );
}
