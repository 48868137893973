import { ReactNode, forwardRef } from 'react';
import { cn } from '@notacami/core/css';
import { Chevron } from './chevron';

type SecondaryTitleProps = {
    isExpandable: boolean;
    isVisible?: boolean;
    onClickTitle: () => void;
    open: boolean;
    title: ReactNode;
};

export const SecondaryTitle = forwardRef<HTMLDivElement, SecondaryTitleProps>(
    ({ isExpandable, isVisible = true, onClickTitle, open, title }, ref) => {
        return (
            <div
                ref={ref}
                className={cn(
                    'relative min-h-16',
                    'bg-gradient-to-b',
                    'from-neutral-100 via-neutral-100/90 to-neutral-100/0',
                    'dark:from-neutral-900 dark:via-neutral-900/90 dark:to-neutral-900/0',
                    'p-4 flex justify-between items-center gap-4',
                    !isVisible && 'opacity-0',
                )}
                onClick={onClickTitle}
            >
                {title}
                {isExpandable ? <Chevron isOpen={open} /> : null}
            </div>
        );
    },
);
SecondaryTitle.displayName = 'SecondaryTitle';
