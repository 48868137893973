import { Mic } from 'lucide-react';
import { Thematic } from '@notacami/core/thematic';
import { getColorVariantByThematic } from '@notacami/core/design';
import { ActionButton } from '../../ui/action-button';
import { SinewavePreview } from './sinewave-preview';
import { Note } from './note';

type RecordingButtonProps = {
    onClick: () => void;
    thematic: Thematic;
};

export function RecordingButton({ onClick, thematic }: RecordingButtonProps) {
    const colorVariant = getColorVariantByThematic(thematic);

    return (
        <div className="relative pl-8 z-10">
            <div className="absolute top-0 bottom-0 left-0 right-2 bg-neutral-200 dark:bg-neutral-600 shadow-inner shadow-neutral-300 dark:shadow-neutral-700 rounded-3xl overflow-hidden">
                <SinewavePreview />
            </div>
            <div className="relative">
                <ActionButton onClick={onClick} colorVariant={colorVariant}>
                    <Mic />
                </ActionButton>
            </div>
            <div className="absolute top-2 bottom-2 -left-6 w-14 flex justify-end items-center">
                <Note />
            </div>
        </div>
    );
}
