import * as Sentry from '@sentry/capacitor';
import * as SentryReact from '@sentry/react';
import { useEffect } from 'react';
import {
    createBrowserRouter,
    createRoutesFromChildren,
    matchRoutes,
    useLocation,
    useNavigationType,
} from 'react-router-dom';

if (import.meta.env.VITE_SENTRY_DSN) {
    Sentry.init(
        {
            dsn: import.meta.env.VITE_SENTRY_DSN,
            release: RELEASE_NAME,
            integrations: [
                SentryReact.reactRouterV6BrowserTracingIntegration({
                    useEffect,
                    useLocation,
                    useNavigationType,
                    createRoutesFromChildren,
                    matchRoutes,
                }),
                SentryReact.replayIntegration({
                    maskAllText: false,
                    blockAllMedia: false,
                }),
            ],
            tracesSampleRate: 1.0,
            replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 1.0,
        },
        SentryReact.init,
    );
}

export const sentryCreateBrowserRouter =
    SentryReact.wrapCreateBrowserRouter(createBrowserRouter);
