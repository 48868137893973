import { Note } from 'tonal';
import { StringDescription } from '../fretboard';

export function getStringListByUniqBaseNote(
    tuningNotes: string[],
): StringDescription[] {
    const stringList = tuningNotes.reduce<StringDescription[]>(
        (accumulator, currentNoteName, currentIndex) => {
            const currentPitchClass = Note.pitchClass(currentNoteName);
            const pitchClassIndexInList = accumulator.findIndex(
                (stringDescription) =>
                    stringDescription.pitchClass === currentPitchClass,
            );

            if (pitchClassIndexInList === -1) {
                return [
                    ...accumulator,
                    {
                        pitchClass: currentPitchClass,
                        stringIndexes: [currentIndex],
                    },
                ];
            } else {
                return accumulator.map((stringDescription, index) =>
                    index === pitchClassIndexInList
                        ? {
                              pitchClass: stringDescription.pitchClass,
                              stringIndexes: [
                                  ...stringDescription.stringIndexes,
                                  currentIndex,
                              ],
                          }
                        : stringDescription,
                );
            }
        },
        [],
    );

    return stringList;
}
