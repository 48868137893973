type ConditionalWrapperProps = {
    items: {
        condition: boolean;
        wrapper: (child: JSX.Element, index: number) => JSX.Element;
    }[];
    children: JSX.Element;
};

export const ConditionalWrapper = ({
    items,
    children,
}: ConditionalWrapperProps) =>
    items.map(
        ({ condition, wrapper }, index) =>
            condition && wrapper(children, index),
    );
