import { useCallback, useContext, useEffect, useReducer } from 'react';
import { PartId } from '../../../../../../services/sequence/sequence.constants';
import { EventInPart } from '../../../../../../services/composer';
import { ServicesContext } from '../../../../../../services/services.context';
import { usePlayingGroupNote } from './use-playing-group-note';
import { notesToShowReducer } from './notes-to-show.reducer';

export function useNoteToDisplay(
    partIdToUse: PartId,
    eventsInPart: EventInPart[],
) {
    const { soundPlayer } = useContext(ServicesContext);

    const playingGroupNote = usePlayingGroupNote(partIdToUse, eventsInPart);
    const [state, dispatch] = useReducer(notesToShowReducer, {
        eventsInPart: [],
        notesToShow: [],
        playingGroupId: null,
        firstPlayingGroupId: null,
    });

    useEffect(() => {
        if (playingGroupNote !== null) {
            dispatch({
                type: 'UPDATE_PLAYING_GROUP_NOTE',
                playingGroupId: playingGroupNote.id,
            });
        }
    }, [playingGroupNote]);

    const handleStop = useCallback(() => {
        dispatch({
            type: 'RESET',
        });
    }, []);

    useEffect(() => {
        dispatch({ type: 'INIT', eventsInPart });
    }, []);

    useEffect(() => {
        soundPlayer.on('stop', handleStop);

        return () => {
            soundPlayer.off('stop', handleStop);
        };
    }, [handleStop]);

    return state.notesToShow;
}
