import { useTranslation } from '../../hooks/use-translation';
import { getIntervalInterpolationInfo } from '../intervals/get-interval-interpolation-info';

export function useIntervalTranslation() {
    const { t } = useTranslation();
    function translate(interval: string) {
        const intervalTranspolationInfo =
            getIntervalInterpolationInfo(interval);
        return t('interval-name', intervalTranspolationInfo);
    }
    return { i: translate };
}
