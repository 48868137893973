import { PitchDetectionService } from '../services/pitch-detection';
import { PitchDetectionConsumerService } from '../services/pitch-detection-consumer/pitch-detection-consumer';
import { NotePlayedService } from '../services/note-played';
import { Services } from '../services/types';
import { getPreferenceAdminCapacitorStore } from '../services/storage/preferences/admin';
import { getPreferenceLeftHandedCapacitorStore } from '../services/storage/preferences/left-handed';
import { getPreferenceNoteNotationCapacitorStore } from '../services/storage/preferences/note-notation';
import { getPreferenceReferenceFrequencyCapacitorStore } from '../services/storage/preferences/reference-frequency';
import { getPreferenceThemeCapacitorStore } from '../services/storage/preferences/theme';
import { getPreferenceTuningCapacitorStore } from '../services/storage/preferences/tuning';
import { getPreferenceMicCapacitorStore } from '../services/storage/preferences/mic-permission';
import { getPreferenceLanguageCapacitorStore } from '../services/storage/preferences/language';
import { getPreferenceGainCapacitorStore } from '../services/storage/preferences/gain';
import { getI18nService } from '../services/i18n';
import { getFindScalePositionProgressInstance } from '../services/progress/find-scale-position';
import { getFindTheIntervalOnTheNeckProgressInstance } from '../services/progress/find-the-interval-on-the-neck';
import { getFindTheNoteFromRootAndIntervalProgressInstance } from '../services/progress/find-the-note-from-root-and-interval';
import { getFindTheNoteOnTheNeckProgressServiceInstance } from '../services/progress/find-the-note-on-the-neck';
import { getFindTheNoteOnTheNeckProgressCapacitorStore } from '../services/storage/progress/find-the-note-on-the-neck';
import { getFindScalePositionProgressCapacitorStore } from '../services/storage/progress/find-scale-position';
import { getFindTheNoteFromRootAndIntervalProgressCapacitorStore } from '../services/storage/progress/find-the-note-from-root-and-interval';
import { getFindTheIntervalOnTheNeckProgressCapacitorStore } from '../services/storage/progress/find-the-interval-on-the-neck';
import { WindowFocusAndBlurService } from '../services/window-focus-and-blur';
import { AppService } from '../services/app/app';
import { getPersonaCapacitorStore } from '../services/persona';
import { getSoundPlayerInstance } from '../services/sound-player';
import { Teacher } from '../services/teacher/teacher';

export function instanciateServices({
    app: intialApp,
    captureModeStore: initialCaptureModeStore,
    findScalePositionProgress: initialFindScalePositionProgress,
    findScalePositionProgressStore: initialFindScalePositionProgressStore,
    findTheIntervalOnTheNeckProgress: initialFindTheIntervalOnTheNeckProgress,
    findTheIntervalOnTheNeckProgressStore:
        initialFindTheIntervalOnTheNeckProgressStore,
    findTheNoteFromRootAndIntervalProgress:
        initialFindTheNoteFromRootAndIntervalProgress,
    findTheNoteFromRootAndIntervalProgressStore:
        initialFindTheNoteFromRootAndIntervalProgressStore,
    findTheNoteOnTheNeckProgress: initialFindTheNoteOnTheNeckProgress,
    findTheNoteOnTheNeckProgressStore: initialFindTheNoteOnTheNeckProgressStore,
    i18n: initialI18n,
    notePlayed: initialNotePlayed,
    pitchDetection: initialPitchDetection,
    pitchDetectionConsumer: initialPitchDetectionConsumer,
    preferenceAdminStore: initialPreferencesAdminStore,
    preferenceGainStore: initialPreferenceGainStore,
    preferenceLanguageStore: initialPreferencesLanguageStore,
    preferenceLeftHandedStore: initialPreferenceLeftHandedStore,
    preferenceMicStore: initialPreferenceMicStore,
    preferenceNoteNotationStore: initialPreferenceNoteNotationStore,
    preferenceReferenceFrequencyStore: initialPreferenceReferenceFrequencyStore,
    preferenceThemeStore: initialPreferenceThemeStore,
    preferenceTuningStore: initialPreferenceTuningStore,
    windowFocusAndBlur: initialWindowFocusAndBlur,
}: Partial<Services>): Services {
    const captureModeStore =
        initialCaptureModeStore ?? getPersonaCapacitorStore();

    const preferenceAdminStore =
        initialPreferencesAdminStore ?? getPreferenceAdminCapacitorStore();

    const preferenceLeftHandedStore =
        initialPreferenceLeftHandedStore ??
        getPreferenceLeftHandedCapacitorStore();

    const preferenceNoteNotationStore =
        initialPreferenceNoteNotationStore ??
        getPreferenceNoteNotationCapacitorStore();

    const preferenceReferenceFrequencyStore =
        initialPreferenceReferenceFrequencyStore ??
        getPreferenceReferenceFrequencyCapacitorStore();

    const preferenceThemeStore =
        initialPreferenceThemeStore ?? getPreferenceThemeCapacitorStore();

    const preferenceTuningStore =
        initialPreferenceTuningStore ?? getPreferenceTuningCapacitorStore();

    const preferenceMicStore =
        initialPreferenceMicStore ?? getPreferenceMicCapacitorStore();

    const preferencesLanguageStore =
        initialPreferencesLanguageStore ??
        getPreferenceLanguageCapacitorStore();

    const preferenceGainStore =
        initialPreferenceGainStore ?? getPreferenceGainCapacitorStore();

    const findTheNoteOnTheNeckProgressStore =
        initialFindTheNoteOnTheNeckProgressStore ??
        getFindTheNoteOnTheNeckProgressCapacitorStore();

    const findTheNoteOnTheNeckProgress =
        initialFindTheNoteOnTheNeckProgress ??
        getFindTheNoteOnTheNeckProgressServiceInstance(
            findTheNoteOnTheNeckProgressStore,
        );

    const findScalePositionProgressStore =
        initialFindScalePositionProgressStore ??
        getFindScalePositionProgressCapacitorStore();

    const findScalePositionProgress =
        initialFindScalePositionProgress ??
        getFindScalePositionProgressInstance(findScalePositionProgressStore);

    const findTheNoteFromRootAndIntervalProgressStore =
        initialFindTheNoteFromRootAndIntervalProgressStore ??
        getFindTheNoteFromRootAndIntervalProgressCapacitorStore();

    const findTheNoteFromRootAndIntervalProgress =
        initialFindTheNoteFromRootAndIntervalProgress ??
        getFindTheNoteFromRootAndIntervalProgressInstance(
            findTheNoteFromRootAndIntervalProgressStore,
        );

    const findTheIntervalOnTheNeckProgressStore =
        initialFindTheIntervalOnTheNeckProgressStore ??
        getFindTheIntervalOnTheNeckProgressCapacitorStore();

    const findTheIntervalOnTheNeckProgress =
        initialFindTheIntervalOnTheNeckProgress ??
        getFindTheIntervalOnTheNeckProgressInstance(
            findTheIntervalOnTheNeckProgressStore,
        );

    const pitchDetection =
        initialPitchDetection ??
        new PitchDetectionService(preferenceGainStore, preferenceMicStore);

    const pitchDetectionConsumer =
        initialPitchDetectionConsumer ??
        new PitchDetectionConsumerService(pitchDetection);

    const notePlayed =
        initialNotePlayed ?? new NotePlayedService(pitchDetection);

    const i18n = initialI18n ?? getI18nService();

    const windowFocusAndBlur =
        initialWindowFocusAndBlur ?? new WindowFocusAndBlurService();

    const app = intialApp ?? new AppService();

    const soundPlayer = getSoundPlayerInstance();

    const teacher = new Teacher(notePlayed);

    return {
        app,
        captureModeStore,
        findScalePositionProgress,
        findScalePositionProgressStore,
        findTheIntervalOnTheNeckProgress,
        findTheIntervalOnTheNeckProgressStore,
        findTheNoteFromRootAndIntervalProgress,
        findTheNoteFromRootAndIntervalProgressStore,
        findTheNoteOnTheNeckProgress,
        findTheNoteOnTheNeckProgressStore,
        i18n,
        notePlayed,
        pitchDetection,
        pitchDetectionConsumer,
        preferenceAdminStore,
        preferenceGainStore,
        preferenceLeftHandedStore,
        preferenceMicStore,
        preferenceNoteNotationStore,
        preferenceReferenceFrequencyStore,
        preferenceLanguageStore: preferencesLanguageStore,
        preferenceThemeStore,
        preferenceTuningStore,
        soundPlayer,
        teacher,
        windowFocusAndBlur,
    };
}
