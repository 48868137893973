import { Step } from '@notacami/core/lesson';
import { createPartEvents } from '../composer';
import { FretboardNoteDetails } from '../fretboard';
import { DerivedStep } from './lesson.types';

export function getDerivedStep(
    step: Step,
    fretboardNoteDetails: FretboardNoteDetails,
): DerivedStep {
    switch (step.type) {
        case 'teacher-speaking':
            return step;
        case 'teacher-listening':
        case 'teacher-playing': {
            const { sequence, ...rest } = step;
            const eventsInPart = createPartEvents(
                sequence,
                fretboardNoteDetails,
            );
            return { ...rest, eventsInPart };
        }
    }
}
