import { useEffect, useRef } from 'react';
import useTimer from '../../hooks/use-timer';
import { ActionButton, ActionButtonProps } from './action-button';

type Props = {
    buttonProps: ActionButtonProps;
    forInMs: number;
    paused?: boolean;
};

export function ButtonTimer({ buttonProps, forInMs, paused = false }: Props) {
    const { time } = useTimer(forInMs, paused);
    const buttonAlreadyClickedByTimer = useRef(false);

    useEffect(() => {
        if (
            time === 0 &&
            buttonProps.onClick &&
            !buttonAlreadyClickedByTimer.current
        ) {
            buttonAlreadyClickedByTimer.current = true;
            buttonProps.onClick();
        }
    }, [time, buttonProps, buttonAlreadyClickedByTimer]);

    const percent = 100 - (time * 100) / forInMs;

    return (
        <div className="relative">
            <ActionButton {...buttonProps} />
            {percent !== undefined ? (
                <div className="absolute h-1 bg-white/30 left-4 bottom-1 right-4 rounded-full">
                    <div
                        className="absolute bg-white h-full rounded-full"
                        style={{ width: `${percent}%` }}
                    ></div>
                </div>
            ) : null}
        </div>
    );
}
