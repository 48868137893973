import {
    FindScalePositionAnswer,
    FindScalePositionConfig,
    FindScalePositionQuestion,
    FindScalePositionQuestionMeta,
    FindScalePositionResultMeta,
} from '../../types';
import { IProgressService } from '../../../../../services/progress/progress.types';
import { FindScalePositionProgressPayload } from '../../../../../services/progress/find-scale-position/types';

export const getProgressDataAction = async (
    progressService: IProgressService<
        FindScalePositionQuestion,
        FindScalePositionAnswer,
        FindScalePositionQuestionMeta,
        FindScalePositionResultMeta,
        FindScalePositionProgressPayload
    >,
    config: FindScalePositionConfig,
) => {
    return progressService.getProgressDataByTuningId(config.tuningInfo.id);
};
