import { useContext } from 'react';
import { FindTheIntervalOnTheNeckExerciseContext } from '../../store/context';
import { usePlayingState } from '../../../../../services/exercise/hooks/use-playing-state';
import { AnswerSectionPlay } from './answer-section-play';
import { AnswerSectionResult } from './answer-section-result';

export function AnswerSection() {
    const store = useContext(FindTheIntervalOnTheNeckExerciseContext);

    const { isInPlayingPlayState, isInPlayingResultState } =
        usePlayingState(store);

    return isInPlayingPlayState || isInPlayingResultState ? (
        <div className="py-5 flex flex-col justify-center items-center min-h-full">
            {isInPlayingPlayState ? <AnswerSectionPlay /> : null}
            {isInPlayingResultState ? <AnswerSectionResult /> : null}
        </div>
    ) : null;
}
