import {
    FindScalePositionTurnQuestion,
    FindScalePositionTurnResult,
} from '../../../components/business/find-scale-position/types';
import { AbstractProgressEntry } from '../abstract-progress-entry';
import { StoredProgressEntryOutcome } from '../progress.types';
import {
    FindScalePositionProgressPayload,
    FindScalePositionProgressStoredPayload,
} from './types';

export function fromStoredEntry(
    entry: StoredProgressEntryOutcome<FindScalePositionProgressStoredPayload>,
) {
    return new ProgressEntry(entry[0], entry[1]);
}

export function fromResult(
    question: FindScalePositionTurnQuestion,
    _result: FindScalePositionTurnResult,
) {
    const questionContent = question.question;

    if (questionContent === null) {
        throw new Error('No question found');
    }

    return new ProgressEntry(
        questionContent.scaleName,

        1,
    );
}

class ProgressEntry extends AbstractProgressEntry<
    FindScalePositionProgressPayload,
    FindScalePositionProgressStoredPayload
> {
    public toOutcome() {
        return {
            id: this.payload,
            payload: this.payload,
            numberOfCorrectAnswer: this.numberOfCorrectAnswer,
        };
    }

    public toStoreOutcome() {
        return [this.payload, this.numberOfCorrectAnswer] satisfies [
            FindScalePositionProgressStoredPayload,
            number,
        ];
    }
}
