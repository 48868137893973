import {
    FindTheNoteFromRootAndIntervalAnswer,
    FindTheNoteFromRootAndIntervalConfig,
    FindTheNoteFromRootAndIntervalTurnQuestion,
    FindTheNoteFromRootAndIntervalTurnResult,
} from '../../types';

export function computeQuizTurnResultAction(
    _config: FindTheNoteFromRootAndIntervalConfig,
    turnQuestion: FindTheNoteFromRootAndIntervalTurnQuestion,
    userAnswer: FindTheNoteFromRootAndIntervalAnswer,
): FindTheNoteFromRootAndIntervalTurnResult {
    return {
        meta: undefined,
        isCorrect: turnQuestion.correctAnswer === userAnswer,
        userAnswer,
    };
}
