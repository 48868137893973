import { ReactNode } from 'react';
import { Title } from '@notacami/ui';
import { Link } from 'react-router-dom';
import { CornerDownLeft } from 'lucide-react';
import { Thematic } from '@notacami/core/thematic';
import { useTranslation } from '../../hooks/use-translation';
import { PageUrls } from '../../utils/routes.contants';
import { PageLayout } from './page-layout';
import { ThematicDot } from './thematic-dot';

type ThematicPageLayoutProps = { thematic: Thematic; children: ReactNode };

export function ThematicPageLayout({
    thematic,
    children,
}: ThematicPageLayoutProps) {
    const { t } = useTranslation();

    return (
        <PageLayout
            leftAction={
                <Link to={PageUrls.HOME} title={t('button.back')}>
                    <CornerDownLeft size={30} />
                </Link>
            }
            title={
                <Title
                    level={2}
                    tagName="h1"
                    left={<ThematicDot thematic={thematic} />}
                >
                    <span>{t(`thematic.${thematic}`)}</span>
                </Title>
            }
            content={
                <div className="vertical-content-distribution-lg">
                    {children}
                </div>
            }
        />
    );
}
