import Emittery from 'emittery';
import { EventInPart } from '../composer';
import { INotePlayedService } from '../note-played';
import { NoteDetails } from '../notes/notes.types';
import { ITeacher, ListeningStep, TeacherEvents } from './teacher.types';
import { createListeningSteps } from './create-listening-steps';

export class Teacher extends Emittery<TeacherEvents> implements ITeacher {
    private listeningSteps: ListeningStep[] = [];
    private currentStepIndex = 0;
    private notePlayedChangedHandler: (eventData: {
        noteDetails: NoteDetails;
    }) => void;

    constructor(private readonly notePlayedService: INotePlayedService) {
        super();

        this.notePlayedChangedHandler = this.handleNotePlayedChanged.bind(this);
    }

    private handleNotePlayedChanged(eventData: { noteDetails: NoteDetails }) {
        if (
            this.listeningSteps[this.currentStepIndex].notes.some(
                (note) => note.noteChroma === eventData.noteDetails.chroma,
            )
        ) {
            this.currentStepIndex++;
            this.emit(
                'step-to-listen-updated',
                this.listeningSteps[this.currentStepIndex],
            );
        }
    }

    public startListening(eventsInPart: EventInPart[]) {
        this.listeningSteps = createListeningSteps(eventsInPart);
        this.currentStepIndex = 0;
        this.emit('step-to-listen-updated', this.listeningSteps[0]);

        this.notePlayedService.on('changed', this.notePlayedChangedHandler);
    }

    public stopListening() {
        this.notePlayedService.off('changed', this.notePlayedChangedHandler);
    }
}
