import { Position } from '@notacami/core/fretboard';
import { getDistanceBetweenPositions } from '../../utils/get-distance-between-points';
import { Fretboard, PositionWithDistance } from './fretboard.types';
import { getFretboardAllPositions } from './get-fretboard-all-positions';

export function getFretboardDistancesByPosition(
    fretboard: Fretboard,
    position: Position,
): PositionWithDistance[] {
    const positions = getFretboardAllPositions(fretboard);

    const distances = positions.map(([stringIndex, fretIndex]) => {
        const stringDiff = stringIndex - position[0];
        const fretDiff = fretIndex - position[1];

        const distance = getDistanceBetweenPositions(
            [stringIndex, fretIndex],
            position,
        );

        return {
            position: [stringIndex, fretIndex] satisfies Position,
            stringDiff,
            fretDiff,
            distance,
        };
    });

    return distances;
}
