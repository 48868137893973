import { useContext } from 'react';
import { FindTheNoteOnTheNeckProgress } from '../../../find-note-on-the-neck-progress';
import { FindTheNoteOnTheNeckExerciseContext } from '../../store/context';
import { useProgressData } from '../../../../../services/exercise/hooks/use-progress-data';

type ResultsProps = {
    notePitchClass: string | null;
};

export function Results({ notePitchClass }: ResultsProps) {
    const store = useContext(FindTheNoteOnTheNeckExerciseContext);
    const progressData = useProgressData(store);

    return progressData !== null ? (
        <FindTheNoteOnTheNeckProgress
            previousProgressData={progressData.previousProgressData}
            currentProgressData={progressData.currentProgressData}
            notePitchClass={notePitchClass}
        />
    ) : null;
}
