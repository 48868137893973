import { TonicAndOctavePosition, Segment } from '../fretboard';
import { getOctavePositionWithIntervalInSegment } from './get-octave-position-with-interval-in-segment';
import { getTonicPositionWithIntervalInSegment } from './get-tonic-position-with-interval-in-segment';

export function getTonicAndOctavePositionBySegment(
    segment: Segment,
): TonicAndOctavePosition {
    const tonicPositionWithInterval =
        getTonicPositionWithIntervalInSegment(segment);
    const octavePositionWithInterval =
        getOctavePositionWithIntervalInSegment(segment);
    return {
        tonicPosition: tonicPositionWithInterval,
        octavePosition: octavePositionWithInterval,
    };
}
