import { useContext } from 'react';
import { usePreferencesStore } from '../preferences/use-preferences-context';
import { ServicesContext } from '../../../services/services.context';
import { States } from './states';
import { manageProgressDataAction } from './store/specific-actions/manage-progress-data';
import { FindTheNoteOnTheNeckExerciseProvider } from './store/context';
import { computeQuizTurnQuestionAction } from './store/specific-actions/compute-quiz-turn-question';
import { computeQuizTurnResultAction } from './store/specific-actions/compute-quiz-turn-result';
import { getDefaultConfig } from './get-default-config';
import { getProgressDataAction } from './store/specific-actions/get-progress-data';

type FindTheNoteOnTheNeckProps = {
    notePitchClass: string | null;
};

export function FindTheNoteOnTheNeck({
    notePitchClass,
}: FindTheNoteOnTheNeckProps) {
    const { findTheNoteOnTheNeckProgress } = useContext(ServicesContext);
    const tuning = usePreferencesStore((state) => state.tuningInfo);
    const fretboard = usePreferencesStore((state) => state.fretboard);
    const defaultConfig = getDefaultConfig(tuning, fretboard, notePitchClass);

    return (
        <FindTheNoteOnTheNeckExerciseProvider
            computeQuizTurnQuestion={computeQuizTurnQuestionAction}
            computeQuizTurnResult={computeQuizTurnResultAction}
            config={defaultConfig}
            getProgressData={getProgressDataAction}
            manageProgressData={manageProgressDataAction}
            progressService={findTheNoteOnTheNeckProgress}
            thematic="notes"
        >
            <States
                defaultConfig={defaultConfig}
                notePitchClass={notePitchClass}
            />
        </FindTheNoteOnTheNeckExerciseProvider>
    );
}
