import { isPositionEqual } from '../../../../../services/fretboard';
import {
    FindTheNoteOnTheNeckTurnResult,
    FindTheNoteOnTheNeckTurnQuestion,
    FindTheNoteOnTheNeckAnswer,
    FindTheNoteOnTheNeckConfig,
} from '../../types';

export function computeQuizTurnResultAction(
    _config: FindTheNoteOnTheNeckConfig,
    turnQuestion: FindTheNoteOnTheNeckTurnQuestion,
    userAnswer: FindTheNoteOnTheNeckAnswer,
): FindTheNoteOnTheNeckTurnResult {
    const isCorrect = isPositionEqual(turnQuestion.correctAnswer, userAnswer);

    return {
        meta: undefined,
        isCorrect,
        userAnswer,
    };
}
