import { PropsWithChildren, createContext } from 'react';
import { Services } from './types';

type PreferenceProviderProps = PropsWithChildren<{ services: Services }>;

export const ServicesContext = createContext<Services>({} as Services);

export function ServicesProvider({
    children,
    services,
}: PreferenceProviderProps) {
    return (
        <ServicesContext.Provider value={services}>
            {children}
        </ServicesContext.Provider>
    );
}
