import {
    getClassnamesByColorVariant,
    getColorVariantByThematic,
} from '@notacami/core/design';
import { cn } from '@notacami/core/css';
import { Thematic } from '@notacami/core/thematic';

type StepperItemProps = {
    step: string;
    thematic: Thematic;
};

/**
 * symbols represent:
 * o : current step
 * v : previous step
 * - : next step
 * x : unreachable step
 */

export function StepperItem({ step, thematic }: StepperItemProps) {
    const colorVariant = getColorVariantByThematic(thematic);
    const classnames = getClassnamesByColorVariant(colorVariant);
    return (
        <div
            className={cn(
                'transition-all rounded-full',
                step === 'o' && [classnames.background, 'w-3 h-3'],
                step === 'v' && 'bg-neutral-600 dark:bg-neutral-300 w-2 h-2',
                step === '-' && 'bg-neutral-400 w-2 h-2',
                step === 'x' && 'bg-neutral-300 dark:bg-neutral-700/80 w-2 h-2',
            )}
        ></div>
    );
}
