import { THEMATICS } from '@notacami/core/thematic';
import { ThematicItem } from './thematic-item';

export function ThematicList() {
    return (
        <ul className="vertical-content-distribution-sm">
            {THEMATICS.map((thematic) => (
                <ThematicItem key={thematic} thematic={thematic} />
            ))}
        </ul>
    );
}
