import { GuitarNeck } from '../../../guitar-neck/guitar-neck';
import { useGuitarNeckVertical } from '../../../../../hooks/use-guitar-neck-vertical';
import {
    DEFAULT_FRET_LENGTH,
    useLeftHanded,
} from '../../../../../services/fretboard';
import {
    FindScalePositionConfig,
    FindScalePositionExistingQuestion,
} from '../../types';
import { AnswerSectionResultCell } from './answer-section-result-cell';

type AnswerSectionResultProps = {
    config: FindScalePositionConfig;
    question: FindScalePositionExistingQuestion;
};

export function AnswerSectionResult({
    config,
    question,
}: AnswerSectionResultProps) {
    const isVertical = useGuitarNeckVertical();
    const leftHanded = useLeftHanded();

    if (question === null) {
        return null;
    }

    const positions = question.scalePosition.positions;

    return (
        <GuitarNeck
            buildCellsComponent={AnswerSectionResultCell}
            isVertical={isVertical}
            leftHanded={leftHanded}
            numberOfFrets={DEFAULT_FRET_LENGTH}
            tuning={config.tuningInfo.notes}
            additionalCellProps={{
                positions,
            }}
        />
    );
}
