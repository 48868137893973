import { useMotionValueEvent, useSpring } from 'framer-motion';
import { RefObject } from 'react';

export function useScrollToTopWithSpringAnimation(
    elementRef: RefObject<HTMLDivElement>,
) {
    const scrollY = useSpring(0, { bounce: 0 });

    useMotionValueEvent(scrollY, 'change', (latest) => {
        if (elementRef.current) {
            elementRef.current.scrollTop = latest;
        }
    });

    const scrollToTop = () => {
        if (!elementRef.current) return;

        scrollY.jump(elementRef.current.scrollTop);
        scrollY.set(0);
    };

    return { scrollToTop };
}
