import { NoteEvent } from '@notacami/core/composer';
import { GroupNoteStartEvent } from './composer.types';

export function getGroupEventWithNoteEventOverlap(
    scaleAndChordEvents: GroupNoteStartEvent[],
    noteEvent: NoteEvent,
): GroupNoteStartEvent | null {
    const foundEvent = scaleAndChordEvents.find((event) => {
        const eventStartTime = event.time;
        const eventEndTime = event.time + event.duration;
        const noteEventTime = noteEvent.time;

        return noteEventTime >= eventStartTime && noteEventTime < eventEndTime;
    });
    return foundEvent !== undefined ? foundEvent : null;
}
