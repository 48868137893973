import { StateCreator, createStore } from 'zustand';
import { AppLanguage } from '@notacami/core/i18n';
import { LessonInfo } from '@notacami/core/lesson';
import { getFretboardNoteDetailsByTuningAndFretLength } from '../fretboard';
import { getTuningInfo } from '../tuning/get-tuning-info';
import {
    CreateLessonStoreProps,
    DataSlice,
    ExerciseState,
    RuntimeSlice,
} from './lesson.types';
import { getDerivedLessonInfo } from './get-derived-lesson-info';

function computeDerivedLessonInfoAndFretboardNoteDetails(
    lessonInfo: LessonInfo,
) {
    const tuningInfo = getTuningInfo(lessonInfo.tuningId);
    const fretboardNoteDetails = getFretboardNoteDetailsByTuningAndFretLength(
        tuningInfo.notes,
        lessonInfo?.fretLength,
    );
    const derivedLessonInfo = getDerivedLessonInfo(
        lessonInfo,
        fretboardNoteDetails,
    );

    return { derivedLessonInfo, fretboardNoteDetails };
}

function getDataSlice(
    lessonInfo?: LessonInfo | null,
    lessonLanguage?: AppLanguage | null,
): StateCreator<ExerciseState, [], [], DataSlice> {
    let fretboardNoteDetails = null;
    let derivedLessonInfo = null;
    if (lessonInfo) {
        const computedLessonInfo =
            computeDerivedLessonInfoAndFretboardNoteDetails(lessonInfo);
        fretboardNoteDetails = computedLessonInfo.fretboardNoteDetails;
        derivedLessonInfo = computedLessonInfo.derivedLessonInfo;
    }
    return (set) => ({
        derivedLessonInfo,
        lessonLanguage: lessonLanguage ?? null,
        fretboardNoteDetails,
        updateLessonInfo: (lessonInfo: LessonInfo | null) => {
            if (lessonInfo !== null) {
                const { fretboardNoteDetails, derivedLessonInfo } =
                    computeDerivedLessonInfoAndFretboardNoteDetails(lessonInfo);
                set({ fretboardNoteDetails, derivedLessonInfo });
            } else {
                set({ derivedLessonInfo: null, fretboardNoteDetails: null });
            }
        },
        updateLessonLanguage: (lessonLanguage: AppLanguage | null) => {
            set({ lessonLanguage });
        },
    });
}

function getRuntimeSlice(): StateCreator<ExerciseState, [], [], RuntimeSlice> {
    return (set, get) => ({
        mainStep: 'introduction',
        previousStepId: null,
        currentStepId: null,
        nextStepId: null,
        isInMicOnboarding: false,
        goToIntroduction: () =>
            set({
                mainStep: 'introduction',
                currentStepId: null,
                previousStepId: null,
                nextStepId: null,
            }),
        goToSteps: () => {
            const derivedLessonInfo = get().derivedLessonInfo;
            if (derivedLessonInfo === null) {
                return;
            }
            if (derivedLessonInfo.steps?.[0]?.id !== undefined) {
                set({
                    mainStep: 'steps',
                    currentStepId: derivedLessonInfo.steps[0].id,
                    nextStepId: derivedLessonInfo.steps?.[1]?.id || null,
                    previousStepId: null,
                });
            }
        },
        goToEndConfirmation: () => set({ mainStep: 'end-confirmation' }),
        goToEndSummary: () => set({ mainStep: 'end-summary' }),
        goToMicOnboarding: () =>
            set({ mainStep: 'mic-onboarding', isInMicOnboarding: true }),
        leaveMicOnboarding: () => {
            set({ isInMicOnboarding: false });
            get().goToSteps();
        },
        updateCurrentStepId: (currentStepId: string | null) => {
            if (currentStepId === null) {
                return set({
                    currentStepId: null,
                    previousStepId: null,
                    nextStepId: null,
                });
            }
            const derivedLessonInfo = get().derivedLessonInfo;
            if (derivedLessonInfo === null) {
                return;
            }
            const stepIndex = derivedLessonInfo.steps.findIndex(
                ({ id }) => id === currentStepId,
            );
            const previousStepId =
                stepIndex === 0
                    ? null
                    : derivedLessonInfo.steps[stepIndex - 1].id;

            const nextStepId =
                stepIndex === derivedLessonInfo.steps.length - 1
                    ? null
                    : derivedLessonInfo.steps[stepIndex + 1].id;

            set({ currentStepId, previousStepId, nextStepId });
        },
        askLeaveLesson: () => {
            set({ mainStep: 'end-confirmation' });
        },
        cancelLeaveLesson: () => {
            set({
                mainStep: get().isInMicOnboarding ? 'mic-onboarding' : 'steps',
            });
        },
        confirmLeaveLesson: async () => {
            get().goToIntroduction();
        },
    });
}

export function createLessonStore(props: CreateLessonStoreProps) {
    return createStore<ExerciseState>()((...a) => ({
        ...getDataSlice(props.lessonInfo, props.lessonLanguage)(...a),
        ...getRuntimeSlice()(...a),
    }));
}
