import { createContext, useRef } from 'react';
import { createExerciseStore } from '../../../../services/exercise/create-exercise-store';
import {
    ExerciseProviderProps,
    ExerciseStoreApi,
} from '../../../../services/exercise/exercise.types';
import {
    FindTheNoteOnTheNeckAnswer,
    FindTheNoteOnTheNeckConfig,
    FindTheNoteOnTheNeckQuestion,
    FindTheNoteOnTheNeckQuestionMeta,
    FindTheNoteOnTheNeckResultMeta,
} from '../types';
import { FindTheNoteOnTheNeckProgressPayload } from '../../../../services/progress/find-the-note-on-the-neck/types';

export const FindTheNoteOnTheNeckExerciseContext = createContext<
    ExerciseStoreApi<
        FindTheNoteOnTheNeckConfig,
        FindTheNoteOnTheNeckQuestion,
        FindTheNoteOnTheNeckAnswer,
        FindTheNoteOnTheNeckQuestionMeta,
        FindTheNoteOnTheNeckResultMeta,
        FindTheNoteOnTheNeckProgressPayload
    >
>(
    {} as ExerciseStoreApi<
        FindTheNoteOnTheNeckConfig,
        FindTheNoteOnTheNeckQuestion,
        FindTheNoteOnTheNeckAnswer,
        FindTheNoteOnTheNeckQuestionMeta,
        FindTheNoteOnTheNeckResultMeta,
        FindTheNoteOnTheNeckProgressPayload
    >,
);

export function FindTheNoteOnTheNeckExerciseProvider({
    children,
    ...props
}: ExerciseProviderProps<
    FindTheNoteOnTheNeckConfig,
    FindTheNoteOnTheNeckQuestion,
    FindTheNoteOnTheNeckAnswer,
    FindTheNoteOnTheNeckQuestionMeta,
    FindTheNoteOnTheNeckResultMeta,
    FindTheNoteOnTheNeckProgressPayload
>) {
    const storeRef = useRef(createExerciseStore(props));

    return (
        <FindTheNoteOnTheNeckExerciseContext.Provider value={storeRef.current}>
            {children}
        </FindTheNoteOnTheNeckExerciseContext.Provider>
    );
}
