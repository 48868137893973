import { Position } from '@notacami/core/fretboard';
import { getIntervalListSemitones } from '../intervals/get-interval-list-semitones';
import { Fretboard, PositionWithInterval } from '../fretboard';

export function getFretboardPositiveIntervalsByPosition(
    fretboard: Fretboard,
    position: Position,
    forceIntervals: string[] = [],
): PositionWithInterval[] {
    const forceIntervalsWithSemitones =
        getIntervalListSemitones(forceIntervals);

    const intervals = fretboard.intervals[position[0]][position[1]]
        .filter((intervalsByPosition) => intervalsByPosition.direction === 1)
        .map((intervalsByPosition) => {
            const intervalIntoForcedIntervals =
                forceIntervalsWithSemitones.find(
                    (intervalWithSemitones) =>
                        intervalWithSemitones.semitones ===
                        intervalsByPosition.semitones,
                );

            return {
                position: intervalsByPosition.position satisfies Position,
                interval: intervalIntoForcedIntervals
                    ? intervalIntoForcedIntervals.interval
                    : intervalsByPosition.interval,
            };
        });

    return intervals;
}
