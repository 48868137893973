import { compose, sortBy, uniq } from 'lodash/fp';
import { Note } from 'tonal';
import { EventInPart } from '../composer';
import { ListeningStep } from './teacher.types';

function getTimeListUniqAndSorted(timeList: number[]) {
    return compose(uniq, sortBy([(time) => time]))(timeList);
}

export function createListeningSteps(
    eventInParts: EventInPart[],
): ListeningStep[] {
    const noteStartEvents = eventInParts.filter(
        (event) => event.type === 'note-start',
    );

    const groupNoteStartEvents = eventInParts.filter(
        (event) => event.type === 'group-note-start',
    );

    const timeListFromCheckpointEvents = groupNoteStartEvents.map(
        ({ time }) => time,
    );

    const timeListFromNoteStartEvents = noteStartEvents.map(({ time }) => time);

    const checkpointsTimeList = getTimeListUniqAndSorted(
        timeListFromCheckpointEvents,
    );
    const notesTimeList = getTimeListUniqAndSorted(timeListFromNoteStartEvents);

    const listeningSteps = notesTimeList.map((time, index) => {
        const notes = noteStartEvents
            .filter((noteStartEvent) => noteStartEvent.time === time)
            .map((noteStartEvent) => ({
                noteChroma: Note.chroma(noteStartEvent.noteNameToPlay),
                noteIdInPartNoteStartEvents: noteStartEvent.id,
            }));

        const nearestPreviousCheckpointTime = checkpointsTimeList.findLast(
            (checkpointTime) => checkpointTime <= time,
        );

        const previousCheckpointListeningStepIndex =
            notesTimeList.findLastIndex(
                (noteTime) => noteTime <= (nearestPreviousCheckpointTime || 0),
            );

        return {
            notes,
            previousCheckpointListeningStepIndex,
            stepIndex: index,
        };
    });

    return listeningSteps;
}
