import { hasValidContext } from './has-valid-context';

export function getCanvas(canvasElement: HTMLCanvasElement | null) {
    if (canvasElement && canvasElement.parentElement) {
        const context = canvasElement.getContext('2d');

        if (!hasValidContext(context)) return undefined;

        const rect = canvasElement.parentElement.getBoundingClientRect();
        const WIDTH = rect.width;
        const HEIGHT = rect.height;

        canvasElement.width = WIDTH;
        canvasElement.height = HEIGHT;

        return {
            width: WIDTH,
            height: HEIGHT,
            context,
        };
    }
}
