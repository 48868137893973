import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import { Check } from 'lucide-react';
import { forwardRef } from 'react';
import { cn } from '@notacami/core/css';

const Checkbox = forwardRef<
    React.ElementRef<typeof CheckboxPrimitive.Root>,
    React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>
>(({ className, ...props }, ref) => (
    <CheckboxPrimitive.Root
        ref={ref}
        className={cn(
            'peer h-4 w-4 shrink-0 rounded-sm ',
            'border-2 border-neutral-500 dark:border-neutral-800',
            'ring-offset-white focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-neutral-950 dark:focus-visible:ring-neutral-300 focus-visible:ring-offset-2',
            'disabled:cursor-not-allowed disabled:opacity-50',
            'data-[state=checked]:bg-neutral-900 data-[state=checked]:text-lighter',
            'dark:data-[state=checked]:bg-neutral-50 dark:data-[state=checked]:text-darker',
            className,
        )}
        {...props}
    >
        <CheckboxPrimitive.Indicator
            className={cn(
                'flex items-center justify-center text-current w-full h-full',
            )}
        >
            <Check className="h-4 w-4" strokeWidth={4} />
        </CheckboxPrimitive.Indicator>
    </CheckboxPrimitive.Root>
));
Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export { Checkbox };
