import { LessonLanguage, LessonLocalizedText } from './i18n.types';

export function getTextByLocalizedTextAndCurrentLanguage(
    localizedText: LessonLocalizedText,
    currentLanguage: LessonLanguage,
): string | null {
    const foundLocalizedText = localizedText.find(
        ({ locale }) => currentLanguage === locale,
    );
    return foundLocalizedText?.text || null;
}
