import { IntervalPositionAndDistance } from '../fretboard';

export function getAvailablePathsForSegment(
    positions: IntervalPositionAndDistance[],
    scaleIntervals: string[],
): IntervalPositionAndDistance[][] {
    const paths = scaleIntervals.reduce<IntervalPositionAndDistance[][]>(
        (pathsAccumulator, currentInterval, currentIndex) => {
            const paths = positions
                .filter(({ interval }) => currentInterval === interval)
                .flatMap((position) =>
                    currentIndex === 0
                        ? [[position]]
                        : pathsAccumulator.map((path) => [...path, position]),
                );

            return paths;
        },
        [],
    );

    return paths;
}
