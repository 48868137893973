import { NoteNotation } from '../../note-notation/note-notation.type';
import { StoreCapacitor } from '../storage.capacitor';

export function getPreferenceNoteNotationCapacitorStore() {
    return new StoreCapacitor<NoteNotation>(
        'settings.note-notation',
        'english',
        (value) => value,
        (value) => value as NoteNotation,
    );
}
