import { getRandomElement } from '../../utils/get-random-element';
import { getFretboardPositionsByStringIndexesAndNumberOfFrets } from './get-fretboard-positions-by-string-indexes-and-number-of-frets';
import { isPositionEqual } from './is-positions-equal';

export function getRandomPositionOnFretboard(
    stringIndexes: number[],
    numberOfFrets: number,
    excludedPositions?: [stringIndex: number, fretIndex: number][],
): [stringIndex: number, fretIndex: number] {
    const positions = getFretboardPositionsByStringIndexesAndNumberOfFrets(
        stringIndexes,
        numberOfFrets,
    );

    const filteredPositions = positions.filter((position) => {
        return excludedPositions === undefined
            ? true
            : !excludedPositions.some((excludedPosition) =>
                  isPositionEqual(position, excludedPosition),
              );
    });

    const position = getRandomElement(filteredPositions);

    return position;
}
