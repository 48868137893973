import { QuizMode } from '../../../../../../services/exercise/exercise.types';
import { ScalePosition } from '../../../../../../services/fretboard';

export function computeNumberOfErrorsAllowed(
    scalePosition: ScalePosition,
    quizMode: QuizMode,
): number {
    return (
        Math.ceil(scalePosition.positions.length / 3) *
        (quizMode === 'guitar' ? 2 : 1)
    );
}
