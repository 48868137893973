import { isPositionEqual } from '../../../../../services/fretboard';
import {
    FindTheIntervalOnTheNeckTurnResult,
    FindTheIntervalOnTheNeckTurnQuestion,
    FindTheIntervalOnTheNeckAnswer,
    FindTheIntervalOnTheNeckConfig,
} from '../../types';
import { computeResultMeta as computeResultMeta } from './utils/compute-result-meta';

export function computeQuizTurnResultAction(
    config: FindTheIntervalOnTheNeckConfig,
    turnQuestion: FindTheIntervalOnTheNeckTurnQuestion,
    userAnswer: FindTheIntervalOnTheNeckAnswer,
): FindTheIntervalOnTheNeckTurnResult {
    const meta = computeResultMeta(
        turnQuestion.correctAnswer,
        config.fretboard.noteDetails,
    );

    const isCorrect = isPositionEqual(turnQuestion.correctAnswer, userAnswer);

    const turnResult = {
        userAnswer: userAnswer,
        meta,
        isCorrect,
    };

    return turnResult;
}
