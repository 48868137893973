import { useContext } from 'react';
import { useStoreWithEqualityFn } from 'zustand/traditional';
import { PreferencesState } from './preferences.store';
import { PreferencesContext } from './preferences.context';

export function usePreferencesStore<T>(
    selector: (state: PreferencesState) => T,
    equalityFn?: (left: T, right: T) => boolean,
): T {
    const store = useContext(PreferencesContext);

    return useStoreWithEqualityFn(store, selector, equalityFn);
}
