import { forwardRef, useContext, useState } from 'react';
import { AudioWaveform, Guitar, TabletSmartphone } from 'lucide-react';
import { motion } from 'framer-motion';
import {
    getClassnamesByColorVariant,
    getColorVariantByThematic,
} from '@notacami/core/design';
import { cn } from '@notacami/core/css';
import { sleep } from '@notacami/core/testing';
import { Thematic } from '@notacami/core/thematic';
import { Button } from '@notacami/ui';
import { MicError } from '../../../services/pitch-detection';
import { TutorialStepContent } from '../tutorial-step-content';
import { Consumers } from '../../../services/pitch-detection-consumer/consumers';
import { useTranslation } from '../../../hooks/use-translation';
import { ServicesContext } from '../../../services/services.context';
import { useMicTutorialStore } from './mic-tutorial.store';

type ExplainPermissionStepProps = {
    thematic: Thematic;
};

export const ExplainPermissionStep = forwardRef<
    HTMLDivElement,
    ExplainPermissionStepProps
>(({ thematic }, ref) => {
    const { pitchDetectionConsumer } = useContext(ServicesContext);
    const { t } = useTranslation();
    const [pending, setPending] = useState(false);
    const grantPermission = useMicTutorialStore(
        (state) => state.grantPermission,
    );
    const denyPermission = useMicTutorialStore((state) => state.denyPermission);
    const unsupportedMic = useMicTutorialStore((state) => state.unsupportedMic);

    const handleAskForMicPermission = async () => {
        setPending(true);
        await sleep(100);
        const micError = await pitchDetectionConsumer.addConsumer(
            Consumers.MIC_TUTORIAL_EXPLAIN_PERMISSION_STEP,
        );

        switch (micError?.error) {
            case null:
                grantPermission();
                break;
            case MicError.NOT_PERMITTED:
                denyPermission();
                break;
            case MicError.UNSUPPORTED:
                unsupportedMic();
                break;
        }

        pitchDetectionConsumer.removeConsumer(
            Consumers.MIC_TUTORIAL_EXPLAIN_PERMISSION_STEP,
        );
    };

    const colorVariant = getColorVariantByThematic(thematic);

    return (
        <TutorialStepContent
            ref={ref}
            content={
                <>
                    <div className="flex justify-center items-center">
                        <Guitar
                            strokeWidth={1.2}
                            className="w-20 h-20 stroke-neutral-600 dark:stroke-neutral-300"
                        />
                        <motion.div
                            animate={{
                                opacity: [0.5, 1, 0.5],
                                transition: {
                                    repeatDelay: 0.5,
                                    repeatType: 'loop',
                                    repeat: Infinity,
                                },
                            }}
                        >
                            <AudioWaveform
                                strokeWidth={1.5}
                                className={cn(
                                    'w-8 h-8',
                                    getClassnamesByColorVariant(colorVariant)
                                        .stroke,
                                )}
                            />
                        </motion.div>
                        <TabletSmartphone
                            strokeWidth={1.5}
                            className="ml-3 w-14 h-14 stroke-neutral-600 dark:stroke-neutral-300"
                        />
                    </div>
                    <span className="text-center whitespace-pre-line max-w-xs">
                        {t(
                            'tutorial.mic-tutorial.explain-permission-step.description',
                        )}
                    </span>
                </>
            }
            action={
                <Button
                    onClick={handleAskForMicPermission}
                    colorVariant={colorVariant}
                    pending={pending}
                >
                    {t('button.next')}
                </Button>
            }
        />
    );
});

ExplainPermissionStep.displayName = 'ExplainPermissionStep';
