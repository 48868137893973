import { groupBy, orderBy } from 'lodash/fp';
import {
    Fretboard,
    PositionWithDistance,
    PositionWithInterval,
} from './fretboard.types';
import { aggregateIntervalPositionAndDistance } from './aggregate-interval-position-and-distance';

export function getNearestPositionForEachInterval(
    fretboard: Fretboard,
    fretboardIntervalsFromRoot: PositionWithInterval[],
    fretboardDistancesFromRoot: PositionWithDistance[],
): PositionWithInterval[] {
    const searchAreaPositionsIntervals = aggregateIntervalPositionAndDistance(
        fretboard,
        fretboardIntervalsFromRoot,
        fretboardDistancesFromRoot,
    );

    const searchAreaPositionsIntervalsGroup = groupBy(
        'interval',
        searchAreaPositionsIntervals,
    );

    const searchAreaPositionsIntervalsEntries = Object.entries(
        searchAreaPositionsIntervalsGroup,
    );

    const searchAreaPositionsNearestIntervals =
        searchAreaPositionsIntervalsEntries.map(
            ([interval, positionInterval]) => {
                const nearestPosition = orderBy(
                    ['distance', (pos) => pos.position[0]],
                    ['asc', 'asc'],
                    positionInterval,
                )[0];
                return {
                    position: nearestPosition.position,
                    interval,
                };
            },
        );

    return searchAreaPositionsNearestIntervals;
}
