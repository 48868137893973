import { getSelectableStringsFromTuning } from './exercise/states/configuration/configuration.utils';
import { NeckHeadStringSelector } from './neck-head-string-selector';

type StringSelectorControlledOneProps = {
    stringIndex: number;
    leftHanded: boolean;
    onChange: (stringIndex: number) => void;
    tuning: string[];
    isVertical: boolean;
    disabledStrings?: boolean[];
};

export function StringSelectorControlledOne({
    stringIndex,
    tuning,
    onChange,
    leftHanded,
    disabledStrings,
    isVertical,
}: StringSelectorControlledOneProps) {
    const handleChange = (value: number) => {
        onChange(value);
    };

    const strings = getSelectableStringsFromTuning(
        tuning,
        undefined,
        disabledStrings,
    ).map((string) => ({
        ...string,
        selected: string.index === stringIndex,
    }));

    return (
        <NeckHeadStringSelector
            isVertical={isVertical}
            strings={strings}
            leftHanded={leftHanded}
            onChange={handleChange}
        />
    );
}
