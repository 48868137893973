import { useSearchParams } from 'react-router-dom';
import { INTERVALS_SEARCH_PARAM } from '../../utils/routes.contants';
import { FindTheNoteFromRootAndInterval } from '../../components/business/find-the-note-from-root-and-interval';

export function FindTheNoteFromRootAndIntervalPage() {
    const [searchParams] = useSearchParams();

    const intervals = searchParams.get(INTERVALS_SEARCH_PARAM);

    const availableIntervals = !intervals ? null : intervals.split(',');

    return (
        <FindTheNoteFromRootAndInterval
            availableIntervals={availableIntervals}
        />
    );
}
