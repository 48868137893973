import { LeftHandedSection } from './left-handed-section';
import { ReferenceFrequencySection } from './reference-frequency-section';
import { TuningSection } from './tuning-section';

export function SettingsInstrument() {
    return (
        <div className="vertical-content-distribution-base">
            <TuningSection />
            <ReferenceFrequencySection />
            <LeftHandedSection />
        </div>
    );
}
