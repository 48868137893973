import { create } from 'zustand';

type State = {
    modalOpened: boolean;
    askForPermission: boolean;
    openComplete: (askForPermission: boolean) => void;
    reset: () => void;
};

const INITIAL_STATE = {
    modalOpened: false,
    askForPermission: false,
};

export const useMicSettingStore = create<State>((set) => ({
    ...INITIAL_STATE,
    reset: () => set(INITIAL_STATE),
    openComplete: (askForPermission: boolean) =>
        set({ modalOpened: true, askForPermission }),
}));
