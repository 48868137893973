import {
    EventInPart,
    NoteStartEvent,
} from '../../../../../../services/composer';

type NotesToShowAction =
    | {
          type: 'INIT';
          eventsInPart: EventInPart[];
      }
    | {
          type: 'UPDATE_PLAYING_GROUP_NOTE';
          playingGroupId: string;
      }
    | {
          type: 'RESET';
      };

export type NotesToShowState = {
    eventsInPart: EventInPart[];
    notesToShow: NoteStartEvent[];
    playingGroupId: string | null;
    firstPlayingGroupId: string | null;
};

export function notesToShowReducer(
    state: NotesToShowState,
    action: NotesToShowAction,
): NotesToShowState {
    switch (action.type) {
        case 'INIT': {
            const firstPlayingGroupId =
                action.eventsInPart
                    .filter(
                        (eventInPart) =>
                            eventInPart.type === 'group-note-start',
                    )
                    .find(
                        (groupNoteStartEvent) => groupNoteStartEvent.time === 0,
                    )?.id || '';
            const notesToShow = action.eventsInPart
                .filter((eventInPart) => eventInPart.type === 'note-start')
                .filter(
                    (eventInPart) =>
                        eventInPart.groupId === firstPlayingGroupId,
                );
            return {
                ...state,
                notesToShow,
                eventsInPart: action.eventsInPart,
                playingGroupId: firstPlayingGroupId,
                firstPlayingGroupId,
            };
        }
        case 'UPDATE_PLAYING_GROUP_NOTE': {
            const notesToShow = state.eventsInPart
                .filter((eventInPart) => eventInPart.type === 'note-start')
                .filter(
                    (eventInPart) =>
                        eventInPart.groupId === action.playingGroupId,
                );
            return {
                ...state,
                notesToShow,
                playingGroupId: action.playingGroupId,
            };
        }
        case 'RESET': {
            const notesToShow = state.eventsInPart
                .filter((eventInPart) => eventInPart.type === 'note-start')
                .filter(
                    (eventInPart) =>
                        eventInPart.groupId === state.firstPlayingGroupId,
                );
            return {
                ...state,
                notesToShow,
                playingGroupId: state.firstPlayingGroupId,
            };
        }
        default:
            return state;
    }
}
