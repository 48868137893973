---
title: Bien commencer
---

J'aimerais vous partager quelques astuces avant que vous commenciez à parcourir l'application.

## A qui s'adresse <LogoText /> ?

Cette application s'adresse à tous les curieux qui se demandent : Comment la musique est construite ? Pourquoi la mélodie sonne parfois triste et parfois joyeuse ?

## Qu'est ce que <LogoText /> peut vous apporter ?

L'application se compose de plusieurs thématiques qui vous permettront
de mieux comprendre le manche de votre instrument et la théorie musicale.

Le but est de vous aider à progresser dans vos **improvisations** et **compositions**.

Les différentes activités ont été pensé pour être le plus ludique possible.

Les exercices vous écoutent et vous guide au besoin.

<LogoText /> vous invite à prendre votre intrument dans les mains et pratiquer.

## Comment utiliser <LogoText /> ?

Le format de l'application est pensé pour venir pratiquer
sur des laps de temps très court (minimum 1 minute).

L'application a été réfléchi avec des thématiques à suivre dans l'ordre.
Vous pouvez commencer par "**Les notes**", puis passer à la suivante.

**Bon jeu !**