import { TonicAndOctavePosition, Segment } from '../fretboard';
import { getTonicAndOctavePositionBySegment } from './get-tonic-and-octave-positions-by-segment';
import { getLeafPositionsBySegments } from './get-leaf-positions-by-segments';
import { getSegmentEndedByOctavePosition } from './get-segment-ended-by-octave-position';
import { getSegmentByTonicAndOctavePosition } from './get-segment-by-tonic-and-octave-position';

export function getScalePositionsSegmentsBySegments(
    segments: Segment[],
): Segment[][] {
    const leafTonicAndOctavePositions = getLeafPositionsBySegments(segments);

    const branches = leafTonicAndOctavePositions.map(
        getSegmentsByLeafTonicAndOctavePosition(segments),
    );

    return branches;
}

function getSegmentsByLeafTonicAndOctavePosition(segments: Segment[]) {
    return (leafTonicAndOctavePosition: TonicAndOctavePosition) => {
        const foundSegment = getSegmentByTonicAndOctavePosition(
            segments,
            leafTonicAndOctavePosition,
        );
        return getNextSegment(segments, foundSegment, [foundSegment]);
    };
}

function getNextSegment(
    segments: Segment[],
    currentSegment: Segment,
    previousSegments: Segment[],
): Segment[] {
    const currentSegmentTonicAndOctavePosition =
        getTonicAndOctavePositionBySegment(currentSegment);

    const nextSegment = getSegmentEndedByOctavePosition(
        segments,
        currentSegmentTonicAndOctavePosition.tonicPosition,
    );
    if (nextSegment !== null) {
        return getNextSegment(segments, nextSegment, [
            nextSegment,
            ...previousSegments,
        ]);
    } else {
        return previousSegments;
    }
}
