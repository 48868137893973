import { ReactNode } from 'react';
import { Button } from '@notacami/ui';
import { Thematic } from '@notacami/core/thematic';
import { getColorVariantByThematic } from '@notacami/core/design';

type SubmitButtonProps = {
    disabled?: boolean;
    children: ReactNode;
    pending?: boolean;
    thematic: Thematic;
};

export function SubmitButton({
    disabled = false,
    pending = false,
    thematic,
    children,
}: SubmitButtonProps) {
    return (
        <Button
            type="submit"
            className="w-full"
            size="lg"
            pending={pending}
            disabled={disabled}
            colorVariant={getColorVariantByThematic(thematic)}
        >
            {children}
        </Button>
    );
}
