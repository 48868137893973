import { useStore } from 'zustand';
import { ExerciseStoreApi } from '../exercise.types';

export function useQuestion<
    Config,
    Question,
    Answer,
    ResultMeta,
    RoundMeta,
    ProgressPayload,
>(
    store: ExerciseStoreApi<
        Config,
        Question,
        Answer,
        ResultMeta,
        RoundMeta,
        ProgressPayload
    >,
) {
    const currentTurn = useStore(store, (state) => state.currentTurn);
    const questions = useStore(store, (state) => state.questions);

    const question = questions[currentTurn - 1];

    return question;
}
