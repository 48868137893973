import { motion } from 'framer-motion';
import { cn } from '@notacami/core/css';
import { useNoteNotation } from '../../services/note-notation/use-note-notation';
import { getStringThickness } from './utils';
import { StringNumber } from './string-number';

type StringSelectorProps = {
    id: string;
    label: string;
    octave: number;
    onChange: (stringIndex: number) => void;
    selected: boolean;
    stringNumber: number;
    stringIndex: number;
    disabled: boolean;
};

export function StringSelectorItem({
    id,
    label,
    octave,
    onChange,
    selected,
    stringNumber,
    stringIndex,
    disabled,
}: StringSelectorProps) {
    const { n } = useNoteNotation();

    const handleChange = () => {
        onChange(stringIndex);
    };

    return (
        <li className={cn('relative', disabled && 'opacity-30')}>
            <input
                hidden
                type="checkbox"
                id={id}
                checked={selected}
                onChange={handleChange}
                disabled={disabled}
            />
            <div className="absolute w-full h-full flex justify-center items-start">
                <div
                    className={cn('h-full', getStringThickness(octave, true))}
                />
            </div>
            <motion.div
                animate={{ y: selected ? -22 : -5 }}
                className="absolute left-1/2 -ml-2"
            >
                <StringNumber stringNumber={stringNumber} selected={selected} />
            </motion.div>
            <div className="py-1">
                <label
                    className={cn(
                        'relative flex justify-center items-center rounded-md px-2 py-1 w-10 h-7 text-sm font-semibold',
                        'border-2 transition-all cursor-pointer',
                        selected &&
                            'bg-stone-600 dark:bg-stone-600 border-stone-600 dark:border-stone-600 text-neutral-50',
                        !selected &&
                            'bg-stone-300 dark:bg-stone-800 border-stone-300 dark:border-stone-800 text-neutral-500 dark:text-neutral-500',
                    )}
                    htmlFor={id}
                >
                    {n(label)}
                </label>
            </div>
        </li>
    );
}
