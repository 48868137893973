import { Instrument } from '../instrument/instrument';
import { Musician } from '../musician/musician';
import { AppAudioContext } from '../app-audio-context/app-audio-context';
import { SoundPlayer } from './sound-player';

const appAudioContext = new AppAudioContext();
const instrument = new Instrument(appAudioContext);
const musician = new Musician(instrument, appAudioContext);

export function getSoundPlayerInstance() {
    return new SoundPlayer(musician);
}
