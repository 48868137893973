import { TFunctionType, useTranslation } from '../../hooks/use-translation';
import { TranslationKey } from '../i18n/translations.types';
import { getIntervalInterpolationInfo } from './get-interval-interpolation-info';
import {
    IntervalInterpolationInfo,
    IntervalInterpolationInfoMaybeQuality,
} from './intervals.types';

function removeIntervalQualityWhenNotUniq(
    accumulator: IntervalInterpolationInfoMaybeQuality[],
    current: IntervalInterpolationInfo,
): IntervalInterpolationInfoMaybeQuality[] {
    const sameIntervalFoundIndex = accumulator.findIndex(
        ({ number }) => number === current.number,
    );

    if (sameIntervalFoundIndex !== -1) {
        return accumulator.map((value, index) =>
            index === sameIntervalFoundIndex
                ? { ...value, quality: null }
                : value,
        );
    } else {
        return [...accumulator, current];
    }
}

function createTranslationFunction(t: TFunctionType) {
    return (intervalList: string[]) => {
        const intervalListInfo = intervalList
            .map(getIntervalInterpolationInfo)
            .reduce(removeIntervalQualityWhenNotUniq, [])
            .map((intervalInterpolationInfoWithMaybeQuality) => {
                if (
                    intervalInterpolationInfoWithMaybeQuality.quality === null
                ) {
                    return t(
                        `interval-number.${intervalInterpolationInfoWithMaybeQuality.number}` as TranslationKey,
                    );
                } else {
                    return t(
                        'interval-name',
                        intervalInterpolationInfoWithMaybeQuality,
                    );
                }
            });
        return intervalListInfo;
    };
}

export function useTextIntervalsWithSeparation() {
    const { t } = useTranslation();
    const translate = createTranslationFunction(t);
    return { translate };
}
