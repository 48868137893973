import { Position } from '@notacami/core/fretboard';
import { isPositionEqual } from './is-positions-equal';

export function isPositionIncludedInPositions(
    positions: Position[],
    maybeIncludedPosition: Position,
) {
    return positions.some((position) =>
        isPositionEqual(position, maybeIncludedPosition),
    );
}
