import { create } from 'zustand';

type Step =
    | 'quite-env'
    | 'explain-permission'
    | 'permission-denied'
    | 'unsupported-mic'
    | 'gain-setting'
    | 'tuning'
    | 'get-ready';

type State = {
    shouldExplainForPermission: boolean;
    steps: string;
    step: Step;
    settleInQuitePlace: () => void;
    _goToExplainPermissionStep: () => void;
    _goToGainSettingStep: () => void;
    grantPermission: () => void;
    reset: () => void;
    denyPermission: () => void;
    unsupportedMic: () => void;
    goToTuning: () => void;
    goToGetReady: () => void;
    start: (shouldExplainForPermission: boolean) => void;
};

const INITIAL_STATE = {
    steps: '',
    step: 'quite-env' as const,
    shouldExplainForPermission: true,
};

function getNumberOfSteps(shouldExplainForPermission: boolean) {
    const baseNumber = 4;
    const stepsNumber = [baseNumber, shouldExplainForPermission ? 1 : 0];
    return stepsNumber.reduce((acc, cur) => acc + cur, 0);
}

function getStepIndex(step: Step, shouldExplainForPermission: boolean) {
    switch (step) {
        case 'quite-env':
            return 0;
        case 'explain-permission':
        case 'permission-denied':
        case 'unsupported-mic':
            return 1;
        case 'gain-setting':
            return shouldExplainForPermission ? 2 : 1;
        case 'tuning':
            return shouldExplainForPermission ? 3 : 2;
        case 'get-ready':
            return shouldExplainForPermission ? 4 : 3;
    }
}

function getStepPadding(step: Step, shouldExplainForPermission: boolean) {
    const stepIndex = getStepIndex(step, shouldExplainForPermission);
    const numberOfSteps = getNumberOfSteps(shouldExplainForPermission);
    const numberOfStepsBefore = stepIndex;
    const numberOfStepsAfter = numberOfSteps - stepIndex - 1;
    return [numberOfStepsBefore, numberOfStepsAfter];
}

function getStepsSymbols(
    step: Step,
    shouldExplainForPermission: boolean,
    canReachNextSteps = true,
) {
    const [before, after] = getStepPadding(step, shouldExplainForPermission);
    return (
        ''.padStart(before, 'v') +
        'o' +
        ''.padEnd(after, canReachNextSteps ? '-' : 'x')
    );
}

function getSteps(step: Step, shouldExplainForPermission: boolean): string {
    switch (step) {
        case 'quite-env':
            return getStepsSymbols('quite-env', shouldExplainForPermission);
        case 'explain-permission':
            return getStepsSymbols(
                'explain-permission',
                shouldExplainForPermission,
            );
        case 'permission-denied':
        case 'unsupported-mic':
            return getStepsSymbols(
                'explain-permission',
                shouldExplainForPermission,
                false,
            );
        case 'gain-setting':
            return getStepsSymbols('gain-setting', shouldExplainForPermission);
        case 'tuning':
            return getStepsSymbols('tuning', shouldExplainForPermission);
        case 'get-ready':
            return getStepsSymbols('get-ready', shouldExplainForPermission);
    }
}

export const useMicTutorialStore = create<State>((set, get) => ({
    ...INITIAL_STATE,
    start: (shouldExplainForPermission: boolean) =>
        set({
            shouldExplainForPermission,
            steps: getSteps('quite-env', shouldExplainForPermission),
        }),
    reset: () => set(INITIAL_STATE),
    settleInQuitePlace: () => {
        const shouldExplainForPermission = get().shouldExplainForPermission;
        if (shouldExplainForPermission) {
            get()._goToExplainPermissionStep();
        } else {
            get()._goToGainSettingStep();
        }
    },
    _goToExplainPermissionStep: () =>
        set((state) => ({
            step: 'explain-permission',
            steps: getSteps(
                'explain-permission',
                state.shouldExplainForPermission,
            ),
        })),
    _goToGainSettingStep: () =>
        set((state) => ({
            step: 'gain-setting',
            steps: getSteps('gain-setting', state.shouldExplainForPermission),
        })),
    grantPermission: () => {
        get()._goToGainSettingStep();
    },
    denyPermission: () =>
        set((state) => ({
            step: 'permission-denied',
            steps: getSteps(
                'permission-denied',
                state.shouldExplainForPermission,
            ),
        })),
    unsupportedMic: () =>
        set((state) => ({
            step: 'unsupported-mic',
            steps: getSteps(
                'unsupported-mic',
                state.shouldExplainForPermission,
            ),
        })),
    goToTuning: () =>
        set((state) => ({
            step: 'tuning',
            steps: getSteps('tuning', state.shouldExplainForPermission),
        })),
    goToGetReady: () =>
        set((state) => ({
            step: 'get-ready',
            steps: getSteps('get-ready', state.shouldExplainForPermission),
        })),
}));
