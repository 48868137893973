import { create } from 'zustand';

type State = {
    hidden: boolean;
    show: () => void;
    hide: () => void;
};
export const useTabBarStore = create<State>((set) => ({
    hidden: true,
    show: () => set({ hidden: false }),
    hide: () => set({ hidden: true }),
}));
