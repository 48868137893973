import { forwardRef } from 'react';
import { Button } from '@notacami/ui';
import { MicUnsupportedContent } from '../mic-unsupported-content';
import { TutorialStepContent } from '../tutorial-step-content';
import { useTranslation } from '../../../hooks/use-translation';

type UnsupportedMicStepProps = {
    abortTutorial: () => void;
};

export const UnsupportedMicStep = forwardRef<
    HTMLDivElement,
    UnsupportedMicStepProps
>(({ abortTutorial }, ref) => {
    const { t } = useTranslation();
    return (
        <TutorialStepContent
            ref={ref}
            content={<MicUnsupportedContent />}
            action={
                <Button onClick={abortTutorial}>{t('button.i-get-it')}</Button>
            }
        />
    );
});

UnsupportedMicStep.displayName = 'UnsupportedMicStep';
