import { ForwardedRef, forwardRef } from 'react';
import { motion } from 'framer-motion';
import { cn } from '@notacami/core/css';
import { getClassnamesByColorVariant } from '@notacami/core/design';
import { Position } from '@notacami/core/fretboard';
import { useNoteNotation } from '../../../services/note-notation/use-note-notation';
import { getNoteItemBaseClassnames } from '../../../services/fretboard';
import { ConditionalWrapper } from '../../../../../../libs/core/src/components/conditional-wrapper';

type NeckNoteProps = {
    isPlayed?: boolean;
    isOpenString: boolean;
    isVertical: boolean;
    notePitchClass: string;
    position: Position;
    onClick?: (position: Position) => void;
};

export const NeckNote = forwardRef<
    HTMLDivElement | HTMLButtonElement,
    NeckNoteProps
>(
    (
        {
            isOpenString,
            isVertical,
            notePitchClass,
            position,
            onClick,
            isPlayed = false,
        },
        ref,
    ) => {
        const { n } = useNoteNotation();

        const handleClick = () => {
            onClick && onClick(position);
        };

        const colors = getClassnamesByColorVariant(
            isOpenString ? 'neutral-invert' : 'neutral',
        );

        const baseClassNames = getNoteItemBaseClassnames(
            isVertical,
            isOpenString,
        );

        const classNames = cn(
            ...baseClassNames,
            'shadow-md',
            colors.text,
            colors.border,
            'border-4',
            !isOpenString && !isPlayed && colors.backgroundTransparent,
            (isOpenString || isPlayed) && colors.background,
        );

        return (
            <ConditionalWrapper
                items={[
                    {
                        condition: onClick !== undefined,
                        wrapper: (children, index) => (
                            <motion.button
                                key={index}
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                ref={ref as ForwardedRef<HTMLButtonElement>}
                                onClick={handleClick}
                                className={classNames}
                            >
                                {children}
                            </motion.button>
                        ),
                    },
                    {
                        condition: onClick === undefined,
                        wrapper: (children, index) => (
                            <motion.div
                                key={index}
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                ref={ref as ForwardedRef<HTMLDivElement>}
                                className={classNames}
                            >
                                {children}
                            </motion.div>
                        ),
                    },
                ]}
            >
                <>
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: isPlayed ? 1 : 0 }}
                        className={cn(
                            baseClassNames,
                            'absolute scale-125',
                            'border-neutral-100 dark:border-neutral-100 border-4 shadow-md',
                        )}
                    />
                    <div className={isOpenString ? 'text-xs' : 'text-base'}>
                        {n(notePitchClass)}
                    </div>
                </>
            </ConditionalWrapper>
        );
    },
);
NeckNote.displayName = 'NeckNote';
