import { useMemo } from 'react';
import { cn } from '@notacami/core/css';
import { getFretboardSymbols } from '../../../services/fretboard';
import { getRangeNumberByLength } from '../../../utils/array.utils';
import { FretboardSymbol } from './fretboard-symbol';

type FretboardSymbolsProps = {
    fretboardLength: number;
    isVertical: boolean;
    leftHanded: boolean;
};

export function FretboardSymbols({
    fretboardLength,
    isVertical,
    leftHanded,
}: FretboardSymbolsProps) {
    const fretboardSymbols = useMemo(
        () => getFretboardSymbols(fretboardLength),
        [fretboardLength],
    );
    return (
        <div
            className={cn(
                'flex border-transparent',
                !isVertical && 'border-2-x',
                !isVertical && !leftHanded && 'flex-row',
                !isVertical && leftHanded && 'flex-row-reverse',
                isVertical && 'flex-col border-2-y',
            )}
        >
            {getRangeNumberByLength(fretboardLength + 1).map((fretIndex) => (
                <FretboardSymbol
                    key={fretIndex}
                    isOpenString={fretIndex === 0}
                    symbol={fretIndex > 0 ? fretboardSymbols[fretIndex - 1] : 0}
                    isVertical={isVertical}
                />
            ))}
        </div>
    );
}
