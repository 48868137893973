import { useStore } from 'zustand';
import { usePrevious } from 'react-use';
import { Progress } from '../../../progress';
import { ExerciseStoreApi } from '../../../../../services/exercise/exercise.types';
import useTimer from '../../../../../hooks/use-timer';
import { usePlayingState } from '../../../../../services/exercise/hooks/use-playing-state';
import { useIsPaused } from '../../../../../services/exercise/hooks/use-is-paused';
import { useStopWatch } from '../../../../../hooks/use-stop-watch';

type ProgressSectionProps<
    Config,
    Question,
    Answer,
    ResultMeta,
    RoundMeta,
    ProgressPayload,
> = {
    store: ExerciseStoreApi<
        Config,
        Question,
        Answer,
        ResultMeta,
        RoundMeta,
        ProgressPayload
    >;
};

function ProgressContainer<
    Config,
    Question,
    Answer,
    ResultMeta,
    RoundMeta,
    ProgressPayload,
>({
    store,
}: ProgressSectionProps<
    Config,
    Question,
    Answer,
    ResultMeta,
    RoundMeta,
    ProgressPayload
>) {
    const thematic = useStore(store, (state) => state.thematic);
    const timeIsUp = useStore(store, (state) => state.timeIsUp);
    const duration = useStore(store, (state) => state.duration);
    const isFinish = useStore(store, (state) => state.isFinish);
    const updateSessionPracticeTime = useStore(
        store,
        (state) => state.updateSessionPracticeTime,
    );

    const { isInPlayingPlayStateStrict, isInPlayingResultStateStrict } =
        usePlayingState(store);

    const isInGame = isInPlayingPlayStateStrict || isInPlayingResultStateStrict;
    const isPaused = useIsPaused(store);

    const isPausedOrNotInGame = isPaused || !isInGame;
    const durationInMS = (duration || 0) * 60 * 1000;
    const { time, set } = useTimer(durationInMS, isPausedOrNotInGame, 100);
    const stopwatchTime = useStopWatch(isPausedOrNotInGame, 1000);
    const previousStopwatchTime = usePrevious(stopwatchTime);

    if (time === 0 && !isFinish) {
        timeIsUp();
    }

    if (stopwatchTime !== previousStopwatchTime && isInGame) {
        updateSessionPracticeTime(stopwatchTime);
    }

    const handleOnClick = () => {
        set(0);
    };

    return (
        <Progress
            time={time}
            thematic={thematic}
            isPaused={isPausedOrNotInGame}
            onClick={handleOnClick}
        />
    );
}

export function ProgressSection<
    Config,
    Question,
    Answer,
    ResultMeta,
    RoundMeta,
    ProgressPayload,
>({
    store,
}: ProgressSectionProps<
    Config,
    Question,
    Answer,
    ResultMeta,
    RoundMeta,
    ProgressPayload
>) {
    const { isInPlayingPlayState, isInPlayingResultState } =
        usePlayingState(store);

    return isInPlayingPlayState || isInPlayingResultState ? (
        <ProgressContainer store={store} />
    ) : null;
}
