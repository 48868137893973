import { StoreCapacitor } from '../storage.capacitor';

export function getPreferenceMicCapacitorStore() {
    return new StoreCapacitor<PermissionStatus['state']>(
        'settings.mic',
        'prompt',
        (value) => value,
        (value) => value as PermissionStatus['state'],
    );
}
