import { Hourglass } from 'lucide-react';
import { motion } from 'framer-motion';
import {
    getClassnamesByColorVariant,
    getColorVariantByThematic,
} from '@notacami/core/design';
import { cn } from '@notacami/core/css';
import { Thematic } from '@notacami/core/thematic';
import { formatMsToMMSSWithoutLeadingZero } from '../../services/time';

type ProgressProps = {
    time: number;
    isPaused: boolean;
    thematic: Thematic;
    onClick: () => void;
};

export function Progress({ time, thematic, isPaused, onClick }: ProgressProps) {
    const formattedTime = formatMsToMMSSWithoutLeadingZero(time);
    const classnames = getClassnamesByColorVariant(
        getColorVariantByThematic(thematic),
    );

    const variants = {
        visible: { opacity: 1 },
        hidden: {
            opacity: [1, 0.2, 1],
            transition: { repeat: Infinity, repeatDelay: 0.2 },
        },
    };

    return (
        <button
            className={cn(
                'flex justify-center border-2 items-center tabular-nums bg-neutral-600 dark:bg-neutral-700 rounded-3xl gap-1 px-3 py-2',
                classnames.border,
                'text-neutral-100',
            )}
            onClick={onClick}
            data-testid="progress-time"
        >
            <motion.div
                animate={{ rotate: isPaused ? 0 : 180 }}
                transition={{
                    repeat: isPaused ? 0 : Infinity,
                    repeatDelay: 5,
                    repeatType: 'mirror',
                }}
            >
                <Hourglass className="h-4" strokeWidth={3} />
            </motion.div>
            <motion.div
                className="leading-none pt-px h-4"
                variants={variants}
                animate={time === 0 ? 'hidden' : 'visible'}
            >
                {formattedTime}
            </motion.div>
        </button>
    );
}
