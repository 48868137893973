import { Position } from '@notacami/core/fretboard';
import { Fretboard, ScalePosition } from '../fretboard';
import { getScalePositionsAcrossEntireNeckHeight } from './get-scale-positions-across-entire-neck-height';

export function getScalePositionsAcrossEntireNeckHeightWithRetry(
    fretboard: Fretboard,
    scaleIntervals: string[],
    position: Position,
    startMaxFretDiffByString: number,
    startMaxFretDiffBySegment: number,
    maxIterationNumber: number,
): ScalePosition[] {
    const scalePositions = tryToFindScalePositions(
        fretboard,
        scaleIntervals,
        position,
        startMaxFretDiffByString,
        startMaxFretDiffBySegment,
        0,
        maxIterationNumber,
    );
    return scalePositions;
}

function tryToFindScalePositions(
    fretboard: Fretboard,
    scaleIntervals: string[],
    position: Position,
    maxFretDiffByString: number,
    maxFretDiffBySegment: number,
    iterationIndex: number,
    maxIterationNumber: number,
) {
    const scalePositions = getScalePositionsAcrossEntireNeckHeight(
        fretboard,
        scaleIntervals,
        position,
        maxFretDiffByString,
        maxFretDiffBySegment,
    );
    if (scalePositions.length === 0 && iterationIndex < maxIterationNumber) {
        return tryToFindScalePositions(
            fretboard,
            scaleIntervals,
            position,
            maxFretDiffByString + 1,
            maxFretDiffBySegment + 1,
            iterationIndex + 1,
            maxIterationNumber,
        );
    }
    return scalePositions;
}
