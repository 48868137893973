import { forwardRef, ReactNode } from 'react';
import { cn } from '@notacami/core/css';
import { Surface } from '@notacami/ui';
import { ColorVariant, SurfaceId } from '@notacami/core/design';

type BadgeProps = {
    children: ReactNode;
    surfaceId?: SurfaceId;
    colorVariant?: ColorVariant;
    className?: string;
};

export const Badge = forwardRef<HTMLDivElement, BadgeProps>(
    ({ children, surfaceId, colorVariant, className }, ref) => {
        return (
            <Surface
                ref={ref}
                surfaceId={surfaceId}
                colorVariant={colorVariant}
                className={cn(
                    'text-sm px-2 py-1 rounded-lg font-semibold text-center',
                    className,
                )}
            >
                {children}
            </Surface>
        );
    },
);
