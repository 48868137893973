import { ReactNode, createElement, forwardRef } from 'react';
import {
    ColorVariant,
    SurfaceId,
    getClassnamesByColorVariant,
    getClassnamesBySurfaceId,
} from '@notacami/core/design';
import { cn } from '@notacami/core/css';
import { TitleContext } from './title.context';

export type TitleLevel = -1 | 0 | 1 | 2 | 3;

export type TitleTagName = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

export type TitleProps = {
    children: ReactNode;
    className?: string;
    onSurface?: SurfaceId;
    onColor?: ColorVariant;
    colorVariant?: ColorVariant;
    left?: ReactNode;
    level: TitleLevel;
    tagName: TitleTagName;
};

function getClassNamesByTitleLevel(titleLevel: TitleLevel) {
    switch (titleLevel) {
        case -1:
            return 'text-4xl gap-4';
        case 0:
            return 'text-3xl md:text-4xl gap-4';
        case 1:
            return 'text-2xl md:text-3xl gap-3';
        case 2:
            return 'text-xl gap-3';
        case 3:
            return 'text-base gap-2';
    }
}

export const Title = forwardRef<HTMLHeadingElement, TitleProps>(
    (
        {
            className,
            level,
            children,
            colorVariant,
            onSurface,
            left,
            onColor,
            tagName = 'h1',
        },
        ref,
    ) => {
        const onSurfaceClassnames =
            onSurface !== undefined
                ? getClassnamesBySurfaceId(onSurface).title
                : undefined;
        const onColorClassnames =
            onColor !== undefined
                ? getClassnamesByColorVariant(onColor).text
                : undefined;
        const colorClassnames =
            colorVariant !== undefined
                ? getClassnamesByColorVariant(colorVariant).textColored
                : undefined;
        const baseClassnames = 'font-semibold flex items-center';

        const classNames = getClassNamesByTitleLevel(level);

        return (
            <TitleContext.Provider value={{ level }}>
                {createElement(
                    tagName,
                    {
                        ref,
                        className: cn(
                            classNames,
                            baseClassnames,
                            onSurfaceClassnames,
                            onColorClassnames,
                            colorClassnames,
                            className,
                        ),
                    },
                    left ? <span>{left}</span> : null,
                    <span>{children}</span>,
                )}
            </TitleContext.Provider>
        );
    },
);

Title.displayName = 'Title';
