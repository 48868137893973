import { createRoot } from 'react-dom/client';
import { Device } from '@capacitor/device';
import { SafeArea } from 'capacitor-plugin-safe-area';
import { Animation, StatusBar } from '@capacitor/status-bar';
import { SentryApp } from './boot/app.tsx';

import './style.css';

export async function boot() {
    const { platform } = await Device.getInfo();
    if (platform !== 'web' && platform !== 'ios') {
        StatusBar.setOverlaysWebView({ overlay: true });
        StatusBar.show({ animation: Animation.None });
        // fix this problem https://github.com/AlwaysLoveme/capacitor-plugin-safe-area/issues/25
        await SafeArea.setImmersiveNavigationBar();
    }

    const container = document.getElementById('root');

    if (container !== null) {
        const root = createRoot(container);
        root.render(<SentryApp />);
    }
}
