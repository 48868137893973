import { Position } from '@notacami/core/fretboard';
import {
    Fretboard,
    getFretboardDistancesByPosition,
    getNearestPositionForEachInterval,
    PositionWithInterval,
} from '../fretboard';

export function getFretboardNearestAvailableIntervalsByPosition(
    fretboard: Fretboard,
    position: Position,
    availablePositionIntervals: PositionWithInterval[],
): PositionWithInterval[] {
    const fretboardDistancesFromPosition = getFretboardDistancesByPosition(
        fretboard,
        position,
    );

    const intervalsNearestPosition = getNearestPositionForEachInterval(
        fretboard,
        availablePositionIntervals,
        fretboardDistancesFromPosition,
    );

    return intervalsNearestPosition;
}
