import { Link, useLocation } from 'react-router-dom';
import { Home } from 'lucide-react';
import { motion } from 'framer-motion';
import { cn } from '@notacami/core/css';
import { useSafeAreaInsets } from '../../hooks/use-safe-area-insets';
import { PageUrls } from '../../utils/routes.contants';
import { useTranslation } from '../../hooks/use-translation';
import { ANIMATION_DELAY } from '../business/page-layout.constants';
import { useTabBarStore } from './tab-bar.store';

export function TabBar() {
    const hidden = useTabBarStore((state) => state.hidden);
    const { bottom, left, right } = useSafeAreaInsets();
    const { pathname } = useLocation();
    const { t } = useTranslation();

    const activeRoute = Object.values(PageUrls).find(
        (href) => pathname === href,
    );

    const isNextHomePage = activeRoute === PageUrls.HOME;

    return (
        <motion.div
            className="fixed bottom-0 left-0 right-0 z-50"
            initial={{ y: 88 + bottom }}
            animate={{ y: hidden ? 88 + bottom : -bottom }}
            transition={{
                type: 'spring',
                bounce: 0.25,
                delay: hidden ? 0 : ANIMATION_DELAY,
            }}
            style={{
                paddingLeft: `${left + 8}px`,
                paddingRight: `${right + 8}px`,
            }}
        >
            <div
                className={cn(
                    'relative container max-w-md mx-auto rounded-t-3xl',
                    'bg-gradient-to-b',
                    'from-stone-900 via-stone-800 to-stone-700',
                    'dark:from-neutral-800 dark:via-neutral-850 dark:to-neutral-900',
                )}
            >
                <div
                    className={cn(
                        'relative flex flex-row items-center justify-center',
                        'px-4 py-2 gap-4',
                    )}
                >
                    <div
                        className="absolute inset-0 top-full bg-stone-700 dark:bg-neutral-900"
                        style={{
                            height: `${bottom + 32}px`,
                        }}
                    />
                    <motion.div
                        initial={{ y: 30 }}
                        animate={{ y: hidden ? 30 : 0 }}
                        transition={{
                            type: 'spring',
                            bounce: 0.25,
                            delay: 0.25,
                        }}
                        className={cn(
                            'absolute inset-x-4 top-full rounded-t-3xl',
                            'bg-gradient-to-t',
                            'from-stone-900 to-stone-900/30',
                            'dark:from-neutral-950 dark:to-neutral-950/30',
                        )}
                        style={{
                            height: `${bottom + 32}px`,
                        }}
                    />
                    <Link to={PageUrls.HOME}>
                        <div className="flex flex-col items-center justify-center gap-2 w-12 h-12">
                            <motion.div
                                className="relative  flex flex-col justify-center items-center"
                                animate={{
                                    y: isNextHomePage ? -5 : 0,
                                }}
                            >
                                <motion.div
                                    animate={{
                                        scale: isNextHomePage ? 1.2 : 1,
                                        y: isNextHomePage ? 6 : 0,
                                    }}
                                >
                                    <Home
                                        size={24}
                                        className="stroke-lighter"
                                    />
                                </motion.div>
                                <motion.div
                                    animate={{
                                        opacity: isNextHomePage ? 0 : 1,
                                    }}
                                >
                                    <div
                                        className={cn(
                                            'transition-all text-xs',
                                            isNextHomePage
                                                ? 'text-lighter'
                                                : 'text-light',
                                        )}
                                    >
                                        {t('tab-bar.home')}
                                    </div>
                                </motion.div>
                                <motion.div
                                    animate={{
                                        scale: isNextHomePage ? 1 : 0,
                                        y: isNextHomePage ? 0 : bottom + 10,
                                    }}
                                    className={cn(
                                        'absolute w-2 h-2 rounded-md -bottom-2',
                                        isNextHomePage
                                            ? 'bg-indigo-400'
                                            : 'bg-transparent',
                                    )}
                                />
                            </motion.div>
                        </div>
                    </Link>
                </div>
            </div>
        </motion.div>
    );
}
