import { ScalePositionType } from '../../../services/fretboard';
import { getScaleList } from '../../../services/scales/scale-list';
import { ScaleName } from '../../../services/scales/scale.type';

export function getConfigDataByMaybeScaleName(scaleName: ScaleName | null) {
    return {
        scalePositionTypes: (
            ['diagonal', 'compact'] satisfies ScalePositionType[]
        ).map((scalePositionType) => ({
            selected: true,
            type: scalePositionType,
        })),
        scaleNames: getScaleList().map((name) => ({
            selected: scaleName === null ? true : scaleName === name,
            name,
        })),
    };
}
