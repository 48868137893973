import { Book } from 'lucide-react';
import { MDX_COMPONENTS } from '@notacami/ui';
import { useLongText } from '../../services/i18n';
import { ExpandableCard } from '../../components/ui/expandable-card/expandable-card';
import { createSimpleExpandableCardTitle } from '../../components/ui/expandable-card/title';
import { PageUrls, STRING_SEARCH_PARAM } from '../../utils/routes.contants';
import { useShowTabBar } from '../../components/ui/tab-bar.hooks';
import { useTranslation } from '../../hooks/use-translation';
import { useNoteNotation } from '../../services/note-notation/use-note-notation';
import { ThematicPageLayout } from '../../components/business/thematic-page-layout';
import { ThematicPageLinkItem } from '../../components/business/thematic-page-link-item';
import { usePreferencesStore } from '../../components/business/preferences/use-preferences-context';
import { ThematicPageContentTitle } from '../../components/business/thematic-page-content-title';

export function NotesPage() {
    useShowTabBar();

    const { t } = useTranslation();
    const { n } = useNoteNotation();

    const notesLongText = useLongText('notes');
    const tuningInfo = usePreferencesStore((state) => state.tuningInfo);

    return (
        <ThematicPageLayout thematic="notes">
            <ExpandableCard
                isDark
                title={createSimpleExpandableCardTitle(
                    <Book />,
                    notesLongText.frontmatter.title,
                )}
                titleText={notesLongText.frontmatter.title}
            >
                <notesLongText.component components={MDX_COMPONENTS} />
            </ExpandableCard>
            <ThematicPageContentTitle>
                {t('notes-on-guitar-neck')}
            </ThematicPageContentTitle>
            <div className="vertical-content-distribution-sm">
                {tuningInfo.stringList.length > 1 ? (
                    <>
                        {tuningInfo.stringList.map(({ pitchClass }) => (
                            <ThematicPageLinkItem
                                key={pitchClass}
                                to={`${PageUrls.FIND_THE_NOTE_ON_THE_NECK}?${STRING_SEARCH_PARAM}=${pitchClass}`}
                                thematic="notes"
                                type="fretboard-exercise"
                                isExercise
                                specificLabels={[
                                    t('string-and-pitch-class', {
                                        pitchClass: n(pitchClass),
                                    }),
                                ]}
                            >
                                {t('pages.FIND_THE_NOTE_ON_THE_NECK.title')}
                            </ThematicPageLinkItem>
                        ))}
                    </>
                ) : null}
                <ThematicPageLinkItem
                    key="all"
                    to={PageUrls.FIND_THE_NOTE_ON_THE_NECK}
                    thematic="notes"
                    type="fretboard-exercise"
                    isExercise
                    specificLabels={[t('all-strings')]}
                >
                    {t(`pages.FIND_THE_NOTE_ON_THE_NECK.title`)}
                </ThematicPageLinkItem>
            </div>
        </ThematicPageLayout>
    );
}
