import { forwardRef } from 'react';
import { motion } from 'framer-motion';
import { cn } from '@notacami/core/css';
import {
    getNoteItemBaseClassnames,
    NoteStatus,
} from '../../../services/fretboard';
import { getNoteColorClassnamesByStatus } from '../utils';

type NeckCellStatusProps = {
    isOpenString: boolean;
    isVertical: boolean;
    label: string;
    status: NoteStatus;
};

export const NeckCellStatus = forwardRef<HTMLDivElement, NeckCellStatusProps>(
    ({ isOpenString, isVertical, label, status }, ref) => {
        const colors = getNoteColorClassnamesByStatus(status);

        return (
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                ref={ref}
                className={cn(
                    ...getNoteItemBaseClassnames(isVertical, isOpenString),
                    colors.text,
                    colors.border,
                    'border-4',
                    !isOpenString && colors.backgroundTransparent,
                    isOpenString && colors.background,
                )}
            >
                <div className={isOpenString ? 'text-xs' : 'text-base'}>
                    {label}
                </div>
            </motion.div>
        );
    },
);
NeckCellStatus.displayName = 'NeckCellStatus';
