import { RefObject, useRef } from 'react';

const getScrollParent = (node: HTMLElement | null): HTMLElement | null => {
    if (node == null) {
        return null;
    }

    if (node.scrollHeight > node.clientHeight) {
        return node;
    } else {
        return getScrollParent(node.parentElement);
    }
};

export function useLockScrollableParentElement<T>(
    childElementRef: RefObject<T & HTMLElement>,
) {
    const parentRef = useRef<HTMLElement>();
    const parentScrollTopRef = useRef(0);

    const lock = () => {
        if (childElementRef.current && childElementRef.current.parentElement) {
            const foundParent = getScrollParent(childElementRef.current);

            if (!foundParent) return;
            parentRef.current = foundParent;
            parentRef.current.style.overflow = 'hidden';
            parentScrollTopRef.current = parentRef.current.scrollTop;
        }
    };

    const unlock = () => {
        if (parentRef.current) {
            parentRef.current.style.removeProperty('overflow');
        }
    };

    return { lock, unlock };
}
