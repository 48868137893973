type TimeInMinutesSecondsMs = {
    minutes: number;
    seconds: number;
    milliseconds: number;
};

export function convertMsToMMSSmm(timeInMs: number): TimeInMinutesSecondsMs {
    const minutes = Math.floor((timeInMs / 60000) % 60);
    const seconds = Math.floor((timeInMs / 1000) % 60);
    const milliseconds = Math.floor((timeInMs / 10) % 100);
    return {
        minutes,
        seconds,
        milliseconds,
    };
}

function addLeadingZero(num: number): string {
    return num.toString().padStart(2, '0');
}

export function formatMsToMMSSmm(timeInMs: number): string {
    const convertedTime = convertMsToMMSSmm(timeInMs);
    return `${addLeadingZero(convertedTime.minutes)}'${addLeadingZero(
        convertedTime.seconds,
    )}"${addLeadingZero(convertedTime.milliseconds)}`;
}

export function formatMsToMMSS(timeInMs: number): string {
    const convertedTime = convertMsToMMSSmm(timeInMs);
    return `${addLeadingZero(convertedTime.minutes)}'${addLeadingZero(
        convertedTime.seconds,
    )}"`;
}

export function formatMsToMMSSWithoutLeadingZero(timeInMs: number): string {
    const convertedTime = convertMsToMMSSmm(timeInMs);
    return `${convertedTime.minutes}:${addLeadingZero(convertedTime.seconds)}`;
}
