import { AppLanguage } from '../i18n';
import { getIsValidLessonId } from './get-is-valid-lesson-id';
import { LESSONS } from './lesson.constants';
import { LessonId } from './lesson.types';

export function getIsValidLessonLanguage(
    lessonId: LessonId | string | undefined,
    maybeLessonLanguage: string | null,
): maybeLessonLanguage is AppLanguage {
    const isLessonIdInUrlValid = getIsValidLessonId(lessonId);

    return isLessonIdInUrlValid
        ? LESSONS[lessonId].availableLanguages.includes(
              maybeLessonLanguage as AppLanguage,
          )
        : false;
}
