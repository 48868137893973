import { useContext, useEffect, useState } from 'react';
import { useStore } from 'zustand';
import { LessonContext } from '../../../../../../services/lesson';
import { RecordingButton } from '../../../../recording-button/recording-button';
import { MicSettingModalController } from '../../../../mic-setting-modal-controller';
import { ServicesContext } from '../../../../../../services/services.context';
import { Consumers } from '../../../../../../services/pitch-detection-consumer/consumers';

export function StepTeacherListeningRecording() {
    const store = useContext(LessonContext);
    const { pitchDetectionConsumer } = useContext(ServicesContext);
    const [micSettingOpened, setMicSettingOpened] = useState(false);

    const mainThematic = useStore(
        store,
        (state) => state.derivedLessonInfo?.mainThematic,
    );

    const shouldListen = useStore(store, (state) => state.mainStep === 'steps');

    const handleRecordingButtonClick = () => {
        setMicSettingOpened(true);
    };

    const handleMicSettingClose = () => {
        setMicSettingOpened(false);
    };

    useEffect(() => {
        if (shouldListen) {
            pitchDetectionConsumer.addConsumer(
                Consumers.LESSON_TEACHER_LISTENING_STEP,
            );
        } else {
            pitchDetectionConsumer.removeConsumer(
                Consumers.LESSON_TEACHER_LISTENING_STEP,
            );
        }
    }, [shouldListen]);

    useEffect(() => {
        return () => {
            pitchDetectionConsumer.removeConsumer(
                Consumers.LESSON_TEACHER_LISTENING_STEP,
            );
        };
    }, []);

    return mainThematic !== undefined ? (
        <>
            <RecordingButton
                onClick={handleRecordingButtonClick}
                thematic={mainThematic}
            />
            <MicSettingModalController
                thematic={mainThematic}
                open={micSettingOpened}
                onClose={handleMicSettingClose}
            />
        </>
    ) : null;
}
