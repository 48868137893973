import { cn } from '@notacami/core/css';

type InputNumberButtonProps = {
    label: string;
    disabled: boolean;
    onClick: () => void;
};

export function InputNumberButton({
    label,
    onClick,
    disabled,
}: InputNumberButtonProps) {
    return (
        <button
            type="button"
            onClick={onClick}
            className={cn(
                'text-neutral-200 rounded-full w-10 h-10 text-2xl bg-stone-600',
                disabled ? 'opacity-50' : 'opacity-100',
            )}
        >
            {label}
        </button>
    );
}
