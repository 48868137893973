import { MicOff } from 'lucide-react';
import { Button } from '@notacami/ui';
import {
    AndroidSettings,
    IOSSettings,
    NativeSettings,
} from 'capacitor-native-settings';
import { useTranslation } from '../../hooks/use-translation';

type MicDeniedPermissionContentProps = {
    platform: 'ios' | 'android' | 'web';
};

export function MicDeniedPermissionContent({
    platform,
}: MicDeniedPermissionContentProps) {
    const { t } = useTranslation();

    const handleOpenSettingsClick = () => {
        NativeSettings.open({
            optionAndroid: AndroidSettings.ApplicationDetails,
            optionIOS: IOSSettings.App,
        });
    };

    return (
        <div className="vertical-content-distribution-base items-center">
            <MicOff
                strokeWidth={1.6}
                className="w-12 h-12 stroke-neutral-600 dark:stroke-neutral-300"
            />
            <p className="max-w-xs text-center">
                {t('tutorial.mic-tutorial.permission-denied-step.description')}
            </p>

            {platform === 'android' || platform === 'ios' ? (
                <>
                    <p className="max-w-xs text-center">
                        {t(
                            'tutorial.mic-tutorial.permission-denied-step.description-native',
                        )}
                    </p>
                    {platform === 'android' || platform === 'ios' ? (
                        <Button onClick={handleOpenSettingsClick}>
                            {t('button.settings')}
                        </Button>
                    ) : null}
                </>
            ) : null}
        </div>
    );
}
