import { getColorVariantByThematic } from '@notacami/core/design';
import { Button } from '@notacami/ui';
import { useContext } from 'react';
import { useStore } from 'zustand';
import { PageLayout } from '../../../page-layout';
import { useTranslation } from '../../../../../hooks/use-translation';
import { LessonContext } from '../../../../../services/lesson';

export const EndSummaryState = () => {
    const { t } = useTranslation();
    const store = useContext(LessonContext);
    const mainThematic = useStore(
        store,
        (state) => state.derivedLessonInfo?.mainThematic,
    );
    const goToIntroduction = useStore(store, (state) => state.goToIntroduction);

    return mainThematic !== undefined ? (
        <PageLayout
            bottomAction={
                <Button
                    className="w-full"
                    onClick={goToIntroduction}
                    colorVariant={getColorVariantByThematic(mainThematic)}
                    size="lg"
                >
                    {t('button.back')}
                </Button>
            }
            content={<>End summary step</>}
        />
    ) : null;
};
