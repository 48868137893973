import { Thematic } from '@notacami/core/thematic';
import { PageUrls } from './routes.contants';

export function getRoutesByThematic(thematic: Thematic) {
    switch (thematic) {
        case 'notes':
            return PageUrls.NOTES;
        case 'scales':
            return PageUrls.SCALES;
        case 'intervals':
            return PageUrls.INTERVALS;
    }
}
