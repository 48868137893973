import { ScaleInfo } from '../types';
import { ScaleName } from './scale.type';

export const ScalesInfo: ScaleInfo[] = [
    {
        id: 'major',
        degrees: ['1', '2', '3', '4', '5', '6', '7'],
    },
    {
        id: 'natural-minor',
        degrees: ['1', '2', '3b', '4', '5', '6b', '7b'],
    },
    {
        id: 'major-pentatonic',
        degrees: ['1', '2', '3', '5', '6'],
    },
    {
        id: 'minor-pentatonic',
        degrees: ['1', '3b', '4', '5', '7b'],
    },
];

export const AVAILABLE_SCALE_NAMES: ScaleName[] = [
    'harmonic minor',
    'major blues',
    'major pentatonic',
    'major',
    'minor blues',
    'minor pentatonic',
    'minor',
];
