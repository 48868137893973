import { SyntheticEvent, useContext } from 'react';
import { FormFieldContainer } from '@notacami/ui';
import { NoteNotation } from '../../../../services/note-notation/note-notation.type';
import { NOTE_NOTATIONS_LIST } from '../../../../services/note-notation/note-notation.constants';
import { usePreferencesStore } from '../../preferences/use-preferences-context';
import { useTranslation } from '../../../../hooks/use-translation';
import { ServicesContext } from '../../../../services/services.context';

export function NoteNotationSection() {
    const { preferenceNoteNotationStore } = useContext(ServicesContext);
    const { t } = useTranslation();
    const changeNoteNotation = usePreferencesStore(
        (state) => state.setNoteNotation,
    );
    const noteNotation = usePreferencesStore((state) => state.noteNotation);

    const handleChange = (event: SyntheticEvent<HTMLSelectElement>) => {
        const selectedNoteNotation = event.currentTarget.value as NoteNotation;
        changeNoteNotation(selectedNoteNotation);
        preferenceNoteNotationStore.set(selectedNoteNotation);
    };

    return (
        <FormFieldContainer
            id="note-notation-setting"
            label={t('pages.settings.note-notation.title')}
            input={
                <select
                    name="note-notation-setting"
                    id="note-notation-setting"
                    onChange={handleChange}
                    value={noteNotation}
                    className="select w-36"
                >
                    {NOTE_NOTATIONS_LIST.map((option) => (
                        <option value={option} key={option}>
                            {t(`pages.settings.note-notation.option.${option}`)}
                        </option>
                    ))}
                </select>
            }
        />
    );
}
