import { create } from 'zustand';

type ModalId = 'gain-setting' | undefined;

type State = {
    modalOpened: ModalId;
    openModal: (modalId: ModalId) => void;
    closeModal: () => void;
    reset: () => void;
};
export const useSettingsStore = create<State>((set, get) => ({
    modalOpened: undefined,
    reset: () => {
        get().closeModal();
    },
    openModal: (modalId: ModalId) => set({ modalOpened: modalId }),
    closeModal: () => set({ modalOpened: undefined }),
}));
