import { MDXComponents } from 'mdx/types';
import { LogoText } from '../logo-text';
import { H1 } from './h1';
import { H2 } from './h2';
import { H3 } from './h3';
import { P } from './p';
import { Ul } from './ul';
import { Li } from './li';
import { Strong } from './strong';
import { Small } from './small';
import { Em } from './em';
import { A } from './a';

export default {
    h1: H1,
    h2: H2,
    h3: H3,
    p: P,
    ul: Ul,
    li: Li,
    strong: Strong,
    small: Small,
    em: Em,
    a: A,
    LogoText,
} satisfies MDXComponents;
