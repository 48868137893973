import { motion } from 'framer-motion';
import { ChevronDown } from 'lucide-react';
import { Surface } from '@notacami/ui';

type ChevronProps = {
    isOpen: boolean;
};

export function Chevron({ isOpen }: ChevronProps) {
    return (
        <Surface
            surfaceId="app"
            className="w-8 h-8 rounded-full flex justify-center items-center"
        >
            <motion.div
                animate={{
                    rotate: isOpen ? 180 : 0,
                }}
            >
                <ChevronDown size={24} />
            </motion.div>
        </Surface>
    );
}
