import { orderBy, partition } from 'lodash/fp';
import { Surface } from '@notacami/ui';
import { ProgressData } from '../../services/progress/progress.types';
import { FindScalePositionProgressPayload } from '../../services/progress/find-scale-position/types';
import { getScaleList } from '../../services/scales/scale-list';
import { useTranslation } from '../../hooks/use-translation';
import { ScaleName } from '../../services/scales/scale.type';
import { ProgressBar } from './progress-bar';
import { ProgressPraticeTime } from './progress-practice-time';

type FindScalePositionProgressProps = {
    scaleName: ScaleName | null;
    currentProgressData: ProgressData<FindScalePositionProgressPayload>;
    previousProgressData: ProgressData<FindScalePositionProgressPayload>;
};

export function FindScalePositionProgress({
    currentProgressData,
    previousProgressData,
    scaleName,
}: FindScalePositionProgressProps) {
    const { t } = useTranslation();
    const scaleList = scaleName === null ? getScaleList() : [scaleName];
    const scalesProgressData = scaleList.map((scaleName) => {
        const previousFoundEntry = previousProgressData.entries.find(
            (entry) => scaleName === entry.payload,
        );
        const currentFoundEntry = currentProgressData.entries.find(
            (entry) => scaleName === entry.payload,
        );

        const previousNumberOfCorrectAnswer = previousFoundEntry
            ? previousFoundEntry.numberOfCorrectAnswer
            : 0;

        const currentNumberOfCorrectAnswer = currentFoundEntry
            ? currentFoundEntry.numberOfCorrectAnswer
            : 0;

        return {
            scaleName,
            previousNumberOfCorrectAnswer,
            currentNumberOfCorrectAnswer,
        };
    });
    const orderedScalesProgressData = orderBy(
        'currentNumberOfCorrectAnswer',
        'desc',
        scalesProgressData,
    );
    const orderedScalesProgressDataPartition = partition(
        ({
            previousNumberOfCorrectAnswer,
            currentNumberOfCorrectAnswer,
        }: {
            previousNumberOfCorrectAnswer: number;
            currentNumberOfCorrectAnswer: number;
        }) => {
            return (
                previousNumberOfCorrectAnswer !== currentNumberOfCorrectAnswer
            );
        },
        orderedScalesProgressData,
    );

    return (
        <div className="relative flex min-h-full justify-center items-center w-full vertical-content-distribution-lg">
            <Surface
                surfaceId="light"
                className="flex justify-center items-center w-full p-4 rounded-xl"
            >
                <ProgressPraticeTime
                    fromInMs={previousProgressData.practiceTime}
                    toInMs={currentProgressData.practiceTime}
                    className="w-full"
                />
            </Surface>
            <div className="vertical-content-distribution-base w-full">
                {orderedScalesProgressDataPartition[0].map(
                    (
                        {
                            scaleName,
                            previousNumberOfCorrectAnswer,
                            currentNumberOfCorrectAnswer,
                        },
                        index,
                    ) => {
                        return (
                            <ProgressBar
                                key={scaleName}
                                staggerIndex={index}
                                title={t('scale-and-name', { scaleName })}
                                label={t('tab-header.number-of-repetition')}
                                min={0}
                                max={10}
                                from={previousNumberOfCorrectAnswer}
                                to={currentNumberOfCorrectAnswer}
                            />
                        );
                    },
                )}
            </div>
            <div className="vertical-content-distribution-base w-full">
                {orderedScalesProgressDataPartition[1].map(
                    (
                        {
                            scaleName,
                            previousNumberOfCorrectAnswer,
                            currentNumberOfCorrectAnswer,
                        },
                        index,
                    ) => {
                        return (
                            <ProgressBar
                                key={scaleName}
                                staggerIndex={index}
                                title={t('scale-and-name', { scaleName })}
                                label={t('tab-header.number-of-repetition')}
                                min={0}
                                max={10}
                                from={previousNumberOfCorrectAnswer}
                                to={currentNumberOfCorrectAnswer}
                                size="sm"
                            />
                        );
                    },
                )}
            </div>
        </div>
    );
}
