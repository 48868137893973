import { CornerDownLeft } from 'lucide-react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect, useMemo, useReducer } from 'react';
import { Scale } from 'tonal';
import { Title } from '@notacami/ui';
import { PageUrls, SCALE_SEARCH_PARAM } from '../../utils/routes.contants';
import { useTranslation } from '../../hooks/use-translation';
import { useHideTabBar } from '../../components/ui/tab-bar.hooks';
import {
    getScalePositionsAcrossEntireNeckHeightWithRetry,
    keepOnlyDiagonalAndCompactScalePositionWhenPossible,
} from '../../services/fretboard-scale';
import {
    MAX_FRET_DIFF_BY_SEGMENT,
    MAX_FRET_DIFF_BY_STRING,
} from '../../services/fretboard';
import { ScaleName } from '../../services/scales/scale.type';
import { Badge } from '../../components/ui/badge';
import { PageLayout } from '../../components/business/page-layout';
import { ScaleSelectorTonal } from '../../components/business/scale-selector-tonal';
import { VisualizeScalePositionsNeck } from '../../components/business/visualize-scale-positions/visualize-scale-positions-neck';
import { VisualizeScalePositionsPositionSelector } from '../../components/business/visualize-scale-positions/visualize-scale-positions-position-selector';
import { VisualizeScalePositionsPlayNotes } from '../../components/business/visualize-scale-positions/visualize-scale-positions-play-notes';
import { usePreferencesStore } from '../../components/business/preferences/use-preferences-context';
import {
    INITIAL_STATE,
    reducer,
} from '../../components/business/visualize-scale-positions/visualize-scale-positions.reducer';

export function VisualizeScalePositionsPage() {
    useHideTabBar();
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();

    const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

    const fretboard = usePreferencesStore((state) => state.fretboard);

    const scaleName = searchParams.get(SCALE_SEARCH_PARAM) as ScaleName | null;

    const resolvedScaleName = scaleName ?? 'major';

    const scalePositionsByString = useMemo(() => {
        const scaleInfo = Scale.get(resolvedScaleName);

        return fretboard.noteDetails
            .map((_, stringIndex) =>
                getScalePositionsAcrossEntireNeckHeightWithRetry(
                    fretboard,
                    scaleInfo.intervals,
                    [
                        stringIndex,
                        Math.floor(fretboard.noteDetails[0].length / 2),
                    ],
                    MAX_FRET_DIFF_BY_STRING,
                    MAX_FRET_DIFF_BY_SEGMENT,
                    3,
                ),
            )
            .map(keepOnlyDiagonalAndCompactScalePositionWhenPossible);
    }, [resolvedScaleName, fretboard]);

    const defaultStringIndex = scalePositionsByString.findIndex(
        (scalePositions) => scalePositions.length > 0,
    );

    useEffect(() => {
        function dispatchScaleChangeAction() {
            dispatch({
                type: 'CHANGE_SCALE',
                scaleName: resolvedScaleName,
                defaultStringIndex: defaultStringIndex,
            });
        }
        dispatchScaleChangeAction();
    }, [resolvedScaleName, defaultStringIndex]);

    const navigate = useNavigate();

    const handleScaleChange = (id: string) => {
        const url = `${
            PageUrls.VISUALIZE_SCALE_POSITIONS
        }?${SCALE_SEARCH_PARAM}=${encodeURIComponent(id)}`;

        navigate(url);
    };

    const handleStringButtonClick = (value: number) => {
        dispatch({ type: 'CHANGE_STRING', stringIndex: value });
    };

    const handlePositionButtonClick = (value: number) => {
        dispatch({ type: 'CHANGE_POSITION', positionIndex: value });
    };

    const currentPositions =
        state.stringIndex !== -1
            ? scalePositionsByString?.[state.stringIndex]?.[state.positionIndex]
                  .positions
            : [];

    const scaleHasAtLeastOnePosition =
        state.stringIndex !== -1 &&
        scalePositionsByString?.[state.stringIndex]?.length > 0;

    const scaleHasAtLeastTwoPosition =
        state.stringIndex !== -1 &&
        scalePositionsByString?.[state.stringIndex]?.length > 1;
    return (
        <PageLayout
            title={
                <div className="flex flex-col items-start">
                    <Title level={2} tagName="h1">
                        {t('pages.VISUALIZE_SCALE_POSITIONS.title')}
                    </Title>
                    <Badge surfaceId="lighter">
                        {scaleName !== null
                            ? t('scale-and-name', { scaleName })
                            : t('all-scales')}
                    </Badge>
                </div>
            }
            leftAction={
                <Link to={PageUrls.SCALES}>
                    <CornerDownLeft size={30} />
                </Link>
            }
            content={
                <div className="vertical-content-distribution-lg justify-center items-stretch min-h-full">
                    {scaleName === null ? (
                        <ScaleSelectorTonal
                            defaultValue={resolvedScaleName}
                            onChange={handleScaleChange}
                        />
                    ) : null}
                    <VisualizeScalePositionsNeck
                        stringIndex={state.stringIndex}
                        positionIndex={state.positionIndex}
                        scalePositionsByStringIndex={scalePositionsByString}
                        onStringButtonClick={handleStringButtonClick}
                        scaleHasAtLeastOnePosition={scaleHasAtLeastOnePosition}
                    />
                </div>
            }
            bottomAction={
                <div className="flex gap-2 justify-center items-center">
                    {scaleHasAtLeastTwoPosition ? (
                        <VisualizeScalePositionsPositionSelector
                            scalePositionsByStringIndex={scalePositionsByString}
                            stringIndex={state.stringIndex}
                            positionIndex={state.positionIndex}
                            onPositionButtonClick={handlePositionButtonClick}
                        />
                    ) : null}
                    {currentPositions !== undefined ? (
                        <VisualizeScalePositionsPlayNotes
                            positionsWithInterval={currentPositions}
                        />
                    ) : null}
                </div>
            }
        />
    );
}
