import { getNotePositiveDistanceInSemitones } from '../notes';
import { getIntervalListSemitones } from './get-interval-list-semitones';
import { BASIC_INTERVAL_LIST } from './intervals.constants';

export function getIntervalBetweenTwoNotes(
    note1: string,
    note2: string,
    forcedIntervals: string[],
): { interval: string; isInForcedIntervals: boolean } {
    const positiveDistanceInSemitones = getNotePositiveDistanceInSemitones(
        note1,
        note2,
    );
    const intervalListInSemitones = getIntervalListSemitones(forcedIntervals);
    const foundInterval = intervalListInSemitones.find(
        ({ simpliedSemitones }) =>
            positiveDistanceInSemitones === simpliedSemitones,
    )?.interval;

    return {
        interval:
            foundInterval || BASIC_INTERVAL_LIST[positiveDistanceInSemitones],
        isInForcedIntervals: foundInterval !== undefined,
    };
}
