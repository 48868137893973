import { PanInfo, motion } from 'framer-motion';
import { useContext, useState } from 'react';
import { Logo, LogoText } from '@notacami/ui';
import { usePreferencesStore } from '../preferences/use-preferences-context';
import { ServicesContext } from '../../../services/services.context';

export function VersionSection() {
    const { preferenceAdminStore: preferencesAdminStore, app } =
        useContext(ServicesContext);
    const [leftDragComplete, setLeftDragComplete] = useState(false);
    const [rightDragComplete, setRightDragComplete] = useState(false);
    const [isAdminUnlocked, setIsAdminUnlocked] = useState(false);

    const unlockAdmin = usePreferencesStore((state) => state.unlockAdmin);

    const handleDrag = (_: unknown, info: PanInfo) => {
        if (info.offset.x < -100 && !rightDragComplete) {
            setLeftDragComplete(true);
        }
        if (info.offset.x < -100 && rightDragComplete && !isAdminUnlocked) {
            setIsAdminUnlocked(true);
            unlockAdmin();
            preferencesAdminStore.set(true);
        }
        if (info.offset.x > 100 && leftDragComplete) {
            setRightDragComplete(true);
        }
    };

    return (
        <div className="flex justify-center">
            <div className="relative tabular-nums flex flex-col items-center gap-4">
                <Logo size={80} className="rounded-xl overflow-hidden" />
                <div className="flex flex-col items-center gap-2">
                    <LogoText type="monochrome" />
                    <span className="text-xs">
                        {'version '}
                        <span className="text-color-title font-semibold">
                            {app.getVersion()}
                        </span>
                    </span>
                </div>
                <motion.div
                    drag="x"
                    dragConstraints={{ left: -100, right: 100 }}
                    className="absolute -inset-2"
                    onDrag={handleDrag}
                />
            </div>
        </div>
    );
}
