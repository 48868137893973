import { create } from 'zustand';

type PageLayoutState = {
    topAndBottomBarHidden: boolean;
    showTopAndBottomBar: () => void;
    hideTopAndBottomBar: () => void;
};

export const usePageLayoutStore = create<PageLayoutState>((set) => ({
    topAndBottomBarHidden: false,
    showTopAndBottomBar: () => set({ topAndBottomBarHidden: false }),
    hideTopAndBottomBar: () => set({ topAndBottomBarHidden: true }),
}));
