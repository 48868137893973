import { useEffect } from 'react';
import { AnimatePresence } from 'framer-motion';
import { Thematic } from '@notacami/core/thematic';
import { ExplainPermissionStep } from './mic-tutorial/explain-permission-step';
import { GainSettingStep } from './mic-tutorial/gain-setting-step';
import { PermissionDeniedStep } from './mic-tutorial/permission-denied-step';
import { TuningStep } from './mic-tutorial/tuning-step';
import { UnsupportedMicStep } from './mic-tutorial/unsupported-mic-step';
import { useMicTutorialStore } from './mic-tutorial/mic-tutorial.store';
import { QuietPlaceStep } from './mic-tutorial/quiet-place-step';
import { GetReadyStep } from './mic-tutorial/get-ready-step';

type MicTutorialProps = {
    getReadyStepButtonLabel: string;
    getReadyStepDescription: string;
    onTutorialAbort: () => void;
    onTutorialComplete: () => void;
    shouldExplainForPermission: boolean;
    thematic: Thematic;
};

export function MicTutorial({
    getReadyStepButtonLabel,
    getReadyStepDescription,
    onTutorialAbort,
    onTutorialComplete,
    shouldExplainForPermission,
    thematic,
}: MicTutorialProps) {
    const step = useMicTutorialStore((state) => state.step);
    const resetMicTutorialStore = useMicTutorialStore((state) => state.reset);
    const startMicTutorial = useMicTutorialStore((state) => state.start);
    const settleInQuitePlace = useMicTutorialStore(
        (state) => state.settleInQuitePlace,
    );

    useEffect(() => {
        resetMicTutorialStore();
    }, [resetMicTutorialStore]);

    useEffect(() => {
        startMicTutorial(shouldExplainForPermission);
    }, [startMicTutorial, shouldExplainForPermission]);

    return (
        <AnimatePresence mode="popLayout">
            {step === 'quite-env' ? (
                <QuietPlaceStep
                    key="quite-env"
                    onClick={settleInQuitePlace}
                    thematic={thematic}
                />
            ) : null}
            {step === 'explain-permission' ? (
                <ExplainPermissionStep
                    key="explain-permission"
                    thematic={thematic}
                />
            ) : null}
            {step === 'gain-setting' ? (
                <GainSettingStep thematic={thematic} key="gain-setting" />
            ) : null}
            {step === 'unsupported-mic' ? (
                <UnsupportedMicStep
                    abortTutorial={onTutorialAbort}
                    key="unsupported-mic"
                />
            ) : null}
            {step === 'permission-denied' ? (
                <PermissionDeniedStep
                    abortTutorial={onTutorialAbort}
                    key="permission-denied"
                />
            ) : null}
            {step === 'tuning' ? (
                <TuningStep thematic={thematic} key="tuning" />
            ) : null}
            {step === 'get-ready' ? (
                <GetReadyStep
                    thematic={thematic}
                    onTutorialComplete={onTutorialComplete}
                    description={getReadyStepDescription}
                    buttonLabel={getReadyStepButtonLabel}
                    key="get-ready"
                />
            ) : null}
        </AnimatePresence>
    );
}
