import { SyntheticEvent } from 'react';
import { FormFieldContainer } from '@notacami/ui';
import { useTranslation } from '../../../../hooks/use-translation';
import { Persona, PERSONAS } from '../../../../services/persona';
import { usePersonaStore } from '../../persona/use-persona-context';

export function PersonaSection() {
    const { t } = useTranslation();
    const setPersona = usePersonaStore((state) => state.setPersona);
    const persona = usePersonaStore((state) => state.persona);

    const handleChange = (event: SyntheticEvent<HTMLSelectElement>) => {
        const selectedCaptureMode = event.currentTarget.value as Persona;
        setPersona(selectedCaptureMode);
    };

    return (
        <FormFieldContainer
            id="persona-setting"
            label={t('pages.settings.admin.persona.label')}
            input={
                <select
                    name="persona-setting"
                    id="persona-setting"
                    onChange={handleChange}
                    value={persona ?? Persona.NONE}
                    className="select w-36"
                >
                    {PERSONAS.map((option) => (
                        <option value={option} key={option}>
                            {option === 'none' ? t('not-defined') : option}
                        </option>
                    ))}
                </select>
            }
        />
    );
}
