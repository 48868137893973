import {
    getAreStringIndexesAreConsecutive,
    getFretBoundariesByPositions,
    getFretBoundariesOnStringsByPositions,
    IntervalPositionAndDistance,
    SegmentScore,
} from '../fretboard';

function getSumOfPositionsDistanceFromMiddleOfSegment(
    sumAccumulator: number,
    currentPosition: IntervalPositionAndDistance,
) {
    return sumAccumulator + currentPosition.distance;
}

export function getSegmentScore(
    segmentIndex: number,
    segmentsIntervalPositionAndDistance: IntervalPositionAndDistance[],
    maxFretDiffByString: number,
    maxFretDiffBySegment: number,
): SegmentScore {
    const segmentPositions = segmentsIntervalPositionAndDistance.map(
        ({ position }) => position,
    );

    const fretBoundariesOnStrings =
        getFretBoundariesOnStringsByPositions(segmentPositions);

    const boundariesOnSegment = getFretBoundariesByPositions(segmentPositions);

    const sumOfPositionsDistanceFromMiddleOfSegment =
        segmentsIntervalPositionAndDistance.reduce(
            getSumOfPositionsDistanceFromMiddleOfSegment,
            0,
        );

    const isCorrectFretDiffForEveryString = fretBoundariesOnStrings.every(
        (boundariesOnString) => {
            const diff = boundariesOnString.max - boundariesOnString.min;
            return diff < maxFretDiffByString;
        },
    );

    const areStringIndexesAreConsecutive =
        getAreStringIndexesAreConsecutive(segmentPositions);

    const isCorrectSegmentFretDiff =
        boundariesOnSegment.max - boundariesOnSegment.min <
        maxFretDiffBySegment;

    return {
        segmentIndex,
        isCorrectFretDiffForEveryString,
        isCorrectSegmentFretDiff,
        areStringIndexesAreConsecutive,
        sumOfPositionsDistanceFromMiddleOfSegment,
    };
}
