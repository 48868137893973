import {
    ProgressEntryOutcome,
    StoredProgressEntryOutcome,
} from './progress.types';

export abstract class AbstractProgressEntry<Payload, StoredPayload> {
    constructor(
        protected payload: Payload,
        protected numberOfCorrectAnswer: number,
    ) {}

    addCorrectAnswer() {
        this.numberOfCorrectAnswer += 1;
    }

    public abstract toOutcome(): ProgressEntryOutcome<Payload>;

    public abstract toStoreOutcome(): StoredProgressEntryOutcome<StoredPayload>;
}
