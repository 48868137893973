import { Settings } from 'lucide-react';
import { Thematic } from '@notacami/core/thematic';
import { RecordingButton } from '../../../recording-button/recording-button';
import { ActionButton } from '../../../../ui/action-button';

type RightActionSectionPlayProps = {
    isConfigSectionExist: boolean;
    onRecordingButtonClick?: () => void;
    onConfigButtonClick: () => void;
    thematic: Thematic;
};

export function RightActionSectionPlay({
    isConfigSectionExist,
    onRecordingButtonClick,
    onConfigButtonClick,
    thematic,
}: RightActionSectionPlayProps) {
    return (
        <>
            {onRecordingButtonClick ? (
                <RecordingButton
                    onClick={onRecordingButtonClick}
                    thematic={thematic}
                />
            ) : null}
            {isConfigSectionExist ? (
                <ActionButton onClick={onConfigButtonClick}>
                    <Settings />
                </ActionButton>
            ) : null}
        </>
    );
}
