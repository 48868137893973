import { DividerWithTitle } from '@notacami/ui';
import { NavigationButtonContainer } from '../../../ui/navigation-button-container';
import { useTranslation } from '../../../../hooks/use-translation';
import { PageUrls } from '../../../../utils/routes.contants';

export function SoundDebugSection() {
    const { t } = useTranslation();

    return (
        <div className="vertical-content-distribution-base">
            <DividerWithTitle title="Sound debug" tagName="h2" titleLevel={3} />
            <NavigationButtonContainer to={PageUrls.SOUND_CAPTURE_DEBUG}>
                {t('pages.SOUND_CAPTURE_DEBUG.title')}
            </NavigationButtonContainer>
        </div>
    );
}
