import { useContext } from 'react';
import { useStore } from 'zustand';
import { LessonContext } from '../../../../../../services/lesson';
import { DerivedTeacherPlayingStep } from '../../../../../../services/lesson/lesson.types';
import { StepTeacherPlaying } from './step-teacher-playing';

type StepTeacherPlayingWrapperProps = {
    step: DerivedTeacherPlayingStep;
};

export function StepTeacherPlayingWrapper({
    step,
}: StepTeacherPlayingWrapperProps) {
    const store = useContext(LessonContext);

    const derivedLessonInfo = useStore(
        store,
        (state) => state.derivedLessonInfo,
    );
    const fretboardNoteDetails = useStore(
        store,
        (state) => state.fretboardNoteDetails,
    );

    return derivedLessonInfo !== null && fretboardNoteDetails !== null ? (
        <StepTeacherPlaying
            eventsInPart={step.eventsInPart}
            fretboardNoteDetails={fretboardNoteDetails}
            tuningId={derivedLessonInfo.tuningId}
            fretLength={derivedLessonInfo.fretLength}
            key={step.id}
        />
    ) : null;
}
