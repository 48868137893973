import { createContext, useRef } from 'react';
import { createExerciseStore } from '../../../../services/exercise/create-exercise-store';
import {
    ExerciseProviderProps,
    ExerciseStoreApi,
} from '../../../../services/exercise/exercise.types';
import {
    FindScalePositionAnswer,
    FindScalePositionConfig,
    FindScalePositionQuestion,
    FindScalePositionQuestionMeta,
    FindScalePositionResultMeta,
} from '../types';
import { FindScalePositionProgressPayload } from '../../../../services/progress/find-scale-position/types';

export const FindScalePostionExerciseContext = createContext<
    ExerciseStoreApi<
        FindScalePositionConfig,
        FindScalePositionQuestion,
        FindScalePositionAnswer,
        FindScalePositionQuestionMeta,
        FindScalePositionResultMeta,
        FindScalePositionProgressPayload
    >
>(
    {} as ExerciseStoreApi<
        FindScalePositionConfig,
        FindScalePositionQuestion,
        FindScalePositionAnswer,
        FindScalePositionQuestionMeta,
        FindScalePositionResultMeta,
        FindScalePositionProgressPayload
    >,
);

export function FindScalePositionExerciseProvider({
    children,
    ...props
}: ExerciseProviderProps<
    FindScalePositionConfig,
    FindScalePositionQuestion,
    FindScalePositionAnswer,
    FindScalePositionQuestionMeta,
    FindScalePositionResultMeta,
    FindScalePositionProgressPayload
>) {
    const storeRef = useRef(createExerciseStore(props));

    return (
        <FindScalePostionExerciseContext.Provider value={storeRef.current}>
            {children}
        </FindScalePostionExerciseContext.Provider>
    );
}
