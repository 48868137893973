import { ReactNode, forwardRef } from 'react';
import { cn } from '@notacami/core/css';
import { MotionSurface } from '@notacami/ui';
import { useSafeAreaInsets } from '../../hooks/use-safe-area-insets';
import { ConditionalWrapper } from '../../../../../libs/core/src/components/conditional-wrapper';

type QuizLayoutProps = {
    answer: ReactNode;
    leftAction?: ReactNode;
    progress?: ReactNode;
    question?: ReactNode;
    rightAction?: ReactNode;
    bottomRightAction?: ReactNode;
    bottomLeftAction?: ReactNode;
    resizeWhenDrawerOpened?: boolean;
};

export const QuizLayout = forwardRef<HTMLDivElement, QuizLayoutProps>(
    (
        {
            answer,
            bottomLeftAction,
            bottomRightAction,
            leftAction,
            progress,
            question,
            resizeWhenDrawerOpened = false,
            rightAction,
        },
        ref,
    ) => {
        const { bottom, left, right, top } = useSafeAreaInsets();
        const hasBottomContent = bottomLeftAction !== undefined;
        return (
            <ConditionalWrapper
                items={[
                    {
                        condition: !resizeWhenDrawerOpened,
                        wrapper: (children, index) => (
                            <div
                                data-testid="quiz-layout"
                                key={index}
                                ref={ref}
                                className="fixed p-2 z-50"
                                style={{ bottom, left, right, top }}
                            >
                                {children}
                            </div>
                        ),
                    },
                    {
                        condition: resizeWhenDrawerOpened,
                        wrapper: (children, index) => (
                            <div
                                data-testid="quiz-layout"
                                key={index}
                                ref={ref}
                                className="fixed p-2 z-50"
                                vaul-drawer-wrapper=""
                                style={{ bottom, left, right, top }}
                            >
                                {children}
                            </div>
                        ),
                    },
                ]}
            >
                <MotionSurface
                    surfaceId="light"
                    initial={{ y: 5, opacity: 0 }}
                    animate={{
                        y: 0,
                        opacity: 1,
                    }}
                    exit={{
                        y: 5,
                        opacity: 0,
                    }}
                    className="relative flex flex-col h-full rounded-3xl justify-between pt-3 overflow-hidden"
                >
                    <div className="flex flex-col gap-4 px-3">
                        <div className="flex gap-4 justify-between">
                            <div className="min-w-12 flex justify-start">
                                {leftAction}
                            </div>
                            <div className="flex grow justify-center items-center">
                                {progress}
                            </div>
                            <div className="min-w-12 flex justify-end gap-2">
                                {rightAction}
                            </div>
                        </div>
                        <div className="z-10">{question}</div>
                    </div>
                    <div
                        className={cn(
                            'grow overflow-y-scroll relative',
                            hasBottomContent && 'pb-14',
                        )}
                    >
                        {answer}
                    </div>
                    {bottomLeftAction ? (
                        <div className="absolute bottom-3 left-3">
                            {bottomLeftAction}
                        </div>
                    ) : null}
                    {bottomRightAction ? (
                        <div className="absolute bottom-3 right-3">
                            {bottomRightAction}
                        </div>
                    ) : null}
                </MotionSurface>
            </ConditionalWrapper>
        );
    },
);
