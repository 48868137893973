import { useContext, useEffect } from 'react';
import GainInput from '../gain-input';
import VuMeter from '../vu-meter';
import { useMicErrorDialogStore } from '../mic-error-dialog.store';
import { Consumers } from '../../../services/pitch-detection-consumer/consumers';
import { ServicesContext } from '../../../services/services.context';
import FrequenciesGraph from './FrequenciesGraph';
import { ChromaticTuner } from './chromatic-tuner';

export default function SoundCaptureDebug() {
    const { pitchDetectionConsumer } = useContext(ServicesContext);
    const openMicErrorDialog = useMicErrorDialogStore(
        (state) => state.openDialog,
    );

    useEffect(() => {
        async function resumePitchDetection() {
            const miError = await pitchDetectionConsumer.addConsumer(
                Consumers.SOUND_DEBUG_CAPTURE,
            );
            const errorMessage = miError?.error;
            if (errorMessage) {
                openMicErrorDialog(errorMessage);
            }
        }
        resumePitchDetection();

        return () => {
            pitchDetectionConsumer.removeConsumer(
                Consumers.SOUND_DEBUG_CAPTURE,
            );
        };
    }, [openMicErrorDialog]);

    return (
        <div className="vertical-content-distribution-base">
            <div className="flex justify-start gap-4 items-center max-w-3xl">
                Gain
                <GainInput thematic="notes" />
            </div>
            <ChromaticTuner />
            <VuMeter />
            <FrequenciesGraph />
        </div>
    );
}
