import { useContext } from 'react';
import { useStore } from 'zustand';
import { ExerciseState } from '../../../../services/exercise/exercise.types';
import {
    FindTheNoteFromRootAndIntervalAnswer,
    FindTheNoteFromRootAndIntervalConfig,
    FindTheNoteFromRootAndIntervalQuestion,
    FindTheNoteFromRootAndIntervalQuestionMeta,
    FindTheNoteFromRootAndIntervalResultMeta,
} from '../types';
import { FindTheNoteFromRootAndIntervalProgressPayload } from '../../../../services/progress/find-the-note-from-root-and-interval/types';
import { FindTheNoteFromRootAndIntervalExerciseContext } from './context';

export const useFindTheNoteFromRootAndIntervalStore = <T>(
    selector: (
        state: ExerciseState<
            FindTheNoteFromRootAndIntervalConfig,
            FindTheNoteFromRootAndIntervalQuestion,
            FindTheNoteFromRootAndIntervalAnswer,
            FindTheNoteFromRootAndIntervalQuestionMeta,
            FindTheNoteFromRootAndIntervalResultMeta,
            FindTheNoteFromRootAndIntervalProgressPayload
        >,
    ) => T,
) => {
    const store = useContext(FindTheNoteFromRootAndIntervalExerciseContext);
    return useStore(store, selector);
};
