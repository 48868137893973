import { Position } from '@notacami/core/fretboard';
import {
    FretboardNoteDetails,
    PositionWithChromaAndMidi,
} from './fretboard.types';

export function getChromaAndMidiByPositions(
    fretboard: FretboardNoteDetails,
    positions: Position[],
): PositionWithChromaAndMidi[] {
    const positionsWithChroma = positions.map((position) => ({
        position,
        chroma: fretboard[position[0]][position[1]].chroma,
        midi: fretboard[position[0]][position[1]].midi,
    }));
    return positionsWithChroma;
}
