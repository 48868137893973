import { Button, DividerWithTitle, FormFieldContainer } from '@notacami/ui';
import { track } from '../../../../services/analytics';
import { useTranslation } from '../../../../hooks/use-translation';
import { useToast } from '../../../ui/use-toast';

export function TriggerAnalyticsSection() {
    const { t } = useTranslation();
    const { toast } = useToast();

    const handleTriggerEventClick = () => {
        track('Test');
        toast({
            description: t('toast.operation-done'),
        });
    };

    return (
        <div className="vertical-content-distribution-base">
            <DividerWithTitle title="Analytics" tagName="h2" titleLevel={3} />
            <FormFieldContainer
                id="trigger-analytics"
                label={t('pages.settings.admin.trigger-analytics.title')}
                input={
                    <Button type="button" onClick={handleTriggerEventClick}>
                        {t('button.trigger')}
                    </Button>
                }
            />
        </div>
    );
}
