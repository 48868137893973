import { Theme } from '../../../../../utils/theme.types';

export function drawSinewave(
    buffer: Float32Array,
    canvas: {
        context: CanvasRenderingContext2D;
        width: number;
        height: number;
    },
    bufferLength: number,
    currentTheme: Theme,
) {
    const WIDTH = canvas.width * 2;
    const HEIGHT = canvas.height;

    const ctx = canvas.context;

    ctx.fillStyle = 'rgba(0, 0, 0, 0)';
    ctx.fillRect(0, 0, WIDTH, HEIGHT);
    ctx.lineWidth = 2;
    ctx.strokeStyle =
        currentTheme === 'dark' ? 'rgb(255, 255, 255)' : 'rgb(85, 85, 85)';
    ctx.beginPath();

    const sliceWidth = (WIDTH * 1.0) / bufferLength;
    let x = 0;

    for (let i = 0; i < bufferLength; i++) {
        const v = buffer[i] * 50;
        const y = HEIGHT / 2 + v;

        if (i === 0) {
            ctx.moveTo(x, y);
        } else {
            ctx.lineTo(x, y);
        }
        x += sliceWidth;
    }

    ctx.lineTo(canvas.width, canvas.height / 2);
    ctx.stroke();
}
