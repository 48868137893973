import { IStore } from './storage.type';
import { StoreCapacitor } from './storage.capacitor';

export class DebouncedCapacitorStore<T> implements IStore<T> {
    private debounceTimeout: number;
    private store: StoreCapacitor<T>;

    constructor(
        private readonly key: string,
        private readonly defaultValue: T,
        private readonly serialize: (value: T) => string,
        private readonly deserialize: (value: string) => T,
        private readonly debounceDuration: number,
    ) {
        this.store = new StoreCapacitor<T>(
            this.key,
            this.defaultValue,
            this.serialize,
            this.deserialize,
        );
    }

    public async set(value: T): Promise<void> {
        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = window.setTimeout(() => {
            this.persistValue(value);
        }, this.debounceDuration);
    }

    private async persistValue(value: T): Promise<void> {
        await this.store.set(value);
    }

    public async get(): Promise<T> {
        return this.store.get();
    }
}
