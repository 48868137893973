import { forwardRef, useContext, useEffect } from 'react';
import { Button } from '@notacami/ui';
import { Thematic } from '@notacami/core/thematic';
import { getColorVariantByThematic } from '@notacami/core/design';
import { GainInputStepContent } from '../gain-setting-step-content';
import { TutorialStepContent } from '../tutorial-step-content';
import { Consumers } from '../../../services/pitch-detection-consumer/consumers';
import { useTranslation } from '../../../hooks/use-translation';
import { ServicesContext } from '../../../services/services.context';
import { useMicTutorialStore } from './mic-tutorial.store';

type GainSettingStepProps = {
    thematic: Thematic;
};

export const GainSettingStep = forwardRef<HTMLDivElement, GainSettingStepProps>(
    ({ thematic }, ref) => {
        const { pitchDetectionConsumer } = useContext(ServicesContext);
        const { t } = useTranslation();

        const goToTuning = useMicTutorialStore((state) => state.goToTuning);
        const colorVariant = getColorVariantByThematic(thematic);

        useEffect(() => {
            pitchDetectionConsumer.addConsumer(
                Consumers.MIC_TUTORIAL_GAIN_SETTING_STEP,
            );
            return () => {
                pitchDetectionConsumer.removeConsumer(
                    Consumers.MIC_TUTORIAL_GAIN_SETTING_STEP,
                );
            };
        }, []);

        return (
            <TutorialStepContent
                ref={ref}
                content={<GainInputStepContent thematic={thematic} />}
                action={
                    <Button onClick={goToTuning} colorVariant={colorVariant}>
                        {t('button.its-ok')}
                    </Button>
                }
            />
        );
    },
);

GainSettingStep.displayName = 'GainSettingStep';
