import { Thematic } from '@notacami/core/thematic';
import { StepperItem } from './stepper-item';

type StepperProps = {
    steps: string;
    thematic: Thematic;
};

export function Stepper({ steps, thematic }: StepperProps) {
    return (
        <div className="flex gap-3 justify-center items-center">
            {steps.split('').map((step, index) => (
                <StepperItem key={index} step={step} thematic={thematic} />
            ))}
        </div>
    );
}
