import { useStore } from 'zustand';
import { ExerciseStoreApi } from '../exercise.types';

export function usePlayingState<
    Config,
    Question,
    Answer,
    ResultMeta,
    RoundMeta,
    ProgressPayload,
>(
    store: ExerciseStoreApi<
        Config,
        Question,
        Answer,
        ResultMeta,
        RoundMeta,
        ProgressPayload
    >,
) {
    const mainStep = useStore(store, (state) => state.mainStep);
    const tutorialId = useStore(store, (state) => state.tutorialId);
    const turnStep = useStore(store, (state) => state.turnStep);

    const isInPlayingState =
        mainStep === 'quiz' ||
        (mainStep === 'end-confirmation' && tutorialId === undefined) ||
        mainStep === 'config' ||
        mainStep === 'pause';

    const isInPlayingPlayState = turnStep === 'play';
    const isInPlayingResultState = turnStep === 'result';

    const isInPlayingPlayStateStrict = isInPlayingPlayState && isInPlayingState;
    const isInPlayingResultStateStrict =
        isInPlayingResultState && isInPlayingState;

    return {
        isInPlayingState,
        isInPlayingPlayState,
        isInPlayingResultState,
        isInPlayingPlayStateStrict,
        isInPlayingResultStateStrict,
    };
}
