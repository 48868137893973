import { PLAYABLE_INTERVALS_LIST } from '../../../services/intervals/intervals.constants';
import { FindTheNoteFromRootAndIntervalConfig } from './types';

export function getDefaultConfig(
    availableIntervals: string[] | null,
): FindTheNoteFromRootAndIntervalConfig {
    return {
        withAlteredNotes: false,
        intervalList: PLAYABLE_INTERVALS_LIST.map((name) => ({
            selected:
                availableIntervals === null
                    ? true
                    : availableIntervals.includes(name),
            name,
        })),
    };
}
