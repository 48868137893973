import { Device } from '@capacitor/device';
import mixpanel, { Dict, RequestOptions, Callback } from 'mixpanel-browser';

const analyticsEnabled = import.meta.env.VITE_MIXPANEL_TOKEN !== undefined;

if (analyticsEnabled) {
    mixpanel.init(import.meta.env.VITE_MIXPANEL_TOKEN, {
        debug: import.meta.env.DEV,
        track_pageview: false,
        persistence: 'localStorage',
        ignore_dnt: true,
    });

    sendStartupEvent();
}

async function sendStartupEvent() {
    const { identifier } = await Device.getId();
    const { platform } = await Device.getInfo();

    if (platform !== 'web') {
        identify(identifier);

        track('App startup');
    }
}

function identify(unique_id?: string | undefined) {
    if (analyticsEnabled) {
        mixpanel.identify(unique_id);
    }
}

export function track(
    event_name: string,
    properties?: Dict | undefined,
    optionsOrCallback?: RequestOptions | Callback | undefined,
    callback?: Callback | undefined,
) {
    if (analyticsEnabled) {
        mixpanel.track(event_name, properties, optionsOrCallback, callback);
    }
}
