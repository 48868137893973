import { cn } from '@notacami/core/css';
import { FretSymbol } from '../../../services/fretboard';
import { getRangeNumberByLength } from '../../../utils/array.utils';
import { Sizes } from './guitar-neck.constants';

type FretboardSymbolProps = {
    isOpenString: boolean;
    isVertical: boolean;
    symbol: FretSymbol;
};

export function FretboardSymbol({
    isOpenString,
    isVertical,
    symbol,
}: FretboardSymbolProps) {
    return (
        <div
            className={cn(
                'flex justify-center items-center gap-1',
                isOpenString &&
                    !isVertical &&
                    Sizes.OPEN_STRING_CELL_BY_STRING.h.w,
                isOpenString &&
                    isVertical &&
                    Sizes.OPEN_STRING_CELL_BY_STRING.v.h,
                !isOpenString &&
                    !isVertical &&
                    Sizes.NECK_FRET_CELL_BY_STRING.h.w,
                !isOpenString &&
                    isVertical &&
                    Sizes.NECK_FRET_CELL_BY_STRING.v.h,
                isVertical && 'flex-row',
                !isVertical && 'flex-col',
                isVertical && Sizes.SYMBOL_TRACK.v.w,
                !isVertical && Sizes.SYMBOL_TRACK.h.h,
            )}
        >
            {getRangeNumberByLength(symbol).map((index) => (
                <div
                    className="w-3 h-3 bg-neutral-700 dark:bg-neutral-500 rounded-full"
                    key={index}
                />
            ))}
        </div>
    );
}
