import { isPositionEqual } from '../../../../../../services/fretboard';
import { FindScalePositionAnswer } from '../../../types';

export function computeIsCorrectAnswer(
    correctAnswer: FindScalePositionAnswer,
    userAnswer: FindScalePositionAnswer,
): boolean {
    if (correctAnswer === null || userAnswer === null) {
        throw new Error('Impossible to compute result meta');
    }
    const isCorrectWayAndUserWayAreSameLength =
        correctAnswer.way.length === userAnswer.way.length;
    const isCorrectWayBackAndUserWayBackAreSameLength =
        correctAnswer.wayBack.length === userAnswer.wayBack.length;

    const isWayAndWayBackAreSameLength =
        isCorrectWayAndUserWayAreSameLength &&
        isCorrectWayBackAndUserWayBackAreSameLength;

    if (!isWayAndWayBackAreSameLength) {
        return false;
    }

    const isCorrectWayAndUserWayAreEqualPositions = correctAnswer.way.every(
        (position, index) => isPositionEqual(position, userAnswer.way[index]),
    );
    const isCorrectWayBackAndUserWayBackAreEqualPositions =
        correctAnswer.wayBack.every((position, index) =>
            isPositionEqual(position, userAnswer.wayBack[index]),
        );

    const isWayAndWayBackAreEqualPositions =
        isCorrectWayAndUserWayAreEqualPositions &&
        isCorrectWayBackAndUserWayBackAreEqualPositions;

    return isWayAndWayBackAreEqualPositions;
}
