import { getClassnamesByColorVariant } from '@notacami/core/design';
import { NoteStatus } from '../../../services/fretboard';
import { getIntervalNumber } from '../../../services/intervals/get-interval-number';

const texturedString =
    'border-dotted border border-neutral-400 bg-neutral-300 dark:border-neutral-400 dark:bg-neutral-600 shadow-md';

const stringWidthClassNames = [
    `h-[5px] ${texturedString}`,
    `h-[4px] ${texturedString}`,
    `h-[3px] ${texturedString}`,
    `h-[2px] ${texturedString}`,
    `h-[2px] bg-neutral-300 dark:bg-neutral-500`,
    'h-px bg-neutral-300 dark:bg-neutral-500',
];
const stringHeightClassNames = [
    `w-[5px] ${texturedString}`,
    `w-[4px] ${texturedString}`,
    `w-[3px] ${texturedString}`,
    `w-[2px] ${texturedString}`,
    'w-[2px] bg-neutral-300 dark:bg-neutral-500',
    'w-px bg-neutral-300 dark:bg-neutral-500',
];

export function getStringThickness(octave: number, vertical: boolean): string {
    const num = [0, 0, 0, 1, 2, 3, 4, 5, 5, 5, 5][octave];
    return vertical ? stringHeightClassNames[num] : stringWidthClassNames[num];
}

export function getNoteColorClassnamesByInterval(interval: string) {
    const degree = getIntervalNumber(interval);

    switch (degree) {
        case 1:
        case 8:
            return getClassnamesByColorVariant('indigo');
        case 5:
        case 12:
            return getClassnamesByColorVariant('yellow');
        case 3:
        case 10:
        case 7:
        case 14:
            return getClassnamesByColorVariant('emerald');
        case 2:
        case 4:
        case 6:
        case 9:
        case 11:
        case 13:
            return getClassnamesByColorVariant('neutral');
        default:
            return getClassnamesByColorVariant('neutral-invert');
    }
}

export function getNoteColorClassnamesByStatus(status: NoteStatus) {
    switch (status) {
        case 'success':
            return getClassnamesByColorVariant('emerald');
        case 'error':
            return getClassnamesByColorVariant('red');
        case 'info':
            return getClassnamesByColorVariant('indigo');
        default:
            return getClassnamesByColorVariant('neutral');
    }
}
