import { FormFieldContainer } from '@notacami/ui';
import { useTranslation } from '../../../../hooks/use-translation';
import { Badge } from '../../../ui/badge';

export function MonitoringSection() {
    const { t } = useTranslation();
    const viteSentryDsn = import.meta.env.VITE_SENTRY_DSN;

    return (
        <FormFieldContainer
            id="sentry-dsn"
            label="Sentry DSN"
            isColumn
            input={
                <>
                    {viteSentryDsn === undefined ? (
                        <Badge surfaceId="darker">{t('not-defined')}</Badge>
                    ) : (
                        <Badge
                            surfaceId="soft-invert"
                            className="max-w-72 overflow-x-scroll"
                        >
                            {viteSentryDsn}
                        </Badge>
                    )}
                </>
            }
        />
    );
}
