import { AnimatePresence } from 'framer-motion';
import { X } from 'lucide-react';
import { Thematic } from '@notacami/core/thematic';
import { ActionButton } from '../ui/action-button';
import { useTranslation } from '../../hooks/use-translation';
import { QuizLayout } from './quiz-layout';
import { MicTutorial } from './mic-tutorial';
import { MicTutorialStepper } from './mic-tutorial/mic-tutorial-stepper';

type MicSettingProps = {
    open: boolean;
    askForPermission: boolean;
    onClose: () => void;
    thematic: Thematic;
};

export function MicSetting({
    open,
    askForPermission,
    onClose,
    thematic,
}: MicSettingProps) {
    const { t } = useTranslation();

    return (
        <AnimatePresence>
            {open ? (
                <QuizLayout
                    progress={<MicTutorialStepper thematic={thematic} />}
                    answer={
                        <MicTutorial
                            thematic={thematic}
                            onTutorialAbort={onClose}
                            onTutorialComplete={onClose}
                            shouldExplainForPermission={askForPermission}
                            getReadyStepDescription={t(
                                'tutorial.mic-tutorial.get-ready-step.mic-setting-get-ready-step-description',
                            )}
                            getReadyStepButtonLabel={t('button.i-get-it')}
                        />
                    }
                    leftAction={
                        <ActionButton onClick={onClose}>
                            <X />
                        </ActionButton>
                    }
                />
            ) : null}
        </AnimatePresence>
    );
}
