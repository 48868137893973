import { Thematic } from '@notacami/core/thematic';
import { Stepper } from '../stepper';
import { useMicTutorialStore } from './mic-tutorial.store';

type MicTutorialStepperProps = {
    thematic: Thematic;
};

export function MicTutorialStepper({ thematic }: MicTutorialStepperProps) {
    const steps = useMicTutorialStore((state) => state.steps);

    return <Stepper steps={steps} thematic={thematic} />;
}
