type State = {
    scaleName: string | null;
    stringIndex: number;
    positionIndex: number;
};

type Action =
    | { type: 'CHANGE_SCALE'; scaleName: string; defaultStringIndex: number }
    | { type: 'CHANGE_STRING'; stringIndex: number }
    | { type: 'CHANGE_POSITION'; positionIndex: number };

export const INITIAL_STATE: State = {
    scaleName: null,
    positionIndex: -1,
    stringIndex: -1,
};

export function reducer(state: State, action: Action): State {
    switch (action.type) {
        case 'CHANGE_SCALE':
            return {
                scaleName: action.scaleName,
                stringIndex: action.defaultStringIndex,
                positionIndex: 0,
            };
        case 'CHANGE_STRING':
            return {
                ...state,
                stringIndex: action.stringIndex,
                positionIndex: 0,
            };
        case 'CHANGE_POSITION':
            return {
                ...state,
                positionIndex: action.positionIndex,
            };
        default:
            return state;
    }
}
