import { Check } from 'lucide-react';
import { forwardRef } from 'react';
import { motion } from 'framer-motion';
import { cn } from '@notacami/core/css';
import { Position } from '@notacami/core/fretboard';
import { getNoteItemBaseClassnames } from '../../../services/fretboard';

type NeckCellSelectProps = {
    isOpenString: boolean;
    isVertical: boolean;
    onSelect: (position: Position) => void;
    position: Position;
    value: boolean;
    className?: string;
    disabled?: boolean;
    dataTestId?: string;
};

export const NeckCellSelect = forwardRef<
    HTMLButtonElement,
    NeckCellSelectProps
>(
    (
        {
            isOpenString,
            isVertical,
            onSelect,
            position,
            value,
            className,
            disabled = false,
            dataTestId,
        },
        ref,
    ) => {
        const handleClick = () => {
            onSelect(position);
        };

        return (
            <motion.button
                data-testid={dataTestId}
                disabled={disabled}
                initial={{ opacity: 0 }}
                animate={{ opacity: disabled ? 0.5 : 1 }}
                exit={{ opacity: 0 }}
                ref={ref}
                onClick={handleClick}
                className={cn(
                    ...getNoteItemBaseClassnames(isVertical, isOpenString),
                    'cursor-pointer',
                    'border-2',
                    value
                        ? 'bg-indigo-400 border-indigo-500 text-white dark:bg-indigo-500 dark:border-indigo-600 dark:text-white'
                        : 'bg-neutral-300 border-neutral-500/80 text-neutral-500 dark:bg-neutral-600 dark:border-neutral-500 dark:text-neutral-300',
                    className,
                )}
            >
                <Check className="h-6 w-6 drop-shadow" />
            </motion.button>
        );
    },
);
NeckCellSelect.displayName = 'NeckCellSelect';
