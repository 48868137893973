import { CornerDownLeft } from 'lucide-react';
import { Link } from 'react-router-dom';
import { useTranslation } from '../../hooks/use-translation';
import { PageUrls } from '../../utils/routes.contants';
import { PageLayout } from '../../components/business/page-layout';
import { TitleWithTextAbove } from '../../components/business/title-with-text-above';
import { useSettingsStore } from '../../components/business/settings/settings.store';
import { MicSettingModalController } from '../../components/business/mic-setting-modal-controller';
import { SettingsNoteDetection } from '../../components/business/settings/note-detection/settings-note-detection';

export function SettingsNoteDetectionPage() {
    const { t } = useTranslation();

    const modalOpened = useSettingsStore((state) => state.modalOpened);
    const closeModal = useSettingsStore((state) => state.closeModal);

    const handleMicSettingClose = () => {
        closeModal();
    };

    return (
        <>
            <PageLayout
                title={
                    <TitleWithTextAbove
                        textAbove={t('pages.settings.title')}
                        titleProps={{
                            level: 2,
                            children: t('pages.settings.note-detection.title'),
                            tagName: 'h1',
                        }}
                    />
                }
                leftAction={
                    <Link to={PageUrls.SETTINGS}>
                        <CornerDownLeft size={30} />
                    </Link>
                }
                content={<SettingsNoteDetection />}
            />
            <MicSettingModalController
                thematic="notes"
                open={modalOpened === 'gain-setting'}
                onClose={handleMicSettingClose}
            />
        </>
    );
}
