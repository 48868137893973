import { useContext } from 'react';
import { useStore } from 'zustand';
import { ExerciseState } from '../../../../services/exercise/exercise.types';
import {
    FindScalePositionAnswer,
    FindScalePositionConfig,
    FindScalePositionQuestion,
    FindScalePositionQuestionMeta,
    FindScalePositionResultMeta,
} from '../types';
import { FindScalePositionProgressPayload } from '../../../../services/progress/find-scale-position/types';
import { FindScalePostionExerciseContext } from './context';

export const useFindScalePositionStore = <T>(
    selector: (
        state: ExerciseState<
            FindScalePositionConfig,
            FindScalePositionQuestion,
            FindScalePositionAnswer,
            FindScalePositionQuestionMeta,
            FindScalePositionResultMeta,
            FindScalePositionProgressPayload
        >,
    ) => T,
) => {
    const store = useContext(FindScalePostionExerciseContext);
    return useStore(store, selector);
};
