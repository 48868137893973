import { Position } from '@notacami/core/fretboard';
import { BuildCellsComponentProps } from '../../../guitar-neck/guitar-neck.types';
import { NeckCellStatus } from '../../../neck-cells/neck-cell-status';
import { NeckNote } from '../../../neck-cells/neck-note';
import { Fretboard, isPositionEqual } from '../../../../../services/fretboard';

type AnswerSectionResultCellProps = BuildCellsComponentProps & {
    fretboard: Fretboard;
    correctAnswerPosition: Position;
};

export function AnswerSectionResultCell({
    isOpenString,
    getElementByPosition,
    isVertical,
    position,
    fretboard,
    correctAnswerPosition,
}: AnswerSectionResultCellProps) {
    const fretboardNote = getElementByPosition(fretboard.noteDetails);

    const isCorrectPosition = isPositionEqual(position, correctAnswerPosition);

    return (
        <>
            {isOpenString ? (
                <NeckNote
                    isOpenString={isOpenString}
                    isVertical={isVertical}
                    notePitchClass={fretboardNote.pitchClass}
                    position={position}
                />
            ) : null}
            {isCorrectPosition && !isOpenString ? (
                <NeckCellStatus
                    isOpenString={isOpenString}
                    isVertical={isVertical}
                    label={fretboardNote.pitchClass}
                    status="success"
                />
            ) : null}
        </>
    );
}
