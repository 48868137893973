import { getColorVariantByThematic } from '@notacami/core/design';
import { useTranslation } from '../../../hooks/use-translation';
import { ScalePosition } from '../../../services/fretboard';
import { ActionButton } from '../../ui/action-button';

type VisualizeScalePositionsPositionSelectorProps = {
    scalePositionsByStringIndex: ScalePosition[][];
    positionIndex: number;
    stringIndex: number;
    onPositionButtonClick: (value: number) => void;
};

export function VisualizeScalePositionsPositionSelector({
    scalePositionsByStringIndex,
    stringIndex,
    positionIndex,
    onPositionButtonClick,
}: VisualizeScalePositionsPositionSelectorProps) {
    const { t } = useTranslation();
    return (
        <div className="flex gap-4 justify-between">
            <div className="flex gap-4 items-center pl-2">
                <span className="text-sm">
                    {t(
                        'components.visualize-scale-positions-position-selector.positions',
                    )}
                </span>
                <div className="flex gap-2">
                    {scalePositionsByStringIndex[stringIndex].map(
                        ({ type }, index) => {
                            const id = `${type}-${index}`;

                            return (
                                <div
                                    key={id}
                                    className="relative flex flex-col items-center "
                                >
                                    <ActionButton
                                        onClick={() =>
                                            onPositionButtonClick(index)
                                        }
                                        colorVariant={
                                            positionIndex === index
                                                ? getColorVariantByThematic(
                                                      'scales',
                                                  )
                                                : 'neutral-invert'
                                        }
                                    >
                                        {index + 1}
                                    </ActionButton>
                                    {type !== 'other' &&
                                    positionIndex === index ? (
                                        <div className="absolute -bottom-2 rounded-full text-dark text-xs px-1 bg-neutral-100">
                                            {t(`scale-position-type.${type}`)}
                                        </div>
                                    ) : null}
                                </div>
                            );
                        },
                    )}
                </div>
            </div>
        </div>
    );
}
