import { isPositionEqual, ScalePosition } from '../../../services/fretboard';
import { BuildCellsComponentProps } from '../guitar-neck/guitar-neck.types';
import { PlayingNotesState } from '../lesson/main-steps/steps/step-teacher-playing/playing-notes.reducer';
import { NeckCellInterval } from '../neck-cells/neck-cell-interval';

type VisualizeScalePositionsNeckCellProps = BuildCellsComponentProps & {
    scalePositionsByStringIndex: ScalePosition[][];
    stringIndex: number;
    positionIndex: number;
    playingNotes: PlayingNotesState;
    scaleHasAtLeastOnePosition: boolean;
};

export function VisualizeScalePositionsNeckCell({
    position,
    isOpenString,
    isVertical,
    scalePositionsByStringIndex,
    stringIndex,
    positionIndex,
    playingNotes,
    scaleHasAtLeastOnePosition,
}: VisualizeScalePositionsNeckCellProps) {
    if (!scaleHasAtLeastOnePosition) {
        return;
    }

    const currentScalePosition =
        scalePositionsByStringIndex[stringIndex][positionIndex];

    const intervalPosition = currentScalePosition.positions.find(
        (positionWithInterval) =>
            isPositionEqual(positionWithInterval.position, position),
    );

    if (intervalPosition === undefined) {
        return null;
    }

    const isPlayed = playingNotes.some((playingNote) =>
        isPositionEqual(playingNote.position, position),
    );

    return (
        <NeckCellInterval
            isPlayed={isPlayed}
            isOpenString={isOpenString}
            isVertical={isVertical}
            interval={intervalPosition.interval ?? ''}
            position={position}
        />
    );
}
