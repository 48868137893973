import { Interval } from 'tonal';
import { IntervalInterpolationInfo } from './intervals.types';

export function getIntervalInterpolationInfo(
    interval: string,
): IntervalInterpolationInfo {
    const intervalNumber = Interval.num(interval);
    const intervalQuality = Interval.quality(interval);

    const interpolationInfo = {
        number: intervalNumber.toString(),
        quality: intervalQuality,
    };

    return interpolationInfo;
}
