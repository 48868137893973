import { SafeArea } from 'capacitor-plugin-safe-area';
import { createContext, useEffect, useState } from 'react';
import { SafeAreaInsets } from './safe-area.types';

export const SafeAreaContext = createContext<SafeAreaInsets>(
    {} as SafeAreaInsets,
);

type SafeAreaProviderProps = {
    children: React.ReactNode;
    forceInsets?: SafeAreaInsets;
};

export function SafeAreaProvider({
    children,
    forceInsets,
}: SafeAreaProviderProps) {
    const [insets, setInsets] = useState<SafeAreaInsets>();

    useEffect(() => {
        const getSafeAreaInsets = async () => {
            if (forceInsets) {
                setInsets(forceInsets);
                return;
            }
            const { insets } = await SafeArea.getSafeAreaInsets();
            setInsets(insets);
        };

        const getSafeAreaEventListener = async () => {
            const eventListener = await SafeArea.addListener(
                'safeAreaChanged',
                (data) => {
                    if (forceInsets) {
                        setInsets(forceInsets);
                        return;
                    }
                    const { insets } = data;
                    setInsets(insets);
                },
            );
            return eventListener;
        };

        getSafeAreaInsets();
        const eventListenerInPromise = getSafeAreaEventListener();

        return () => {
            eventListenerInPromise.then((eventListener) => {
                eventListener.remove();
            });
        };
    }, [forceInsets]);

    return insets !== undefined ? (
        <SafeAreaContext.Provider value={insets}>
            {children}
        </SafeAreaContext.Provider>
    ) : null;
}
