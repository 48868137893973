import { FormFieldContainer } from '@notacami/ui';
import { useTranslation } from '../../../../hooks/use-translation';
import { Badge } from '../../../ui/badge';

export function AnalyticsSection() {
    const { t } = useTranslation();
    const viteMixpanelToken = import.meta.env.VITE_MIXPANEL_TOKEN;

    return (
        <FormFieldContainer
            id="mixpanel-token"
            label="Mixpanel Token"
            isColumn
            input={
                <>
                    {viteMixpanelToken === undefined ? (
                        <Badge surfaceId="darker">{t('not-defined')}</Badge>
                    ) : (
                        <Badge
                            surfaceId="soft-invert"
                            className="max-w-72 overflow-x-scroll"
                        >
                            {viteMixpanelToken}
                        </Badge>
                    )}
                </>
            }
        />
    );
}
