import { SyntheticEvent } from 'react';
import { cn } from '@notacami/core/css';
import { InputNumberButton } from './input-number-button';

type InputNumberProps = {
    onMinusClick: () => void;
    onPlusClick: () => void;
    value: number;
    min: number;
    max: number;
    id: string;
    onValueChange: (value: number) => void;
    onBlur?: () => void;
    className?: string;
};

export function InputNumber({
    className,
    onMinusClick,
    onPlusClick,
    value,
    min,
    max,
    id,
    onValueChange,
    onBlur,
}: InputNumberProps) {
    const handleChange = (event: SyntheticEvent<HTMLInputElement>) => {
        onValueChange(parseInt(event.currentTarget.value));
    };

    return (
        <div className="flex gap-2">
            <InputNumberButton
                label="-"
                onClick={onMinusClick}
                disabled={value <= min}
            />
            <input
                id={id}
                name={id}
                type="number"
                value={value}
                onChange={handleChange}
                inputMode="numeric"
                min={min}
                max={max}
                onBlur={onBlur}
                pattern="\d*"
                className={cn(
                    className,
                    'surface-app pointer-events-none tabular-nums text-center h-10 p-1 regular-border rounded-lg',
                )}
            />
            <InputNumberButton
                label="+"
                onClick={onPlusClick}
                disabled={value >= max}
            />
        </div>
    );
}
