import { ReactNode } from 'react';
import { cn } from '@notacami/core/css';
import { Title } from '@notacami/ui';
import { Thematic } from '@notacami/core/thematic';
import { getForegroundColorByThematic } from '@notacami/core/design';
import { LessonType } from '@notacami/core/lesson';
import { useTranslation } from '../../../../../hooks/use-translation';
import { Badge } from '../../../../ui/badge';

type ThematicPageProps = {
    children: ReactNode;
    thematic: Thematic;
    lessonType: LessonType;
    specificLabels?: string[];
};

export function ExerciseConfigurationTitle({
    children,
    thematic,
    lessonType,
    specificLabels,
}: ThematicPageProps) {
    const { t } = useTranslation();
    return (
        <div className="vertical-content-distribution-base justify-center items-center">
            <span
                className={cn(
                    getForegroundColorByThematic(thematic),
                    'text-xs font-semibold',
                )}
            >
                {t(`lessonType.${lessonType}`)}
            </span>
            <Title level={2} className="text-center" tagName="h2">
                {children}
            </Title>
            <div className="flex gap-2 flex-wrap">
                {specificLabels !== undefined
                    ? specificLabels.map((label) => (
                          <Badge
                              surfaceId="lighter"
                              className="whitespace-nowrap"
                              key={label}
                          >
                              {label}
                          </Badge>
                      ))
                    : null}
            </div>
        </div>
    );
}
