import { NeckFrets } from './neck-frets';
import { NeckSizeLayout } from './neck-size-layout';

type NeckBackgroundProps = {
    fretboardLength: number;
    isVertical: boolean;
    numOfStrings: number;
    leftHanded: boolean;
};

export function NeckBackground({
    fretboardLength,
    isVertical,
    numOfStrings,
    leftHanded,
}: NeckBackgroundProps) {
    return (
        <div className="relative">
            <NeckSizeLayout
                fretLength={fretboardLength}
                numOfStrings={numOfStrings}
                isVertical={isVertical}
            />
            <NeckFrets
                leftHanded={leftHanded}
                fretLength={fretboardLength}
                isVertical={isVertical}
                isTransparentBorder={false}
            />
        </div>
    );
}
