import { forwardRef } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { HelpCircle, X } from 'lucide-react';
import { MotionSurface, Surface } from '@notacami/ui';
import { Thematic } from '@notacami/core/thematic';
import { getColorVariantByThematic } from '@notacami/core/design';
import { ActionButton } from '../../../../ui/action-button';
import { useTranslation } from '../../../../../hooks/use-translation';

type HelpButtonProps = {
    onClick: () => void;
    isOpen: boolean;
    hasAnswerHelpText: boolean;
    thematic: Thematic;
};

export const HelpButton = forwardRef<HTMLDivElement, HelpButtonProps>(
    ({ hasAnswerHelpText, isOpen, onClick, thematic }, ref) => {
        const { t } = useTranslation();

        return (
            <motion.div
                className="relative"
                ref={ref}
                initial={{ opacity: 0, y: 5 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 5 }}
            >
                <div className="absolute left-8 -bottom-2 -top-2 flex overflow-hidden">
                    <MotionSurface
                        initial={{ x: '-100%' }}
                        animate={{ x: hasAnswerHelpText ? 0 : '-100%' }}
                        transition={{ delay: hasAnswerHelpText ? 0 : 0.5 }}
                        onClick={hasAnswerHelpText ? onClick : undefined}
                        surfaceId={hasAnswerHelpText ? 'ultra-light' : 'light'}
                        className="my-3 mr-2 pl-6 pr-4 text-xs font-semibold rounded-r-full flex justify-center items-center leading-none"
                    >
                        <AnimatePresence>
                            {!isOpen && hasAnswerHelpText ? (
                                <motion.div
                                    className="absolute -right-1 -top-1"
                                    initial={{ opacity: 0, y: 2 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    exit={{ opacity: 0, y: 2 }}
                                >
                                    {hasAnswerHelpText ? (
                                        <Surface
                                            colorVariant={getColorVariantByThematic(
                                                thematic,
                                            )}
                                            className="w-5 h-5 flex justify-center items-center rounded-full shadow-sm font-semibold animate-bounce"
                                        >
                                            !
                                        </Surface>
                                    ) : null}
                                </motion.div>
                            ) : null}
                        </AnimatePresence>
                        {t('exercise.help')}
                    </MotionSurface>
                </div>
                <ActionButton
                    onClick={hasAnswerHelpText ? onClick : undefined}
                    colorVariant={
                        hasAnswerHelpText ? 'neutral-invert' : 'neutral'
                    }
                    className="relative overflow-hidden"
                    disabled={!hasAnswerHelpText}
                >
                    <AnimatePresence mode="popLayout">
                        {isOpen ? (
                            <motion.div
                                key="close"
                                initial={{ opacity: 0, y: 32 }}
                                animate={{ opacity: 1, y: 0 }}
                                exit={{ opacity: 0, y: -32 }}
                            >
                                <X />
                            </motion.div>
                        ) : (
                            <motion.div
                                key="help"
                                initial={{ opacity: 0, y: 32 }}
                                animate={{ opacity: 1, y: 0 }}
                                exit={{ opacity: 0, y: -32 }}
                            >
                                <HelpCircle />
                            </motion.div>
                        )}
                    </AnimatePresence>
                </ActionButton>
            </motion.div>
        );
    },
);
HelpButton.displayName = 'AnswerTip';
