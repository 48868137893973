import { useContext } from 'react';
import { FindTheNoteFromRootAndIntervalExerciseContext } from '../../store/context';
import { useFindTheNoteFromRootAndIntervalStore } from '../../store/hook';
import { useQuestion } from '../../../../../services/exercise/hooks/use-question';
import { useResult } from '../../../../../services/exercise/hooks/use-result';
import { Answer } from './answer';

export function AnswerSection() {
    const store = useContext(FindTheNoteFromRootAndIntervalExerciseContext);

    const submitAnswer = useFindTheNoteFromRootAndIntervalStore(
        (state) => state.submitAnswer,
    );
    const addError = useFindTheNoteFromRootAndIntervalStore(
        (state) => state.addError,
    );

    const question = useQuestion(store);
    const result = useResult(store);

    const handleNoteSelect = (note: string) => {
        if (note === question.correctAnswer) {
            submitAnswer(note);
        } else {
            addError(note);
        }
    };

    return (
        <div className="py-5 flex flex-col justify-center items-center min-h-full">
            <Answer
                onNoteSelect={handleNoteSelect}
                listNotes={question.meta.listNotes}
                selectedAnswer={result !== null ? result.userAnswer : undefined}
                correctAnswer={question.correctAnswer}
            />
        </div>
    );
}
