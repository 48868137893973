import { ChangeEventHandler } from 'react';
import { motion } from 'framer-motion';
import { Surface } from '@notacami/ui';
import { Guitar, Pointer } from 'lucide-react';
import { cn } from '@notacami/core/css';
import { QuizMode } from '../../services/exercise/exercise.types';

type QuizModeSelectorProps = {
    appLabel: string;
    defaultValue?: QuizMode;
    guitarLabel: string;
    onChange: (value: QuizMode) => void;
    value: QuizMode;
};

export function QuizModeSelector({
    appLabel,
    defaultValue,
    guitarLabel,
    onChange,
    value,
}: QuizModeSelectorProps) {
    const handleInputRadioChange: ChangeEventHandler<HTMLInputElement> = (
        event,
    ) => {
        onChange(event.target.value as QuizMode);
    };

    const buttonActiveClassName = 'text-neutral-100';
    return (
        <Surface
            surfaceId="app"
            className="relative rounded-xl regular-border text-sm"
        >
            <motion.div
                initial={{ x: defaultValue === 'app' ? '100%' : '0%' }}
                animate={{ x: value === 'app' ? '100%' : '0%' }}
                transition={{ type: 'spring', bounce: 0.25 }}
                className="absolute w-28 h-full p-1"
            >
                <div className="bg-stone-600 w-full h-full rounded-xl"></div>
            </motion.div>
            <div className="relative w-56 flex p-1 gap-2">
                <div
                    className={cn(
                        'flex justify-center items-center w-28 transition-colors',
                        value === 'guitar' && buttonActiveClassName,
                    )}
                >
                    <input
                        type="radio"
                        id="playWithGuitar"
                        name="quizMode"
                        value="guitar"
                        checked={value === 'guitar'}
                        onChange={handleInputRadioChange}
                        hidden
                    />
                    <label
                        className="flex flex-col gap-1 items-center text-right w-full p-2 cursor-pointer"
                        htmlFor="playWithGuitar"
                    >
                        <Guitar size={18} />
                        <span>{guitarLabel}</span>
                    </label>
                </div>

                <div
                    className={cn(
                        'flex justify-center items-center w-28 transition-colors',
                        value === 'app' && buttonActiveClassName,
                    )}
                >
                    <input
                        type="radio"
                        id="playWithApp"
                        name="quizMode"
                        value="app"
                        checked={value === 'app'}
                        onChange={handleInputRadioChange}
                        hidden
                    />
                    <label
                        className="flex flex-col gap-1 items-center text-left w-full p-2 cursor-pointer"
                        htmlFor="playWithApp"
                    >
                        <Pointer size={18} />
                        <span>{appLabel}</span>
                    </label>
                </div>
            </div>
        </Surface>
    );
}
