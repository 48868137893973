import { Position } from '@notacami/core/fretboard';
import { Segment } from '../fretboard';

export function getTonicPositionWithIntervalInSegment(
    segment: Segment,
): Position {
    const foundPositionWithInterval = segment.find(
        ({ interval }) => interval === '1P',
    );

    if (!foundPositionWithInterval) {
        throw new Error('No tonic position for this segment');
    }

    return foundPositionWithInterval.position;
}
