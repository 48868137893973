import { uniqWith } from 'lodash/fp';
import { Position } from '@notacami/core/fretboard';
import {
    Fretboard,
    isPositionEqual,
    isPositionWithInterval,
    Segment,
} from '../fretboard';
import { getScalePositionSegmentsByPosition } from './get-scale-position-segments-by-position';

export function getScalePositionSegmentsAcrossEntireNeckHeight(
    fretboard: Fretboard,
    scaleIntervals: string[],
    tonicPosition: Position,
    maxFretDiffByString: number,
    maxFretDiffBySegment: number,
): Segment[] {
    const scalePositions = getSegments(
        fretboard,
        scaleIntervals,
        [tonicPosition],
        [],
        maxFretDiffByString,
        maxFretDiffBySegment,
    );

    return scalePositions;
}

function getSegments(
    fretboard: Fretboard,
    scaleIntervals: string[],
    positions: Position[],
    previousSegments: Segment[],
    maxFretDiffByString: number,
    maxFretDiffBySegment: number,
): Segment[] {
    const scalePositions = positions.flatMap((position) =>
        getScalePositionSegmentsByPosition(
            fretboard,
            scaleIntervals,
            position,
            maxFretDiffByString,
            maxFretDiffBySegment,
        ),
    );

    const nextOctavePositions = scalePositions
        .map((scalePosition) => {
            const maybeOctavePosition = scalePosition.find(
                ({ interval }) => interval === '8P',
            );
            return maybeOctavePosition;
        })
        .filter(isPositionWithInterval)
        .map(({ position }) => position);

    const nextUniqOctavePosition = uniqWith(
        isPositionEqual,
        nextOctavePositions,
    );

    if (nextUniqOctavePosition.length === 0) {
        return previousSegments;
    }

    return getSegments(
        fretboard,
        scaleIntervals,
        nextUniqOctavePosition,
        [...previousSegments, ...scalePositions],
        maxFretDiffByString,
        maxFretDiffBySegment,
    );
}
