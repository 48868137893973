import { useState } from 'react';
import { cn } from '@notacami/core/css';
import { Position } from '@notacami/core/fretboard';
import { useGuitarNeckVertical } from '../../../hooks/use-guitar-neck-vertical';
import {
    DEFAULT_FRET_LENGTH,
    getFretboardReachablePositionsByRootPosition,
    isPositionEqual,
    MAX_FRET_DIFF_BY_STRING,
    useLeftHanded,
} from '../../../services/fretboard';
import { GuitarNeck } from '../guitar-neck/guitar-neck';
import { usePreferencesStore } from '../preferences/use-preferences-context';
import { StringSelectorControlledOne } from '../string-selector-controlled-one';
import { BASIC_INTERVAL_LIST } from '../../../services/intervals/intervals.constants';
import { VisualizeIntervalPositionsNeckCell } from './visualize-interval-positions-neck-cell';

type VisualizeIntervalPositionsNeckProps = {
    availableIntervals: string[] | null;
};

export function VisualizeIntervalPositionsNeck({
    availableIntervals,
}: VisualizeIntervalPositionsNeckProps) {
    const isVertical = useGuitarNeckVertical();
    const leftHanded = useLeftHanded();

    const [stringIndex, setStringIndex] = useState(0);

    const fretboard = usePreferencesStore((state) => state.fretboard);

    const tuning = usePreferencesStore((state) => state.tuningInfo);

    const fretIndex = Math.round(fretboard.noteDetails[0].length / 2);

    const rootPosition: Position = [stringIndex, fretIndex];

    const intervalsForRootPosition =
        fretboard.intervals[stringIndex][fretIndex];

    const resolvedIntervals = availableIntervals ?? BASIC_INTERVAL_LIST;

    const foundPositionForSearchedIntervals = intervalsForRootPosition.filter(
        (intervalByPosition) =>
            resolvedIntervals.includes(intervalByPosition.simplifiedInterval) ||
            (intervalByPosition.interval === '1P' &&
                isPositionEqual(intervalByPosition.position, rootPosition)),
    );

    const reachablePositionsFromRootPosition =
        getFretboardReachablePositionsByRootPosition(
            fretboard.noteDetails,
            rootPosition,
            MAX_FRET_DIFF_BY_STRING,
        );

    return (
        <div
            className={cn(
                'flex flex-col justify-center gap-4',
                isVertical && 'mt-5',
            )}
        >
            <GuitarNeck
                showStringNumbers={false}
                buildCustomNeckHeadFactory={({ isVertical, leftHanded }) => (
                    <StringSelectorControlledOne
                        isVertical={isVertical}
                        stringIndex={stringIndex}
                        tuning={tuning.notes}
                        leftHanded={leftHanded}
                        onChange={setStringIndex}
                    />
                )}
                showFretSymbols={false}
                leftHanded={leftHanded}
                isVertical={isVertical}
                numberOfFrets={DEFAULT_FRET_LENGTH}
                tuning={tuning.notes}
                buildCellsComponent={VisualizeIntervalPositionsNeckCell}
                additionalCellProps={{
                    intervals: foundPositionForSearchedIntervals,
                    reachablePositions: reachablePositionsFromRootPosition,
                }}
            />
        </div>
    );
}
