import {
    PositionWithInterval,
    PositionWithMaybeInterval,
} from './fretboard.types';

export function isPositionWithInterval(
    positionWithMaybeInterval: PositionWithMaybeInterval | undefined,
): positionWithMaybeInterval is PositionWithInterval {
    return (
        positionWithMaybeInterval !== undefined &&
        positionWithMaybeInterval.interval !== null
    );
}
