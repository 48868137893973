import { Control, useWatch } from 'react-hook-form';
import { Thematic } from '@notacami/core/thematic';
import { FindTheIntervalOnTheNeckConfig } from '../../types';
import { SubmitButton } from '../../../submit-button';
import { useTranslation } from '../../../../../hooks/use-translation';

type SubmitButtonProps = {
    thematic: Thematic;
    control: Control<FindTheIntervalOnTheNeckConfig>;
};

export function ConfigurationModalSubmitButton({
    control,
    thematic,
}: SubmitButtonProps) {
    const { t } = useTranslation();

    const selectableStrings = useWatch({ control, name: 'selectableStrings' });
    const intervalList = useWatch({ control, name: 'intervalList' });

    const hasAtLeastOneStringSelected = selectableStrings.some(
        (string) => string.selected,
    );

    const hasAtLeastOneIntervalSelected = intervalList.some(
        (string) => string.selected,
    );

    const startGameNotAllowed =
        !hasAtLeastOneStringSelected || !hasAtLeastOneIntervalSelected;

    return (
        <SubmitButton thematic={thematic} disabled={startGameNotAllowed}>
            {t('button.apply')}
        </SubmitButton>
    );
}
