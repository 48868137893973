import { useContext } from 'react';
import { useProgressData } from '../../../../../services/exercise/hooks/use-progress-data';
import { FindScalePositionProgress } from '../../../find-scale-position-progress';
import { FindScalePostionExerciseContext } from '../../store/context';
import { ScaleName } from '../../../../../services/scales/scale.type';

type ResultsProps = {
    scaleName: ScaleName | null;
};

export function Results({ scaleName }: ResultsProps) {
    const store = useContext(FindScalePostionExerciseContext);
    const progressData = useProgressData(store);

    return progressData !== null ? (
        <FindScalePositionProgress
            previousProgressData={progressData.previousProgressData}
            currentProgressData={progressData.currentProgressData}
            scaleName={scaleName}
        />
    ) : null;
}
