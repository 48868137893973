import { Loader2 } from 'lucide-react';

type SpinLoaderProps = {
    className?: string;
};

export function SpinLoader({ className }: SpinLoaderProps) {
    return (
        <span className="animate-spin">
            <Loader2 className={className} />
        </span>
    );
}
