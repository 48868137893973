import { useCallback, useContext, useEffect } from 'react';
import { useStore } from 'zustand';
import {
    FromAppPostMessageAction,
    ToAppPostMessageAction,
} from '@notacami/core/lesson';
import { LessonContext } from '../../../services/lesson';
import { ServicesContext } from '../../../services/services.context';

export function useLessonPostMessageEvent() {
    const { i18n } = useContext(ServicesContext);
    const store = useContext(LessonContext);
    const updateLessonInfo = useStore(store, (state) => state.updateLessonInfo);
    const updateLessonLanguage = useStore(
        store,
        (state) => state.updateLessonLanguage,
    );
    const updateCurrentStepId = useStore(
        store,
        (state) => state.updateCurrentStepId,
    );
    const currentStepId = useStore(store, (state) => state.currentStepId);

    const handleMessage = useCallback((event: MessageEvent) => {
        if (event.origin !== 'http://localhost:5174') {
            return;
        }
        const action = event.data as ToAppPostMessageAction;
        switch (action.type) {
            case 'UPDATE_LESSON_INFO':
                updateLessonInfo(action.lessonInfo);
                return;
            case 'UPDATE_LESSON_LANGUAGE':
                updateLessonLanguage(action.lessonLanguage);
                if (action.lessonLanguage !== null) {
                    i18n.updateLanguage(action.lessonLanguage);
                }
                return;
            case 'UPDATE_CURRENT_STEP_ID':
                updateCurrentStepId(action.currentStepId);
                return;
        }
    }, []);

    useEffect(() => {
        window.parent.postMessage(
            {
                type: 'CURRENT_STEP_ID_CHANGED',
                currentStepId,
            } satisfies FromAppPostMessageAction,
            'http://localhost:5174',
        );
    }, [currentStepId]);

    useEffect(() => {
        window.parent.postMessage(
            {
                type: 'APP_READY',
            } satisfies FromAppPostMessageAction,
            'http://localhost:5174',
        );

        window.addEventListener('message', handleMessage, false);

        return () => {
            window.removeEventListener('message', handleMessage, false);
        };
    }, [handleMessage]);
}
