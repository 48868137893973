import {
    FindScalePositionTurnResult,
    FindScalePositionTurnQuestion,
    FindScalePositionAnswer,
    FindScalePositionConfig,
} from '../../types';
import { computeIsCorrectAnswer } from './utils/compute-is-correct-anwser';

export function computeQuizTurnResultAction(
    _config: FindScalePositionConfig,
    turnQuestion: FindScalePositionTurnQuestion,
    userAnswer: FindScalePositionAnswer,
): FindScalePositionTurnResult {
    const isCorrect = computeIsCorrectAnswer(
        turnQuestion.correctAnswer,
        userAnswer,
    );

    return {
        meta: undefined,
        isCorrect,
        userAnswer,
    };
}
