import { useTranslation } from '../../../hooks/use-translation';
import { Duration } from '../duration';

type DurationFieldProps = {
    name: string;
    value: number;
    onChange: (value: number) => void;
    onBlur: () => void;
};

export function DurationField({
    name,
    value,
    onChange,
    onBlur,
}: DurationFieldProps) {
    const { t } = useTranslation();

    return (
        <div className="flex gap-4 justify-between items-center">
            <label htmlFor="duration">
                {t('fields.practice-duration.label')}
            </label>
            <Duration
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                name={name}
            />
        </div>
    );
}
