import { X } from 'lucide-react';
import { useStore } from 'zustand';
import { useContext } from 'react';
import { ActionButton } from '../../../../ui/action-button';
import { LessonContext } from '../../../../../services/lesson';

export function LeftActionSection() {
    const store = useContext(LessonContext);
    const askLeaveLesson = useStore(store, (state) => state.askLeaveLesson);

    return (
        <ActionButton onClick={askLeaveLesson}>
            <X />
        </ActionButton>
    );
}
