import { DeviceId } from './device.types';

export function getDeviceInfo(deviceId: DeviceId) {
    switch (deviceId) {
        case 'iphone55': {
            // expected screenshot export size 1242x2208
            const EXPECTED_WIDTH = 1242;
            const EXPECTED_HEIGHT = 2208;
            const DEVICE_SCALE_FACTOR = 3;

            return {
                screen: {
                    width: EXPECTED_WIDTH / DEVICE_SCALE_FACTOR,
                    height: EXPECTED_HEIGHT / DEVICE_SCALE_FACTOR,
                    pixelRatio: DEVICE_SCALE_FACTOR,
                    roundedCornerSizeInPx: 8,
                },
                device: {
                    offsetWidth: 16,
                    offsetHeight: 100,
                    roundedCornerSizeInPx: 52,
                },
                insets: {
                    bottom: 0,
                    top: 0,
                    left: 0,
                    right: 0,
                },
            };
        }
        case 'iphone67': {
            // expected screenshot export size 1290x2796
            const EXPECTED_WIDTH = 1290;
            const EXPECTED_HEIGHT = 2796;
            const DEVICE_SCALE_FACTOR = 3;

            return {
                screen: {
                    width: EXPECTED_WIDTH / DEVICE_SCALE_FACTOR,
                    height: EXPECTED_HEIGHT / DEVICE_SCALE_FACTOR,
                    pixelRatio: DEVICE_SCALE_FACTOR,
                    roundedCornerSizeInPx: 60,
                },
                device: {
                    offsetWidth: 12,
                    offsetHeight: 12,
                    roundedCornerSizeInPx: 74,
                },
                insets: {
                    bottom: 40,
                    top: 40,
                    left: 0,
                    right: 0,
                },
            };
        }
    }
}
