import { Position } from '@notacami/core/fretboard';

export function getFretboardMiddlePositionBetweenTwoPositions(
    position1: Position,
    position2: Position,
): Position {
    return [
        position1[0] - (position1[0] - position2[0]) / 2,
        position1[1] - (position1[1] - position2[1]) / 2,
    ];
}
