import { useEffect } from 'react';
import { useTabBarStore } from './tab-bar.store';

export function useHideTabBar() {
    const hide = useTabBarStore((state) => state.hide);

    useEffect(() => {
        hide();
    }, [hide]);
}

export function useShowTabBar() {
    const show = useTabBarStore((state) => state.show);

    useEffect(() => {
        show();
    }, [show]);
}
