import { ReactNode, forwardRef } from 'react';
import { motion } from 'framer-motion';
import { Surface } from '@notacami/ui';

type QuestionBubbleProps = {
    children: ReactNode;
    result: ReactNode;
};

export const QuestionBubble = forwardRef<HTMLDivElement, QuestionBubbleProps>(
    ({ children, result }, ref) => {
        return (
            <motion.div
                ref={ref}
                className="relative max-w-md"
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: -20, opacity: 0 }}
            >
                <Surface
                    surfaceId="darker"
                    className="relative px-4 py-2 rounded-xl z-10"
                >
                    {children}
                    <div className="absolute right-2 -bottom-3">{result}</div>
                </Surface>
            </motion.div>
        );
    },
);
QuestionBubble.displayName = 'QuestionBubble';
