import { cn } from '@notacami/core/css';
import { Surface } from './surface';

type DividerProps = {
    className?: string;
};

export function Divider({ className }: DividerProps) {
    return <Surface surfaceId="darker" className={cn('h-px', className)} />;
}
