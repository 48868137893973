import { Persona, PERSONAS } from '../services/persona';

function isExpectedPersonaFromUrl(
    maybePersona: string | null,
): maybePersona is Persona {
    return maybePersona !== null
        ? PERSONAS.includes(maybePersona as Persona)
        : false;
}

export function useGetInitialPersonaFromUrl() {
    // TODO: use usePathname and useSearchParams
    const pathname = document.location.pathname;
    const searchParams = new URLSearchParams(document.location.search);

    const personaInUrl = searchParams.get('persona');

    return isExpectedPersonaFromUrl(personaInUrl) && pathname === '/'
        ? personaInUrl
        : null;
}
