import type { AppLanguage } from './i18n.types';

export const LANGUAGES_DETAILS: Record<
    AppLanguage,
    { icon: string; right: AppLanguage; title: string }
> = {
    en: { right: 'en', icon: '🇺🇸', title: 'English' },
    fr: { right: 'fr', icon: '🇫🇷', title: 'Français' },
};

export const SUPPORTED_APP_LANGUAGES: AppLanguage[] = Object.values(
    LANGUAGES_DETAILS,
).map((language) => language.right);

export const DEFAULT_APP_LANGUAGE: AppLanguage = 'en';
