import { ReactNode } from 'react';
import { cn } from '@notacami/core/css';

type FormFieldContainerProps = {
    id: string;
    label: string;
    input: ReactNode;
    isColumn?: boolean;
};

export function FormFieldContainer({
    id,
    label,
    input,
    isColumn = false,
}: FormFieldContainerProps) {
    return (
        <div
            className={cn(
                'flex',
                isColumn && 'flex-col gap-2',
                !isColumn && 'gap-4 items-center justify-between',
            )}
        >
            <label htmlFor={id} className="leading-tight">
                {label}
            </label>
            <div>{input}</div>
        </div>
    );
}
