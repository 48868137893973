import {
    FC,
    ReactNode,
    useCallback,
    useEffect,
    useLayoutEffect,
    useReducer,
    useRef,
} from 'react';
import { useClickAway } from 'react-use';
import { createPortal } from 'react-dom';
import { cn } from '@notacami/core/css';
import { useHaptic } from '../../../hooks/use-haptic';
import { useLockScrollableParentElement } from '../../../hooks/use-lock-scrollable-parent-element';
import { useScrollToTopWithSpringAnimation } from '../../../hooks/use-scroll-to-top-with-spring-animation';
import { usePageLayoutStore } from '../../business/page-layout.store';
import { useTabBarStore } from '../tab-bar.store';
import { useSize } from '../../../hooks/use-size';
import { DEFAULT_STATE, reducer } from './expandable-card.reducer';
import { Content } from './content';
import { PUSH_MAX_HEIGHT } from './expandable-card.constants';

export type ExpandableCardTitleProps = {
    isDarkPicture: boolean;
    isOnPicture: boolean;
};

type ExpandableCardProps = {
    children: ReactNode;
    picture?: string;
    title: FC<ExpandableCardTitleProps>;
    isDark?: boolean;
    titleText: string;
    onlyShowTitleWhenClosed?: boolean;
};

function getContainerBaseClassnames(
    hasPicture: boolean,
    onlyShowTitleWhenClosed: boolean,
) {
    if (hasPicture) {
        return 'h-52';
    }
    if (!onlyShowTitleWhenClosed) {
        return 'max-h-52';
    }
}

export function ExpandableCard({
    children,
    picture,
    title,
    titleText,
    isDark = false,
    onlyShowTitleWhenClosed = false,
}: ExpandableCardProps) {
    const { impact } = useHaptic();
    const showTopAndBottomBar = usePageLayoutStore(
        (state) => state.showTopAndBottomBar,
    );
    const hideTopAndBottomBar = usePageLayoutStore(
        (state) => state.hideTopAndBottomBar,
    );
    const tabBarHidden = useTabBarStore((state) => state.hidden);
    const tabBarHiddenRef = useRef<boolean>();

    const hideTabBar = useTabBarStore((state) => state.hide);
    const showTabBar = useTabBarStore((state) => state.show);

    const containerRef = useRef<HTMLButtonElement>(null);
    const contentRef = useRef<HTMLDivElement>(null);
    const longTextRef = useRef<HTMLDivElement>(null);
    const pictureRef = useRef<HTMLImageElement>(null);
    const scrollingContentRef = useRef<HTMLDivElement>(null);
    const secondaryTitleContainerRef = useRef<HTMLDivElement>(null);
    const size = useSize(containerRef);

    const [state, dispatch] = useReducer(reducer, DEFAULT_STATE);
    const { unlock, lock } = useLockScrollableParentElement(containerRef);
    const { scrollToTop } =
        useScrollToTopWithSpringAnimation(scrollingContentRef);

    const handleClose = () => {
        dispatch({ type: 'CLOSE' });
        showTopAndBottomBar();
        impact();
        if (!tabBarHiddenRef.current) {
            showTabBar();
        }
    };

    const handleClickAway = () => {
        if (state.isOpenCompleted) {
            handleClose();
        }
    };

    useClickAway(contentRef, handleClickAway);

    const maxPushHeight =
        onlyShowTitleWhenClosed && secondaryTitleContainerRef.current
            ? secondaryTitleContainerRef.current.scrollHeight
            : PUSH_MAX_HEIGHT;

    const handleResize = useCallback(() => {
        if (containerRef.current === null || contentRef.current === null) {
            return;
        }

        dispatch({
            type: 'INIT',
            height:
                (picture !== undefined ? PUSH_MAX_HEIGHT - 32 : 0) +
                (longTextRef?.current?.scrollHeight ?? 0) +
                (picture !== undefined
                    ? 0
                    : secondaryTitleContainerRef?.current?.scrollHeight ?? 0) -
                16,
            pushHeight: Math.min(
                maxPushHeight,
                contentRef.current.scrollHeight,
            ),
            pushWidth: containerRef.current.clientWidth,
        });
    }, [contentRef, containerRef, maxPushHeight]);

    useLayoutEffect(() => {
        if (!state.isInitialized) {
            handleResize();
        }
    }, [handleResize, state]);

    useLayoutEffect(() => {
        if (
            size?.width !== undefined &&
            containerRef.current !== null &&
            contentRef.current !== null
        ) {
            const rect = containerRef.current.getBoundingClientRect();
            dispatch({
                type: 'RESIZE',
                pushWidth: size.width,
                pushHeight: Math.min(
                    maxPushHeight,
                    contentRef.current.scrollHeight,
                ),
                x: rect.x,
                y: rect.y,
            });
        }
    }, [size]);

    const handleAnimationStart = (variant: string) => {
        if (variant === 'normal') {
            scrollToTop();
        }
    };

    const handleAnimationComplete = (variant: string) => {
        if (variant === 'expand' && state.open) {
            dispatch({ type: 'OPEN_COMPLETED' });
        }
        if (variant === 'normal' && !state.open) {
            unlock();
            dispatch({ type: 'CLOSE_COMPLETED' });
        }
    };

    const handlePictureLoad = useCallback(() => {
        if (!state.isPictureLoaded) {
            dispatch({ type: 'PICTURE_LOADED' });
        }
    }, [state.isPictureLoaded]);

    useEffect(() => {
        function checkPictureLoaded() {
            if (pictureRef?.current?.complete) {
                handlePictureLoad();
            }
        }
        checkPictureLoaded();
    }, [handlePictureLoad]);

    const isExpandable =
        state.pushHeight < state.height || picture !== undefined;

    const handleTitleClick = () => {
        if (!isExpandable || !containerRef.current) {
            return;
        }
        if (!state.open && !state.isTransitioning) {
            const rect = containerRef.current.getBoundingClientRect();
            hideTopAndBottomBar();
            dispatch({
                type: 'OPEN',
                y: rect.y,
                x: rect.x,
            });
            tabBarHiddenRef.current = tabBarHidden;
            if (!tabBarHiddenRef.current) {
                hideTabBar();
            }
            impact();
            lock();
            return;
        }
        if (state.open) {
            handleClose();
        }
    };

    return (
        <button
            ref={containerRef}
            onClick={
                !state.open && !state.isTransitioning
                    ? handleTitleClick
                    : undefined
            }
            className={cn(
                'block relative text-left',
                getContainerBaseClassnames(
                    picture !== undefined,
                    onlyShowTitleWhenClosed,
                ),
                state.open && 'z-10',
            )}
            style={{
                height: state.pushHeight,
            }}
            type="button"
            title={titleText}
        >
            {!state.open && !state.isTransitioning ? (
                <Content
                    contentRef={contentRef}
                    isDarkPicture={isDark}
                    isExpandable={isExpandable}
                    isRenderInPortal={false}
                    longTextRef={longTextRef}
                    onAnimationComplete={handleAnimationComplete}
                    onAnimationStart={handleAnimationStart}
                    onTitleClick={handleTitleClick}
                    onPictureLoad={handlePictureLoad}
                    picture={picture}
                    pictureLoaded={state.isPictureLoaded}
                    pictureRef={pictureRef}
                    scrollingContentRef={scrollingContentRef}
                    secondaryTitleContainerRef={secondaryTitleContainerRef}
                    state={state}
                    title={title}
                >
                    {children}
                </Content>
            ) : (
                createPortal(
                    <Content
                        contentRef={contentRef}
                        isDarkPicture={isDark}
                        isExpandable={isExpandable}
                        isRenderInPortal
                        longTextRef={longTextRef}
                        onAnimationComplete={handleAnimationComplete}
                        onAnimationStart={handleAnimationStart}
                        onTitleClick={handleTitleClick}
                        onPictureLoad={handlePictureLoad}
                        picture={picture}
                        pictureLoaded={state.isPictureLoaded}
                        pictureRef={pictureRef}
                        scrollingContentRef={scrollingContentRef}
                        secondaryTitleContainerRef={secondaryTitleContainerRef}
                        state={state}
                        title={title}
                    >
                        {children}
                    </Content>,
                    document.body,
                )
            )}
        </button>
    );
}
