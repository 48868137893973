import { useStore } from 'zustand';
import { AnimatePresence } from 'framer-motion';
import { useContext } from 'react';
import { MicTutorial } from '../../../mic-tutorial';
import { MicTutorialStepper } from '../../../mic-tutorial/mic-tutorial-stepper';
import { useTranslation } from '../../../../../hooks/use-translation';
import { LessonLayout } from '../../../lesson-layout';
import { LessonContext } from '../../../../../services/lesson';
import { LeftActionSection } from './left-action-section';

export function LessonMicOnboarding() {
    const { t } = useTranslation();
    const store = useContext(LessonContext);

    const mainStep = useStore(store, (state) => state.mainStep);
    const mainThematic = useStore(
        store,
        (state) => state.derivedLessonInfo?.mainThematic,
    );
    const isInMicOnboarding = useStore(
        store,
        (state) => state.isInMicOnboarding,
    );
    const goToIntroduction = useStore(store, (state) => state.goToIntroduction);
    const leaveMicOnboarding = useStore(
        store,
        (state) => state.leaveMicOnboarding,
    );

    const isInMicOnboardingStep =
        mainStep === 'mic-onboarding' ||
        (mainStep === 'end-confirmation' && isInMicOnboarding);

    return (
        <AnimatePresence>
            {isInMicOnboardingStep && mainThematic ? (
                <LessonLayout
                    resizeWhenDrawerOpened
                    topCenter={<MicTutorialStepper thematic={mainThematic} />}
                    center={
                        <MicTutorial
                            shouldExplainForPermission
                            thematic={mainThematic}
                            onTutorialAbort={goToIntroduction}
                            onTutorialComplete={leaveMicOnboarding}
                            getReadyStepDescription={t(
                                'tutorial.mic-tutorial.get-ready-step.find-the-note-on-the-neck-playing-description',
                            )}
                            getReadyStepButtonLabel={t('button.lets-go')}
                        />
                    }
                    topLeftAction={<LeftActionSection />}
                />
            ) : null}
        </AnimatePresence>
    );
}
