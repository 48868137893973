import { NoteName } from './notes.types';

export const UNALTERED_NOTES: NoteName[] = ['C', 'D', 'E', 'F', 'G', 'A', 'B'];

export const UNALTERED_NOTES_LATIN: string[] = [
    'Do',
    'Re',
    'Mi',
    'Fa',
    'Sol',
    'La',
    'Si',
];

export const UNALTERED_NOTES_SEMITONES: Record<NoteName, number> = {
    C: 0,
    D: 2,
    E: 4,
    F: 5,
    G: 7,
    A: 9,
    B: 11,
};

export const NOTES_WITH_ALTERED_NOTES: NoteName[] = [
    'Cb',
    'C',
    'C#',
    'Db',
    'D',
    'D#',
    'Eb',
    'E',
    'E#',
    'Fb',
    'F',
    'F#',
    'Gb',
    'G',
    'G#',
    'Ab',
    'A',
    'A#',
    'Bb',
    'B',
    'B#',
];

export const ALTERED_NOTES: NoteName[] = [
    'Cb',
    'C#',
    'Db',
    'D#',
    'Eb',
    'E#',
    'Fb',
    'F#',
    'Gb',
    'G#',
    'Ab',
    'A#',
    'Bb',
    'B#',
];

export const DEFAULT_NOTES_POSITION_FOR_SCALE: NoteName[] = [
    'C',
    'C#',
    'D',
    'Eb',
    'E',
    'F',
    'F#',
    'G',
    'Ab',
    'A',
    'Bb',
    'B',
];
