import { Play, Square } from 'lucide-react';
import { ActionButton } from './action-button';

type PlayButtonProps = {
    onClick: () => void;
    isPlaying: boolean;
};

export function PlayButton({ onClick, isPlaying }: PlayButtonProps) {
    return (
        <ActionButton onClick={onClick}>
            {isPlaying ? <Square /> : <Play />}
        </ActionButton>
    );
}
