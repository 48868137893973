import { useContext } from 'react';
import { FindTheNoteOnTheNeckExerciseContext } from '../../store/context';
import { usePlayingState } from '../../../../../services/exercise/hooks/use-playing-state';
import { useFindTheNoteOnTheNeckStore } from '../../store/hook';
import { useQuestion } from '../../../../../services/exercise/hooks/use-question';
import { AnswerSectionPlay } from './answer-section-play';
import { AnswerSectionResult } from './answer-section-result';

export function AnswerSection() {
    const store = useContext(FindTheNoteOnTheNeckExerciseContext);

    const { isInPlayingPlayState, isInPlayingResultState } =
        usePlayingState(store);

    const addError = useFindTheNoteOnTheNeckStore((state) => state.addError);
    const config = useFindTheNoteOnTheNeckStore((state) => state.config);
    const quizMode = useFindTheNoteOnTheNeckStore((state) => state.quizMode);
    const submitAnswer = useFindTheNoteOnTheNeckStore(
        (state) => state.submitAnswer,
    );
    const question = useQuestion(store);

    return isInPlayingPlayState || isInPlayingResultState ? (
        <div className="py-5 flex flex-col justify-center items-center min-h-full">
            {isInPlayingPlayState ? (
                <AnswerSectionPlay
                    addError={addError}
                    config={config}
                    quizMode={quizMode}
                    submitAnswer={submitAnswer}
                />
            ) : null}
            {isInPlayingResultState ? (
                <AnswerSectionResult
                    config={config}
                    quizMode={quizMode}
                    correctAnswerPosition={question.correctAnswer}
                />
            ) : null}
        </div>
    ) : null;
}
