import { ReactNode, forwardRef } from 'react';
import { MotionSurface } from '@notacami/ui';
import { useSafeAreaInsets } from '../../hooks/use-safe-area-insets';
import { ConditionalWrapper } from '../../../../../libs/core/src/components/conditional-wrapper';

type LessonLayoutProps = {
    bottomCenterAction?: ReactNode;
    bottomLeftAction?: ReactNode;
    bottomRightAction?: ReactNode;
    center: ReactNode;
    resizeWhenDrawerOpened?: boolean;
    topCenter?: ReactNode;
    topLeftAction?: ReactNode;
    topRightAction?: ReactNode;
};

export const LessonLayout = forwardRef<HTMLDivElement, LessonLayoutProps>(
    (
        {
            bottomCenterAction,
            bottomLeftAction,
            bottomRightAction,
            center,
            resizeWhenDrawerOpened = false,
            topCenter,
            topLeftAction,
            topRightAction,
        },
        ref,
    ) => {
        const { bottom, left, right, top } = useSafeAreaInsets();
        return (
            <ConditionalWrapper
                items={[
                    {
                        condition: !resizeWhenDrawerOpened,
                        wrapper: (children, index) => (
                            <div
                                data-testid="lesson-layout"
                                key={index}
                                ref={ref}
                                className="fixed p-2 z-50"
                                style={{ bottom, left, right, top }}
                            >
                                {children}
                            </div>
                        ),
                    },
                    {
                        condition: resizeWhenDrawerOpened,
                        wrapper: (children, index) => (
                            <div
                                data-testid="lesson-layout"
                                key={index}
                                ref={ref}
                                className="fixed p-2 z-50"
                                vaul-drawer-wrapper=""
                                style={{ bottom, left, right, top }}
                            >
                                {children}
                            </div>
                        ),
                    },
                ]}
            >
                <MotionSurface
                    surfaceId="light"
                    initial={{ y: 5, opacity: 0 }}
                    animate={{
                        y: 0,
                        opacity: 1,
                    }}
                    exit={{
                        y: 5,
                        opacity: 0,
                    }}
                    className="relative h-full rounded-3xl overflow-hidden"
                >
                    <div className="absolute inset-0 overflow-y-scroll">
                        <div className="min-h-full py-14 flex justify-center items-center">
                            {center}
                        </div>
                    </div>
                    <div className="absolute inset-x-3 top-3 flex gap-4 justify-between">
                        <div className="w-24 flex justify-start">
                            {topLeftAction}
                        </div>
                        <div className="flex grow justify-center items-center">
                            {topCenter}
                        </div>
                        <div className="w-24 flex justify-end gap-2">
                            {topRightAction}
                        </div>
                    </div>

                    <div className="absolute inset-x-3 bottom-3 flex gap-4 justify-between">
                        <div className="w-24 flex justify-start">
                            {bottomLeftAction}
                        </div>
                        <div className="flex grow justify-center items-center">
                            {bottomCenterAction}
                        </div>
                        <div className="w-24 flex justify-end gap-2">
                            {bottomRightAction}
                        </div>
                    </div>
                </MotionSurface>
            </ConditionalWrapper>
        );
    },
);
