import { cn } from '@notacami/core/css';

type TunerHeadCentsOffIndicatorProps = {
    symbol: string;
    variant: 'green' | 'red' | 'idle';
};

function getIndicatorClassNames(variant: 'green' | 'red' | 'idle') {
    switch (variant) {
        case 'green':
            return 'bg-green-500 border-green-300 shadow-green-400';
        case 'red':
            return 'bg-red-500 border-red-300 shadow-red-400';
        case 'idle':
        default:
            return 'bg-neutral-700 border-neutral-300 shadow-white';
    }
}

export function TunerHeadCentsOffIndicator({
    symbol,
    variant,
}: TunerHeadCentsOffIndicatorProps) {
    return (
        <div className="flex flex-col items-center ">
            <div className="text-neutral-600 text-lg">{symbol}</div>
            <div
                className={cn(
                    'transition-all w-10 h-2 rounded-full mb-4 border shadow-md',
                    getIndicatorClassNames(variant),
                )}
            ></div>
        </div>
    );
}
